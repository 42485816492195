<template>
  <!-- cabecera -->
  <header-component @openTracking="autentification.estateTracking = $event" />
  <!-- tracking component -->
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />

  <section class="min-h-screen flex bg-gray-light gap-10">
    <!-- Nav del user -->
    <nav-component
      @cambio="formSelect = $event"
      :formSelect="formSelect"
      :img="data.img"
      :id="data.id"
      :dni="data.validateDni"
    />
    <!-- Formulario para editar usuario -->
    <edit-component
      v-if="formSelect === 'perfil'"
      :id="data.id"
      :identificador="data.identificador"
      :img="data.img"
    />
    <!-- Vista de reviews -->
    <reviews-component
      v-if="formSelect === 'reviews'"
      :id="data.id"
      :identificador="data.identificador"
      :img="data.img"
    />
    <!-- Eliminar cuenta  -->
    <delete-component v-if="formSelect === 'delete'" :id="data.id" />
    <!-- Formulario para editar credenciales -->
    <credential-component
      v-if="formSelect === 'credentials'"
      @cambio="formSelect = $event"
      :id="data.id"
      :dni="data.validateDni"
      :name="data.name"
      :correo="data.email"
      :telefono="data.telefono"
      :type="data.type"
      :identificador="data.identificador"
      :verifytelefono="verify.telefono"
      :verify="verify"
      :numberDni="data.dni"
    />

    <!-- Tabla de los pedidos -->
    <pedido-component v-if="formSelect === 'pedidos'" :id="data.id" />
    <!--Verificar cuenta con DNI -->
    <verification-component
      @changeSection="formSelect = $event"
      v-if="formSelect === 'verification'"
      :id="data.id"
    />

    <direcciones-component
      v-if="formSelect === 'direcciones'"
      :dataUser="data"
    />
    <cupones-component
      v-if="formSelect === 'cupones'"
      :dni="data.validateDni"
    />
    <cotizacion-component v-if="formSelect === 'cotizaciones'" :id="data.id" />
    <balance-component v-if="formSelect === 'balance'" :id="data.id" />
    <recomendados-component
      v-if="formSelect === 'recomendados'"
      :id="data.id"
    />

    <viajes-component v-if="formSelect === 'viajes'" :id="data.id" />
    <atrapar-component v-if="formSelect === 'atraparPedidos'" :id="data.id" />
  </section>
  <footer-component />
</template>
<script>
//Importando serve
import { server } from "../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";

//Componentes
import headerComponent from "../components/header/headerComponent.vue";
import navComponent from "../components/usuario/navComponent.vue";
import editComponent from "../components/usuario/editCompoent.vue";
import credentialComponent from "../components/usuario/credentialComponent.vue";
import pedidoComponent from "../components/usuario/pedidoComponent.vue";
import verificationComponent from "../components/usuario/verificationComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
import direccionesComponent from "../components/usuario/direccionesComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import cuponesComponent from "../components/usuario/cuponesComponent.vue";
import CuponesComponent from "../components/usuario/cuponesComponent.vue";
import cotizacionComponent from "../components/usuario/cotizacionComponent.vue";
import CotizacionComponent from "../components/usuario/cotizacionComponent.vue";
import balanceComponent from "../components/usuario/balanceComponent.vue";
import BalanceComponent from "../components/usuario/balanceComponent.vue";
import recomendadosComponent from "../components/usuario/recomendadosComponent.vue";
import viajesComponent from "../components/usuario/viajesComponent.vue";
import atraparComponent from "../components/usuario/atraparPedidos.vue";
import reviewsComponent from "../components/usuario/reviews.vue";
import deleteComponent from "../components/usuario/delete.vue";

export default {
  components: {
    headerComponent,
    navComponent,
    editComponent,
    credentialComponent,
    pedidoComponent,
    verificationComponent,
    trackingComponent,
    direccionesComponent,
    footerComponent,
    cuponesComponent,
    CuponesComponent,
    cotizacionComponent,
    CotizacionComponent,
    balanceComponent,
    BalanceComponent,
    recomendadosComponent,
    viajesComponent,
    atraparComponent,
    reviewsComponent,
    deleteComponent,
  },
  data() {
    return {
      formSelect: this.$route.params.type,
      data: {
        name: "",
        lastname: "",
        img: "",
        id: 0,
        email: "",
        telefono: "",
        validateDni: false,
        identificador: "",
        type: 0,
        documento: "",
        dni: "",
      },
      autentification: { estateTracking: false },
      verify: {
        dni: null,
        email: null,
        telefono: null,
      },
    };
  },
  created() {
    this.getDataLocalStorage();
  },
  mounted() {
    this.scrollToTop();
    document.body.classList.remove("overflow-y-hidden");
    window.localStorage.removeItem("open");
    window.localStorage.removeItem("producto");
  },
  methods: {
    //obteniendo los datos del localstorage para la imagen y datos del usuario
    getDataLocalStorage() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        this.data.id = dataUser.globalID;
        this.getUser();
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Error";
        this.$store.state.alert.description =
          "Vuelva a iniciar sesión por favor";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.$router.push({ name: "Home" });
        window.localStorage.removeItem("auth");
      }
    },
    getUser() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.data.id)
        .then((response) => {
          if (response.status === 200) {
            // var dataUser = response.body.data.attributes;
            var dataUser = response.data.body.data.attributes;

            this.data.dni = dataUser.profile.attributes.document_number;
            this.data.img =
              dataUser.profile.attributes.userable.attributes.profile_image_url;
            this.data.name =
              dataUser.profile.attributes.name === null
                ? ""
                : dataUser.profile.attributes.name;
            this.data.validateDni = dataUser.validation.verified;
            this.data.email =
              dataUser.profile.attributes.userable.attributes.email;

            if (dataUser.profile.attributes.cellphone) {
              const separed =
                dataUser.profile.attributes.cellphone.split("+51");
              const options = {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                minimumIntegerDigits: 9,
              };
              const formattedNumber = parseInt(separed[1]).toLocaleString(
                "es-ES",
                options
              ); // "123 456 789"
              this.data.telefono = formattedNumber.replace(/\./g, " ");
            } else {
              this.data.telefono = dataUser.profile.attributes.cellphone;
            }

            this.data.identificador = response.data.body.data.client_code;
            this.data.lastname =
              dataUser.profile.attributes.surnames === null
                ? ""
                : dataUser.profile.attributes.surnames;
            if (dataUser.profile.attributes.userable.attributes.facebook_id) {
              this.data.type = 3;
            } else if (
              dataUser.profile.attributes.userable.attributes.gmail_id
            ) {
              this.data.type = 2;
            } else {
              this.data.type = 1;
            }
            this.verify.dni = dataUser.validation.verified;
            this.verify.email = dataUser.email_verified;
            this.verify.telefono =
              dataUser.profile.attributes.cellphone_verified;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al obtener sus datos";
            this.$store.state.alert.estado = true;
            //--------------------------//
            console.log("ERROR EN USER: ", error);
          }
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    "$route.params.type": function (val) {
      this.formSelect = val;
    },
  },
};
</script>
