<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <form class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">Reviews</h3>
      <router-link
        :to="{ name: 'User', params: { type: 'perfil' } }"
        class="flex items-center gap-3 mb-6 text-sm xl:text-base font-semibold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: #0b132a; transform: ; msfilter: "
        >
          <path
            d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
          ></path>
        </svg>
        Volver
      </router-link>

      <div
        class="xl:w-2/3 grid grid-cols-3 gap-3 xl:gap-4 text-xs xl:text-base mb-10"
      >
        <!-- font-semibold ring-4 ring-purple-300 -->
        <button
          @click="changeFilter('Todos')"
          type="button"
          :class="
            filter == 'Todos' &&
            'font-semibold ring-4 ring-purple-300 text-purple-700'
          "
          class="bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-300 rounded-lg px-5 py-4"
        >
          Todos
        </button>
        <button
          @click="changeFilter('Viajando')"
          type="button"
          :class="
            filter == 'Viajando' &&
            'font-semibold ring-4 ring-purple-300 text-purple-700'
          "
          class="bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-300 rounded-lg px-5 py-4"
        >
          Viajando
        </button>
        <button
          @click="changeFilter('Comprando')"
          type="button"
          :class="
            filter == 'Comprando' &&
            'font-semibold ring-4 ring-purple-300 text-purple-700'
          "
          class="bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-purple-300 rounded-lg px-5 py-4"
        >
          Comprando
        </button>
      </div>

      <div v-if="!loading" class="grid xl:grid-cols-2 gap-6 xl:gap-8 mb-8">
        <article
          v-for="review in reviews"
          :key="review.id"
          class="border-2 shadow-sm hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 transition-colors delay-400 cursor-pointer"
        >
          <header class="flex gap-5 items-center mb-3">
            <img
              v-if="review.client.profile_image_url"
              :src="review.client.profile_image_url"
              class="block w-12 h-12 rounded-full object-cover"
            />
            <img
              v-else
              src="../../assets/icons/user-login.svg"
              class="block w-12 h-12 rounded-full object-cover"
            />
            <div>
              <span class="block text-lg font-medium">{{
                review.client.profile.name
              }}</span>
              <span class="block text-sm text-gray-400 -mt-1">{{
                review.client.email
              }}</span>
            </div>
          </header>
          <p class="text-sm text-gray-400 line-clamp-1 mb-4">
            {{ review.purchaseorder.product_info }}
          </p>
          <p class="font-medium text-black text-sm xl:text-base mb-6">
            {{ review.review }}
          </p>
          <footer class="flex gap-5 items-center text-sm text-gray-400">
            <p>{{ convertDate(review.created_at) }}</p>
            <div class="ml-auto flex gap-3 items-center">
              <template v-for="i in 5" :key="i">
                <img
                  v-if="i <= review.score"
                  src="../../assets/icons/icon-star.png"
                />
                <img
                  v-else
                  src="../../assets/icons/icon-star-outline.png"
                  class="opacity-50"
                />
              </template>
            </div>
          </footer>
        </article>
      </div>
      <!-- Loader -->
      <p v-if="loading" class="font-medium text-center pb-8 pt-14">
        Cargando reviews...
      </p>
      <!-- Pagination -->
      <div class="mt-12 flex justify-end items-center gap-12">
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{ page }}</span> de
          {{ lastPage }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="page !== 1"
            @click.prevent="prevPage"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-left-bold.svg" />
          </figure>
          <figure
            v-if="page !== lastPage"
            @click.prevent="nextPage"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-right-bold.svg" />
          </figure>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["id", "identificador", "img"],
  data() {
    return {
      data: {
        idAdress: "",
        state: false,
        name: "",
        apellido: "",
        documento: "",
        telefono: "",
        emailContact: "",
        direccion: "",
        piso: "",
        referencia: "",
        departamento: "",
        provincia: "",
        distrito: "",
        lat: 0,
        lng: 0,
        qualifications: {},
        travel_squad: 0,
      },
      error: {
        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
        documento: false,
        telefono: false,
        emailContact: false,
        direccion: false,
        lat: false,
        lng: false,
      },
      imgData: "",
      imagePreview: "",
      loading: true,
      reviews: [],
      totalReviews: null,
      limit: 4,
      filter: "Todos",
      // offset: 0,
      page: 1,
    };
  },
  created() {
    this.getUser();
    this.getReviews();
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    //Obteniendo datos del usuario
    getUser() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.id)
        .then((response) => {
          if (response.status === 200) {
            var dataUser = response.data.body.data;
            // Travel Squad
            this.data.travel_squad = dataUser.attributes.travel_squad;
            // Calificaciones
            this.data.qualifications =
              dataUser.attributes.qualification_summary;
            //Nombre
            this.data.name =
              dataUser.attributes.profile.attributes.name === null
                ? ""
                : dataUser.attributes.profile.attributes.name;
            //apellido
            this.data.apellido =
              dataUser.attributes.profile.attributes.surnames === null
                ? ""
                : dataUser.attributes.profile.attributes.surnames;
            //Documento
            this.data.documento =
              dataUser.attributes.profile.attributes.document_number === null
                ? ""
                : dataUser.attributes.profile.attributes.document_number;
            //telefono
            this.data.telefono =
              dataUser.attributes.profile.attributes.cellphone === null
                ? ""
                : dataUser.attributes.profile.attributes.cellphone;
            // correo de contacto
            this.data.emailContact =
              dataUser.attributes.profile.attributes.userable.attributes
                .biography === null
                ? dataUser.attributes.profile.attributes.userable.attributes
                    .email
                : dataUser.attributes.profile.attributes.userable.attributes
                    .biography;
            //Datos de la dirección y valida si el campo de addres es cero se les coloca string vacio
            if (dataUser.attributes.addresses.length !== 0) {
              this.data.state = true;
              //direccion
              this.data.direccion =
                dataUser.attributes.addresses[0].attributes.address === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.address;
              //piso
              this.data.piso =
                dataUser.attributes.addresses[0].attributes.inner === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.inner;
              //referencia
              this.data.referencia =
                dataUser.attributes.addresses[0].attributes.reference === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.reference;
              //departamento
              this.data.departamento =
                dataUser.attributes.addresses[0].attributes.department === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.department;
              //provincia
              this.data.provincia =
                dataUser.attributes.addresses[0].attributes.city === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.city;
              //distrito
              this.data.distrito =
                dataUser.attributes.addresses[0].attributes.district === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.district;
              //latitud
              this.data.lat =
                dataUser.attributes.addresses[0].attributes.lat === null
                  ? 0
                  : dataUser.attributes.addresses[0].attributes.lat;
              //longitud
              this.data.lng =
                dataUser.attributes.addresses[0].attributes.lng === null
                  ? 0
                  : dataUser.attributes.addresses[0].attributes.lng;
              this.data.idAdress = dataUser.attributes.addresses[0].id;
            } else {
              this.data.direccion = "";
              this.data.piso = "";
              this.data.referencia = "";
              this.data.departamento = "";
              this.data.provincia = "";
              this.data.distrito = "";
              this.data.lat = 0;
              this.data.lng = 0;
              this.data.state = false;
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al mostrar sus datos";
            this.$store.state.alert.estado = true;
            console.log("ERROR EN PERFIL: ", error);
            //--------------------------//
          }
        });
    },
    getReviews() {
      this.loading = true;
      axios
        .get(`${BASE_URL}qualification/${this.id}/listQualification`, {
          params: {
            limit: this.limit,
            offset: this.page,
            filter: this.filter,
          },
        })
        .then((res) => {
          this.reviews = res.data.body.reviews;
          this.lastPage = Math.ceil(res.data.body.totalReviews / this.limit);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prevPage() {
      this.page = this.page - 1;
      this.getReviews();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getReviews();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    // Convertir fechas a formato legible
    convertDate(dateString) {
      const months = {
        1: "enero",
        2: "febrero",
        3: "marzo",
        4: "abril",
        5: "mayo",
        6: "junio",
        7: "julio",
        8: "agosto",
        9: "septiembre",
        10: "octubre",
        11: "noviembre",
        12: "diciembre",
      };
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Get the day of the month
      const day = date.getDate();

      // Get the month
      const month = date.getMonth() + 1;

      // Get the year
      const year = date.getFullYear();

      // Format the date
      const formattedDate = `${day} de ${months[month]} del ${year}`;

      // Return the formatted date
      return formattedDate;
    },
    // Cambiar filtro de reviews
    changeFilter(filter) {
      this.filter = filter;
      this.getReviews();
    },
  },
};
</script>
