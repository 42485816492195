<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none"
  >
    <div
      v-if="saldoTotalStatus"
      class="xl:flex items-center justify-between mb-10"
    >
      <h3 class="text-text-blue font-semibold xl:text-lg mb-6 xl:mb-0">
        Pagar con:
      </h3>
      <div
        class="p-1 rounded-2xl bg-gray-100 bg-opacity-50 shadow-sm grid grid-cols-2"
      >
        <button
          class="font-semibold text-gray-400 py-5 xl:px-12 bg-main-orange bg-opacity-50 rounded-2xl text-xs xl:text-base cursor-not-allowed"
        >
          PEN <span class="text-white">(s/.)</span>
        </button>
        <button
          class="font-semibold text-gray-400 py-5 xl:px-12 bg-gray-100 bg-opacity-50 rounded-2xl text-xs xl:text-base cursor-not-allowed"
        >
          Dólares <span class="text-gray-400">($)</span>
        </button>
      </div>
    </div>
    <div v-else class="xl:flex items-center justify-between mb-10">
      <h3 class="text-text-blue font-semibold xl:text-lg mb-6 xl:mb-0">
        Pagar con:
      </h3>
      <div
        class="p-1 rounded-2xl bg-gray-100 bg-opacity-50 shadow-sm grid grid-cols-2"
      >
        <button
          @click.prevent="changeSymbol('PEN')"
          :class="
            currencySymbol === 'PEN' ? 'bg-main-orange text-textColor' : ''
          "
          class="font-semibold py-5 xl:px-12 rounded-2xl text-xs xl:text-base transition-colors"
        >
          PEN
          <span
            :class="currencySymbol === 'PEN' && 'text-white'"
            class="transition-colors"
            >(s/.)</span
          >
        </button>
        <button
          @click.prevent="changeSymbol('USD')"
          :class="currencySymbol === 'USD' && 'bg-main-orange text-textColor'"
          class="font-semibold py-5 xl:px-12 rounded-2xl text-xs xl:text-base transition-colors"
        >
          Dólares
          <span
            :class="currencySymbol === 'USD' && 'text-white'"
            class="transition-colors"
            >($)</span
          >
        </button>
      </div>
    </div>

    <!-- Pagar con tarjeta -->
    <span v-if="currencySymbol === 'PEN'" v-on:click="typePayment">
      <tarjeta-component
        @paymentSelect="paymenSelect = $event"
        :dataCifImpuesto="dataCifImpuesto"
        :dataTarifa="dataTarifa"
        :typeDirection="typeDirection"
        :dataDirection="dataDirection"
        :typeService="typeService"
        :currencySymbol="currencySymbol"
        :paymenSelect="paymenSelect"
        @next="processMp = $event"
        @purchaseData="purchaseDataMp = $event"
        @paymentMercadoPago="sendData"
        @ResetFormTarjeta="resetTarjeta"
        :terminos="terminos"
      />
    </span>

    <!--ATM -->
    <span v-show="currencySymbol === 'PEN'">
      <atm-component
        @click.prevent="typePayment"
        :paymenSelect="paymenSelect"
        @paymentSelect="paymenSelect = $event"
      />
    </span>
    <!-- Paypal -->
    <span v-show="currencySymbol === 'USD'">
      <paypal-component
        @click.prevent="typePayment"
        :paymenSelect="paymenSelect"
        @paymentSelect="paymenSelect = $event"
        :terminos="terminos"
      />
    </span>
    <!-- Transferencia bancaria -->
    <span v-show="currencySymbol === 'USD' || currencySymbol === 'PEN'">
      <transferencia-component
        @click.prevent="typePayment"
        :paymenSelect="paymenSelect"
        :currencySymbol="currencySymbol"
        @paymentSelect="paymenSelect = $event"
      />
    </span>
  </div>
</template>
<script>
import transferenciaComponent from "../payment/transferenciaComponent.vue";
import paypalComponent from "../payment/paypalComponent.vue";
import tarjetaComponent from "../payment/tarjetaComponent.vue";
import atmComponent from "../payment/atmComponent.vue";
export default {
  props: [
    "currencySymbol",
    "dataCifImpuesto",
    "dataTarifa",
    "typeDirection",
    "dataDirection",
    "typeService",
    "terminos",
  ],
  components: {
    transferenciaComponent,
    paypalComponent,
    tarjetaComponent,
    atmComponent,
  },
  data() {
    return {
      processMp: 0,
      purchaseDataMp: {},
      popUp: false,
      paymenSelect: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    resetTarjeta() {
      this.paymenSelect = 0;
      this.$emit("changeSymboloMoney", "USD");
    },
    //metodo que envia los datos cuando se pago con mercado pago
    sendData() {
      this.$emit("next", this.processMp);
      this.$emit("purchaseData", this.purchaseDataMp);
    },
    // 1 -> pago con tarjeta
    // 2 ->paypal
    // 3 ->transferencia bancaria

    //Manda el tipo de metodo de pago que selecciono el usuario
    typePayment() {
      this.$emit("dataPayment", this.paymenSelect);
    },
    //metodo para filtro segun el tipo de moneda con la cual va pagar el usuario
    changeSymbol(data) {
      this.$emit("currencySymbol", data);
    },
    //Este metodo es para pago con tarjeta
    openPopUp() {
      this.popUp = !this.popUp;
    },
  },
  computed: {
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
};
</script>
