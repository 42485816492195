<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <form class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <div class="flex items-center justify-between mb-10">
        <h3 class="text-xl xl:text-3xl font-bold text-main-red">Mi Perfil</h3>
        <router-link
          class="py-4 px-6 bg-main-red text-white font-semibold rounded-xl text-sm flex items-center gap-3"
          :to="{ name: 'User', params: { type: 'delete' } }"
        >
          <img src="../../assets/icons/eliminar.png" alt="Editar..." />
          <span class="hidden xl:block">Eliminar cuenta</span>
        </router-link>
      </div>
      <h3 class="font-bold text-text-blue text-sm xl:text-lg mb-8">
        Información del cliente
      </h3>
      <div class="grid xl:grid-cols-2 gap-8 mb-14">
        <div class="grid gap-8 order-2 xl:order-1">
          <!-- Nombres -->
          <div>
            <label
              for="nombres"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Nombres:</label
            >
            <input
              :class="error.name ? 'ring ring-main-red' : ''"
              v-model="data.name"
              id="nombres"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Nombres"
            />
            <span
              class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
              v-if="error.name"
              >*Ingrese su nombre</span
            >
          </div>
          <!-- Apellidos -->
          <div>
            <label
              for="apellidos"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Apellidos:</label
            >
            <input
              :class="error.apellido ? 'ring ring-main-red' : ''"
              v-model="data.apellido"
              id="apellidos"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Apellidos"
            />
            <span
              class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
              v-if="error.apellido"
              >*Ingrese su apellido</span
            >
          </div>
        </div>
        <div class="order-1 xl:order-2 grid xl:grid-cols-12 gap-6">
          <!-- Foto de perfil -->
          <div class="relative xl:col-span-4">
            <label class="block mb-2 font-semibold text-xs xl:text-sm"
              >Foto de perfil:</label
            >
            <img
              id="imagen_preview"
              :src="img"
              class="object-contain w-full rounded-full border shadow-xl"
              alt="Usuario de Envios USA"
            />
            <label class="cursor-pointer" for="imagen_perfil">
              <img
                class="absolute bottom-0 -right-2 cursor-pointer"
                src="../../assets/icons/camara-circle.svg"
                alt="Icono de Envios USA"
              />
              <input
                type="file"
                @change="previewImage"
                accept="image/png,image/jpeg,image/jpg"
                id="imagen_perfil"
                class="hidden"
              />
            </label>
          </div>
          <div v-if="data.qualifications" class="xl:col-span-8">
            <h3
              class="mb-2 font-semibold text-xs xl:text-sm flex items-center justify-between"
            >
              Reviews
              <router-link
                class="text-xs xl:text-sm text-blue-500 font-semibold"
                :to="{ name: 'User', params: { type: 'reviews' } }"
              >
                Ver todas
              </router-link>
            </h3>
            <h4 class="font-bold text-xl">
              {{
                data.qualifications.average_score
                  ? Number(data.qualifications.average_score).toFixed(1)
                  : 0
              }}
              <span class="text-sm font-semibold">/5</span>
              <span
                v-if="data.travel_squad == 1"
                class="ml-3 px-3 py-1 rounded-full text-xs bg-blue-400 bg-opacity-20 w-max text-blue-500 font-semibold"
              >
                Travel Squad
              </span>
            </h4>
            <div class="flex items-center justify-between">
              <p>
                Básado en {{ data.qualifications.total_qualification }} reviews
              </p>
              <img
                v-if="
                  Number(data.qualifications.average_score).toFixed(1) >= 4.5
                "
                src="../../assets/icons/icon-star-green.png"
                class="w-8"
              />
              <img
                v-else-if="
                  Number(data.qualifications.average_score).toFixed(1) >= 4
                "
                src="../../assets/icons/icon-star.png"
                class="w-8"
              />
              <img
                v-else
                src="../../assets/icons/icon-star-red.svg"
                class="w-8"
              />
            </div>
            <hr class="text-gray-800 my-4" />
            <div class="flex gap-3">
              <article
                v-for="(score, i) in data.qualifications.score_percentages"
                :key="i"
              >
                <template v-if="score < 25">
                  <div
                    class="mb-4 h-12 w-12 rounded-full border-4 border-gray-100 shadow-md flex items-center justify-center"
                  >
                    <span
                      class="text-xl font-bold text-center text-purple-500"
                      >{{ Number(i.slice(-1)) + 1 }}</span
                    >
                  </div>
                  <p class="text-center">{{ Number(score).toFixed(1) }}%</p>
                </template>
                <template v-else-if="score >= 25">
                  <div
                    class="mb-4 h-12 w-12 rounded-full border-4 border-gray-100 shadow-md flex items-center justify-center"
                    style="border-top-color: #8b5cf6"
                  >
                    <span
                      class="text-xl font-bold text-center text-purple-500"
                      >{{ Number(i.slice(-1)) + 1 }}</span
                    >
                  </div>
                  <p class="text-center">{{ Number(score).toFixed(1) }}%</p>
                </template>
                <template v-else-if="score >= 50">
                  <div
                    class="mb-4 h-12 w-12 rounded-full border-4 border-gray-100 shadow-md flex items-center justify-center"
                    style="
                      border-top-color: #8b5cf6;
                      border-right-color: #8b5cf6;
                    "
                  >
                    <span
                      class="text-xl font-bold text-center text-purple-500"
                      >{{ Number(i.slice(-1)) + 1 }}</span
                    >
                  </div>
                  <p class="text-center">{{ Number(score).toFixed(1) }}%</p>
                </template>
                <template v-else-if="score >= 75">
                  <div
                    class="mb-4 h-12 w-12 rounded-full border-4 border-gray-100 shadow-md flex items-center justify-center"
                    style="
                      border-top-color: #8b5cf6;
                      border-right-color: #8b5cf6;
                      border-bottom-color: #8b5cf6;
                    "
                  >
                    <span
                      class="text-xl font-bold text-center text-purple-500"
                      >{{ Number(i.slice(-1)) + 1 }}</span
                    >
                  </div>
                  <p class="text-center">{{ Number(score).toFixed(1) }}%</p>
                </template>
                <template v-else-if="score >= 85">
                  <div
                    class="mb-4 h-12 w-12 rounded-full border-4 border-gray-100 shadow-md flex items-center justify-center"
                    style="border-color: #8b5cf6"
                  >
                    <span
                      class="text-xl font-bold text-center text-purple-500"
                      >{{ Number(i.slice(-1)) + 1 }}</span
                    >
                  </div>
                  <p class="text-center">{{ Number(score).toFixed(1) }}%</p>
                </template>
              </article>
            </div>
          </div>
        </div>
      </div>
      <h3 class="font-bold text-text-blue text-sm xl:text-lg mb-8">
        Dirección de facturación
      </h3>
      <div class="grid xl:grid-cols-2 gap-8 mb-8">
        <!-- DIRECCIÓN -->
        <div>
          <label
            for="direccion"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Dirección:</label
          >
          <input
            id="direccion"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Dirección"
            :class="error.direccion ? 'ring ring-main-red' : ''"
            v-model="data.direccion"
            @keypress="stateGmapAUtocomplete"
            v-if="data.state"
          />
          <GMapAutocomplete
            placeholder="Ingrese una dirección"
            @place_changed="setPlace"
            :class="error.direccion ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            v-else
          >
          </GMapAutocomplete>
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.direccion || error.lat || error.lng"
            >*Ingrese y seleccione su dirección</span
          >
        </div>
        <!-- Departamento -->
        <div>
          <label
            for="departamento"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Departamento:</label
          >
          <input
            id="departamento"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Departamento"
            :class="error.departamento ? 'ring ring-main-red' : ''"
            v-model="data.departamento"
          />
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.departamento"
            >*Ingrese el departamento</span
          >
        </div>
        <!-- N° Piso/Oficina -->
        <div>
          <label for="piso" class="block mb-2 font-semibold text-xs xl:text-sm"
            >N° Piso/Oficina</label
          >
          <input
            id="piso"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="N° Piso/Oficina"
            :class="error.piso ? 'ring ring-main-red' : ''"
            v-model="data.piso"
          />
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.piso"
            >*Ingrese el piso u oficina</span
          >
        </div>
        <!-- Provincia -->
        <div>
          <label
            for="provincia"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Provincia:</label
          >
          <input
            id="provincia"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Provincia"
            :class="error.provincia ? 'ring ring-main-red' : ''"
            v-model="data.provincia"
          />
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.provincia"
            >*Ingrese la provincia</span
          >
        </div>
        <!-- Referencia -->
        <div>
          <label
            for="referencia"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Referencia:</label
          >
          <input
            id="referencia"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Referencia"
            :class="error.referencia ? 'ring ring-main-red' : ''"
            v-model="data.referencia"
          />
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.referencia"
            >*Ingrese la referencia</span
          >
        </div>
        <!-- Distrito -->
        <div>
          <label
            for="distrito"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Distrito:</label
          >
          <input
            id="distrito"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Distrito"
            :class="error.distrito ? 'ring ring-main-red' : ''"
            v-model="data.distrito"
          />
          <span
            class="block text-main-red text-xs xl:text-sm pt-2 font-medium"
            v-if="error.distrito"
            >*Ingrese el distrito</span
          >
        </div>
        <button
          @click.prevent="updateUser"
          class="w-full mt-4 pt-5 pb-4 px-6 bg-main-green text-white text-center font-bold rounded-2xl"
          type="submit"
        >
          Guardar cambios
        </button>
        <!-- CODIGO DE USUARIO -->
        <div class="hidden bg-white rounded-2xl border shadow-sm text-sm">
          <header class="py-4 px-6 bg-main-red rounded-tl-2xl rounded-tr-2xl">
            <p class="text-white font-semibold text-xs xl:text-sm">
              Código de usuario
            </p>
          </header>
          <div class="px-6 py-4 font-semibold">
            {{ identificador.toUpperCase() }}
          </div>
        </div>
      </div>
    </form>
  </section>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["id", "identificador", "img"],
  data() {
    return {
      data: {
        idAdress: "",
        state: false,
        name: "",
        apellido: "",
        documento: "",
        telefono: "",
        emailContact: "",
        direccion: "",
        piso: "",
        referencia: "",
        departamento: "",
        provincia: "",
        distrito: "",
        lat: 0,
        lng: 0,
        qualifications: {},
        travel_squad: 0,
      },
      error: {
        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
        documento: false,
        telefono: false,
        emailContact: false,
        direccion: false,
        lat: false,
        lng: false,
      },
      imgData: "",
      imagePreview: "",
    };
  },
  created() {
    this.getUser();
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.imgData = e.target.result;
        this.updateImage();
      };
      reader.readAsDataURL(file);
    },
    updateImage() {
      axios
        .put(`${BASE_URL}` + "client/update-client-image/" + this.id, {
          profile_image: this.imagePreview,
        })
        .then((response) => {
          if (response.status === 200) {
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description =
              "Foto de perfil actualizado con éxito";
            this.$store.state.alert.estado = true;
            const dataId = response.data.body.id;
            const dataName =
              response.data.body.attributes.profile.attributes.name;
            const dataLastName =
              response.data.body.attributes.profile.attributes.surnames;
            const dataImage =
              response.data.body.attributes.profile.attributes.userable
                .attributes.profile_image_url;
            const dni = response.data.body.attributes.validation.verified;
            const dataLocal = JSON.parse(localStorage.getItem("auth"));
            const email = dataLocal.glovalEmail;
            const dataIdentificador = dataLocal.identificador;
            const type = dataLocal.type;
            const dataUser = {
              globalID: dataId,
              globalName: dataName,
              globalLastName: dataLastName,
              globalImage: dataImage,
              globalValidate: dni,
              glovalEmail: email,
              identificador: dataIdentificador,
              type: type,
            };
            const dataCompress = JSON.stringify(dataUser);
            localStorage.setItem("auth", dataCompress);
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al intentar cambiar la foto de Perfil";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //validar que todos los campos esten rellenado para actualizar
    formValidate() {
      this.error.name = this.data.name.length !== 0 ? false : true;
      this.error.apellido = this.data.apellido.length !== 0 ? false : true;
      this.error.piso = this.data.piso.length !== 0 ? false : true;
      this.error.referencia = this.data.referencia.length !== 0 ? false : true;
      this.error.departamento =
        this.data.departamento.length !== 0 ? false : true;
      this.error.provincia = this.data.provincia.length !== 0 ? false : true;
      this.error.distrito = this.data.distrito.length !== 0 ? false : true;
      // this.error.documento = this.data.documento.toString().length !== 0 ? false : true;
      // this.error.telefono = this.data.telefono.toString().length !== 0 ? false : true;
      // this.error.emailContact = this.data.emailContact.length !== 0 ? false : true;
      this.error.direccion = this.data.direccion.length !== 0 ? false : true;
      this.error.lat = this.data.lat !== 0 ? false : true;
      this.error.lng = this.data.lng !== 0 ? false : true;

      if (
        this.error.name ||
        this.error.apellido ||
        this.error.piso ||
        this.error.referencia ||
        this.error.departamento ||
        this.error.provincia ||
        this.error.distrito ||
        // this.error.documento ||
        // this.error.telefono ||
        // this.error.emailContact ||
        this.error.direccion ||
        this.error.lat ||
        this.error.lng
      ) {
        return false;
      } else {
        return true;
      }
    },
    //metodo para actualizar al usuario
    updateUser() {
      const result = this.formValidate();
      if (result) {
        axios
          .post(`${BASE_URL}` + "client/update-client-sendi/" + this.id, {
            name: this.data.name,
            surnames: this.data.apellido,
            // cellphone: this.data.telefono,
            // document_number: this.data.documento,
            // email: this.data.emailContact,
            address: this.data.direccion,
            inner: this.data.piso,
            reference: this.data.referencia,
            department: this.data.departamento,
            city: this.data.provincia,
            district: this.data.distrito,
            address_id: this.data.idAdress,
            latitud: this.data.lat,
            longitud: this.data.lng,
          })
          .then((response) => {
            if (response.status === 200) {
              const data = response.data.body;
              const name = data.attributes.profile.attributes.name;
              const apellido = data.attributes.profile.attributes.surnames;
              const dataIdentificador = data.client_code;
              const dataLocal = JSON.parse(localStorage.getItem("auth"));
              const id = dataLocal.globalID;
              const img = dataLocal.globalImage;
              const dni = dataLocal.globalValidate;
              const email = dataLocal.glovalEmail;
              const type = dataLocal.type;
              const dataUser = {
                globalID: id,
                globalName: name,
                globalLastName: apellido,
                globalImage: img,
                globalValidate: dni,
                glovalEmail: email,
                identificador: dataIdentificador,
                type: type,
              };
              const dataCompress = JSON.stringify(dataUser);
              localStorage.setItem("auth", dataCompress);
              if (response.data.body.attributes.addresses.length !== 0) {
                const data = {};
                data.nombre =
                  response.data.body.attributes.addresses[0].attributes.address;
                data.lat =
                  response.data.body.attributes.addresses[0].attributes.lat;
                data.lng =
                  response.data.body.attributes.addresses[0].attributes.lng;
                data.departamento =
                  response.data.body.attributes.addresses[0].attributes.department;
                data.provincia =
                  response.data.body.attributes.addresses[0].attributes.city;
                data.distrito =
                  response.data.body.attributes.addresses[0].attributes.district;
                data.location =
                  response.data.body.attributes.addresses[0].attributes.free;
                const dataCompress = JSON.stringify(data);
                window.localStorage.setItem("direction", dataCompress);
              }
              location.reload();
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al actualizar los datos";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    //cambia el estado dle input para que se muestre el otro componente
    stateGmapAUtocomplete() {
      this.data.state = false;
      this.data.direccion = "";
    },
    //solo numeros
    onlyNumber($event) {
      if (
        $event.key === "-" ||
        $event.key === "," ||
        $event.key === "." ||
        $event.key === "+"
      ) {
        $event.preventDefault();
      }
    },
    setPlace(place) {
      this.data.direccion = place.name;
      this.data.lat = place.geometry.location.lat();
      this.data.lng = place.geometry.location.lng();
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";

      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }
      });
      if (country !== "PE" || country === "") {
        this.data.direction = "";
        this.data.lat = 0;
        this.data.lng = 0;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//

        this.error.direccion = true;
      } else {
        this.error.direccion = false;
      }
      this.data.departamento = departamento;
      this.data.provincia = provincia;
      this.data.distrito = distrito;
    },
    //Obteniendo datos del usuario
    getUser() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.id)
        .then((response) => {
          if (response.status === 200) {
            var dataUser = response.data.body.data;
            // Travel Squad
            this.data.travel_squad = dataUser.attributes.travel_squad;
            // Calificaciones
            this.data.qualifications =
              dataUser.attributes.qualification_summary;
            console.log(
              "QUALIFICATIONS RESPONSE: ",
              dataUser.attributes.qualification_summary
            );
            //Nombre
            this.data.name =
              dataUser.attributes.profile.attributes.name === null
                ? ""
                : dataUser.attributes.profile.attributes.name;
            //apellido
            this.data.apellido =
              dataUser.attributes.profile.attributes.surnames === null
                ? ""
                : dataUser.attributes.profile.attributes.surnames;
            //Documento
            this.data.documento =
              dataUser.attributes.profile.attributes.document_number === null
                ? ""
                : dataUser.attributes.profile.attributes.document_number;
            //telefono
            this.data.telefono =
              dataUser.attributes.profile.attributes.cellphone === null
                ? ""
                : dataUser.attributes.profile.attributes.cellphone;
            // correo de contacto
            this.data.emailContact =
              dataUser.attributes.profile.attributes.userable.attributes
                .biography === null
                ? dataUser.attributes.profile.attributes.userable.attributes
                    .email
                : dataUser.attributes.profile.attributes.userable.attributes
                    .biography;
            //Datos de la dirección y valida si el campo de addres es cero se les coloca string vacio
            if (dataUser.attributes.addresses.length !== 0) {
              this.data.state = true;
              //direccion
              this.data.direccion =
                dataUser.attributes.addresses[0].attributes.address === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.address;
              //piso
              this.data.piso =
                dataUser.attributes.addresses[0].attributes.inner === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.inner;
              //referencia
              this.data.referencia =
                dataUser.attributes.addresses[0].attributes.reference === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.reference;
              //departamento
              this.data.departamento =
                dataUser.attributes.addresses[0].attributes.department === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.department;
              //provincia
              this.data.provincia =
                dataUser.attributes.addresses[0].attributes.city === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.city;
              //distrito
              this.data.distrito =
                dataUser.attributes.addresses[0].attributes.district === null
                  ? ""
                  : dataUser.attributes.addresses[0].attributes.district;
              //latitud
              this.data.lat =
                dataUser.attributes.addresses[0].attributes.lat === null
                  ? 0
                  : dataUser.attributes.addresses[0].attributes.lat;
              //longitud
              this.data.lng =
                dataUser.attributes.addresses[0].attributes.lng === null
                  ? 0
                  : dataUser.attributes.addresses[0].attributes.lng;
              this.data.idAdress = dataUser.attributes.addresses[0].id;
            } else {
              this.data.direccion = "";
              this.data.piso = "";
              this.data.referencia = "";
              this.data.departamento = "";
              this.data.provincia = "";
              this.data.distrito = "";
              this.data.lat = 0;
              this.data.lng = 0;
              this.data.state = false;
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al mostrar sus datos";
            this.$store.state.alert.estado = true;
            console.log("ERROR EN PERFIL: ", error);
            //--------------------------//
          }
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
