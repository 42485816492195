<template>
  <div
    :class="status2 ? '' : 'oculto'"
    class="min-h-screen bg-sky flex items-center justify-center"
  >
    <div class="u-container">
      <img
        src="../assets/images/cuenta_verificada.svg"
        class="mx-auto mb-8"
        v-if="status"
        alt="Icono de Envios USA"
      />
      <img
        src="../assets/images/404.svg"
        class="mx-auto mb-8"
        alt="Icono de Envios USA"
        v-else
      />

      <h3 class="mb-10 text-2xl xl:text-4xl font-semibold text-center">
        {{ status ? "Cuenta verificada" : "  ¡Uy! Algo salió mal!" }}
      </h3>

      <router-link
        :to="{ name: 'Home' }"
        class="block mx-auto w-max px-28 xl:px-36 py-5 bg-main-red rounded-2xl text-center text-white font-semibold"
      >
        {{ status ? "Continuar" : "Ir al home" }}</router-link
      >
    </div>
  </div>
</template>
<script>
import { server } from "../config/global";

const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  data() {
    return {
      status2: false,
      status: false,
      token: this.$route.params.token,
    };
  },
  mounted() {
    this.scrollToTop();

    this.verification();
    window.localStorage.removeItem("open");
    window.localStorage.removeItem("producto");
  },
  methods: {
    verification() {
      axios
        .get(`${BASE_URL}` + "client/verify/" + this.token)
        .then((response) => {
          if (response.status === 200) {
            this.status = true;
            this.status2 = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.status = false;
            this.status2 = true;
          }
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
