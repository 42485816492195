<template>
  <form class="w-1/3 hidden xl:block relative" v-on:submit="processPurchase">
    <img
      src="../../assets/icons/buscar-red.svg"
      alt="Buscar"
      class="absolute left-6 bottom-4 cursor-pointer"
    />
    <input
      :class="error.link ? ' text-main-red' : ''"
      v-model="data.link"
      @keypress="changeText()"
      class="text-textColor py-4 pl-16 px-8 rounded-2xl w-full"
      placeholder="www.tienda.com/producto"
      type="text"
    />
  </form>
</template>
<script>
export default {
  data() {
    return {
      seeAlert: false,
      error: {
        link: false,
      },
      data: {
        link: "",
      },
      preload: {
        product: false,
        error: false,
      },
    };
  },
  mounted() {
    this.data.link = this.$route.params.link;
  },
  methods: {
    resetDataInput() {
      this.data.link = "";
      this.error.link = false;
      this.seeAlert = false;
    },
    changeText() {
      this.error.link = false;
      this.seeAlert = false;
    },
    //validación del link
    validateLink() {
      const data = this.data.link;
      if (data.length !== 0) {
        this.error.link = false;
        var urlRegex = /^(ftp|http|https):\/\/?[^ "]+$/;
        const result = urlRegex.test(data);
        if (result) {
          this.seeAlert = false;
          this.error.link = false;
          return true;
        } else {
          this.seeAlert = true;
          this.error.link = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese un link válido 'https://www.'o 'http://www.'";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.seeAlert = true;
        this.error.link = true;
        this.data.link = "Ingrese el link de su producto";
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el link de su producto";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //verificación si el servicio da respuesta o error
    processPurchase(e) {
      e.preventDefault();
      const result = this.validateLink();
      if (result) {
        this.$router.push({
          name: "Purchase",
          params: { link: this.data.link },
        });
      }
    },
    test(e) {
      alert(e);
      alert("SUBMIT");
    },
  },
};
</script>
