<template>
  <header-component
    @openLogin="autentification.estateLogin = $event"
    @openRegister="autentification.estateRegister = $event"
    @openTracking="autentification.estateTracking = $event"
  />
  <main>
    <section class="section-flujo-compra">
      <div class="u-container">
        <section class="bg-white p-6 xl:p-14 rounded-2xl shadow-xl border">
          <article class="mb-16">
            <span
              class="flex items-center gap-4 text-bg-black mb-6 text-2xl xl:text-4xl font-bold cursor-pointer"
            >
              <img
                @click.prevent="step = 1"
                src="../assets/icons/icono-volver.png"
                class="cursor-pointer"
                v-if="step !== 1"
              />

              Ayuda
            </span>

            <p class="font-semibold mb-3">
              SENDIBOX PERU S.A.C. - R.U.C 20606153130
            </p>
            <p class="text-sm">
              Completa la información y revisaremos tu caso. Te contestaremos
              dentro de los próximos 15 días <br />
              hábiles. Para conseguir una respuesta más rápida,
              <a
                href="https://api.whatsapp.com/send?phone=51987294960&text=Hola%2C%20requiero%20ayuda%20%F0%9F%93%A6%3A%20%20"
                class="text-main-red"
                target="_blank"
                >contáctanos a nuestro canal de ayuda</a
              >.
            </p>
          </article>

          <form class="grid gap-6">
            <!-- Paso 1 -->
            <article class="" v-if="step === 1">
              <h3 class="text-bg-black-blue mb-8 text-lg md:text-2xl font-bold">
                Tus datos personales
              </h3>
              <div class="grid xl:grid-cols-2 gap-6 gap-x-10 mb-14">
                <!-- Nombre y Apellidos: -->
                <div>
                  <label
                    for="nombres"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Nombre y Apellidos:</label
                  >
                  <input
                    id="nombres"
                    v-model="form.name"
                    class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                    type="text"
                    placeholder="Nombre y Apellidos"
                  />
                </div>
                <!-- Documento de identidad: -->
                <div>
                  <label
                    for="documento"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Documento de identidad:</label
                  >
                  <input
                    id="documento"
                    v-model="form.document"
                    class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                    type="tel"
                    placeholder="DNI ó Pasaporte"
                  />
                </div>
                <!-- Direccion -->
                <div>
                  <label
                    for="direccion"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Dirección:</label
                  >
                  <input
                    id="direccion"
                    class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                    type="text"
                    v-model="form.direction"
                    placeholder="Dirección"
                  />
                </div>
                <!-- Telefono -->
                <div>
                  <label
                    for="telefono"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Teléfono:</label
                  >
                  <input
                    id="telefono"
                    class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                    type="tel"
                    v-model="form.phone"
                    placeholder="+51 000 000 000"
                  />
                </div>
                <!-- Correo electrónico -->
                <div>
                  <label
                    for="email"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Correo electrónico:</label
                  >
                  <input
                    id="email"
                    class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                    type="email"
                    v-model="form.email"
                    placeholder="ejemplo@correo.com"
                  />
                </div>
              </div>
            </article>
            <!-- Paso 2 -->

            <article class="" v-else-if="step === 2">
              <h3 class="text-bg-black-blue mb-8 text-lg md:text-2xl font-bold">
                Elige una opción
              </h3>
              <div class="grid xl:grid-cols-2 gap-6 text-sm xl:text-xl">
                <button
                  @click.prevent="descripProblemt()"
                  class="p-6 bg-white border-2 border-gray-700 shadow-md hover:shadow-lg hover:bg-blue-50 rounded-md text-center transition-all"
                >
                  Tengo un problema con una compra o servicio
                </button>
                <button
                  @click.prevent="contact()"
                  class="p-6 bg-white border-2 border-gray-700 shadow-md hover:shadow-lg hover:bg-blue-50 rounded-md text-center transition-all"
                >
                  Problemas al usar/configurar mi cuenta
                </button>
                <router-link
                  :to="{ name: 'Complaints' }"
                  class="xl:col-span-2 xl:w-1/2 xl:mx-auto p-6 bg-white border-2 border-gray-700 shadow-md hover:shadow-lg hover:bg-blue-50 rounded-md text-center transition-all"
                >
                  Libro de reclamaciones
                </router-link>
              </div>
              <button></button>
            </article>
            <!-- Paso 3 -->
            <article class="" v-else-if="step === 3">
              <div
                class="grid xl:grid-cols-2 gap-6 text-sm xl:text-xl xl:w-2/3 xl:mx-auto"
              >
                <h3
                  class="xl:col-span-2 text-bg-black-blue mb-2 text-lg md:text-2xl font-bold"
                >
                  ¿Cómo prefieres conversar?
                </h3>
                <a
                  href="https://api.whatsapp.com/send?phone=51987294960&text=%22Hola%2C%20requiero%20ayudar%20al%20usar%20mi%20cuenta%22"
                  target="_blank"
                  class="xl:col-span-2 p-6 bg-white border-2 border-gray-700 shadow-lg hover:shadow-xl hover:border-green-700 hover:bg-green-50 rounded-md transition-all"
                >
                  <header class="flex items-center gap-2 mb-6">
                    <img src="../assets/icons/whatsapp-black.png" alt="" />
                    <h3 class="font-medium">WhatsApp</h3>
                    <img
                      src="../assets/icons/arrow-right-bold.svg"
                      class="ml-2"
                    />
                    <span
                      class="font-semibold text-sm px-4 py-2 rounded-xl text-main-green bg-main-green bg-opacity-20 ml-4"
                      >Recomendado</span
                    >
                  </header>
                  <p class="text-sm xl:text-base text-left">
                    Te enviaremos un mensaje en menos de <b>5min</b>
                  </p>
                </a>
                <a
                  href="tel:+51-987-294-960"
                  class="cursor-pointer p-6 bg-white border-2 border-gray-700 shadow-lg hover:shadow-xl hover:border-blue-700 hover:bg-blue-50 rounded-md transition-all"
                >
                  <header class="flex items-center gap-2 mb-6">
                    <img src="../assets/icons/telefono.svg" alt="" />
                    <h3 class="font-medium">Teléfono</h3>
                    <img
                      src="../assets/icons/arrow-right-bold.svg"
                      class="ml-2"
                    />
                  </header>
                  <p class="text-sm xl:text-base text-left">
                    Llámanos al siguiente número <b>(+51) 987 294 960</b>
                  </p>
                </a>
                <a
                  href="mailto:hola@enviosusaperu.com"
                  class="cursor-pointer p-6 bg-white border-2 border-gray-700 shadow-lg hover:shadow-xl hover:border-purple-700 hover:bg-purple-50 rounded-md transition-all"
                >
                  <header class="flex items-center gap-2 mb-6">
                    <img src="../assets/icons/email.svg" alt="" />
                    <h3 class="font-medium">Email</h3>
                    <img
                      src="../assets/icons/arrow-right-bold.svg"
                      class="ml-2"
                    />
                  </header>
                  <p class="text-sm xl:text-base text-left">
                    Te responderemos por <b>e-mail</b>
                  </p>
                </a>
              </div>
            </article>
            <!-- Paso 4 -->
            <article class="" v-else-if="step === 4">
              <h3 class="text-bg-black-blue mb-8 text-lg md:text-2xl font-bold">
                Describe tu problema
              </h3>
              <div class="grid xl:grid-cols-3 gap-6 gap-x-10 mb-14">
                <div>
                  <label
                    for="producto_servicio"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >¿Por qué producto o servicio reclamas?</label
                  >
                  <div class="relative">
                    <select
                      v-model="form.product"
                      class="cursor-pointer w-full py-6 px-5 text-sm xl:text-base font-semibold bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl focus:outline-none focus:ring focus:ring-blue-400"
                    >
                      <optgroup
                        class="cursor-not-allowed font-bold"
                        label="Servicios..."
                      >
                        <option value="Lite" class="pl-8">Lite</option>
                        <option value="Premium" class="pl-8">Premium</option>
                      </optgroup>
                      <optgroup
                        class="cursor-not-allowed font-bold"
                        label="Órdenes..."
                      >
                        <option
                          v-for="data in ordenes"
                          :key="data"
                          :value="data.id"
                          class="m-8"
                        >
                          {{ data.id }} - {{ data.attributes.product_info }}
                        </option>
                      </optgroup>
                    </select>
                    <div
                      class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                    >
                      <img src="../assets/icons/arrow-down-black.svg" />
                    </div>
                  </div>
                </div>
                <div></div>
              </div>

              <div>
                <label
                  for="descripcion_problema"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >¿Cuál fué el problema?</label
                >
                <textarea
                  v-model="form.problem"
                  name="descripcion_problema"
                  id="descripcion_problema"
                  placeholder="Escribe aquí ..."
                  rows="7"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                ></textarea>
              </div>
              <button></button>
            </article>

            <button
              v-if="
                form.name &&
                form.email &&
                form.document &&
                form.phone &&
                form.direction &&
                step === 1
              "
              @click.prevent="next()"
              class="px-10 py-4 rounded-md bg-main-green text-white xl:mx-auto block font-medium"
            >
              Continuar
            </button>

            <button
              v-if="form.problem && step === 4"
              @click.prevent="saveComplaint()"
              class="px-10 py-4 rounded-md bg-main-green text-white xl:mx-auto block font-medium"
            >
              Enviar
            </button>
            <button
              v-if="form.problem === null && step === 4"
              class="px-10 py-4 rounded-md bg-gray-500 text-white xl:mx-auto block font-medium cursor-not-allowed"
            >
              Enviar
            </button>
          </form>
        </section>
      </div>
    </section>
  </main>
  <footer-component />
  <!-- inicio de sesión -->
  <login-component
    :estate="autentification.estateLogin"
    @close="autentification.estateLogin = $event"
    @changeView="changeView"
    @resetPassword="resetPassword = $event"
  />
  <!-- registro -->
  <register-component
    :estate="autentification.estateRegister"
    @close="autentification.estateRegister = $event"
  />
  <!-- Modal para pedir cambiar contraseña -->
  <reset-password-component
    @closeResetPassword="resetPassword = $event"
    :resetPassword="resetPassword"
  />
  <!-- tracking -->
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
</template>
<script>
import HeaderComponent from "../components/header/headerComponent.vue";
import FooterComponent from "../components/footer/footerComponent.vue";
import loginComponent from "../components/autentification/loginComponent.vue";
import registerComponent from "../components/autentification/registerComponent.vue";
import resetPasswordComponent from "../components/autentification/resetPasswordComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;
import axios from "axios";
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    loginComponent,
    registerComponent,
    resetPasswordComponent,
    trackingComponent,
  },
  data() {
    return {
      ordenes: [],
      step: 1,
      form: {
        store_id: 2,
        name: null,
        document: null,
        direction: null,
        phone: null,
        email: null,
        problem: null,
        dateClain: "",
        hourClain: "",
        product: "Lite",
      },
      autentification: {
        estateLogin: false,
        estateRegister: false,
        estateTracking: false,
      },
      resetPassword: false,
    };
  },
  mounted() {
    this.getUser();
    this.getDateHour();
    this.getOrders();
  },
  methods: {
    getOrders() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        axios
          .get(
            `${BASE_URL}` +
              "purchaseOrder/" +
              dataUser.globalID +
              `/list-orders-by-client?orderBy=created_at`
          )
          .then((response) => {
            this.ordenes = response.data.data ?? [];
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al mostrar sus órdenes";
            this.$store.state.alert.estado = true;
          });
      }
    },
    changeView() {
      this.autentification.estateLogin = false;
      this.autentification.estateRegister = true;
    },
    saveComplaint() {
      axios
        .post(`${BASE_URL}` + "create/libroDeReclamaciones", this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Enviado";
            this.$store.state.alert.estado = true;
          }
        })
        .catch(() => {
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description = "Error";
          this.$store.state.alert.estado = true;
        });
    },
    getDateHour() {
      var today = new Date();
      var hourData = today.toLocaleTimeString("es-ES");
      var dateData = today.toLocaleDateString("es-ES");
      this.form.hourClain = hourData;
      this.form.dateClain = dateData;
    },
    getUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        axios
          .get(`${BASE_URL}` + "client/getById/" + dataUser.globalID)
          .then((response) => {
            if (response.status === 200) {
              // var dataUser = response.data.body.attributes;
              var dataUser = response.data.body.data.attributes;

              this.form.name =
                dataUser.profile.attributes.name +
                " " +
                dataUser.profile.attributes.surnames;
              this.form.email =
                dataUser.profile.attributes.userable.attributes.email;
              this.form.document = dataUser.profile.attributes.document_number;
              this.form.phone = dataUser.profile.attributes.cellphone;
              if (dataUser.addresses.length !== 0) {
                this.form.direction =
                  dataUser.addresses[0].attributes.address === null
                    ? ""
                    : dataUser.addresses[0].attributes.address;
              }
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al obtener sus datos";
              this.$store.state.alert.estado = true;
              //--------------------------//
              console.info("Error en ayuda: ", error);
            }
          });
      }
    },
    next() {
      this.step = 2;
    },
    contact() {
      this.step = 3;
      this.saveComplaint();
    },
    descripProblemt() {
      this.step = 4;
    },
  },
  watch: {
    step: function () {
      window.scrollTo(0, 0);
    },
  },
};
</script>
