import { createRouter, createWebHistory } from "vue-router";
// Import de las rutas

// Dinamicos
// const Home = import("../views/Home.vue").then((c) => c.default);
// const Purchase = import("../views/Purchase.vue").then((c) => c.default);
// const User = import("../views/User.vue").then((c) => c.default);
// const Tracking = import("../views/Tracking.vue").then((c) => c.default);
// const DetailProduct = import("../views/DetailProduct.vue").then(
//   (c) => c.default
// );
// const ResetPassword = import("../views/ResetPassword.vue").then(
//   (c) => c.default
// );
// const Contacto = import("../views/Contacto.vue").then((c) => c.default);
// const Verification = import("../views/Verification.vue").then((c) => c.default);
// const CompraSegura = import("../views/CompraSegura.vue").then((c) => c.default);
// const Tiendas = import("../views/Tiendas.vue").then((c) => c.default);
// const Afiliados = import("../views/Afiliado.vue").then((c) => c.default);
// const Ayuda = import("../views/Ayuda.vue").then((c) => c.default);
// const auth = import("../middleware/auth").then((c) => c.default);
// const Complaints = import("../views/Complaints.vue").then((c) => c.default);

// Estaticos
import Home from "../views/Home.vue";
import Purchase from "../views/Purchase.vue";
import User from "../views/User.vue";
import Tracking from "../views/Tracking.vue";
import DetailProduct from "../views/DetailProduct.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Contacto from "../views/Contacto.vue";
import Verification from "../views/Verification.vue";
import CompraSegura from "../views/CompraSegura.vue";
import Tiendas from "../views/Tiendas.vue";
import Afiliados from "../views/Afiliado.vue";
import Ayuda from "../views/Ayuda.vue";
import auth from "../middleware/auth";
import Complaints from "../views/Complaints.vue";

const routes = [
  //Ruta de inicio
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //Ruta para ingresar al proceso de compra
  {
    path: "/purchase/:link",
    name: "Purchase",
    component: Purchase,
  },
  //Ruta para ingresar al perfil del usuario
  {
    path: "/user/:type",
    name: "User",
    component: User,
    beforeEnter: auth,
  },
  //Ruta para ingresar al tracking de un pedido
  {
    path: "/tracking/:code",
    name: "Tracking",
    component: Tracking,
  },
  //Ruta para ingresar al detalle de un pedido
  {
    path: "/detail/product/:codeProduct",
    name: "Detalle",
    component: DetailProduct,
    beforeEnter: auth,
  },
  // Ruta para cambiar contraseña
  {
    path: "/resetPassword/:token",
    name: "Reset",
    component: ResetPassword,
  },
  // Ruta para la vista de contacto
  {
    path: "/viajes",
    name: "Contacto",
    component: Contacto,
    beforeEnter: auth,
  },
  //Verificar la cuenta del usuario

  {
    path: "/verification/account/:token",
    name: "Verification",
    component: Verification,
  },
  {
    path: "/compra/segura",
    name: "CompraSegura",
    component: CompraSegura,
  },
  {
    path: "/guia/tiendas",
    name: "Tiendas",
    component: Tiendas,
  },
  {
    path: "/afiliados/:code",
    name: "Afiliados",
    component: Afiliados,
  },
  {
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
  },
  {
    path: "/ayuda",
    name: "Ayuda",
    component: Ayuda,
    beforeEnter: auth,
  },
  //Cualquier ruta que no este registrada,sera redireccionada al Home
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
