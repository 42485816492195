<template>
  <header-component
    @openLogin="autentification.estateLogin = $event"
    @openRegister="autentification.estateRegister = $event"
    @openTracking="autentification.estateTracking = $event"
  />

  <div
    v-if="modal"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="mx-2 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm">
      <img
        src="../assets/images/icon-viajeros-modal.svg"
        class="mx-auto mb-10"
        alt="Ganar dinero viajando"
      />
      <h3 class="text-xl xl:text-3xl font-semibold mb-5 text-center">
        Gracias por registrar tu viaje
      </h3>
      <p class="text-sm xl:text-base text-center mb-10">
        En las próximas horas se comunicarán contigo
        <br class="hidden sm:block" />
        para coordinar los detalles del viaje
      </p>
      <button
        @click.prevent="closeModalViaje"
        class="block py-5 w-full rounded-2xl bg-main-red text-white font-bold text-xs xl:text-base"
      >
        Continuar
      </button>
    </div>
  </div>

  <section class="bg-main-red relative">
    <article
      class="u-container xl:grid xl:grid-cols-3 items-center py-32 text-white z-10 relative"
    >
      <div class="xl:col-span-2">
        <router-link
          :to="{ name: 'User', params: { type: 'viajes' } }"
          class="flex items-center gap-3 mb-6 text-sm xl:text-base font-semibold text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: rgba(255, 255, 255, 1); transform: ; msfilter: "
          >
            <path
              d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
            ></path>
          </svg>
          Volver
        </router-link>
        <h2 class="text-4xl xl:text-6xl font-bold mb-6">
          Gana dinero viajando
        </h2>
        <p class="text-xs xl:text-base font-medium mb-6 xl:mb-16">
          Registra tus viajes con nosotros, y gana dinero con espacio de tu
          equipaje que no usas
        </p>
        <form class="xl:pr-40 2xl:pr-80">
          <label class="block mb-8 relative">
            <GMapAutocomplete
              placeholder="Viaje desde"
              @place_changed="setPlaceOrigin"
              class="shadow-xl w-full rounded-2xl p-5 text-textColor text-xs xl:text-base focus:ring-transparent"
              v-if="estatusOrigen"
            >
            </GMapAutocomplete>

            <input
              v-else
              @keypress="changeEstatusOrigen"
              @keydown="changeEstatusOrigen"
              class="shadow-xl w-full rounded-2xl p-5 text-textColor text-xs xl:text-base focus:ring-transparent"
              placeholder="Viaje desde"
            />

            <img
              src="../assets/icons/market.svg"
              class="absolute top-5 right-5"
              alt="Icono de Envios USA"
            />
          </label>
          <label class="block mb-8 relative">
            <GMapAutocomplete
              placeholder="Viaje a"
              @place_changed="setPlaceDestino"
              class="shadow-xl w-full rounded-2xl p-5 text-textColor text-xs xl:text-base focus:ring-transparent"
              v-if="estatusDestino"
            >
            </GMapAutocomplete>

            <input
              v-else
              @keypress="changeEstatusDestino"
              @keydown="changeEstatusDestino"
              class="shadow-xl w-full rounded-2xl p-5 text-textColor text-xs xl:text-base focus:ring-transparent"
              placeholder="Viaje a"
            />
            <img
              src="../assets/icons/icon-avion.svg"
              class="absolute top-4 right-5 h-6"
              alt="Icono de Envios USA"
            />
          </label>

          <label class="block mb-8 relative">
            <datepicker
              v-model="formulario.fecha"
              :lowerLimit="new Date()"
              placeholder="Fecha de llegada"
              class="shadow-xl w-full rounded-2xl p-5 text-textColor text-xs xl:text-base focus:ring-transparent"
            />

            <img
              src="../assets/icons/icon-fecha.svg"
              class="absolute top-4 right-6 w-5"
              alt="Icono de Envios USA"
            />
          </label>

          <input
            @click.prevent="formTravel"
            value="Añadir viaje"
            class="text-center cursor-pointer block w-full shadow-2xl rounded-xl xl:rounded-2xl bg-main-green px-5 py-4 xl:px-12 xl:py-5 font-bold text-xs xl:text-base"
          />
        </form>
      </div>
      <figure class="hidden xl:block relative">
        <img
          data-id="1"
          src="../assets/images/hero-image-landing.png"
          class="mr-auto opacity-0"
          alt="Ganar dinero viajando"
        />
        <img
          data-id="1"
          src="../assets/images/hero-image-landing.png"
          class="absolute top-0 left-0 transition-all delay-400 hero-image"
          alt="Ganar dinero viajando"
        />
        <img
          data-id="2"
          src="../assets/images/hero-image-landing-2.png"
          class="absolute top-0 left-0 transition-all delay-400 oculto hero-image"
          alt="Ganar dinero viajando"
        />
        <img
          data-id="3"
          src="../assets/images/hero-image-landing-3.png"
          class="absolute top-0 left-0 transition-all delay-400 oculto hero-image"
          alt="Ganar dinero viajando"
        />
      </figure>
    </article>
  </section>
  <section class="u-container relative">
    <h2 class="font-medium text-xl xl:text-4xl mb-16 text-center">
      ¿Cómo ganar dinero viajando?
    </h2>
    <div class="grid xl:grid-cols-2 items-center gap-8">
      <article class="">
        <div
          data-id="1"
          class="pasos-card__active pasos-card flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8 cursor-pointer"
        >
          <span
            class="pasos-card__number xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 transition-colors"
          >
            <span
              class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm transition-colors"
            >
              1
            </span>
          </span>
          <div class="col-span-10">
            <h3 class="font-semibold mb-2 text-sm xl:text-base">
              Registra y elige los pedidos que gustes
            </h3>
            <p class="text-xs xl:text-sm xl:leading-8">
              Todos los pedidos están 100% pagados y confirmados por el
              comprador. Olvídate de preocupaciones y gana entre el 8% al 20%
              sobre el valor del pedido.
            </p>
          </div>
        </div>
        <div
          data-id="2"
          class="pasos-card flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8 cursor-pointer"
        >
          <span
            class="pasos-card__number xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 transition-colors"
          >
            <span
              class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm transition-colors"
            >
              2
            </span>
          </span>
          <div class="col-span-10">
            <h3 class="font-semibold mb-2 text-sm xl:text-base">
              Compra el pedido y llévalo en tu viaje
            </h3>
            <p class="text-xs xl:text-sm xl:leading-8">
              Recibirás el reembolso total por las compras y tu comisión una vez
              entregues o envíes los pedidos. Todos los pedidos ya están
              pagados, así que no te preocupes por la cobranza.
            </p>
          </div>
        </div>
        <div
          data-id="3"
          class="pasos-card flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8 cursor-pointer"
        >
          <span
            class="pasos-card__number xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 transition-colors"
          >
            <span
              class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm transition-colors"
            >
              3
            </span>
          </span>
          <div class="col-span-10">
            <h3 class="font-semibold mb-2 text-sm xl:text-base">
              Entrega el pedido y recibe tu pago
            </h3>
            <p class="text-xs xl:text-sm xl:leading-8">
              Entrega todos los pedidos en un solo punto y recibe tu pago en tu
              cuenta bancaria de preferencia.
            </p>
          </div>
        </div>
      </article>
      <aside class="">
        <figure class="">
          <img
            class="mx-auto pasos-card__aside transition-all"
            src="../assets/images/viajes-paso-1.png"
            alt="Ganar dinero viajando"
          />
          <img
            class="mx-auto hidden pasos-card__aside transition-all"
            src="../assets/images/viajes-paso-2.png"
            alt="Ganar dinero viajando"
          />
          <img
            class="mx-auto hidden pasos-card__aside transition-all"
            src="../assets/images/viajes-paso-3.png"
            alt="Ganar dinero viajando"
          />
        </figure>
      </aside>
    </div>
  </section>

  <section class="bg-gray-100 bg-opacity-30">
    <article class="u-container">
      <h2 class="font-medium text-xl xl:text-4xl mb-6 text-center">
        Con la confianza de
        <span class="xl:mt-4 xl:block">miles de clientes felices</span>
      </h2>
      <p
        class="xl:w-1/2 mx-auto xl:leading-8 text-center mb-16 text-sm xl:text-base"
      >
        Estas son las historias de nuestro usuarios y las razones por que
        deciden confiar en nosotros.
      </p>
      <!-- SLIDER DESKTOP-->
      <div class="hidden xl:block">
        <div class="xl:flex gap-12 mb-16">
          <div
            data-id="1"
            class="testimony_card--active testimony_card border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-1.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">Elisa Clark</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>4.5</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Gané 150 dolarés con mi primer viaje.
            </p>
          </div>
          <div
            data-id="2"
            class="hidden xl:block testimony_card border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-2.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">José Díaz</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>5.0</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Necesito repetirlo otra vez, dinero rápido.
            </p>
          </div>
          <div
            data-id="3"
            class="hidden xl:block testimony_card border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-3.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">Jesus Romero</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>4.5</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Envíos USA PERÚ, es lo mejor que existe
            </p>
            <p class="text-sm xl:text-base opacity-0">
              Envíos USA PERÚ, es lo mejor que existe
            </p>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex gap-4">
            <span
              class="dot h-4 w-12 rounded-2xl bg-main-red transition-all"
            ></span>
            <span
              class="dot h-4 w-4 rounded-full bg-gray-200 transition-all"
            ></span>
            <span
              class="dot h-4 w-4 rounded-full bg-gray-200 transition-all"
            ></span>
          </div>
          <div class="flex gap-5">
            <figure
              id="arrow-left"
              class="cursor-pointer w-8 xl:w-12 h-8 xl:h-12 p-2 rounded-full border-2 border-main-red flex items-center justify-center"
            >
              <img
                width="20"
                height="18"
                src="../assets/icons/arrow-left-red.svg"
                alt="Ganar dinero viajando"
              />
            </figure>
            <figure
              id="arrow-right"
              class="cursor-pointer w-8 xl:w-12 h-8 xl:h-12 p-2 rounded-full bg-main-red flex items-center justify-center"
            >
              <img
                width="20"
                height="18"
                src="../assets/icons/arrow-right-white.svg"
                alt="Ganar dinero viajando"
              />
            </figure>
          </div>
        </div>
      </div>
      <!-- SLIDER MOVIL -->
      <div class="xl:hidden">
        <div class="relative xl:flex gap-12 mb-16">
          <div
            class="opacity-0 border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                width="50"
                height="50"
                src="../assets/images/user-1.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">Hugo Vaquez</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >Lambayeque, Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>4.5</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Súper recomendado, envié un paquete desde NYC a Lima.
            </p>
          </div>
          <div
            data-id="1"
            class="absolute inset-x-0 top-0 testimony_card--active--mobile testimony_card--mobile opacity-0 border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-all delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-1.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">Elisa Clark</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>4.5</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Súper recomendado, envié un paquete desde NYC a Lima.
            </p>
          </div>
          <div
            data-id="2"
            class="absolute inset-x-0 top-0 testimony_card--mobile opacity-0 border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-all delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-2.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">José Díaz</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>5.0</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Necesito repetirlo otra vez, dinero rápido.
            </p>
          </div>
          <div
            data-id="3"
            class="absolute inset-x-0 top-0 testimony_card--mobile opacity-0 border-2 hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-all delay-400 cursor-pointer"
          >
            <div class="flex gap-5 items-center mb-8">
              <img
                src="../assets/images/user-viajes-3.svg"
                alt="Ganar dinero viajando"
              />
              <div>
                <span class="block text-lg font-medium">Jesus Romero</span>
                <span class="block text-sm text-gray-400 -mt-1"
                  >E.E.U.U hacia Perú</span
                >
              </div>
              <div class="ml-auto flex gap-3">
                <span>4.5</span>
                <img
                  width="14"
                  height="14"
                  src="../assets/icons/estrella.svg"
                  alt="Ganar dinero viajando"
                />
              </div>
            </div>
            <p class="text-sm xl:text-base">
              Envíos USA PERÚ, es lo mejor que existe
            </p>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div class="flex gap-4">
            <span
              class="dotMobile h-4 w-12 rounded-2xl bg-main-red transition-all"
            ></span>
            <span
              class="dotMobile h-4 w-4 rounded-full bg-gray-200 transition-all"
            ></span>
            <span
              class="dotMobile h-4 w-4 rounded-full bg-gray-200 transition-all"
            ></span>
          </div>
          <div class="flex gap-5">
            <figure
              id="arrow-left--mobile"
              class="cursor-pointer w-8 xl:w-12 h-8 xl:h-12 p-2 rounded-full border-2 border-main-red flex items-center justify-center"
            >
              <img
                width="20"
                height="18"
                src="../assets/icons/arrow-left-red.svg"
                alt="Ganar dinero viajando"
              />
            </figure>
            <figure
              id="arrow-right--mobile"
              class="cursor-pointer w-8 xl:w-12 h-8 xl:h-12 p-2 rounded-full bg-main-red flex items-center justify-center"
            >
              <img
                width="20"
                height="18"
                src="../assets/icons/arrow-right-white.svg"
                alt="Ganar dinero viajando"
              />
            </figure>
          </div>
        </div>
      </div>
    </article>
  </section>
  <register-component
    @openRegister="autentification.estateRegister = $event"
    v-if="!getAuth"
  />
  <footer-component />
  <register-user-component
    :estate="autentification.estateRegister"
    @close="autentification.estateRegister = $event"
  />
  <login-component
    :estate="autentification.estateLogin"
    @close="autentification.estateLogin = $event"
    @changeView="changeView"
    @resetPassword="resetPassword = $event"
  />
  <reset-password-component
    @closeResetPassword="resetPassword = $event"
    :resetPassword="resetPassword"
  />
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
</template>
<script>
import headerComponent from "../components/header/headerComponent.vue";
import registerComponent from "../components/home/registerViewComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import registerUserComponent from "../components/autentification/registerComponent.vue";
import loginComponent from "../components/autentification/loginComponent.vue";
import resetPasswordComponent from "../components/autentification/resetPasswordComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
import Datepicker from "vue3-datepicker";

import { server } from "../config/global";

const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    Datepicker,
    headerComponent,
    registerComponent,
    footerComponent,
    registerUserComponent,
    loginComponent,
    resetPasswordComponent,
    trackingComponent,
  },
  data() {
    return {
      modal: false,
      estatusDestino: true,
      estatusOrigen: true,
      resetPassword: false,
      autentification: {
        estateLogin: false,
        estateRegister: false,
        estateTracking: false,
      },
      formulario: {
        origen: "",
        destino: "",
        pais_origen: "",
        pais_destino: "",
        fecha: "",
      },
      iduser: 0,
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.scrollToTop();

    window.localStorage.removeItem("open");
    window.localStorage.removeItem("producto");
    // Cambio de placeholder en input fecha

    // FUNCIONALIDAD PARA INTERCAMBIO DE IMAGENES AUTOMATICO EN EL HERO
    let actual = 0;
    const hero_images = [...document.querySelectorAll(".hero-image")];

    setInterval(() => {
      hero_images[actual].classList.add("oculto");

      actual === 2
        ? hero_images[0].classList.remove("oculto")
        : hero_images[actual + 1].classList.remove("oculto");

      actual === 2 ? (actual = 0) : actual++;
    }, 1500);
    //-------------------------------------------------------------------//
    // FUNCIONALIDAD PARA CAMBIAR LAS IMAGENES EN LOS PASOS DE USO
    const pasos_cards = [...document.querySelectorAll(".pasos-card")];
    const pasos_images = [...document.querySelectorAll(".pasos-card__aside")];

    pasos_cards.map((card) => {
      card.addEventListener("click", () => {
        // Eliminar clase active al card anterior
        document
          .querySelector(".pasos-card__active")
          .classList.remove("pasos-card__active");
        // Obtener id de la card a la que se le da click
        let id = card.dataset.id;
        card.classList.add("pasos-card__active");
        // Desaparecer la imagen actual
        pasos_images.map((image) => image.classList.add("hidden", "opacity-0"));
        // Aparecer imagen segun el id al que se le dio click
        pasos_images[id - 1].classList.remove("hidden");
        setTimeout(() => {
          pasos_images[id - 1].classList.remove("opacity-0");
        }, 300);
      });
    });
    //-------------------------------------------------------------------//
    // FUNCIONALIDAD DEL CARRUSEL DE TESTIMONIOS
    const arrowLeft = document.getElementById("arrow-left"),
      arrowRight = document.getElementById("arrow-right"),
      cards = [...document.querySelectorAll(".testimony_card")],
      dots = [...document.querySelectorAll(".dot")];

    // FUNCIONALIDAD PARA LAS FLECHAS EN DESKTOP
    arrowLeft.addEventListener("click", () => {
      let maximo = cards.length - 1;
      let activo =
        parseInt(document.querySelector(".testimony_card--active").dataset.id) -
        1;

      cards[activo].classList.remove("testimony_card--active");
      dots[activo].classList.remove("w-12", "bg-main-red");
      dots[activo].classList.add("w-4", "bg-gray-200");
      if (activo - 1 < 0) {
        cards[maximo].classList.add("testimony_card--active");
        dots[maximo].classList.add("w-12", "bg-main-red");
      } else {
        cards[activo - 1].classList.add("testimony_card--active");
        dots[activo - 1].classList.add("w-12", "bg-main-red");
      }
    });

    arrowRight.addEventListener("click", () => {
      let maximo = cards.length - 1;
      let activo =
        parseInt(document.querySelector(".testimony_card--active").dataset.id) -
        1;

      cards[activo].classList.remove("testimony_card--active");
      dots[activo].classList.remove("w-12", "bg-main-red");
      dots[activo].classList.add("w-4", "bg-gray-200");
      if (activo + 1 > maximo) {
        cards[0].classList.add("testimony_card--active");
        dots[0].classList.add("w-12", "bg-main-red");
      } else {
        cards[activo + 1].classList.add("testimony_card--active");
        dots[activo + 1].classList.add("w-12", "bg-main-red");
      }
    });

    // FUNCIONALIDAD PARA LAS FLECHAS EN MOBILE
    const arrowLeftMobile = document.getElementById("arrow-left--mobile"),
      arrowRightMobile = document.getElementById("arrow-right--mobile"),
      cardsMobile = [...document.querySelectorAll(".testimony_card--mobile")],
      dotsMobile = [...document.querySelectorAll(".dotMobile")];

    arrowLeftMobile.addEventListener("click", () => {
      let maximo = cardsMobile.length - 1;
      let activo =
        parseInt(
          document.querySelector(".testimony_card--active--mobile").dataset.id
        ) - 1;

      cardsMobile[activo].classList.remove("testimony_card--active--mobile");
      dotsMobile[activo].classList.remove("w-12", "bg-main-red");
      dotsMobile[activo].classList.add("w-4", "bg-gray-400");
      if (activo - 1 < 0) {
        cardsMobile[maximo].classList.add("testimony_card--active--mobile");
        dotsMobile[maximo].classList.add("w-12", "bg-main-red");
      } else {
        cardsMobile[activo - 1].classList.add("testimony_card--active--mobile");
        dotsMobile[activo - 1].classList.add("w-12", "bg-main-red");
      }
    });

    arrowRightMobile.addEventListener("click", () => {
      let maximo = cardsMobile.length - 1;
      let activo =
        parseInt(
          document.querySelector(".testimony_card--active--mobile").dataset.id
        ) - 1;

      cardsMobile[activo].classList.remove("testimony_card--active--mobile");
      dotsMobile[activo].classList.remove("w-12", "bg-main-red");
      dotsMobile[activo].classList.add("w-4", "bg-gray-400");
      if (activo + 1 > maximo) {
        cardsMobile[0].classList.add("testimony_card--active--mobile");
        dotsMobile[0].classList.add("w-12", "bg-main-red");
      } else {
        cardsMobile[activo + 1].classList.add("testimony_card--active--mobile");
        dotsMobile[activo + 1].classList.add("w-12", "bg-main-red");
      }
    });

    // FUNCIONALIDAD LA HACER CLICK EN UNA CARD
    const handleClick = (card) => {
      cards.map((cardTestimony) =>
        cardTestimony.classList.remove("testimony_card--active")
      );
      card.classList.add("testimony_card--active");
      dots.map((dot) => {
        dot.classList.remove("w-12", "bg-main-red");
        dot.classList.add("w-4", "bg-gray-200");
      });
      dots[card.dataset.id - 1].classList.add("w-12", "bg-main-red");
    };

    cards.map((card) => {
      card.addEventListener("click", () => {
        handleClick(card);
      });
    });
    //-------------------------------------------------------------------//
  },
  methods: {
    closeModalViaje() {
      this.modal = false;
    },
    changeEstatusOrigen() {
      this.estatusOrigen = true;
    },
    changeEstatusDestino() {
      this.estatusDestino = true;
    },

    setPlaceOrigin(place) {
      var paisOrigen =
        place.address_components[place.address_components.length - 1].long_name;
      var nombreDirection = place.name;

      this.formulario.origen = nombreDirection;
      this.formulario.pais_origen = paisOrigen;
    },
    setPlaceDestino(place) {
      var paisDestino =
        place.address_components[place.address_components.length - 1].long_name;
      var nombreDirection = place.name;

      this.formulario.destino = nombreDirection;
      this.formulario.pais_destino = paisDestino;
    },
    validateFormTravel() {
      const origen = this.formulario.origen;
      const destino = this.formulario.destino;
      const fecha = this.formulario.fecha;

      if (origen.length !== 0) {
        if (destino.length !== 0) {
          if (fecha.length !== 0) {
            const fechaDestino = new Date(fecha);
            // 01, 02, 03, ... 29, 30, 31
            var dd =
              (fechaDestino.getDate() < 10 ? "0" : "") + fechaDestino.getDate();
            // 01, 02, 03, ... 10, 11, 12
            var MM =
              (fechaDestino.getMonth() + 1 < 10 ? "0" : "") +
              (fechaDestino.getMonth() + 1);
            // 1970, 1971, ... 2015, 2016, ...
            var yyyy = fechaDestino.getFullYear();
            this.formulario.fecha = String(yyyy + "-" + MM + "-" + dd);

            return true;
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese la fecha de llegada";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese el destino del viaje y selecciónela";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el origen del viaje y selecciónela";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    formTravel() {
      const result = this.validateFormTravel();
      if (result) {
        axios
          .post(`${BASE_URL}` + "client/addTravel", {
            client_id: this.iduser,
            origin: this.formulario.origen,
            destination: this.formulario.destino,
            arrival_date: this.formulario.fecha,
            country_origin: this.formulario.pais_origen,
            country_destination: this.formulario.pais_destino,
          })
          .then((response) => {
            if (response.status === 200) {
              this.formulario.origen = "";
              this.formulario.destino = "";
              this.formulario.fecha = "";
              this.estatusDestino = false;
              this.estatusOrigen = false;

              // this.modal = true;
              // this.$router.push('/user/viajes/' + true);
              this.$router.push({
                name: "User",
                params: { type: "viajes", added: true },
              });
            }
          })
          .catch((error) => {
            if (error) {
              this.modal = false;
              this.estatusDestino = false;
              this.estatusOrigen = false;
              this.formulario.origen = "";
              this.formulario.destino = "";
              this.formulario.fecha = "";
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al registrar el viaje";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    getUser(data) {
      this.iduser = data.globalID;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    //Obtiene si existe el localstorage
    getAuth() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data != null) {
        this.getUser(dataUser);
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style>
.pasos-card,
.pasos-card h3,
.pasos-card__number {
  transition: all 0.3s;
}

.pasos-card:hover h3 {
  color: #de1a3e;
}

.pasos-card:hover .pasos-card__number {
  background-color: rgba(222, 26, 62, 0.1);
}

.pasos-card:hover .pasos-card__number > span {
  background-color: #de1a3e;
}

.pasos-card__active h3 {
  color: #de1a3e;
}
.pasos-card__active .pasos-card__number > span {
  background-color: #de1a3e;
}

.pasos-card__active .pasos-card__number {
  background-color: rgba(222, 26, 62, 0.1);
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
 

  background-image: url("../assets/icons/icon-fecha.svg");
  background-size: 20px;
} */
</style>
