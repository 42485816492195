<template>
  <div
    v-if="travelAdded"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="mx-2 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm">
      <img
        src="../../assets/images/icon-viajeros-modal.svg"
        class="mx-auto mb-10"
        alt="Ganar dinero viajando"
      />
      <h3 class="text-xl xl:text-3xl font-semibold mb-5 text-center">
        Gracias por registrar tu viaje
      </h3>
      <p class="text-sm xl:text-base text-center mb-10">
        En las próximas horas se comunicarán contigo
        <br class="hidden sm:block" />
        para coordinar los detalles del viaje
      </p>
      <button
        @click.prevent="closeModalViaje"
        class="block py-5 w-full rounded-2xl bg-main-red text-white font-bold text-xs xl:text-base"
      >
        Continuar
      </button>
    </div>
  </div>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <div
        class="flex gap-10 items-center mb-10 justify-between xl:justify-start"
      >
        <h3 class="text-xl xl:text-3xl font-bold text-main-red">Mis viajes</h3>
        <a
          @click.prevent="viewContacto"
          class="flex gap-3 items-center font-medium xl:font-semibold text-xs xl:text-sm py-4 px-6 bg-main-green rounded-2xl cursor-pointer text-white"
        >
          <img src="../../assets/icons/plus-white.svg" alt="" />
          Añadir viaje
        </a>
      </div>
      <div>
        <header
          class="grid xl:grid-cols-12 gap-5 xl:gap-3 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
        >
          <p class="text-center xl:hidden">Dashboard</p>
          <p class="hidden xl:block col-span-1"></p>
          <p class="hidden xl:block col-span-3">Viaje desde</p>
          <p class="hidden xl:block col-span-3">Viaje a</p>
          <p class="hidden xl:block col-span-2">Fecha de viaje</p>
          <p class="hidden xl:block col-span-2">Recompensa</p>
          <p class="hidden xl:block col-span-1"></p>
        </header>
        <div v-if="!loading">
          <div
            v-for="(travel, index) in filteredTravels"
            :key="travel.client_travels_id"
          >
            <details>
              <summary
                class="grid grid-cols-6 xl:grid-cols-12 gap-5 xl:gap-3 xl:grid-cols-12 p-5 border-b text-sm xl:text-base xl:items-center"
              >
                <div class="col-span-3 xl:col-span-1 order-1">
                  <img
                    v-if="travel.country_destination === 'Estados Unidos'"
                    src="../../assets/icons/bandera-usa.svg"
                    class="mr-auto xl:mx-auto w-10 h-10 rounded-full object-cover border shadow-md"
                  />
                  <img
                    v-else-if="travel.country_destination === 'Perú'"
                    src="../../assets/icons/bandera-peru.svg"
                    class="mr-auto xl:mx-auto w-10 h-10 rounded-full object-cover border shadow-md"
                  />
                  <img
                    v-else
                    src="../../assets/icons/icon-info.svg"
                    class="mr-auto xl:mx-auto w-10 h-10 rounded-full object-cover border shadow-md"
                  />
                </div>
                <p class="col-span-3 text-xs xl:text-base order-3 xl:order-2">
                  <span class="font-semibold block xl:hidden mb-2"
                    >Viaje desde</span
                  >
                  <span class="block font-medium xl:font-semibold">{{
                    travel.country_origin
                  }}</span>
                  <span class="xl:text-sm opacity-80">{{ travel.origin }}</span>
                </p>
                <p class="col-span-3 text-xs xl:text-base order-4 xl:order-3">
                  <span class="font-semibold block xl:hidden mb-2"
                    >Viaje a</span
                  >
                  <span class="block font-medium xl:font-semibold">{{
                    travel.country_destination
                  }}</span>
                  <span class="xl:text-sm opacity-80">{{
                    travel.destination
                  }}</span>
                </p>
                <p
                  class="col-span-3 xl:col-span-2 text-xs xl:text-base order-5 xl:order-4"
                >
                  <span class="font-semibold block xl:hidden mb-2"
                    >Fecha de viaje</span
                  >
                  {{ travel.arrival_date }}
                </p>
                <p
                  class="col-span-3 xl:col-span-2 text-xs xl:text-base order-6 xl:order-5"
                >
                  <span class="font-semibold block xl:hidden mb-2"
                    >Recompensa</span
                  >
                  <span
                    v-if="Number(travel.recompensa) === 0"
                    class="font-medium"
                  >
                    <span class="hidden xl:block tooltip">
                      <img src="../../assets/icons/question.svg" />
                      <span class="tooltip-box xl:-left-10"
                        >Antes de obtener recompensas, debes atrapar
                        pedidos</span
                      >
                    </span>
                    <span class="text-xs xl:hidden"
                      >Antes de obtener recompensas, debes atrapar pedidos</span
                    >
                  </span>
                  <span v-else
                    ><span class="text-main-red font-bold">USD</span>
                    {{ Number(travel.recompensa).toFixed(2) }}</span
                  >
                </p>
                <div
                  class="col-span-3 xl:col-span-1 flex items-center gap-5 order-2 xl:order-6 justify-end xl:justify-start"
                >
                  <button
                    @click="showOptions('tooltip-' + index)"
                    type="button"
                    class="relative"
                  >
                    <img
                      src="../../assets/icons/config-icon.png"
                      class="buttonOptions"
                    />
                    <span
                      :id="'tooltip-' + index"
                      class="oculto optionsTravel absolute bg-white border shadow-lg py-2 top-4 -left-40 rounded-xl text-left transition-opacity"
                    >
                      <router-link
                        @click="
                          sendIdFromAtraparPedidos(travel.client_travels_id)
                        "
                        :to="{
                          name: 'User',
                          params: {
                            type: 'atraparPedidos',
                            idTravel: travel.client_travels_id,
                          },
                        }"
                        class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-main-blue hover:bg-opacity-20"
                      >
                        Atrapar pedidos
                      </router-link>
                      <router-link
                        :to="{
                          name: 'Ayuda',
                        }"
                        class="block text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                      >
                        Ayuda
                      </router-link>
                      <a
                        @click="
                          openModal({
                            idModal: 'modal-cancelar-viaje',
                            idTravel: travel.client_travels_id,
                          })
                        "
                        v-if="travel.purchase_orders.length === 0"
                        class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-main-red hover:bg-opacity-20"
                        href="#"
                        >Cancelar viaje</a
                      >
                    </span>
                  </button>
                  <span
                    :class="travel.purchase_orders.length == 0 && 'oculto'"
                    class="cursor-pointer px-2"
                  >
                    <img src="../../assets/icons/arrow-down-black.svg" alt="" />
                  </span>
                </div>
              </summary>
              <div
                v-if="travel.purchase_orders.length > 0"
                class="p-5 xl:px-10 shadow-sm rounded-md grid gap-8"
              >
                <div v-for="order in travel.purchase_orders" :key="order.id">
                  <header class="flex items-center justify-between gap-6 mb-4">
                    <div class="flex items-center gap-6">
                      <figure>
                        <img
                          v-if="order.client.profile_image_url"
                          :src="order.client.profile_image_url"
                          class="w-8 h-8 object-cover rounded-full"
                        />
                        <img
                          v-else
                          src="../../assets/icons/user-login.svg"
                          class="w-8 h-8 object-cover rounded-full"
                        />
                      </figure>
                      <p class="text-xs xl:text-base">
                        <b>{{ order.client.profile.name }}</b>
                        <br class="xl:hidden" />
                        <span class="opacity-70 xl:pl-3">{{
                          order.delivery_date
                        }}</span>
                      </p>
                    </div>
                    <button
                      type="button"
                      class="relative"
                      @click="showOptionsOrder('optionOrder-' + order.id)"
                    >
                      <img
                        src="../../assets/icons/icon-tres-puntos.svg"
                        class="transform rotate-90 buttonOptionsOrder"
                      />
                      <span
                        :id="'optionOrder-' + order.id"
                        class="oculto optionsOrders absolute bg-white border shadow-lg py-2 top-4 -left-40 rounded-xl text-left transition-opacity"
                      >
                        <a
                          @click="
                            openModal({
                              idModal: 'modal-liberar-pedido',
                              idOrder: order.id,
                              idTravel: travel.client_travels_id,
                            })
                          "
                          class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-main-blue hover:bg-opacity-20"
                        >
                          Liberar pedido
                        </a>
                        <a
                          v-if="!order.qualification"
                          @click="
                            calificarComprador({
                              id_emisor: travel.client_id,
                              id_receptor: order.client_id,
                              id_order: order.id,
                            })
                          "
                          class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-main-blue hover:bg-opacity-20 w-max"
                        >
                          Calificar comprador
                        </a>
                        <a
                          v-else
                          @click="
                            openFilledModal({
                              score: order.qualification.score,
                              review: order.qualification.review,
                            })
                          "
                          class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-main-blue hover:bg-opacity-20 w-max"
                        >
                          Ver calificación
                        </a>
                      </span>
                    </button>
                  </header>
                  <article class="grid grid-cols-12 gap-4 xl:gap-6 items-start">
                    <figure
                      :class="order.product_image_url && 'border shadow-sm'"
                      class="p-4 col-span-12 xl:col-span-4"
                    >
                      <img
                        v-if="order.product_image_url"
                        :src="order.product_image_url"
                        class="mx-auto"
                      />
                      <img
                        v-else
                        src="../../assets/images/caja-paso-4.svg"
                        class="w-full"
                      />
                    </figure>
                    <div class="col-span-12 xl:col-span-8 xl:p-4">
                      <h3
                        class="font-bold text-sm xl:text-lg line-clamp-2 mb-4"
                      >
                        {{ order.product_info }}
                      </h3>
                      <p class="text-xs xl:text-sm mb-2">
                        Entregar en
                        <span class="font-medium">{{
                          travel.destination
                        }}</span>
                      </p>
                      <p class="text-xs xl:text-sm mb-2">
                        Entregar desde
                        <span class="font-medium">{{ travel.origin }}</span>
                      </p>
                      <p class="text-xs xl:text-sm mb-6">
                        Antes de
                        <span class="font-medium">{{
                          order.delivery_date
                        }}</span>
                      </p>
                      <div
                        class="p-5 rounded-md bg-blue-100 bg-opacity-80 mb-6"
                      >
                        <p class="text-xs xl:text-base mb-2">
                          Donde comprar
                          <a
                            target="_blank"
                            :href="order.reference_link"
                            class="font-semibold text-blue-500"
                            >{{ getStore(order.reference_link) }}</a
                          >
                        </p>
                        <p class="text-xs xl:text-base">
                          Precio del producto:
                          <span class="font-semibold"
                            ><span class="text-main-red">USD</span>
                            {{ order.client_price.toFixed(2) }}</span
                          >
                          + Sales tax:
                          <span class="font-semibold"
                            ><span class="text-main-red">USD</span>
                            {{ order.real_tax.toFixed(2) }}</span
                          >
                        </p>
                      </div>
                      <div class="flex items-center justify-between mb-4">
                        <p class="text-xs xl:text-base font-medium">
                          Recompensa de entrega
                        </p>
                        <p class="text-sm xl:text-2xl font-bold">
                          <span class="text-main-red">USD</span>
                          {{ order.igv.toFixed(2) }}
                        </p>
                      </div>

                      <label
                        v-if="
                          !order.ordervouchersusa ||
                          order.ordervouchersusa.length == 0
                        "
                        class="xl:mx-16 cursor-pointer font-semibold text-white rounded-md bg-main-orange py-5 text-xs xl:text-base flex items-center justify-center gap-4"
                      >
                        <input
                          type="file"
                          class="hidden"
                          @change="factura($event, order.id)"
                          ref="fileupload"
                          accept="image/*,.pdf"
                        />
                        <img src="../../assets/icons/subir-archivo.png" />
                        Agregar factura comercial
                      </label>
                      <template v-else>
                        <div
                          class="mb-6 xl:mx-16 font-semibold rounded-md bg-main-green text-main-green ring ring-main-green bg-opacity-20 py-5 text-xs xl:text-base flex items-center justify-center gap-4 cursor-pointer"
                        >
                          Factura comercial cargada
                          <img
                            src="../../assets/icons/check-green.png"
                            class="w-6"
                          />
                        </div>
                        <div
                          class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
                        >
                          <span
                            v-for="(invoice, index) in order.ordervouchersusa"
                            :key="invoice"
                            class="cursor-pointer"
                          >
                            <a
                              :href="invoice.url"
                              target="_Blank"
                              class="flex items-center gap-4"
                            >
                              <img src="../../assets/icons/file.svg" alt="" />
                              Invoice-{{ index + 1 }}
                              <span class="tooltip ml-auto">
                                <img
                                  v-if="invoice.status === 1"
                                  src="../../assets/icons/check-green.png"
                                />
                                <img
                                  v-if="invoice.status === 0"
                                  src="../../assets/icons/error-orange.png"
                                />

                                <span class="tooltip-box">
                                  {{
                                    invoice.status === 1
                                      ? "Factura aprobada"
                                      : "Factura en revisión"
                                  }}
                                </span>
                              </span>
                            </a>
                            <hr class="my-3" />
                          </span>
                          <label
                            class="cursor-pointer flex items-center justify-center gap-4 text-blue-500 text-xsxl:text-base"
                          >
                            <input
                              type="file"
                              class="hidden"
                              @change="factura($event, order.id)"
                              ref="fileupload"
                              accept="image/*,.pdf"
                            />
                            <img src="../../assets/icons/plus-blue.svg" />
                            Agregar otra factura comercial
                          </label>
                        </div>
                      </template>
                    </div>
                  </article>
                </div>
              </div>
            </details>
          </div>
        </div>

        <!-- Loader -->
        <p v-if="loading" class="font-medium text-center pb-8 pt-14">
          Cargando sus viajes...
        </p>
        <!-- Sino tiene viajes -->
        <p
          class="font-medium text-center pb-8 pt-14"
          v-if="travels.length === 0 && !loading"
        >
          No tienes viajes registrados
        </p>
        <!-- Pagination -->
        <div
          class="mt-12 flex justify-end items-center gap-12 col-start-3 xl:col-start-auto"
        >
          <p>
            Pág.
            <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
              page + 1
            }}</span>
            de {{ totalPages }}
          </p>
          <div class="flex items-center gap-4">
            <figure
              v-if="page > 0"
              @click="getPrevPage"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-left-bold.svg" />
            </figure>
            <figure
              v-if="page + 1 < totalPages"
              @click="getNextPage"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-right-bold.svg" />
            </figure>
          </div>
        </div>
      </div>
    </article>

    <!-- Modal cancelar viaje -->
    <div
      id="modal-cancelar-viaje"
      class="oculto fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
    >
      <div
        class="mx-4 px-8 xl:px-24 py-16 xl:py-24 bg-white rounded-2xl border shadow-sm"
      >
        <p
          class="text-gray-400 font-semibold xl:text-lg text-center mb-8 xl:mb-12 px-12 xl:px-28"
        >
          ¿Está seguro de cancelar su viaje?
        </p>
        <button
          @click="deleteTravel(travelToDelete)"
          class="block mb-6 text-center w-full text-white bg-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
        >
          Si
        </button>
        <button
          @click="closeModal('modal-cancelar-viaje')"
          class="block text-center w-full text-main-red border-2 border-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
        >
          No
        </button>
      </div>
    </div>

    <!-- Liberar pedido -->
    <div
      id="modal-liberar-pedido"
      class="oculto fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
    >
      <div
        class="mx-2 bg-white px-6 py-8 xl:py-10 w-full xl:w-1/3 rounded-2xl relative"
      >
        <p
          class="font-semibold xl:text-lg text-center mb-8 xl:mb-12 px-12 xl:px-28"
        >
          ¿Liberar pedido?
        </p>
        <form v-on:submit.prevent="deleteOrder(orderToDelete)">
          <div class="mb-8">
            <label
              for="descripcion_producto"
              class="block mb-2 font-semibold text-xs xl:text-sm"
            >
              ¿Por qué deseas liberar este pedido?
              <span class="text-main-green">(Opcional)</span>
            </label>
            <textarea
              v-model="orderReason"
              id="descripcion_producto"
              class="py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
              placeholder="Escribe aquí..."
              rows="3"
            ></textarea>
          </div>

          <!-- BUTTONS -->
          <button
            type="submit"
            class="block btn bg-main-green text-white py-5 w-10/12 mx-auto mb-4 font-bold rounded-2xl"
          >
            Liberar
          </button>
        </form>

        <img
          @click="closeModal('modal-liberar-pedido')"
          class="absolute -right-8 -top-6 cursor-pointer"
          src="../../assets/icons/cerrar-box.png"
          alt="Cerrar"
        />
      </div>
    </div>

    <!-- Calificar comprador -->
    <div
      id="calificar-comprador"
      @click.prevent="detectClick"
      :class="!calificarCompradorModal && 'oculto'"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
    >
      <article
        class="mx-4 px-4 xl:px-8 py-6 xl:py-12 bg-white rounded-2xl border shadow-sm w-full xl:w-1/3"
      >
        <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
          Calificar comprador
        </h3>
        <article class="flex items-center justify-center mb-4">
          <div>
            <img
              v-if="starActive >= 1 || starHoverActive >= 1"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
              @mouseleave="handleLeaveStar"
              @click="selectStar(1)"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
              @mouseover="handleHoverStar(1)"
            />
          </div>
          <div>
            <img
              v-if="starActive >= 2 || starHoverActive >= 2"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
              @mouseleave="handleLeaveStar"
              @click="selectStar(2)"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
              @mouseover="handleHoverStar(2)"
            />
          </div>
          <div>
            <img
              v-if="starActive >= 3 || starHoverActive >= 3"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
              @mouseleave="handleLeaveStar"
              @click="selectStar(3)"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
              @mouseover="handleHoverStar(3)"
            />
          </div>
          <div>
            <img
              v-if="starActive >= 4 || starHoverActive >= 4"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
              @mouseleave="handleLeaveStar"
              @click="selectStar(4)"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
              @mouseover="handleHoverStar(4)"
            />
          </div>
          <div>
            <img
              v-if="starActive >= 5 || starHoverActive >= 5"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
              @mouseleave="handleLeaveStar"
              @click="selectStar(5)"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
              @mouseover="handleHoverStar(5)"
            />
          </div>
        </article>
        <article class="relative mb-6">
          <textarea
            v-model="qualifications.data.description"
            :class="qualifications.error.description && 'ring ring-main-red'"
            class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
            placeholder="Excelente servicio, rápido y eficiente."
            rows="3"
          ></textarea>
          <p
            class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
          >
            Cuentanos que te parecio el servicio.
          </p>
        </article>
        <button
          @click="createCalification"
          class="border-2 border-main-green text-main-green hover:bg-main-green hover:text-white focus:bg-main-green focus:text-white transition-colors rounded-md p-4 font-semibold block w-full text-sm xl:text-base focus:outline-none"
        >
          Enviar
        </button>
      </article>
    </div>
    <!-- Ver califiicacion -->
    <div
      id="filledModal"
      @click.prevent="detectClick"
      :class="!stateFilledModal && 'oculto'"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
    >
      <article
        class="mx-4 px-4 xl:px-8 py-6 xl:py-12 bg-white rounded-2xl border shadow-sm w-full xl:w-1/3"
      >
        <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
          Calificación de viajero
        </h3>
        <article class="flex items-center justify-center mb-4">
          <div>
            <img
              v-if="qualifications.dataFilled.score >= 1"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
            />
          </div>
          <div>
            <img
              v-if="qualifications.dataFilled.score >= 2"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
            />
          </div>
          <div>
            <img
              v-if="qualifications.dataFilled.score >= 3"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
            />
          </div>
          <div>
            <img
              v-if="qualifications.dataFilled.score >= 4"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
            />
          </div>
          <div>
            <img
              v-if="qualifications.dataFilled.score >= 5"
              src="../../assets/icons/calificado.png"
              class="cursor-pointer px-3 py-2"
            />
            <img
              v-else
              src="../../assets/icons/calificar.png"
              class="cursor-pointer px-3 py-2"
            />
          </div>
        </article>
        <article class="relative">
          <textarea
            v-model="qualifications.dataFilled.description"
            class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
            readonly
            rows="3"
          ></textarea>
          <p
            class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
          >
            Esta es la calificación que dejaste a este viajero
          </p>
        </article>
      </article>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
// const PAGE = 0;
// const LIMIT = 4;
// const OFFSET = PAGE * LIMIT;

export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      travels: [],
      filteredTravels: [],
      page: 0,
      limit: 4,
      offset: this.page * this.limit,
      totalPages: 0,
      openTravelIndex: null,

      imageVaucher: "",
      travelToDelete: null,
      orderToDelete: null,
      orderReason: "",

      travelAdded: this.$route.params.added,

      receptor_id: null,
      emisor_id: null,
      order_id: null,
      starActive: 0,
      starHoverActive: 0,
      calificarCompradorModal: false,
      stateFilledModal: false,
      qualifications: {
        data: {
          title: "Buen servicio",
          description: "",
        },
        error: {
          description: false,
        },
        dataFilled: {
          description: "TEST",
          score: 3,
        },
      },
    };
  },
  created() {
    this.getTravels();
    window.addEventListener("click", (e) => {
      this.closeAllOptions(e);
    });
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    viewContacto() {
      this.$router.push({ name: "Contacto" });
    },
    //Servicio para obtener los viajes del cliente
    getTravels() {
      // const API_URL = `${BASE_URL}client/${this.id}/listTravels`
      const API_URL_TWO = `${BASE_URL}client/${this.id}/listTravels2`;
      axios
        .get(API_URL_TWO)
        .then((res) => {
          this.travels = res.data.body.data;
          this.totalPages = Math.ceil(this.travels.length / this.limit);
          this.loading = false;
          this.filteredTravels = this.travels.slice(
            this.offset,
            (this.page + 1) * this.limit
          );
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al listar sus viajes";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    // FLecha de siguiente
    getNextPage() {
      this.page = this.page + 1;
      this.offset = this.page * this.limit;

      this.filteredTravels = this.travels.slice(
        this.offset,
        (this.page + 1) * this.limit
      );

      // Cerrar detalles
      this.openTravelIndex = null;
    },
    // Flecha de atras
    getPrevPage() {
      this.page = this.page - 1;
      this.offset = this.page * this.limit;

      this.filteredTravels = this.travels.slice(
        this.offset,
        (this.page + 1) * this.limit
      );

      // Cerrar detalles
      this.openTravelIndex = null;
    },
    showOptions(idTooltip) {
      const tooltips = [...document.querySelectorAll(".optionsTravel")];
      const tooltipAbierto = tooltips.find(
        (tooltip) => !tooltip.matches(".oculto")
      );
      if (tooltipAbierto && !tooltipAbierto.matches(`#${idTooltip}`)) {
        tooltipAbierto.classList.add("oculto");
      }

      const tooltip = document.getElementById(idTooltip);
      tooltip.classList.toggle("oculto");
    },
    showOptionsOrder(idTooltip) {
      const tooltips = [...document.querySelectorAll(".optionsOrders")];
      const tooltipAbierto = tooltips.find(
        (tooltip) => !tooltip.matches(".oculto")
      );
      if (tooltipAbierto && !tooltipAbierto.matches(`#${idTooltip}`)) {
        tooltipAbierto.classList.add("oculto");
      }

      const tooltip = document.getElementById(idTooltip);
      tooltip.classList.toggle("oculto");
    },
    closeAllOptions(e) {
      if (!e.target.matches("img.buttonOptions")) {
        const tooltips = [...document.querySelectorAll(".optionsTravel")];
        const tooltipAbierto = tooltips.find(
          (tooltip) => !tooltip.matches(".oculto")
        );
        if (tooltipAbierto) tooltipAbierto.classList.add("oculto");
      }

      if (!e.target.matches("img.buttonOptionsOrder")) {
        const tooltips = [...document.querySelectorAll(".optionsOrders")];
        const tooltipAbierto = tooltips.find(
          (tooltip) => !tooltip.matches(".oculto")
        );
        if (tooltipAbierto) tooltipAbierto.classList.add("oculto");
      }
    },
    factura(e, orderId) {
      const file = e.target.files[0];
      this.cargarImagen(file, orderId);
    },
    cargarImagen(file, orderId) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageVaucher = e.target.result;
        this.sendVaucher(orderId);
      };
      reader.readAsDataURL(file);
    },
    sendVaucher(orderId) {
      axios
        .put(`${BASE_URL}` + "purchaseOrder/update-order-no-grabr/" + orderId, {
          voucher_usa: this.imageVaucher,
        })
        .then((response) => {
          if (response.status === 200) {
            // this.$refs.fileupload.value = null;
            // this.imageVaucher = "";
            // this.data.vaucher = true;
            // this.invoices.push(response.data.body.attributes.voucher_usa_link);
            //----------component--------//
            // this.$store.state.alert.titulo = "Éxito";
            window.location.reload();
            this.$store.state.alert.description = "Factura comercial enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//

            // location.reload();
            // this.closeModal();
            // this.exito = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.imageVaucher = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su factura comercial";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    deleteTravel(id) {
      const API_URL = `${BASE_URL}client/deleteTravel/${id}`;
      axios
        .delete(API_URL)
        .then((res) => {
          if (res.status === 200) {
            this.closeModal("modal-cancelar-viaje");
            this.loading = true;
            this.getTravels();
          }
        })
        .catch((err) => {
          if (err) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al cancelar viaje";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    deleteOrder(id) {
      const API_URL = `${BASE_URL}client/unlinkTravel/${id}`;
      axios
        .delete(API_URL, {
          params: {
            travel_client_id: this.travelToDelete,
            reason: this.orderReason,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.closeModal("modal-liberar-pedido");
            this.loading = true;
            this.getTravels();
          }
        })
        .catch((err) => {
          if (err) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al liberar pedido";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    openModal({ idModal, idTravel = null, idOrder = null }) {
      const modal = document.getElementById(idModal);
      modal.classList.remove("oculto");

      this.travelToDelete = idTravel;
      this.orderToDelete = idOrder;
    },
    closeModal(id) {
      const modal = document.getElementById(id);
      modal.classList.add("oculto");

      this.travelToDelete = null;
      this.orderToDelete = null;
    },
    getStore(link) {
      if (link !== null) {
        var separation1 = link.split(".", 2);
        if (separation1[0] !== "https://www") {
          var separation2 = link.split("//", 3);
          var separation3 = separation2[1].split(".", 1);

          return separation3[0];
        }

        return separation1[1][0].toUpperCase() + separation1[1].substring(1);
      }
      return "Sin store";
    },

    closeModalViaje() {
      this.travelAdded = false;
    },

    // Guardar ID del viaje en el local storage para atraparpedidos
    sendIdFromAtraparPedidos(id) {
      localStorage.setItem("atraparPedidosID", id);
    },

    // Abrir modal de calificar comprador
    calificarComprador({ id_receptor, id_emisor, id_order }) {
      this.calificarCompradorModal = true;
      this.receptor_id = id_receptor;
      this.emisor_id = id_emisor;
      this.order_id = id_order;
    },
    //Detecta si da click fuera del input para que se cierre
    detectClick(e) {
      const data = e.target.matches("#calificar-comprador, #filledModal");
      if (data) {
        this.calificarCompradorModal = false;
        this.stateFilledModal = false;
        this.starActive = 0;
        this.starHoverActive = 0;
        this.receptor_id = null;
        this.emisor_id = null;
        this.order_id = null;
        this.qualifications.error.description = false;
        this.qualifications.data.description = "";
      }
    },
    // Detecta el evento para mostrar la estrella en dorado cuando se hace hover
    handleHoverStar(star) {
      this.starHoverActive = star;
    },
    handleLeaveStar() {
      this.starHoverActive = 0;
    },
    selectStar(star) {
      this.starActive = star;
    },
    // Crear calificacion de comprador
    createCalification() {
      const API_URL = `${BASE_URL}qualification/create-qualification`;
      const DATA = {
        titulo: this.qualifications.data.title,
        comentario: this.qualifications.data.description,
        puntaje: this.starActive,
        emisor: this.emisor_id,
        receptor: this.receptor_id,
        idorder: this.order_id,
        tipo: "Comprador",
      };

      // Validar que el campo descripcion no este vacio
      if (this.qualifications.data.description.trim() === "") {
        this.qualifications.error.description = true;
      } else {
        this.qualifications.error.description = false;
      }

      // Enviar data si el campo no esta vacio
      if (!this.qualifications.error.description) {
        axios
          .post(API_URL, DATA)
          .then((res) => {
            if (res.status === 200) {
              this.calificarCompradorModal = false;
              this.starActive = 0;
              this.starHoverActive = 0;
              this.receptor_id = null;
              this.emisor_id = null;
              this.qualifications.error.description = false;
              this.qualifications.data.description = "";

              //----------component--------//
              this.$store.state.alert.titulo = "Exito";
              this.$store.state.alert.description =
                "Calificación enviada con exito";
              this.$store.state.alert.estado = true;
              setTimeout(() => {
                location.reload();
              }, 700);
              //--------------------------//
            }
          })
          .catch((err) => {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al crear calificación";
            this.$store.state.alert.estado = true;
            //--------------------------//
            console.warn(err);
          });
      }
    },
    // Abrir modal lleno
    openFilledModal({ score, review }) {
      this.stateFilledModal = true;
      this.qualifications.dataFilled.description = review;
      this.qualifications.dataFilled.score = score;
    },
  },
};
</script>
