<template>
  <div
    v-if="directionDefault === 'current'"
    :class="directionType ? 'ring-main-green' : 'ring-gray-100'"
    class="relative ring hover:ring-main-green shadow-sm rounded-2xl mb-8"
  >
    <div
      @click.prevent="calculateDirection"
      :class="directionType && 'border-b border-main-green'"
      class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
    >
      <div class="text-text-blue grid gap-2">
        <span class="text-sm xl:text-base"
          >{{ data.nombre }} {{ data.apellido }}</span
        >
        <span class="text-sm xl:text-sm">{{ data.direccion }}</span>
        <span class="text-sm xl:text-sm">{{ data.departamento }}</span>
        <span class="text-sm xl:text-sm">{{ data.telefono }}</span>
        <span
          @click.prevent="changeStyleDirection"
          class="flex gap-3 items-center text-xs xl:text-sm text-main-red"
        >
          <span>Editar</span>
        </span>
      </div>
      <span
        :class="
          directionType
            ? 'bg-main-green text-white'
            : 'bg-gray-light text-gray-400'
        "
        class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
      >
        {{ directionType ? "Seleccionado" : "Seleccionar" }}
      </span>
    </div>

    <div :class="style.direction ? '' : 'hidden'" class="px-2 py-6">
      <div class="xl:grid grid-cols-2 gap-3">
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Nombre</span
          >
          <input
            :class="error.name ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Ingrese el nombre"
            v-model="data.nombre"
          />
        </label>
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Apellidos</span
          >
          <input
            :class="error.apellido ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Ingrese el apellido"
            v-model="data.apellido"
          />
        </label>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Documento</span
          >
          <input
            :class="error.documento ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="tel"
            placeholder="Ingrese el documento"
            v-model="data.documento"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </label>
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Teléfono</span
          >
          <input
            :class="error.telefono ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="number"
            placeholder="Ingrese el teléfono"
            v-model="data.telefono"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </label>
      </div>
      <label class="relative block mb-5" v-if="data.state">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Dirección</span
        >
        <input
          :class="error.direccion ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese la dirección"
          v-model="data.direccion"
          @keypress="stateGmapAUtocomplete"
        />
        <div class="absolute right-3 inset-y-0 flex items-center">
          <img
            src="../../../assets/icons/icon-global.svg"
            class="cursor-pointer"
            @click.prevent="directionMap"
            alt="Icono de Envios USA"
          />
        </div>
      </label>

      <label class="relative block mb-5" v-if="data.state === false">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Dirección</span
        >

        <GMapAutocomplete
          placeholder="Ingrese la dirección"
          @place_changed="setPlace"
          :class="error.direccion ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        >
        </GMapAutocomplete>
        <div class="absolute right-3 inset-y-0 flex items-center">
          <img
            src="../../../assets/icons/icon-global.svg"
            class="cursor-pointer"
            alt="Icono de Envios USA"
            @click.prevent="directionMap"
          />
        </div>
      </label>
      <label class="relative block mb-5" v-if="statusMap">
        <figure style="height: 250px" class="mb-6 mt-6">
          <GMapMap
            :center="center"
            :zoom="15"
            map-type-id="terrain"
            class="h-full"
          >
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
              />
            </GMapCluster>
          </GMapMap>
        </figure>
      </label>
      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Piso u Oficina</span
        >
        <input
          :class="error.piso ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="1502"
          v-model="data.piso"
        />
      </label>
      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Referencia</span
        >
        <input
          :class="error.referencia ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingresar la referencia"
          v-model="data.referencia"
        />
      </label>

      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Departamento</span
        >
        <input
          :class="error.departamento ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese el departamento"
          v-model="data.departamento"
        />
      </label>
      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Provincia</span
        >
        <input
          :class="error.provincia ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese la provincia"
          v-model="data.provincia"
        />
      </label>
      <label class="relative block mb-8">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Distrito</span
        >
        <input
          :class="error.distrito ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese el distrito"
          v-model="data.distrito"
        />
      </label>
      <button
        @click.prevent="updateDirection"
        class="bg-main-green block w-full text-center p-5 font-bold rounded-2xl text-white"
      >
        Guardar
      </button>
    </div>
    <div class="absolute inset-x-0 -bottom-4">
      <figure
        :class="style.direction ? 'flex' : 'hidden'"
        @click.prevent="changeStyleDirection"
        class="cursor-pointer mx-auto w-8 h-8 p-2 rounded-full bg-white border-2 shadow-lg items-center justify-center"
      >
        <img
          :class="
            style.direction || directionType ? '' : 'transform rotate-180'
          "
          src="../../../assets/icons/drop-up-blue.svg"
          alt="Icono de Envios USA"
        />
      </figure>
    </div>
  </div>
</template>
<script>
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["typeDirection"],
  data() {
    return {
      statusMap: false,
      center: { lat: -12.080730407219086, lng: -77.03606970955586 },
      markers: [
        {
          position: {
            lat: -12.080730407219086,
            lng: -77.03606970955586,
          },
        }, // Along list of clusters
      ],
      style: {
        direction: false,
      },
      directionDefault: "",
      userId: 0,
      data: {
        state: false,
        id: 0,
        nombre: "",
        apellido: "",
        documento: "",
        telefono: "",
        direccion: "",
        referencia: "",
        piso: "",
        departamento: "",
        provincia: "",
        distrito: "",
        lat: 0,
        lng: 0,
        free: 0,
      },
      error: {
        direccion: false,
        documento: false,
        telefono: false,

        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
      },
    };
  },
  created() {
    this.getDataLocalStorage();
  },
  methods: {
    directionMap() {
      this.statusMap = !this.statusMap;
    },
    //validateData "nombre"- "apellido" - "piso" -"referencia" - "departamento" - "provincia" - "distrito"
    valData() {
      const name = this.data.nombre;
      const apellido = this.data.apellido;
      const piso = this.data.piso;
      const referencia = this.data.referencia;
      const departamento = this.data.departamento;
      const provincia = this.data.provincia;
      const distrito = this.data.distrito;
      if (name.length !== 0) {
        this.error.name = false;
        if (apellido.length !== 0) {
          this.error.apellido = false;
          if (piso.length !== 0) {
            this.error.piso = false;
            if (referencia.length !== 0) {
              this.error.referencia = false;
              if (departamento.length !== 0) {
                this.error.departamento = false;
                if (provincia.length !== 0) {
                  this.error.provincia = false;
                  if (distrito.length !== 0) {
                    this.error.distrito = false;
                    return true;
                  } else {
                    this.error.distrito = true;
                    //----------component--------//
                    this.$store.state.alert.titulo = "Validación";
                    this.$store.state.alert.description = "Ingrese el distrito";
                    this.$store.state.alert.estado = true;
                    //--------------------------//

                    return false;
                  }
                } else {
                  this.error.provincia = true;
                  //----------component--------//
                  this.$store.state.alert.titulo = "Validación";
                  this.$store.state.alert.description = "Ingrese la provincia";
                  this.$store.state.alert.estado = true;
                  //--------------------------//
                  return false;
                }
              } else {
                this.error.departamento = true;
                //----------component--------//
                this.$store.state.alert.titulo = "Validación";
                this.$store.state.alert.description = "Ingrese el departamento";
                this.$store.state.alert.estado = true;
                //--------------------------//
                return false;
              }
            } else {
              this.error.referencia = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description = "Ingrese una referencia";
              this.$store.state.alert.estado = true;
              //--------------------------//
              return false;
            }
          } else {
            this.error.piso = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese el piso u oficina";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.apellido = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese el apellido";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.name = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el nombre";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    // metodo para validar dirección
    valDirection() {
      const direction = this.data.direccion;
      const lat = this.data.lat;
      const lng = this.data.lng;
      if (direction.length !== 0) {
        this.error.direccion = false;
        if (lat !== 0) {
          this.error.direccion = false;
          if (lng !== 0) {
            this.error.direccion = false;
            return true;
          } else {
            this.error.direccion = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Ingrese una dirección  y selecciónela";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          this.error.direccion = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese una dirección  y selecciónela";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese una dirección  y selecciónela";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //validateData "documento " - "telefono" - "correo de contacto"
    varDataEspecial() {
      const documento = this.data.documento.toString();
      const telefono = this.data.telefono.toString();

      if (documento.length !== 0) {
        this.error.documento = false;
        if (documento.length >= 8) {
          this.error.documento = false;
          if (telefono.length !== 0) {
            this.error.telefono = false;
            if (telefono.length >= 9) {
              this.error.telefono = false;
              return true;
            } else {
              this.error.telefono = true;

              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "El teléfono debe ser mínimo 9 dígitos";
              this.$store.state.alert.estado = true;
              //--------------------------//
              return false;
            }
          } else {
            this.error.telefono = true;

            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese su teléfono";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.documento = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "El documento debe ser mínimo 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.documento = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el número de su documento";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //Actualizar dirección
    updateDirection() {
      const valDirection = this.valDirection();
      const valDataNumber = this.varDataEspecial();
      const valData = this.valData();
      // const valData = this.validateData();
      if (valDirection && valDataNumber && valData) {
        axios
          .post(`${BASE_URL}` + "client/update-client-sendi/" + this.userId, {
            name: this.data.nombre,
            surnames: this.data.apellido,
            cellphone: this.data.telefono,
            document_number: this.data.documento,
            address: this.data.direccion,
            inner: this.data.piso,
            reference: this.data.referencia,
            department: this.data.departamento,
            city: this.data.provincia,
            district: this.data.distrito,
            address_id: this.data.id,
            latitud: this.data.lat,
            longitud: this.data.lng,
          })
          .then((response) => {
            if (response.status === 200) {
              var updateData = response.data.body.attributes.addresses[0];

              this.data.nombre = updateData.attributes.owner_name;
              this.data.apellido = updateData.attributes.owner_lastname;
              this.data.telefono = updateData.attributes.telephone_number;
              this.data.documento = updateData.attributes.owner_document_number;
              this.data.direccion = updateData.attributes.address;
              this.data.piso = updateData.attributes.inner;
              this.data.referencia = updateData.attributes.reference;
              this.data.departamento = updateData.attributes.department;
              this.data.provincia = updateData.attributes.city;
              this.data.distrito = updateData.attributes.district;
              this.data.lat = updateData.attributes.lat;
              this.data.lng = updateData.attributes.lng;
              //-----------------------------//
              this.center.lat = updateData.attributes.lat;
              this.center.lng = updateData.attributes.lng;
              this.markers[0].position.lat = updateData.attributes.lat;
              this.markers[0].position.lng = updateData.attributes.lng;
              this.data.free = updateData.attributes.free;

              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Dirección actualizada";
              this.$store.state.alert.estado = true;
              //--------------------------//
              this.style.direction = false;
              this.$emit("existForm", this.style.direction);
              this.calculateDirection();
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al actualizar su dirección";
              this.$store.state.alert.estado = true;

              //--------------------------//
            }
          });
      }
    },
    //Obtener la dirección latitud y longitud del componente de google maps
    setPlace(place) {
      this.data.direccion = place.name;
      this.data.lat = place.geometry.location.lat();
      this.data.lng = place.geometry.location.lng();
      //---------------------------------//
      this.center.lat = place.geometry.location.lat();
      this.center.lng = place.geometry.location.lng();
      this.markers[0].position.lat = place.geometry.location.lat();
      this.markers[0].position.lng = place.geometry.location.lng();
      //_--------------------------------//
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";
      var location = 0;
      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }
        //Determinamos si la direcciòn pertenece a Lima
        if (
          value.long_name === "Callao Region" ||
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima" ||
          value.long_name === "Callao"
        ) {
          location = 1;
        }
      });
      if (country !== "PE" || country === "") {
        this.data.direccion = "";
        this.data.lat = 0;
        this.data.lng = 0;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.error.direccion = true;
        this.center.lat = -12.080730407219086;
        this.center.lng = -77.03606970955586;
        this.markers[0].position.lat = -12.080730407219086;
        this.markers[0].position.lng = -77.03606970955586;
      } else {
        this.error.direccion = false;
        this.calculateDirection();
      }
      this.data.departamento = departamento;
      this.data.provincia = provincia;
      this.data.distrito = distrito;
      this.data.free = location;
    },
    //Obtener la dirección del usuario
    getAddress() {
      axios
        .get(`${BASE_URL}` + "client/" + this.userId + "/listAddresses")
        .then((response) => {
          if (response.status === 200) {
            this.data.state = true;
            var listAddress = {};
            listAddress = response.data.body;

            listAddress.forEach((value) => {
              if (value.attributes.default === "current") {
                this.$emit("existDirection", true);

                this.directionDefault = "current";
                this.data.free = value.attributes.free;
                this.data.id = value.id;
                this.data.nombre =
                  value.attributes.owner_name === null
                    ? ""
                    : value.attributes.owner_name;

                this.data.apellido =
                  value.attributes.owner_lastname === null
                    ? ""
                    : value.attributes.owner_lastname;

                this.data.documento =
                  value.attributes.owner_document_number === null
                    ? ""
                    : value.attributes.owner_document_number;

                this.data.telefono =
                  value.attributes.telephone_number === null
                    ? ""
                    : value.attributes.telephone_number;

                this.data.direccion =
                  value.attributes.address === null
                    ? ""
                    : value.attributes.address;

                this.data.referencia =
                  value.attributes.reference === null
                    ? ""
                    : value.attributes.reference;

                this.data.piso =
                  value.attributes.inner === null ? "" : value.attributes.inner;

                this.data.departamento =
                  value.attributes.department === null
                    ? ""
                    : value.attributes.department;

                this.data.provincia =
                  value.attributes.city === null ? "" : value.attributes.city;

                this.data.distrito =
                  value.attributes.district === null
                    ? ""
                    : value.attributes.district;

                this.data.lat =
                  value.attributes.lat === null ? 0 : value.attributes.lat;
                this.data.lng =
                  value.attributes.lng === null ? 0 : value.attributes.lng;

                this.center.lat =
                  value.attributes.lat === null ? 0 : value.attributes.lat;
                this.center.lng =
                  value.attributes.lng === null ? 0 : value.attributes.lng;
                this.markers[0].position.lat =
                  value.attributes.lat === null ? 0 : value.attributes.lat;
                this.markers[0].position.lng =
                  value.attributes.lng === null ? 0 : value.attributes.lng;

                // this.markers[0].position.lat = value.attributes.lat;
                // this.markers[0].position.lng = value.attributes.lng;
                const direction = localStorage.getItem("direction");
                if (direction !== null) {
                  const dataDirection = JSON.parse(direction);

                  if (this.data.direccion === dataDirection.nombre) {
                    this.$emit("DirectionIguals", true);
                    this.calculateDirection();
                  } else {
                    this.$emit("DirectionIguals", false);
                    // this.calculateDirection();
                  }
                }
              }
            });
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al plasmar la dirección del usuario";
            this.$store.state.alert.estado = true;
            //--------------------------//

            this.directionDefault = "";
          }
        });
    },
    //cambia el estado dle input para que se muestre el otro componente
    stateGmapAUtocomplete() {
      this.data.state = false;
      this.data.direccion = "";
    },
    //obteniendo los datos del localstorage obtener el id del usuario
    getDataLocalStorage() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        this.userId = dataUser.globalID;
        this.getAddress();
      } else {
        this.directionDefault = "";
      }
    },
    //Cambiar el estado de style.direction()
    changeStyleDirection() {
      this.style.direction = !this.style.direction;
      // this.calculateDirection();
      this.$emit("existForm", this.style.direction);
      this.getAddress();
    },
    //Metodo que envia el tipo de dirección para el calculo de tarifa de servicio.
    //1->dirección existente
    //2->Nueva dirección
    //3->dirección default ->recogo en oficina
    calculateDirection() {
      const typeDirection = 1;
      this.$emit("dataTypeDirection", typeDirection);
      this.$emit("dataDirection", this.data.id);
      this.$emit("dataDirectionName", this.data);
      this.$emit("latitud", this.data.lat);
      this.$emit("longitud", this.data.lng);
      this.$emit("calculate");
      this.$emit("existForm", this.style.direction);
    },
    //Solo numeros
    onlyNumber($event) {
      if (
        $event.key === "-" ||
        $event.key === "," ||
        $event.key === "." ||
        $event.key === "+"
      ) {
        $event.preventDefault();
      }
    },
    formChangeDirection() {
      this.style.direction = false;
    },
  },
  computed: {
    directionType() {
      if (this.typeDirection === 1) {
        return true;
      } else {
        this.formChangeDirection();
        return false;
      }
    },
  },
};
</script>
