<template>
  <div
    class="text-sm xl:text-base border shadow-lg bg-white rounded-2xl overflow-hidden mb-10"
  >
    <div class="hidden xl:block p-5 text-white font-semibold bg-main-red mb-6">
      <p class="text-xs xl:text-base font-semibold text-center">
        Mapa en tiempo real
      </p>
    </div>
    <figure style="height: 700px" class="mb-6 mt-6 px-8 xl:px-16">
      <GMapMap class="h-full" :center="center" :zoom="3" map-type-id="terrain">
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="
              m.estado
                ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
            "
          />
        </GMapCluster>
      </GMapMap>
    </figure>
  </div>
</template>
<script>
export default {
  props: ["center", "markers"],
  data() {
    return {};
  },
};
</script>
