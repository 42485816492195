<template>
  <!-- //componente del header -->
  <header-component @openTracking="autentification.estateTracking = $event" />
  <!-- tracking -->
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
  <section class="section-flujo-compra">
    <article class="u-container py-14 grid xl:grid-cols-2 gap-8">
      <!-- Bloque de la izquierda -->
      <section>
        <article class="bg-white border shadow-lg rounded-2xl">
          <div
            class="bg-main-red text-white p-5 rounded-tl-2xl rounded-tr-2xl xl:mb-9"
          >
            <p
              class="text-xs xl:text-base font-semibold flex items-center justify-center gap-2"
            >
              Detalles de la compra
            </p>
          </div>
          <!-- Resumen -->
          <div
            class="px-4 py-6 xl:px-9 rounded-2xl bg-white xl:border xl:shadow-lg xl:border-none xl:shadow-none"
          >
            <h4
              class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center justify-between"
            >
              Detalles del producto
              <span class="text-xs xl:text-sm text-textColor">
                <span>N° orden:</span>
                <span class="ml-2 text-main-red">EUP{{ product.id }}</span>
              </span>
            </h4>
            <!-- Producto -->
            <div class="p-6 rounded-2xl border shadow-sm mb-8">
              <div class="flex items-center gap-4 mb-6">
                <img
                  v-if="product.img !== null"
                  class="w-16 h-16 xl:w-28 xl:h-28"
                  :src="product.img"
                  alt="Icono de Envios USA"
                />
                <img
                  v-else
                  class="w-16 h-16 xl:w-28 xl:h-28"
                  src="../assets/images/caja-paso-4.svg"
                  alt="Icono de Envios USA"
                />
                <div class="text-xs xl:text-base font-bold">
                  <!-- Estatus / Tipo de servicio -->
                  <div class="flex items-center gap-4 mb-4">
                    <span
                      class="hidden px-3 py-1 rounded-full text-xs bg-gray-400 w-max text-white font-semibold"
                      >Solicitud recibida</span
                    >
                    <span class="flex gap-3 items-center">
                      <img src="../assets/icons/icon-tracking-statu.png" />
                      <span>
                        <span
                          v-if="statusTracking === 1"
                          class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                        >
                          EN REVISIÓN</span
                        ><span
                          v-if="statusTracking === 2"
                          class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                        >
                          COMPRADO</span
                        ><span
                          v-if="statusTracking === 3"
                          class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                        >
                          PREPARANDO PARA ENVIAR</span
                        ><span
                          v-if="statusTracking === 4"
                          class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                        >
                          LISTO PARA ENVIAR</span
                        ><span
                          v-if="statusTracking === 5"
                          class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                        >
                          ENVIADO</span
                        ><span
                          v-if="statusTracking === 6"
                          class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                        >
                          LISTO PARA ENTREGAR</span
                        ><span
                          v-if="statusTracking === 7"
                          class="px-3 py-1 rounded-full text-xs bg-main-green bg-opacity-20 w-max text-main-green font-semibold"
                        >
                          ENTREGADO</span
                        ><span
                          v-if="statusTracking === 8"
                          class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
                        >
                          SINIESTRADO</span
                        ><span
                          v-if="statusTracking === 9"
                          class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
                        >
                          EN PROCESO DE DEVOLUCIÓN</span
                        ><span
                          v-if="statusTracking === 10"
                          class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                        >
                          RETORNADO AL PROVEEDOR</span
                        >
                      </span>
                    </span>

                    <span
                      class="px-3 py-1 rounded-full text-xs border border-main-orange w-max text-main-orange font-semibold"
                      >Servicio
                      {{ product.typeService === 1 ? "Lite" : "Premium" }}</span
                    >
                  </div>
                  <div class="flex items-start gap-4">
                    <p class="line-clamp-2">
                      {{ product.name }}
                    </p>
                    <a
                      :href="product.link"
                      target="_Blank"
                      v-if="product.link !== null"
                    >
                      <img
                        src="../assets/icons/icon-url.svg"
                        style="max-width: 32px"
                        alt="Icono de Envios USA"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="flex flex-col xl:flex-row gap-5 text-xs xl:text-base">
                <p class="flex items-center gap-3">
                  <span class="font-medium text-sm">Precio:</span>
                  <span class="font-bold"
                    ><span class="text-main-red">USD</span>
                    {{ product.precio }}</span
                  >
                </p>
                <p class="flex items-center gap-3">
                  <span class="font-medium text-sm">Cantidad:</span>
                  <span class="font-bold">{{ product.cantidad }}</span>
                </p>
                <p class="flex items-center gap-3">
                  <span class="font-medium text-sm">Peso:</span>
                  <span class="font-bold">{{ product.peso }} kg</span>
                </p>
              </div>
            </div>
            <!-- Dirección de entrega -->
            <hr class="my-4" />
            <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
              Dirección de entrega
            </h4>
            <div
              class="relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
              v-if="product.directionDefault === 0"
            >
              <div class="flex items-center gap-4 xl:gap-6 py-4 px-5 xl:p-6">
                <img src="../assets/icons/oficina.svg" alt="" />
                <div
                  class="flex items-center justify-between font-semibold cursor-pointer"
                >
                  <div class="text-text-blue">
                    <p class="text-sm xl:text-base mb-2">
                      {{ product.directionName }}
                      {{ product.directionApellido }}
                    </p>
                    <p class="text-xs xl:text-sm mb-2">
                      {{ product.directionAddres }}
                    </p>
                    <p class="text-xs xl:text-sm mb-2">
                      {{ product.directionDepartamento }}
                    </p>
                    <p class="text-xs xl:text-sm mb-2">
                      {{ product.directionThelephone }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Dirección de entrega CASO 2 -->
            <div
              class="relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
              v-if="product.directionDefault === 1"
            >
              <div
                class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
              >
                <p class="text-text-blue text-sm xl:text-base">
                  Recojo en Oficina
                </p>
              </div>
              <div
                class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
              >
                <div class="flex items-center gap-4 xl:gap-6">
                  <img src="../assets/icons/oficina.svg" alt="" />
                  <div>
                    <p class="text-sm font-bold">Lima</p>
                    <p class="text-xs text-gray-400">
                      Jr. Emilio Althaus Nro. 121 Oficina 403
                    </p>
                  </div>
                </div>
                <a
                  href="https://goo.gl/maps/e2vcgRdQ93BdyYrC7"
                  target="Blank"
                  class="ml-auto"
                >
                  <img
                    src="../assets/icons/ver-direccion.png"
                    class="ml-auto"
                  />
                </a>
              </div>
            </div>
            <!-- Métodos de pago -->
            <hr class="my-4" />
            <h4
              class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center gap-6"
            >
              Método de pago
              <span class="flex gap-3 items-center">
                <img src="../assets/icons/icon-pago-status.png" alt="" />
                <span>
                  <span
                    v-if="statusPayed === 1"
                    class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                    >PENDIENTE</span
                  >
                  <span
                    v-else-if="statusPayed === 2"
                    class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                    >EN PROCESO</span
                  >
                  <span
                    v-else-if="statusPayed === 3"
                    class="px-3 py-1 rounded-full text-xs bg-main-green bg-opacity-20 w-max text-main-green font-semibold"
                    >CONFIRMADO</span
                  >
                  <span
                    v-else-if="statusPayed === 10"
                    class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
                    >RECHAZADO"</span
                  >
                  <span
                    v-else-if="statusPayed === 11"
                    class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                    >RECLAMADO</span
                  >
                  <span
                    v-else-if="statusPayed === 12"
                    class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                    >RETORNADO</span
                  >
                </span>
              </span>
            </h4>
            <!-- <div class="relative p-4 xl:p-6 rounded-2xl border shadow-sm mb-8"> -->
            <div class="mb-8">
              <!-- Tarjeta de debito -->
              <div
                v-if="product.metodoDepago === 3"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1"
              >
                <label class="relative block">
                  <span
                    class="bg-white absolute left-4 -top-2 text-xs font-medium"
                    >Tarjeta de débito</span
                  >
                  <div
                    class="absolute inset-y-0 left-3 xl:left-5 flex items-center"
                  >
                    <img
                      src="../assets/icons/tarjetas-de-credito-gray.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                  <input
                    class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
                    type="text"
                    value="0000-0000-0000-0000"
                    readonly
                  />
                  <div
                    class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                  >
                    <img
                      class="w-6"
                      src="../assets/icons/visa.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                </label>
              </div>
              <!-- Tarjeta de debito -->
              <div
                class="hidden text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1"
              >
                <label class="relative block">
                  <span
                    class="bg-white absolute left-4 -top-2 text-xs font-medium"
                    >Tarjeta de crédito</span
                  >
                  <div
                    class="absolute inset-y-0 left-3 xl:left-5 flex items-center"
                  >
                    <img
                      src="../assets/icons/tarjetas-de-credito-gray.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                  <input
                    class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
                    type="text"
                    value="0000-0000-0000-0000"
                    readonly
                  />
                  <div
                    class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                  >
                    <img
                      class="w-6"
                      src="../assets/icons/visa.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                </label>
              </div>
              <!-- Banca por internet y móvil -->
              <div
                class="hidden text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
              >
                <div
                  class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-4 xl:left-6 flex items-center"
                  >
                    <img
                      src="../assets/icons/banca-por-internet.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                  <span>
                    Banca por internet y móvil
                    <span class="block text-xs text-gray-400 font-normal"
                      >Via pago efectivo</span
                    >
                  </span>
                </div>
              </div>
              <!-- Pago Efectivo -->
              <div
                v-if="product.metodoDepago === 4"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-3"
              >
                <div
                  class="relative rounded-2xl border-b pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-4 xl:left-6 flex items-center"
                  >
                    <img
                      src="../assets/icons/pago-efectivo-p.png"
                      class="w-8"
                      alt="pago efectivo"
                    />
                  </div>
                  <span class="text-text-blue">
                    PagoEfectivo
                    <span class="block text-xs text-gray-400 font-normal"
                      >Agentes y agencias</span
                    >
                  </span>
                </div>
                <div class="py-4">
                  <p
                    class="text-gray-400 text-xs xl:text-base flex items-center justify-center mb-2 gap-2 xl:gap-6 px-2"
                    v-if="product.metodoDepago === 4"
                  >
                    <span>Este es tu código de pago CIP:</span>
                    <span
                      class="text-right text-textColor font-bold xl:text-xl flex items-center gap-2"
                    >
                      {{ product.atmCodigo }}
                      <span class="tooltip text-left">
                        <img src="../assets/icons/icon-clock.png" alt="" />
                        <span class="tooltip-box -left-8"
                          >Tienes tiempo hasta el
                          {{ product.experienceAtm }}</span
                        >
                        <!-- <span class="tooltip-box -left-8">Tienes dos dias para completar tu pago</span> -->
                      </span>
                    </span>
                  </p>
                  <a
                    :href="product.atmLink"
                    target="_blank"
                    class="block text-center text-main-blue font-semibold text-xs xl:text-base"
                    >Ver instrucciones de pago
                  </a>
                </div>
              </div>
              <!-- Transferencia bancaria -->
              <div
                class="text-text-blue relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
                v-if="product.metodoDepago === 5"
              >
                <div
                  class="relative rounded-2xl border-b flex items-center justify-between pl-16 xl:pl-20 p-6 font-semibold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-2 xl:left-4 flex items-center"
                  >
                    <img
                      src="../assets/icons/tarjetas-transferencias.png"
                      alt="Icono de Envios USA"
                    />
                  </div>
                  <span class="text-xs xl:text-base"
                    >Transferencia bancaria</span
                  >
                </div>
                <div class="px-6 py-6">
                  <div class="flex items-start gap-3 xl:gap-8 mb-8">
                    <img
                      src="../assets/icons/interbank-2.svg"
                      class="mt-2 w-10 xl:w-auto"
                      alt="Icono de Envios USA"
                    />
                    <p class="text-xs xl:text-base">
                      <span id="transferencia-nombre" class="block font-bold"
                        >GRUPO CASZA S.A.C.</span
                      >
                      <span id="transferencia-ruc" class="block text-gray-400"
                        >20609271044</span
                      >
                      <span
                        id="transferencia-cuenta"
                        class="block text-gray-400"
                        >{{ product.typeMoney === "USD" ? "Cuenta corriente: 200-3005545622" : "Cuenta Corriente: 200-3005545615" }}</span
                      >
                      <span
                        id="transferencia-cci"
                        class="block text-gray-400"
                        >{{ product.typeMoney === "USD" ? "CCI: 003-200-003005545622-39" : "CCI: 003-200-003005545615-33" }}</span
                      >
                    </p>
                    <div class="ml-auto xl:mr-6" @click.prevent="copyPayment">
                      <img
                        src="../assets/icons/icon-copiar.svg"
                        class="cursor-pointer mx-auto w-5 xl:w-auto"
                        alt="Icono de Envios USA"
                      />
                      <span class="text-gray-400 text-xs">Copiar</span>
                    </div>
                  </div>
                  <div v-if="product.metodoDepago === 5">
                    <div class="grid grid-cols-12 xl:gap-4 items-center">
                      <label
                        for="num_operacion"
                        class="col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base mb-4 font-semibold cursor-pointer"
                        >N° Operación:</label
                      >
                      <div class="col-span-12 xl:col-span-9 xl:mb-4 relative">
                        <input
                          v-if="styleTrans === false"
                          type="text"
                          class="border border-gray-600 w-full rounded-2xl shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                          placeholder="Escribe aquí ..."
                          v-model="trans.number"
                          :disabled="styleTrans"
                        />
                        <input
                          v-else-if="
                            styleTrans && statusPayed !== 3 && trans.number
                          "
                          type="text"
                          class="border border-main-orange w-full rounded-2xl shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                          placeholder="Escribe aquí ..."
                          v-model="trans.number"
                          :disabled="styleTrans"
                        />
                        <input
                          v-else-if="
                            styleTrans && statusPayed === 3 && trans.number
                          "
                          type="text"
                          class="border border-main-green w-full rounded-2xl shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                          placeholder="Escribe aquí ..."
                          v-model="trans.number"
                          :disabled="styleTrans"
                        />
                        <img
                          v-if="styleTrans && statusPayed === 3 && trans.number"
                          src="../assets/icons/check-green.png"
                          class="absolute right-4 bottom-5"
                        />
                      </div>
                      <span
                        class="font-semibold mb-6 col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base"
                        >Voucher:</span
                      >
                      <label
                        v-if="
                          styleTrans === false ||
                          trans.nombreImg === 'Sin Voucher'
                        "
                        class="underline col-span-12 xl:col-span-9 mb-6 rounded-2xl border border-gray-600 shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                      >
                        <img src="../assets/icons/agregar__boleta.png" alt="" />
                        <input
                          type="file"
                          class="overflow-hidden h-0 w-0"
                          @change="factura2($event)"
                          accept="image/*,.pdf"
                          :disabled="styleTrans"
                        />
                        {{ trans.nombreImg }}
                      </label>
                      <label
                        v-else-if="
                          styleTrans &&
                          statusPayed !== 3 &&
                          trans.nombreImg === 'Voucher cargado'
                        "
                        class="underline col-span-12 xl:col-span-9 mb-6 rounded-2xl border border-main-orange shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                      >
                        <img src="../assets/icons/agregar__boleta.png" alt="" />
                        <input
                          type="file"
                          class="overflow-hidden h-0 w-0"
                          @change="factura2($event)"
                          accept="image/*,.pdf"
                          :disabled="styleTrans"
                        />
                        <span v-if="trans.nombreImg === 'Voucher cargado'">
                          <a
                            :href="trans.url.url"
                            target="_Blank"
                            v-if="trans.url.from_admin === 0"
                            >{{ trans.nombreImg }}</a
                          >
                          <a v-else>{{ trans.nombreImg }}</a>
                        </span>
                        <span v-else> {{ trans.nombreImg }} </span>
                      </label>
                      <label
                        v-else-if="
                          styleTrans &&
                          statusPayed === 3 &&
                          trans.nombreImg === 'Voucher cargado'
                        "
                        class="relative underline col-span-12 xl:col-span-9 mb-6 rounded-2xl border border-main-green shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                      >
                        <img src="../assets/icons/agregar__boleta.png" alt="" />
                        <input
                          type="file"
                          class="overflow-hidden h-0 w-0"
                          @change="factura2($event)"
                          accept="image/*,.pdf"
                          :disabled="styleTrans"
                        />
                        <span v-if="trans.nombreImg === 'Voucher cargado'">
                          <a
                            :href="trans.url.url"
                            target="_Blank"
                            v-if="trans.url.from_admin === 0"
                            >{{ trans.nombreImg }}</a
                          >
                          <a v-else>{{ trans.nombreImg }}</a>
                        </span>
                        <span v-else> {{ trans.nombreImg }} </span>
                        <img
                          src="../assets/icons/check-green.png"
                          class="absolute right-4 bottom-5"
                        />
                      </label>
                    </div>

                    <button
                      :class="
                        trans.number.length !== 0
                          ? 'bg-main-green'
                          : ' bg-bg-black'
                      "
                      v-if="styleTrans === false"
                      @click.prevent="sendVaucher2"
                      class="text-white py-5 font-bold rounded-2xl block w-10/12 mx-auto mb-2 transition-colors"
                    >
                      Enviar
                    </button>

                    <p
                      v-if="styleTrans && statusPayed !== 3"
                      class="text-center text-xs mt-2 xl:text-sm"
                    >
                      Acreditaremos tu pago en las proximas 48 horas
                    </p>
                    <p
                      v-if="styleTrans && statusPayed === 3"
                      class="text-center text-xs mt-2 xl:text-sm"
                    >
                      Acreditamos tu pago correctamente
                    </p>
                  </div>
                </div>
              </div>
              <!-- Paypal -->
              <div
                v-if="product.metodoDepago === 2"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
              >
                <div
                  class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-4 xl:left-6 flex items-center"
                  >
                    <img
                      src="../assets/icons/paypal.svg"
                      alt="Icono de Envios USA"
                    />
                  </div>
                  <span>Paypal</span>
                </div>
              </div>

              <div
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
                v-if="product.metodoDepago === 6"
              >
                <div
                  class="relative rounded-2xl p-6 font-bold cursor-pointer text-main-green"
                >
                  <span>Mi balance</span>
                </div>
              </div>
            </div>

            <!-- Detalles de pago -->
            <hr class="my-4" />
            <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
              Detalles de pago
            </h4>
            <div class="py-4 px-5 rounded-2xl border mb-8 text-xs xl:text-base">
              <div>
                <div>
                  <p class="flex items-center justify-between mb-2 font-bold">
                    <span>{{
                      product.typeService === 2 ? "Producto" : "Gastos en USA"
                    }}</span>
                    <span> {{ product.typeMoney }} {{ detalle.cif }} </span>
                  </p>
                  <hr class="my-3" />
                  <span v-if="product.typeService === 2">
                    <p class="xl:pl-2 flex items-center justify-between mb-2">
                      <span class="flex gap-2">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt="Icono de Envios USA"
                        />
                        Precio del producto</span
                      >
                      <span
                        >{{ product.typeMoney }}
                        {{ detalle.precioProducto }}</span
                      >
                    </p>
                    <p class="xl:pl-2 flex items-center justify-between mb-2">
                      <span class="flex gap-2">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt="Icono de Envios USA"
                        />Shipping</span
                      >
                      <span
                        >{{ product.typeMoney }} {{ detalle.shipping }}</span
                      >
                    </p>
                    <p class="xl:pl-2 flex items-center justify-between mb-2">
                      <span class="flex gap-2">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt="Icono de Envios USA"
                        />Tax</span
                      >
                      <span>{{ product.typeMoney }} {{ detalle.tax }}</span>
                    </p>
                  </span>

                  <p class="xl:pl-2 flex items-center justify-between mb-2">
                    <span class="flex gap-2">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt="Icono de Envios USA"
                      />Warehouse</span
                    >
                    <span>{{ product.typeMoney }} {{ detalle.warehouse }}</span>
                  </p>
                  <p class="xl:pl-2 flex items-center justify-between mb-2">
                    <span class="flex gap-2">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt="Icono de Envios USA"
                      />Millas de vuelo</span
                    >
                    <span>{{ product.typeMoney }} {{ detalle.flete }}</span>
                  </p>
                </div>
                <hr class="my-5" />
                <div>
                  <!-- //si contiene impuestos -->
                  <span v-if="detalle.impuestos !== '0.00'">
                    <p class="flex items-center justify-between font-bold">
                      <span>Recompensa viajero</span>
                      <span
                        >{{ product.typeMoney }} {{ detalle.impuestos }}</span
                      >
                    </p>
                    <p
                      class="hidden flex items-center justify-between mb-2 font-semibold"
                    >
                      <span>Ad/Valorem</span>
                      <span>{{ product.typeMoney }} {{ detalle.valorem }}</span>
                    </p>
                    <p
                      class="hidden flex items-center justify-between mb-2 font-semibold"
                    >
                      <span>IGV</span>
                      <span>{{ product.typeMoney }} {{ detalle.igv }}</span>
                    </p>
                    <p
                      class="hidden flex items-center justify-between mb-2 font-semibold"
                    >
                      <span>IPM</span>
                      <span>{{ product.typeMoney }} {{ detalle.ipm }}</span>
                    </p>
                  </span>
                  <!-- //Si no contiene impuestos -->
                  <p
                    v-else
                    class="text-main-green flex items-center justify-between font-bold"
                  >
                    <span>Recompensa viajero</span>
                    <span>Gratis</span>
                  </p>
                </div>
                <hr class="my-5" />
                <div>
                  <p class="flex items-center justify-between mb-2 font-bold">
                    <span>Tarifa de servicio</span>
                    <span>{{ product.typeMoney }} {{ detalle.tarifa }}</span>
                  </p>
                  <hr class="my-5" />
                  <p class="xl:pl-2 flex items-center justify-between mb-2">
                    <span class="flex gap-2">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt="Icono de Envios USA"
                      />Margen de servicio</span
                    >
                    <span
                      :class="
                        detalle.tramiteAduanero === '0.00'
                          ? 'text-main-green font-bold'
                          : detalle.tramiteAduanero
                      "
                      >{{ product.typeMoney }}
                      {{
                        detalle.tramiteAduanero === "0.00"
                          ? "Gratis"
                          : detalle.tramiteAduanero
                      }}</span
                    >
                  </p>
                  <p class="xl:pl-2 flex items-center justify-between mb-2">
                    <span class="flex gap-2">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        alt="Icono de Envios USA"
                        class="w-2"
                      />Envío nacional</span
                    >
                    <span :class="free === 1 ? 'text-main-green font-bold' : ''"
                      >{{ product.typeMoney }}
                      {{ free === 1 ? "Gratis" : detalle.envioNacional }}</span
                    >
                  </p>
                </div>
                <hr class="my-5" v-if="servicios.length !== 0" />
                <div>
                  <p
                    class="flex items-center justify-between mb-2 font-bold"
                    v-if="servicios.length !== 0"
                  >
                    <span>Servicios Adicionales</span>
                    <span>
                      {{ product.typeMoney }}
                      {{
                        product.typeMoney === "USD"
                          ? Number(servicioDolares).toFixed(2)
                          : Number(servicioSoles).toFixed(2)
                      }}
                    </span>
                  </p>
                  <hr class="my-5" />
                  <p
                    class="xl:pl-2 flex items-center justify-between mb-2"
                    v-for="data in servicios"
                    :key="data"
                  >
                    <span class="flex gap-2">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt="Icono de Envios USA"
                      />{{ data.name }}</span
                    >
                    <span>
                      {{ product.typeMoney }}
                      {{
                        product.typeMoney === "USD"
                          ? data.pivot.amount.toFixed(2)
                          : (data.pivot.amount * data.exchange_rate).toFixed(2)
                      }}
                    </span>
                  </p>
                  <hr v-if="servicios.length !== 0" class="my-6" />
                </div>
                <div>
                  <p
                    class="flex items-center justify-between mb-2 font-bold"
                    v-if="cuponUtilizado !== null"
                  >
                    <span>Cupón utilizado</span>
                    <span>
                      {{ product.typeMoney === "USD" ? "- USD" : "- PEN" }}
                      {{
                        product.typeMoney === "USD"
                          ? Number(cuponUtilizado).toFixed(2)
                          : Number(cuponUtilizadoSoles).toFixed(2)
                      }}
                    </span>
                  </p>
                  <hr class="my-6" v-if="cuponUtilizado !== null" />
                  <p
                    class="flex items-center justify-between mb-2 font-bold"
                    v-if="saldoUtilizado !== null"
                  >
                    <span>Saldo utilizado</span>
                    <span>- USD {{ saldoUtilizado }} </span>
                  </p>
                  <hr class="my-6" v-if="saldoUtilizado !== null" />
                </div>

                <!-- v-if="
                    purchaseData.attributes.coupon_discount !== null ||
                    saldoUtilizado !== null
                  " -->
                <p
                  class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
                  v-if="newTotal === null"
                >
                  <span>Pago total</span>
                  <span>
                    <span
                      class="text-main-red"
                      v-if="product.typeMoney === 'USD'"
                      >USD
                    </span>
                    <span
                      class="text-main-red"
                      v-if="product.typeMoney === 'PEN'"
                      >PEN
                    </span>
                    <span v-if="product.typeMoney === 'USD'">
                      {{ detalle.totalPagar }}</span
                    >
                    <span v-if="product.typeMoney === 'PEN'">
                      {{ detalle.totalPagar }}</span
                    >
                  </span>
                </p>
                <p
                  class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
                  v-else
                >
                  <span>Pago total</span>
                  <span>
                    <span
                      class="text-main-red"
                      v-if="product.typeMoney === 'USD'"
                      >USD
                    </span>
                    <span
                      class="text-main-red"
                      v-if="product.typeMoney === 'PEN'"
                      >PEN
                    </span>
                    <span v-if="product.typeMoney === 'USD'">
                      {{ newTotal }}</span
                    >
                    <span v-if="product.typeMoney === 'PEN'">
                      {{ newTotal }}</span
                    >
                  </span>
                </p>
              </div>
            </div>
            <!-- Tiempo de entrega -->
            <div
              class="flex items-center justify-center gap-4 mb-6 text-xs xl:text-base"
            >
              <img
                src="../assets/icons/entrega.svg"
                alt="Icono de Envios USA"
              />
              <p>
                Tiempo estimado de entrega:
                <span class="font-semibold text-main-red">{{
                  product.fechaEntrega
                }}</span>
              </p>
            </div>
          </div>
        </article>
      </section>
      <!-- Bloque de la derecha -->
      <section>
        <!-- Agradecimiento e ID de tracking -->
        <article
          class="relative bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
        >
          <button
            @click.prevent="imprimir"
            class="tooltip fixed xl:absolute right-4 top-20 xl:right-16 xl:top-12 block ml-auto bg-bg-black text-white rounded-2xl p-3 xl:py-4 xl:px-6 font-bold flex items-center gap-4"
          >
            <img
              src="../assets/icons/icon-impresora.svg"
              class="w-6 xl:w-8"
              alt="Icono de Envios USA"
            />
            <span
              class="tooltip-box bottom-16 xl:bottom-20 text-xs xl:text-sm -left-6 xl:left-0"
              >Imprimir</span
            >
          </button>
          <div class="xl:p-8 text-center">
            <h4 class="text-sm xl:text-base font-semibold mb-10">
              Solicitud recibida
            </h4>
            <img
              src="../assets/images/caja-paso-4.svg"
              class="mx-auto mb-8"
              alt="Icono de Envios USA"
            />
            <p class="mb-4 text-xs xl:text-sm">
              Tu número de tracking ha sido enviado a:

              <a href="#" class="text-main-red block font-bold">{{
                product.email
              }}</a>
            </p>
            <div
              class="font-medium text-sm xl:text-base text-black text-center flex items-center justify-center gap-3 border-b border-l border-r shadow-xl rounded-xl px-6 pb-6"
            >
              <p id="tracking-code">{{ product.numberTracking }}</p>
              <button
                type="button"
                @click.prevent="copyCode"
                @keydown="copyCode"
                class="cursor-pointer"
                alt="Icono de Envios USA"
              >
                <img src="../assets/icons/icon-copiar-3.png" alt="" />
              </button>
            </div>
          </div>
        </article>
        <!-- Invoice de compra -->

        <article
          v-if="
            product.typeService === 1 ||
            (product.typeService === 2 && product.vaucherStatus.length !== 0)
          "
          class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
        >
          <h3
            v-if="
              product.typeService === 1 && product.vaucherStatus.length === 0
            "
            class="text-text-blue font-semibold xl:text-lg mb-6 text-center"
          >
            No olvides agregar tu factura comercial
          </h3>
          <label
            v-if="
              product.typeService === 1 && product.vaucherStatus.length === 0
            "
            class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-main-orange py-5 text-sm xl:text-base flex items-center justify-center gap-4"
          >
            <input
              type="file"
              class="hidden"
              @change="factura"
              ref="fileupload"
              accept="image/*,.pdf"
            />
            <img src="../assets/icons/subir-archivo.png" />
            Agregar factura comercial
          </label>

          <span v-if="product.vaucherStatus.length !== 0">
            <div
              class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-main-green text-main-green ring ring-main-green bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
            >
              Factura comercial cargado
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>

            <div
              class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
            >
              <span
                v-for="(invoice, index) in product.vaucherStatus"
                :key="invoice"
                class="cursor-pointer"
              >
                <a
                  :href="invoice.url"
                  target="_Blank"
                  class="flex items-center gap-4"
                >
                  <img src="../assets/icons/file.svg" alt="" />
                  Invoice-{{ index + 1 }}
                  <span class="tooltip ml-auto">
                    <img
                      v-if="invoice.status === 1"
                      src="../assets/icons/check-green.png"
                      class=""
                    />
                    <img
                      v-if="invoice.status === 0"
                      src="../assets/icons/error-orange.png"
                      class=""
                    />
                    <span class="tooltip-box">{{
                      invoice.status === 1
                        ? "Factura aprobada"
                        : "Factura en revisión"
                    }}</span>
                  </span>
                </a>
                <hr class="my-3" />
              </span>

              <label
                v-if="product.typeService === 1"
                class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
              >
                <input
                  type="file"
                  class="hidden"
                  @change="factura"
                  ref="fileupload"
                  accept="image/*,.pdf"
                />
                <img src="../assets/icons/plus-blue.svg" />
                Agregar otra factura comercial
              </label>
            </div>
          </span>
        </article>
        <!-- Servicio adicional return -->
        <!-- && (statusTracking === 9 || statusTracking === 10) -->
        <article
          v-if="sreturn.status"
          class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
        >
          <!-- Estado inicial -->
          <div
            v-if="!initialReturn && sreturn.files.length === 0"
            @click="startReturn"
            :class="statusPayed !== 3 && 'cursor-not-allowed'"
            class="xl:mx-16 font-semibold rounded-2xl bg-main-red text-main-red ring ring-main-red bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
          >
            <span
              class="tooltip"
              :class="statusPayed !== 3 && 'cursor-not-allowed'"
            >
              Detener y/o devolver producto
              <span v-if="statusPayed !== 3" class="tooltip-box"
                >El pago debe estar confirmado antes de usar el servicio</span
              >
            </span>
            <img src="../assets/icons/x-circle.svg" class="w-6" />
          </div>
          <!-- Cargar etiqueta de devolución si aun no ha sido cargada ninguna -->
          <h3
            v-if="sreturn.files.length === 0 && initialReturn"
            class="text-text-blue font-semibold xl:text-lg mb-6 text-center"
          >
            No olvides agregar tu etiqueta de devolución
          </h3>
          <label
            v-if="sreturn.files.length === 0 && initialReturn"
            class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-main-orange py-5 text-sm xl:text-base flex items-center justify-center gap-4"
          >
            <input
              @change="devolucion"
              type="file"
              class="hidden"
              ref="fileupload"
              accept="image/*,.pdf"
            />
            <img src="../assets/icons/subir-archivo.png" />
            Agregar etiqueta de devolución
          </label>
          <!-- Boton Verde -->
          <tempalte>
            <div
              v-if="
                sreturn.files.length > 0 &&
                (statusTracking === 9 || statusTracking === 10)
              "
              class="xl:mx-16 font-semibold rounded-2xl bg-main-green text-main-green ring ring-main-green bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
            >
              Paquete/Producto devuelto al proveedor
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>
            <div
              v-else-if="sreturn.files.length > 0"
              class="xl:mx-16 font-semibold rounded-2xl bg-main-green text-main-green ring ring-main-green bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
            >
              Etiqueta de devolución cargada
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>
          </tempalte>
          <!-- Listar archivos -->
          <div
            v-if="sreturn.files.length > 0"
            class="mt-6 xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
          >
            <span
              v-for="(fileReturn, index) in sreturn.files"
              :key="fileReturn"
              class="cursor-pointer"
            >
              <a
                :href="fileReturn.url_return"
                target="_Blank"
                class="flex items-center gap-4"
              >
                <img src="../assets/icons/file.svg" alt="" />
                {{ index === 0 ? "Etiqueta de devolución" : "Evidencia" }}
                <span v-if="index !== 0">
                  -
                  {{ index }}
                </span>

                <span class="tooltip ml-auto">
                  <img
                    v-if="fileReturn.status === 1"
                    src="../assets/icons/check-green.png"
                    class=""
                  />
                  <img
                    v-if="fileReturn.status === 0"
                    src="../assets/icons/error-orange.png"
                    class=""
                  />
                  <span class="tooltip-box">{{
                    fileReturn.status === 1 ? "Aprobada" : "En revisión"
                  }}</span>
                </span>
              </a>
              <hr class="my-3" />
            </span>
            <label
              class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
            >
              <input
                type="file"
                class="hidden"
                @change="devolucion"
                ref="fileupload"
                accept="image/*,.pdf"
              />
              <img src="../assets/icons/plus-blue.svg" />
              Agregar otra etiqueta de devolución
            </label>
          </div>
        </article>
        <!-- Servicio adicional review -->
        <article
          v-if="review.status"
          class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
        >
          <h3 class="text-main-blue -mb-1 font-medium">REVIEW</h3>
          <h3 class="font-bold xl:text-xl mb-6">Servicio adicional</h3>

          <article
            class="border shadow-sm rounded-md p-4 grid gap-6 bg-blue-50 bg-opacity-70"
          >
            <span v-if="review.statusPregunta === false">
              <div class="flex gap-4 items-center">
                <img src="../assets/images/user-default.svg" alt="" />
                <p
                  class="mr-8 px-4 py-3 bg-white rounded-lg font-medium border shadow-sm"
                >
                  {{ review.pregunta }}
                  <span class="block mt-3 opacity-80 text-xs">
                    {{ convertDate(review.pregunta_created_at) }}
                  </span>
                </p>
              </div>
              <div
                class="flex flex-row-reverse gap-4 items-center pl-8 mt-2"
                v-for="respuesta in review.respuesta"
                :key="respuesta"
              >
                <img src="../assets/images/user-1.svg" alt="" />
                <p
                  class="ml-8 px-4 py-3 bg-white rounded-lg font-medium border shadow-sm"
                >
                  {{ respuesta.mesagge_review }} <br />
                  <span v-if="respuesta.url_review">
                    <a
                      :href="respuesta.url_review"
                      class="text-blue-600"
                      target="_blank"
                      >Documento</a
                    >
                  </span>
                  <span class="block mt-3 opacity-80 text-xs">
                    {{ convertDate(respuesta.created_at) }}
                  </span>
                </p>
              </div>
            </span>

            <div class="flex mt-6" v-if="review.statusPregunta">
              <template v-if="statusPayed === 3">
                <textarea
                  class="border rounded-tl-md rounded-bl-md p-4 w-full focus:z-10 focus:outline-none focus:ring focus:ring-blue-500 transition-colors"
                  rows="1"
                  style="resize: none"
                  placeholder="Describe que debemos revisar..."
                  v-model="review.pregunta"
                ></textarea>
                <button
                  class="p-4 px-6 rounded-tr-md rounded-br-md bg-gray-600 text-white font-semibold cursor-not-allowed"
                  v-if="review.pregunta.length === 0"
                >
                  Enviar
                </button>
                <button
                  @click.prevent="crearPreguntaReviewService"
                  class="p-4 px-6 rounded-tr-md rounded-br-md bg-main-green text-white font-semibold"
                  v-else
                >
                  Enviar
                </button>
              </template>
              <template v-else>
                <textarea
                  class="border rounded-tl-md rounded-bl-md p-4 w-full focus:z-10 focus:outline-none focus:ring focus:ring-blue-500 transition-colors"
                  rows="1"
                  style="resize: none"
                  placeholder="Describe que debemos revisar..."
                  readonly
                ></textarea>
                <button
                  type="button"
                  class="cursor-not-allowed p-4 px-6 rounded-tr-md rounded-br-md bg-gray-600 text-white font-semibold cursor-not-allowed"
                >
                  Enviar
                </button>
              </template>
            </div>
          </article>
        </article>
        <!-- Productos relacionados -->
        <article
          v-if="codeAmazon"
          class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
        >
          <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
            Te podría interesar
          </h3>
          <span v-if="productsRecommend">
            <div class="grid grid-cols-3 gap-8">
              <router-link
                :to="{ name: 'Purchase', params: { link: data.url } }"
                v-for="data in productsRecommend"
                :key="data"
              >
                <img
                  :src="data.image"
                  class="w-full h-40 object-contain mb-3"
                />
                <img src="../assets/images/amazon.svg" class="w-16" />
                <p class="text-xs xl:text-sm font-medium line-clamp-2 mb-3">
                  {{ data.title }}
                </p>
                <p class="text-xs xl:text-sm font-bold">
                  <span class="text-main-orange">USD</span>
                  {{ data.price }}
                </p>
              </router-link>
            </div>
          </span>
          <span v-else>
            <div class="grid grid-cols-3 gap-8">
              <a href="#">
                <img
                  src="../assets/icons/loader.svg"
                  class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                  style="animation-duration: 1.5s"
                />
                <p
                  class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                >
                  Cargando
                </p>
              </a>
              <a href="#">
                <img
                  src="../assets/icons/loader.svg"
                  class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                  style="animation-duration: 1.5s"
                />
                <p
                  class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                >
                  Cargando
                </p>
              </a>
              <a href="#">
                <img
                  src="../assets/icons/loader.svg"
                  class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                  style="animation-duration: 1.5s"
                />
                <p
                  class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                >
                  Cargando
                </p>
              </a>
            </div>
          </span>
        </article>
      </section>
    </article>
  </section>

  <!-- componente del footer -->
  <footer-component />
</template>
<script>
//importando componentes
import headerComponent from "../components/header/headerComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
//Importando serve
import { server } from "../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  components: { headerComponent, footerComponent, trackingComponent },
  data() {
    return {
      review: {
        status: false,
        statusPregunta: false,
        pregunta: "",
        respuesta: [],
        pregunta_created_at: "",
      },
      sreturn: {
        status: false,
        files: [],
      },
      statusPayed: null,
      statusTracking: null,
      saldoUtilizado: null,
      cuponUtilizado: null,
      cuponUtilizadoSoles: null,

      servicios: [],
      servicioSoles: 0.0,
      servicioDolares: 0.0,
      newTotal: null,
      styleTrans: false,
      trans: {
        number: "",
        img: "",
        nombreImg: "Adjuntar archivo",
        url: null,
      },
      autentification: {
        estateTracking: false,
      },
      idProduct: this.$route.params.codeProduct,
      product: {
        link: "",
        id: 0,
        numberTracking: "",
        img: "",
        name: "",
        precio: "",
        cantidad: "",
        peso: "",
        directionDefault: 0,
        directionName: "",
        directionApellido: "",
        directionAddres: "",
        directionDepartamento: "",
        directionThelephone: "",
        metodoDepago: 0,
        typeMoney: "",
        email: "",
        typeService: 0,
        vaucherStatus: "",
        atmLink: "",
        atmCodigo: "",
        fechaEntrega: "",
        experienceAtm: "",
      },
      codeAmazon: null,
      free: 0,
      detalle: {
        cif: "",
        precioProducto: "",
        shipping: "",
        tax: "",
        warehouse: "",
        flete: "",
        impuestos: "",
        valorem: "",
        igv: "",
        ipm: "",
        tarifa: "",
        tramiteAduanero: "",
        envioNacional: "",
        totalPagar: "",
      },
      imageVaucher: "",
      productsRecommend: null,
      imageDevolucion: "",
      initialReturn: false,
    };
  },
  created() {
    this.getDataProduct();
  },
  mounted() {
    this.scrollToTop();

    window.localStorage.removeItem("open");
    window.localStorage.removeItem("producto");
  },

  methods: {
    // Convertir fechas a formato legible
    convertDate(dateString) {
      const months = {
        1: "enero",
        2: "febrero",
        3: "marzo",
        4: "abril",
        5: "mayo",
        6: "junio",
        7: "julio",
        8: "agosto",
        9: "septiembre",
        10: "octubre",
        11: "noviembre",
        12: "diciembre",
      };
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Get the day of the month
      const day = date.getDate();

      // Get the month
      const month = date.getMonth() + 1;

      // Get the year
      const year = date.getFullYear();

      // Format the date
      const formattedDate = `${day} de ${months[month]} del ${year}`;

      // Return the formatted date
      return formattedDate;
    },
    // Crear un pregunta para el servicio de review
    crearPreguntaReviewService() {
      if (this.review.pregunta.length !== 0) {
        axios
          .post(`${BASE_URL}` + "purchaseOrder/new-review/" + this.product.id, {
            review_mensaje: this.review.pregunta,
            from_admin: "0",
          })
          .then(() => {
            location.reload();
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Error al enviar el review";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    //metodo para traer productos recomendados segun el codigo de la orden
    getRecommendProducts(codeAmazon) {
      axios
        .get(`${BASE_URL}` + "product/getMalltinaProductRelation/" + codeAmazon)
        .then((response) => {
          this.productsRecommend = response.data.body ?? null;
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al traer productos relacionados";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    factura2(e) {
      const file = e.target.files[0];
      this.trans.nombreImg = e.target.files[0].name;
      this.cargarImagen2(file);
    },
    cargarImagen2(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.trans.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    sendVaucher2() {
      const result = this.validateFormTrans();
      if (result) {
        axios
          .put(
            `${BASE_URL}` +
              "purchaseOrder/update-order-no-grabr/" +
              this.product.id,
            {
              payment_code: this.trans.number,
              voucher: { voucher: this.trans.img, isFromAdmin: false },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.trans.number = response.data.body.attributes.payment_code;
              this.trans.url =
                response.data.body.attributes.vouchers_usa[
                  response.data.body.attributes.vouchers_usa.length - 1
                ] ?? null;
              if (response.data.body.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }
              this.styleTrans = true;

              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Orden Actualizada";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al subir el Voucher de la transferencia de pago";
              this.$store.state.alert.estado = true;
              //--------------------------
            }
          });
      }
    },
    validateFormTrans() {
      const imagen = this.trans.img;
      const codigo = this.trans.number;

      if (codigo.length !== 0) {
        if (imagen.length === 0) {
          this.trans.img = null;
        }
        return true;
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Debe subir el N° de Operación";
        this.$store.state.alert.estado = true;
        //--------------------------

        return false;
      }
    },
    copyPayment() {
      const nombre = document.getElementById(
        "transferencia-nombre"
      ).textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById(
        "transferencia-cuenta"
      ).textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de tranferencia con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------
        })
        .catch((error) => console.log(error));
    },
    //   metodo para traer la data del producto segun el id
    getDataProduct() {
      axios
        .get(`${BASE_URL}` + "purchaseOrder/get/" + this.idProduct)
        .then((response) => {
          if (response.status === 200) {
            const dataProduct = response.data.body;
            console.log(dataProduct);

            // review
            if (dataProduct.attributes.Reviews.length !== 0) {
              const foundReturn = dataProduct.attributes.Reviews.find(
                (element) => element.from_admin === 0
              );
              if (foundReturn) {
                this.review.statusPregunta = false;
                this.review.pregunta = foundReturn.mesagge_review;
                this.review.pregunta_created_at = foundReturn.created_at;
                let respuestaAdmin = dataProduct.attributes.Reviews.filter(
                  (data) => data.from_admin !== 0
                );
                this.review.respuesta = respuestaAdmin;
              } else {
                this.review.statusPregunta = true;
              }
            } else {
              this.review.statusPregunta = true;
            }

            this.statusPayed = dataProduct.attributes.payed;
            this.statusTracking =
              dataProduct.attributes.order_tracking_details[
                dataProduct.attributes.order_tracking_details.length - 1
              ].attributes.status;
            this.saldoUtilizado = dataProduct.attributes.balance_discount;
            this.cuponUtilizado = dataProduct.attributes.coupon_discount;
            this.cuponUtilizadoSoles =
              this.cuponUtilizado * dataProduct.attributes.exchange;

            this.newTotal = dataProduct.attributes.new_total_cost;
            this.product.fechaEntrega = dataProduct.attributes.delivery_date;

            this.servicios = dataProduct.attributes.services;
            var totalServicioDolar = 0.0;
            var totalServicioSoles = 0.0;
            if (this.servicios.length !== 0) {
              this.servicios.forEach((value) => {
                totalServicioDolar = totalServicioDolar + value.pivot.amount;
                totalServicioSoles =
                  totalServicioSoles + value.pivot.amount * value.exchange_rate;
              });
              this.servicioDolares = totalServicioDolar;
              this.servicioSoles = totalServicioSoles;

              // review
              const foundReview = this.servicios.find(
                (element) => element.name === "Review"
              );
              const foundReturn = this.servicios.find(
                (element) => element.name === "Return"
              );
              if (foundReview) {
                this.review.status = true;
              }
              if (foundReturn) {
                this.sreturn.status = true;
                this.sreturn.files = dataProduct.attributes.serviceReturn;
                console.log("DATA: ", dataProduct.attributes.serviceReturn);
              }
            }
            this.free =
              dataProduct.attributes.shipping_address === null
                ? 1
                : dataProduct.attributes.shipping_address.attributes.free;

            if (
              dataProduct.attributes.payment_code !== null ||
              dataProduct.attributes.voucher_link !== null
            ) {
              this.styleTrans = true;
              this.trans.number = response.data.body.attributes.payment_code;
              if (dataProduct.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
                this.trans.url =
                  response.data.body.attributes.vouchers_usa[
                    response.data.body.attributes.vouchers_usa.length - 1
                  ] ?? null;
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }
            } else {
              this.styleTrans = false;
            }

            this.product.link = dataProduct.attributes.reference_link;

            this.product.numberTracking =
              dataProduct.attributes.system_tracking_number;
            this.product.img = dataProduct.attributes.product_image_url;
            this.product.name = dataProduct.attributes.product_info;
            this.product.precio = dataProduct.attributes.real_price;
            this.product.cantidad = dataProduct.attributes.product_specimens;
            this.product.peso = dataProduct.attributes.weight_kg;
            this.product.directionDefault =
              dataProduct.attributes.at_office_flag;
            this.product.metodoDepago =
              dataProduct.attributes.payment_method_flag;
            this.product.typeMoney = dataProduct.attributes.currency;
            this.product.email =
              dataProduct.attributes.client.attributes.profile.attributes.userable.attributes.email;
            //Almacena el tipo de servicio 1-curier -2-shooper
            this.product.typeService = dataProduct.attributes.direct_type;
            //ALmacenamos lo que contiene vaucher_limnk
            this.product.vaucherStatus =
              dataProduct.attributes.vouchers_usa === null
                ? ""
                : dataProduct.attributes.vouchers_usa;
            // Obtenemos el id del paquete
            this.product.id = dataProduct.id;
            //ALmacenamos el link de atm
            this.product.atmLink =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.external_resource_url;
            this.product.atmCodigo =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.payment_code;
            this.product.experienceAtm =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.expiration;

            //Datos de la dirección y valida si el campo de addres es cero se les coloca string vacio
            if (dataProduct.attributes.shipping_address !== null) {
              //Nombre
              this.product.directionName =
                dataProduct.attributes.shipping_address.attributes
                  .owner_name === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .owner_name;

              this.product.directionApellido =
                dataProduct.attributes.shipping_address.attributes
                  .owner_lastname === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .owner_lastname;
              this.product.directionAddres =
                dataProduct.attributes.shipping_address.attributes.address ===
                null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes.address;

              this.product.directionDepartamento =
                dataProduct.attributes.shipping_address.attributes
                  .department === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .department;
              this.product.directionThelephone =
                dataProduct.attributes.shipping_address.attributes
                  .telephone_number === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .telephone_number;
            } else {
              this.product.directionDepartamento = "";
              this.product.directionThelephone = "";
              this.product.directionAddres = "";
              this.product.directionName = "";
            }
            //Datos del detalle del pago -CIF
            this.detalle.cif = (
              dataProduct.attributes.cif_cost * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.precioProducto = (
              dataProduct.attributes.real_price *
              dataProduct.attributes.product_specimens *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.shipping = (
              dataProduct.attributes.real_shipping *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.tax = (
              dataProduct.attributes.real_tax * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.warehouse = (
              dataProduct.attributes.warehouse * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.flete = (
              dataProduct.attributes.flete_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            //Datos del detalle del pago - Impuestos
            this.detalle.impuestos = (
              dataProduct.attributes.arancel_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.valorem = (
              dataProduct.attributes.ad_valorem *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.igv = (
              dataProduct.attributes.igv * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.ipm = (
              dataProduct.attributes.ipm * dataProduct.attributes.exchange
            ).toFixed(2);
            //Datos del detalle del pago - Tarifa
            this.detalle.tarifa = (
              dataProduct.attributes.distribution_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.tramiteAduanero = (
              dataProduct.attributes.tramite_aduanero *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.envioNacional = (
              dataProduct.attributes.national_shipping *
              dataProduct.attributes.exchange
            ).toFixed(2);
            //Total a pagar
            this.detalle.totalPagar = (
              dataProduct.attributes.total_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);

            if (dataProduct.attributes.code_amazon) {
              this.codeAmazon = dataProduct.attributes.code_amazon;
              this.getRecommendProducts(dataProduct.attributes.code_amazon);
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Lo sentimos el código es incorrecto";
            this.$store.state.alert.estado = true;
            //--------------------------
            this.$router.push({ name: "User", params: { type: "pedidos" } });
          }
        });
    },
    //metodo para copiar el numero de tracking
    copyCode() {
      const trackingCode = document.getElementById("tracking-code").textContent;
      navigator.clipboard
        .writeText(trackingCode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "El número de tracking se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------
        })
        .catch((error) => console.log(error));
    },
    imprimir() {
      window.print();
    },
    factura(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageVaucher = e.target.result;
        this.sendVaucher();
      };
      reader.readAsDataURL(file);
    },
    sendVaucher() {
      axios
        .put(
          `${BASE_URL}` +
            "purchaseOrder/update-order-no-grabr/" +
            this.product.id,
          {
            voucher_usa: this.imageVaucher,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$refs.fileupload.value = null;
            this.imageVaucher = "";

            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Factura comercial enviado";
            this.$store.state.alert.estado = true;
            //--------------------------
            location.reload();
            // this.closeModal();
            // this.exito = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.imageVaucher = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su factura comercial";
            this.$store.state.alert.estado = true;
            //--------------------------
          }
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    devolucion(e) {
      const file = e.target.files[0];
      this.cargarDevolucion(file);
    },
    cargarDevolucion(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageDevolucion = e.target.result;
        this.sendEtiquetaDevolucion();
      };
      reader.readAsDataURL(file);
    },
    sendEtiquetaDevolucion() {
      axios
        .post(
          `${BASE_URL}` +
            "purchaseOrder/createServiceReturn/" +
            this.product.id,
          {
            from_admin: 0,
            ServiceReturn_archivo: this.imageDevolucion,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$refs.fileupload.value = null;
            this.imageDevolucion = "";

            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description =
              "Etiqueta de devolucion enviado";
            this.$store.state.alert.estado = true;
            //--------------------------
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            this.imageDevolucion = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su etiqueta de devolución";
            this.$store.state.alert.estado = true;
            //--------------------------
          }
        });
    },
    startReturn() {
      if (this.statusPayed !== 3) return;
      this.initialReturn = true;
    },
  },
};
</script>
