<template>
  <article
    :class="stattusNoticy ? '' : 'hidden'"
    class="fixed right-4 xl:right-8 top-28 bg-sky-black px-3 py-5 xl:p-5 rounded-2xl flex justify-between items-center gap-3 xl:gap-8 z-30"
  >
    <div class="flex items-center gap-3 xl:gap-5">
      <img src="../../assets/images/caja-regalo.png" />
      <div>
        <h4 class="text-xs xl:text-sm font-bold mb-3">Recibe $10 USD Gratis</h4>
        <p class="text-xs">
          ¡Gana $10 USD por cada <br />
          amigo que compre en <br />
          <b>ENVÍOS USA PERÚ!</b>
        </p>
      </div>
    </div>
  </article>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <article
      v-if="page === 1"
      class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm"
    >
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">
        Recomendados
      </h3>
      <div class="xl:grid grid-cols-2 gap-8 mb-10">
        <article>
          <header
            class="text-center xl:text-left rounded-tl-2xl rounded-tr-2xl py-4 px-5 xl:px-10 bg-bg-black text-white font-bold text-xs xl:text-sm"
          >
            Cant. de Recomendados
          </header>
          <div
            class="mb-14 xl:mb-0 flex flex-col xl:flex-row justify-between items-center gap-10 xl:ga-0 bg-white border shadow-md rounded-bl-2xl rounded-br-2xl p-5 xl:py-6 xl:px-10 text-xs xl:text-sm"
          >
            <p>
              Tienes
              <b class="text-main-orange">
                {{ totalAsociados }}
                {{
                  totalAsociados === 0 || totalAsociados === 1
                    ? "Asociado"
                    : "Asociados"
                }}
              </b>
            </p>
            <p class="font-semibold">
              {{ (totalAsociados * 10).toFixed(2) }}
              <span class="text-main-red">USD</span> Ganados
            </p>
          </div>
        </article>
        <article
          class="bg-sky-black px-3 py-5 xl:p-5 rounded-2xl flex flex-col xl:flex-row gap-6 xl:gap-0 justify-between items-center gap-3 xl:gap-8"
        >
          <div class="flex items-center gap-3 xl:gap-5">
            <img src="../../assets/images/caja-regalo.png" />
            <div>
              <h4 class="text-xs xl:text-sm font-bold mb-3">
                Recibe $10 USD Gratis
              </h4>
              <p class="text-xs">
                ¡Gana $10 USD por cada <br />
                amigo que compre en <br />
                <b>ENVÍOS USA PERÚ!</b>
              </p>
            </div>
          </div>
          <!-- @click.prevent="sharedLink(code)" -->
          <button
            type="button"
            class="flex items-center gap-3 font-bold text-sm"
            @click.prevent="changePageNextView"
          >
            <img src="../../assets/icons/compartir.png" alt="Compartir" />
            <span>COMPARTIR</span>
          </button>
          <!-- <button
            type="button"
            @click.prevent="changePageNextView"
            class="bg-main-green px-5 xl:px-8 py-3 text-white font-bold text-xs rounded-2xl"
          >
            <span class="w-max">GANAR $10 USD</span>
          </button> -->
        </article>
      </div>

      <span v-if="influencer === 1">
        <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-4">
          Influencers
        </h3>
        <h4 class="text-text-blue xl:text-xl font-bold mb-6 xl:mb-10">
          Mis códigos de campaña:
        </h4>
        <header
          class="grid xl:grid-cols-5 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
        >
          <p class="text-center xl:hidden">Dashboard</p>
          <p class="hidden xl:block">Campaña</p>
          <p class="hidden xl:block">Código</p>
          <p class="hidden xl:block">Descuento</p>
          <p class="hidden xl:block">Registrados</p>
        </header>
        <p class="font-medium text-center pb-8 pt-14" v-if="loading">
          Cargando Campañas...
        </p>
        <div v-for="data in dataCupones" :key="data">
          <div
            class="grid grid-cols-2 gap-3 xl:grid-cols-5 p-5 border-b text-sm xl:text-base"
          >
            <p class="order-3 xl:order-1">
              {{ data.description }}
            </p>
            <p class="order-1 xl:order-2">
              {{ data.code }}
            </p>
            <p
              class="order-2 xl:order-3 text-blue-400 font-semibold text-right xl:text-left"
            >
              ${{ data.amount }} dscto.
            </p>
            <p class="order-4 text-right xl:text-left">
              <span class="text-main-orange">{{ data.quantity }}</span
              >/100
            </p>
            <button
              type="button"
              @click.prevent="sharedCupon(data.code)"
              class="hidden xl:flex items-center gap-3 order-5"
            >
              <img src="../../assets/icons/icon-copiar-2.png" alt="Compartir" />
              <span>Compartir</span>
            </button>
          </div>
        </div>
        <!-- Pagination -->
        <div class="mt-12 flex justify-end items-center gap-12">
          <p>
            Pág.
            <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
              dataTable.from
            }}</span>
            de
            {{ dataTable.page }}
          </p>
          <div class="flex items-center gap-4">
            <figure
              v-if="dataTable.pastStatus"
              @click.prevent="getCuponInfluencer(0)"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-left-bold.svg" />
            </figure>
            <figure
              v-if="dataTable.nextStatus"
              @click.prevent="getCuponInfluencer(1)"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-right-bold.svg" />
            </figure>
          </div>
        </div>

        <p
          class="font-medium text-center pb-8 pt-14"
          v-if="dataCupones.length === 0 && !loading"
        >
          No hay ninguna campaña
        </p>
      </span>
    </article>
    <article
      v-if="page === 2"
      class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm"
    >
      <h3
        class="flex items-center gap-6 text-xl xl:text-3xl font-bold text-main-red mb-10"
      >
        <button @click.prevent="changePagePrevView" type="button">
          <img src="../../assets/icons/icono-volver.png" alt="" />
        </button>
        Gana hasta $100 USD Gratis
      </h3>
      <h4 class="text-text-blue font-bold text-sm xl:text-base mb-3">
        Gana $10 USD, Por cada amigo que compre en EnviosUsaPerú
      </h4>
      <p class="mb-8 text-sm xl:text-base">
        Para ganar este beneficio tus amigos deberán registrarse y comprar en
        nuestra plaforma.
      </p>
      <p class="mb-10 text-sm xl:text-base">
        Comparte tu link en cualquier red social.
      </p>
      <button
        @click.prevent="sharedLink(code)"
        class="py-4 px-10 text-sm xl:text-base text-white font-semibold bg-main-green rounded-xl"
      >
        Click para copiar tu enlace
      </button>
      <hr class="my-10" />
      <div class="flex flex-col xl:flex-row gap-10 items-start">
        <div class="flex items-start gap-4">
          <img src="../../assets/icons/compartir-ws.png" class="w-10" />
          <p class="text-sm">
            Con tu amigos a través de <br />
            WhatsApp o Messenger <br />
            (o en grupos).
          </p>
        </div>
        <div class="flex items-start gap-4">
          <img src="../../assets/icons/compartir-fb.png" class="w-10" />
          <p class="text-sm">
            En tu muro de facebook <br />
            o grupos favoritos.
          </p>
        </div>
        <div class="flex items-start gap-4">
          <img src="../../assets/icons/compartir-tw.png" class="w-10" />
          <p class="text-sm">
            Twitter y otras redes <br />
            sociales.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>
<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      page: 1,
      stattusNoticy: true,
      dataCupones: [],
      totalAsociados: 0,
      code: "",
      influencer: 0,
      nombreUser: "",
      loading: true,
      pageNext: 1,
      pagePast: 1,
      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
    };
  },
  mounted() {
    this.scrollToTop();

    this.getRecomendadoUser();
    setTimeout(() => {
      this.stattusNoticy = false;
    }, 3000);
  },
  methods: {
    changePageNextView() {
      this.page = 2;
    },
    changePagePrevView() {
      this.page = 1;
    },
    sharedCupon(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "El cupón de campaña se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------
        })
        .catch((error) => console.log(error));
    },
    sharedLink(value) {
      navigator.clipboard

        .writeText("https://enviosusaperu.com/afiliados/" + value)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "El enlace se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------
        })
        .catch((error) => console.log(error));
    },
    async getRecomendadoUser() {
      await axios

        .get(`${BASE_URL}` + "clientAsociations/" + this.id)
        .then((response) => {
          this.totalAsociados = response.data.body.cant;
          this.code = response.data.body.id;
          this.influencer = response.data.body.influncer;
          this.nombreUser = response.data.body.name;
          this.nombreUser = this.nombreUser.replace(" ", "_");
          if (response.data.body.influncer === 1) {
            this.getCuponInfluencer(1);
          }
        })
        .catch(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description = "Error al mostrar asociados";
          this.$store.state.alert.estado = true;
          //--------------------------//
        });
    },
    async getCuponInfluencer(value) {
      var url = "";
      if (value === 1) {
        url =
          `${BASE_URL}` +
          "campaigns/getList/" +
          this.id +
          `?length=${this.dataTable.pagination}&page=${this.pageNext}`;
      }
      if (value === 0) {
        url =
          `${BASE_URL}` +
          "campaigns/getList/" +
          this.id +
          `?length=${this.dataTable.pagination}&page=${this.pagePast}`;
      }
      await axios

        .get(url)
        .then((response) => {
          this.dataCupones = response.data.body.data;
          this.loading = false;
          this.dataTable.page = response.data.body.last_page;
          this.dataTable.from = response.data.body.current_page;
          this.dataTable.next = response.data.body.next_page_url;
          this.dataTable.past = response.data.body.prev_page_url;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");

            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }

          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description =
            "Error al mostrar los códigos de campaña";
          this.$store.state.alert.estado = true;
          //--------------------------//
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
