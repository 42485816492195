<template>
  <div
    id="register"
    :class="estate ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="max-h-screen overflow-y-auto bg-gray-100 px-8 grid items-center justify-center w-full h-full xl:w-auto xl:h-auto xl:py-24 xl:px-32 xl:rounded-2xl relative"
    >
      <div>
        <h2 class="text-4xl text-center font-semibold mb-2">Registrarse</h2>
        <p class="mb-6 text-center">con tu cuenta para continuar...</p>
        <div class="grid grid-cols-2 items-center gap-4">
          <!-- google -->
          <google-component />
          <!-- facebook -->
          <facebook-component />
        </div>
        <div class="my-6 flex items-center gap-4">
          <hr class="w-full" />
          <span class="h-4 w-8 rounded-full border-2 border-gray-400"></span>
          <hr class="w-full" />
        </div>
        <form action="" class="">
          <!-- NOMBRE -->
          <label class="relative grid items-center mb-5">
            <input
              :class="error.name ? 'ring ring-main-red' : ''"
              class="px-7 py-6 input w-full text-xs sm:text-sm font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              placeholder="Ingresa tu nombre"
              v-model="data.name"
            />
            <span class="absolute -top-2 left-7 bg-gray-100 text-xs font-medium"
              >Nombre</span
            >
          </label>
          <!-- APELLIDO -->
          <label class="relative grid items-center mb-5">
            <input
              :class="error.lastName ? 'ring ring-main-red' : ''"
              class="px-7 py-6 input w-full text-xs sm:text-sm font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              placeholder="Ingresa tu apellido"
              v-model="data.lastName"
            />
            <span class="absolute -top-2 left-7 bg-gray-100 text-xs font-medium"
              >Apellido</span
            >
          </label>

          <label class="relative grid items-center mb-5">
            <input
              :class="error.telefono ? 'ring ring-main-red' : ''"
              class="px-7 py-6 input w-full text-xs sm:text-sm font-semibold bg-white border shadow-sm rounded-2xl"
              type="tel"
              placeholder="999 999 999"
              v-model="data.telefono"
              pattern="\d{3}\s?\d{3}\s?\d{3}"
            />
            <span class="absolute -top-2 left-7 bg-gray-100 text-xs font-medium"
              >Celular</span
            >
          </label>
          <!-- CORREO ELECTRÓNICO -->
          <label class="relative grid items-center mb-5">
            <input
              :class="error.email ? 'ring ring-main-red' : ''"
              class="px-7 py-6 input w-full text-xs sm:text-sm font-semibold bg-white border shadow-sm rounded-2xl"
              type="email"
              placeholder="Ingresa tu correo electrónico"
              v-model="data.email"
            />
            <span class="absolute -top-2 left-7 bg-gray-100 text-xs font-medium"
              >Correo Electrónico</span
            >
          </label>
          <!-- CONTRASEÑA -->
          <label class="relative grid items-center mb-6">
            <input
              :class="error.password ? 'ring ring-main-red' : ''"
              class="px-7 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
              :type="style.password ? 'text' : 'password'"
              placeholder="Ingresa contraseña"
              v-model="data.password"
            />
            <div
              class="absolute right-5 inset-y-0 flex items-center text-gray-400"
            >
              <img
                @click.prevent="seePassword"
                class="inline cursor-pointer"
                width="23"
                height="15"
                src="../../assets/icons/watch.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <span class="absolute -top-2 left-7 bg-gray-100 text-xs font-medium"
              >Contraseña</span
            >
          </label>
          <!-- BUTTONS -->
          <button
            @click.prevent="petitionRegister"
            class="btn bg-main-red text-white w-full py-5 mb-4 font-bold rounded-2xl"
          >
            Crear cuenta
          </button>
        </form>
      </div>
      <img
        id="register-exit"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        @click.prevent="closeRegister"
        alt="Icono de Envios USA"
      />
    </div>
  </div>

  <div
    id=""
    :class="verification ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div class="mx-4 bg-sky px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative">
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Verifica tu correo
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Esta acción requiere una verificación de correo.
          <br class="hiddensm:block" />
          Por favor revisa tu buzón de correo y <br class="hidden sm:block" />
          sigue las instrucciones enviadas.
        </p>
        <p class="mb-6 text-center">El correo fue enviado a:</p>
        <p class="font-semibold text-base xl:text-lg mb-8 text-center">
          {{ data.email }}
        </p>

        <button
          @click.prevent="sendEmail"
          class="w-full block bg-main-red py-6 text-white font-bold rounded-2xl"
        >
          Reenviar
        </button>
      </div>
      <img
        @click.prevent="cloeVerification"
        id="register-exit"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        alt="Icono de Envios USA"
      />
    </div>
  </div>
</template>
<script>
//Componentes
import facebookComponent from "../autentification/socialNetwork/facebookComponent.vue";
import googleComponent from "../autentification/socialNetwork/googleComponent.vue";

//Importando serve
import { server } from "../../config/global";

const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
// import sha1 from "js-sha1";
export default {
  props: ["estate"],
  components: { facebookComponent, googleComponent },
  data() {
    return {
      codeInfluencer: this.$route.query.invitation,
      verification: false,
      style: {
        password: false,
      },
      data: {
        name: "",
        lastName: "",
        email: "",
        password: "",
        telefono: "",
      },
      error: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        telefono: false,
      },
      idUser: 0,
    };
  },
  methods: {
    //metodo para renviar correo
    sendEmail() {
      axios
        .get(`${BASE_URL}` + "client/sendVerificationEmail/" + this.idUser)
        .then((response) => {
          if (response.status === 200) {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Correo enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Inténtelo más tarde";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //resetea los errores
    cloeVerification() {
      this.verification = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    resetError() {
      this.error.name = false;
      this.error.lastName = false;
      this.error.email = false;
      this.error.password = false;
      this.error.telefono = false;
    },
    //consumo del servicio de registro
    petitionRegister() {
      const resultPassword = this.validatePassword();
      const resultEmail = this.validateEmail();
      const resultLastName = this.validateLastName();
      const resultName = this.validateName();
      const resultTelefono = this.validateTelefono();
      if (
        resultPassword &&
        resultEmail &&
        resultLastName &&
        resultName &&
        resultTelefono
      ) {
        axios
          .post(`${BASE_URL}` + "client/create-client", {
            name: this.data.name,
            surnames: this.data.lastName,
            email: this.data.email,
            password: this.data.password,
            store_id: 2,
            influencer_code: this.codeInfluencer,
            cellphone: "+51" + this.data.telefono,
          })
          .then((response) => {
            window.localStorage.removeItem("open");
            if (response.status === 200) {
              this.idUser = response.data.body.id;
              this.resetError();
              this.$emit("close", false);

              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Registro con éxito";
              this.$store.state.alert.estado = true;
              //--------------------------//
              //Verificar Correo
              this.verification = true;
            }
          })
          .catch((error) => {
            window.localStorage.removeItem("open");
            if (error.response.status === 404) {
              this.error.email = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "El correo ya esta en uso";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Inténtelo más tarde o reporte el error al siguiente correo 'admin@sendibox.com'";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    validateTelefono() {
      const data = this.data.telefono;
      if (data.length !== 0) {
        this.error.telefono = false;
        return true;
      } else {
        this.error.telefono = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su teléfono";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //válida la contraseña
    validatePassword() {
      const data = this.data.password;
      if (data.length !== 0) {
        if (data.length >= 8) {
          this.error.password = false;
          return true;
        } else {
          this.error.password = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.password = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese la contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //válida el correo
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        const expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const resultado = expReg.test(data);
        if (resultado) {
          this.error.email = false;
          return true;
        } else {
          this.error.email = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo válido";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.email = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //válida el nombre
    validateName() {
      const data = this.data.name;
      if (data.length !== 0) {
        this.error.name = false;
        return true;
      } else {
        this.error.name = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su nombre";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //válida el apellido
    validateLastName() {
      const data = this.data.lastName;
      if (data.length !== 0) {
        this.error.lastName = false;
        return true;
      } else {
        this.error.lastName = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su apellido";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //cambia el type del input de la contraseña
    seePassword() {
      this.style.password = !this.style.password;
    },
    //cierra el formulario de registro
    closeRegister() {
      this.resetError();
      this.data.name = "";
      this.data.lastName = "";
      this.data.email = "";
      this.data.password = "";
      this.data.telefono = "";
      this.$emit("close", false);
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
