<template>
  <div
    :class="estado ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative mx-4 px-8 xl:px-20 py-24 xl:py-32 bg-white rounded-2xl border shadow-sm"
    >
      <img
        class="mb-8 xl:mb-12 mx-auto"
        width="88"
        height="75"
        src="../../assets/images/alert-error.svg"
        style="animation-duration: 2s"
      />
      <p
        class="font-semibold text-lg text-center mb-8"
        v-if="currentRouteName === 'Home'"
      >
        No se ha encontrado tu producto, inténtalo de nuevo.
      </p>
      <p
        class="font-semibold text-lg text-center mb-8"
        v-if="currentRouteName === 'Purchase'"
      >
        No se logró obtener los datos del producto
      </p>
      <button
        @click.prevent="closeModalError"
        class="py-5 px-16 block mx-auto mb-6 rounded-2xl bg-main-green text-white font-bold text-sm"
      >
        Cotizar manualmente
      </button>
      <a
        @click.prevent="linkWhatsapp"
        class="text-main-green text-center text-sm block font-medium cursor-pointer flex justify-center items-center gap-3"
      >
        <img src="../../assets/icons/whatsapp.png" alt="" />
        <span class="block mt-1">Cotizar por WhatsApp</span>
      </a>
      <button
        @click.prevent="closeModalError"
        type="button"
        class="absolute top-6 right-6"
      >
        <img src="../../assets/icons/exit.svg" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["estado", "productoLink"],
  methods: {
    linkWhatsapp() {
      window.open(
        "https://api.whatsapp.com/send?phone=51987294960&text=Hola%2C%20requiero%20ayuda%20con%20mi%20pedido%20%F0%9F%93%A6%3A%20%20" +
          this.productoLink,
        "_blank"
      );
    },
    //metodo para cerrar el modal de error
    closeModalError() {
      this.$emit("clodeErrorModal", false);
    },
  },
  computed: {
    //Nombre de la ruta
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
