<template>
  <!-- Compra en las mejores tiendas -->
  <section class="bg-white shadow-2xl mt-6">
    <div class="u-container py-10">
      <h3
        class="text-sm xl:text-2xl text-center font-normal text-bg-black mb-10"
      >
        Compra en las <span class="font-semibold">mejores tiendas</span> del
        mundo
      </h3>

      <Splide :options="optionTiendas" :extensions="extensions">
        <SplideSlide
          v-for="tienda in tiendas"
          :key="tienda"
          class="items-center"
        >
          <a :href="tienda.link" target="_blank">
            <img :src="tienda.img" data-slide="1" class="mx-auto" />
          </a>
        </SplideSlide>
      </Splide>
    </div>
  </section>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
//Import imagenes de las tiendas
import tienda1 from "@/assets/images/landing-ebay.svg";
import tienda2 from "@/assets/images/landing-amazon.png";
import tienda3 from "@/assets/images/landing-walmart.png";
import tienda4 from "@/assets/images/landing-ebay.svg";
import tienda5 from "@/assets/images/landing-the-home.png";
import tienda6 from "@/assets/images/landing-bh.png";

export default {
  data() {
    return {
      tiendas: [
        { link: "https://www.ebay.com", img: tienda1 },
        { link: "https://www.amazon.com", img: tienda2 },
        { link: "https://www.walmart.com", img: tienda3 },
        { link: "https://www.ebay.com", img: tienda4 },
        { link: "https://www.homedepot.com.mx", img: tienda5 },
        { link: "https://www.bhphotovideo.com", img: tienda6 },
      ],
      optionTiendas: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 5,
        breakpoints: {
          640: {
            perPage: 2,
          },
        },
        autoScroll: {
          speed: 1,
        },
      },
      extensions: {
        AutoScroll,
      },
    };
  },
};
</script>
