<template>
  <!-- Gana dinero viajando -->
  <section class="bg-main-red relative overflow-x-hidden">
    <div class="u-container xl:py-60 z-10 relative">
      <h3 class="text-2xl xl:text-4xl font-normal text-white mb-2">
        ¿Cómo ganar
      </h3>
      <h3 class="text-2xl xl:text-4xl text-white mb-5 font-bold">
        dinero viajando?
      </h3>
      <p class="text-white mb-6 text-xs xl:text-base">
        Busca el producto en la tienda <br class="xl:hidden" />
        de USA y pega el link
      </p>
      <a
        @click.prevent="authViewContact"
        class="cursor-pointer text-xs xl:text-base px-6 py-4 xl:py-5 xl:px-16 rounded-lg xl:rounded-2xl bg-main-green text-white font-bold shadow-2xl z-10 relative"
      >
        Viajar
      </a>
      <img
        src="../../assets/images/ganar-dinero-viajando.png"
        alt="Ganar dinero viajando"
        class="absolute bottom-0 -right-8 xl:right-0 w-full xl:w-3/5"
      />
    </div>
    <img
      src="../../assets/images/mosaico-hero.png"
      class="absolute inset-x-0 bottom-0 pointer-event-none w-full object-cover"
    />
  </section>
  <!-- Emcargos recientes -->
  <section class="u-container xl:py-40">
    <div
      class="bg-white border shadow-2xl px-3 py-10 xl:px-8 xl:py-28 rounded-2xl xl:flex items-center justify-around xl:w-11/12 mx-auto"
    >
      <article>
        <h3
          class="text-center xl:text-left text-2xl xl:text-5xl text-bg-black mb-1 xl:mb-2"
        >
          Encargos
        </h3>
        <h3
          class="text-center xl:text-left text-2xl xl:text-5xl font-bold text-bg-black mb-6 xl:mb-3"
        >
          Recientes
        </h3>
      </article>
      <aside>
        <div
          v-for="producto in products2"
          :key="producto.id"
          class="border shadow-xl rounded-xl xl:rounded-2xl pt-4 px-3 pb-5 xl:pt-4 xl:pb-6 xl:px-12 xl:flex gap-8 xl:mb-6"
        >
          <figure class="rounded-2xl border shadow-sm py-6 px-6 mb-4 xl:mb-0">
            <img
              :src="producto.product_image_url"
              class="h-24 w-32 mx-auto object-contain"
              alt="Cotización en Envios USA"
            />
          </figure>
          <div class="text-bg-black">
            <header class="flex xl:justify-between items-center mb-2">
              <figure>
                <img
                  v-if="producto.profile_image_url !== null"
                  :src="producto.profile_image_url"
                  class="xl:hidden w-8 h-8 rounded-full object-cover"
                  alt="Perfil de comprador en Envios USA"
                />
                <img
                  v-else
                  src="../../assets/images/user-default.svg"
                  class="xl:hidden w-8 h-8 rounded-full object-cover"
                  alt="Perfil por default de comprardor en Envios USA"
                />
              </figure>

              <p class="text-xs ml-3 xl:ml-0">{{ producto.name }}</p>
              <p class="text-xs ml-auto">{{ producto.time }}</p>
            </header>
            <p
              class="font-semibold text-xs xl:text-lg mb-2 max-w-xs xl:mb-0 xl:w-4/5 line-clamp-2"
            >
              {{ producto.product_info }}
            </p>
            <p class="text-xs xl:text-sm mb-4 xl:mb-2">
              {{ producto.us_store }}
            </p>
            <div class="xl:flex gap-4 justify-between">
              <div class="grid grid-cols-2 gap-4">
                <a
                  @click.prevent="copyOrder(producto.reference_link)"
                  class="rounded-xl bg-bg-black text-white font-semibold text-xs xl:text-sm py-4 xl:px-8 text-center"
                >
                  Compartir
                </a>
                <router-link
                  :to="{
                    name: 'Purchase',
                    params: { link: producto.reference_link },
                  }"
                  class="rounded-xl bg-main-green text-white font-semibold text-xs xl:text-sm py-4 xl:px-8 text-center"
                >
                  Comprar ahora
                </router-link>
              </div>
              <img
                :src="producto.profile_image_url"
                v-if="producto.profile_image_url !== null"
                class="hidden xl:block ml-12 order-3 w-12 h-12 rounded-full object-cover"
                alt="Perfil de comprardor en Envios USA"
              />
              <img
                src="../../assets/images/user-default.svg"
                v-else
                class="hidden xl:block ml-12 order-3 w-12 h-12 rounded-full object-cover"
                alt="Perfil por default de comprardor en Envios USA"
              />
            </div>
          </div>
        </div>
      </aside>
    </div>
  </section>
</template>
<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";

export default {
  props: ["getAuth"],
  data() {
    return {
      products2: [],
    };
  },
  mounted() {
    this.getPurchaseOrder();
  },
  methods: {
    copyOrder(link) {
      var encodeLink = encodeURIComponent(link);

      var linkEncode =
        "https://enviosusaperu.com/purchase/" +
        encodeLink +
        "?sharedProduct=true";

      navigator.clipboard
        .writeText(linkEncode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "Enlace de compra copiado";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    getPurchaseOrder() {
      axios
        .get(`${BASE_URL}` + "purchaseOrder/list-last-orders?store=enviosusa")
        .then((response) => {
          if (response.status === 200) {
            this.products2 = response.data;
          }
        })
        .catch((error) => {
          if (error) {
            this.products2 = [];
          }
        });
    },
    authViewContact() {
      if (this.getAuth) {
        this.$router.push({ name: "Contacto" });
      } else {
        this.$emit("openLogin", true);
      }
    },
  },
};
</script>
