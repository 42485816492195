<template>
  <!-- componente de preload al momento de buscar un producto -->
  <!-- <preload-product-component :estado="preload.product" /> -->
  <!-- componente de preload al momento que da error la busqeuda -->
  <!-- <preload-error-component
    :estado="preload.error"
    @clodeErrorModal="preload.error = $event"
  /> -->
  <section class="bg-main-red relative pb-8 xl:pb-40 overflow-hidden">
    <article class="u-container py-40 text-white z-20 relative">
      <div class="xl:w-7/12">
        <h2 class="text-2xl xl:text-4xl mb-4 xl:mb-6">
          <span class="block mb-2 font-bold">
            Olvídate de los trámites aduaneros
          </span>
          Envíos Usa Perú: Tu solución para comprar
          <br class="hidden xl:block" />
          en tiendas extranjeras y recibir <br class="hidden xl:block" />
          tus productos en Perú.
        </h2>
        <p class="text-xs xl:text-base font-medium mb-6">
          Busca el producto en tu tienda favorita y pega el link
        </p>
        <form class="relative mb-4">
          <input
            type="text"
            :class="error.link ? ' text-main-red' : ''"
            class="shadow-xl w-full rounded-2xl pl-5 pr-32 py-5 xl:pl-8 xl:pr-72 text-textColor text-xs xl:text-base focus:ring-transparent"
            placeholder="https://www.tienda.com/producto"
            v-model="data.link"
            @keypress="changeText()"
          />
          <img
            v-if="data.link.length !== 0"
            @click.prevent="resetDataInput"
            src="../../assets/icons/exit.svg"
            class="absolute right-24 xl:right-60 bottom-4 xl:bottom-5 cursor-pointer"
            alt="Icono de Envios USA"
          />

          <button
            @click.prevent="processPurchase"
            @keydown="processPurchase"
            @touchstart="processPurchase"
            class="shadow-2xl absolute right-1 xl:right-0 bottom-1 xl:bottom-0 rounded-xl xl:rounded-2xl bg-purple-700 px-5 py-4 xl:px-12 xl:py-5 font-bold text-xs xl:text-base"
          >
            Cotizar <span class="hidden xl:inline">GRATIS</span>
          </button>
        </form>

        <p
          class="text-white flex items-center justify-center gap-4"
          style="margin-left: -30%"
          :class="seeAlert ? '' : 'oculto'"
        >
          <img
            src="../../assets/icons/alert-error.svg"
            alt="Icono de Envios USA"
          />
          <span class="mt-1">Ingresa un enlace</span>
        </p>
      </div>
    </article>

    <aside class="absolute u-container py-0 inset-0 z-10">
      <figure class="absolute bottom-0 -right-60 xl:right-0 xl:top-16">
        <img
          src="../../assets/images/banner1.png"
          class="h-full object-cover mx-auto"
          style="max-width: 480px"
        />
      </figure>
    </aside>
    <img
      src="../../assets/images/mosaico-hero.png"
      class="h-60 xl:h-auto absolute inset-x-0 bottom-0 pointer-event-none w-full object-cover"
    />
  </section>
</template>
<script>
//Importando componentes
// import preloadProductComponent from "../preload/preloadProductComponent.vue";
// import preloadErrorComponent from "../preload/preloadErrorComponent.vue";

export default {
  // components: { preloadProductComponent, preloadErrorComponent },
  // props: ["seeAlert"],
  data() {
    return {
      seeAlert: false,
      error: {
        link: false,
      },
      data: {
        link: "",
      },
      preload: {
        product: false,
        error: false,
      },
    };
  },

  methods: {
    resetDataInput() {
      this.data.link = "";
      this.error.link = false;
      this.seeAlert = false;
    },
    changeText() {
      this.error.link = false;
      this.seeAlert = false;
    },
    //validación del link
    validateLink() {
      const data = this.data.link;
      if (data.length !== 0) {
        this.error.link = false;
        var urlRegex = /^(ftp|http|https):\/\/?[^ "]+$/;
        const result = urlRegex.test(data);
        if (result) {
          this.seeAlert = false;
          this.error.link = false;
          return true;
        } else {
          this.seeAlert = true;
          this.error.link = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese un link válido 'https://www.'o 'http://www.'";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.seeAlert = true;
        this.error.link = true;
        this.data.link = "Ingrese el link de su producto";
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el link de su producto";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //verificación si el servicio da respuesta o error
    processPurchase() {
      const result = this.validateLink();
      if (result) {
        this.$router.push({
          name: "Purchase",
          params: { link: this.data.link },
        });
      }
    },
  },
};
</script>
