<template>
  <article
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3 class="mb-5 text-text-blue font-semibold xl:text-lg">
      Servicios adicionales
      <span
        class="font-normal text-sm text-main-red cursor-pointer"
        @click.prevent="changeStatusForm"
        >{{ hideForm ? "(Ver más)" : "(Ver menos)" }}</span
      >
    </h3>
    <span v-for="data in dataServicios" :key="data">
      <div
        :class="
          serviciosElegidos.find((value) => value.id === data.id)
            ? ' ring-main-green'
            : ' ring-gray-100'
        "
        @click.prevent="changeServicioExtra(data)"
        class="tooltip no-underline hover:no-underline grid grid-cols-8 xl:grid-cols-7 items-center py-6 px-5 mb-6 shadow-md rounded-2xl cursor-pointer ring hover:ring-main-green transition-colors"
      >
        <div
          :class="
            serviciosElegidos.find((value) => value.id === data.id)
              ? ' border-main-green'
              : ' border-gray-light'
          "
          class="col-span-5 xl:col-span-5 pr-5 border-r"
        >
          <p
            :class="!hideForm ? 'mb-2' : 'mb-0'"
            class="font-bold text-xs xl:text-base flex items-center gap-3"
          >
            <img :src="data.img" :alt="data.name" />
            {{ data.name }}
          </p>
          <p
            v-if="!hideForm"
            class="text-xs xl:text-sm font-medium xl:leading-6"
          >
            {{ data.detail }}
          </p>
        </div>
        <div
          class="col-span-3 xl:col-span-2 font-bold pl-2 xl:pl-6 xl:pr-2 mx-auto"
        >
          <p class="text-center text-xs xl:text-base mb-5">
            <span class="text-main-red">USD</span>
            {{
              data.type === 1
                ? Number(data.newCost).toFixed(2)
                : Number(data.cost).toFixed(2)
            }}
          </p>
          <span
            :class="
              serviciosElegidos.find((value) => value.id === data.id)
                ? 'bg-main-green ring-main-green'
                : 'bg-gray-light ring-gray-light'
            "
            class="flex items-center justify-center mx-auto w-4 h-4 rounded-sm ring ring-offset-2 transition-colors"
          >
            <img
              src="../../../assets/icons/check-white.svg"
              :class="
                serviciosElegidos.find((value) => value.id === data.id)
                  ? ''
                  : 'opacity-0'
              "
              class="w-3 h-auto"
            />
          </span>
        </div>
        <span v-if="hideForm" class="tooltip-box bottom-24">{{
          data.detail
        }}</span>
      </div>
    </span>
  </article>
</template>
<script>
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  data() {
    return {
      hideForm: false,
      dataServicios: [],
      serviciosElegidos: [],
    };
  },
  mounted() {
    this.getServicio();
    console.log(BASE_URL);
  },
  methods: {
    changeStatusForm() {
      this.hideForm = !this.hideForm;
    },
    changeServicioExtra(value) {
      if (value !== 0) {
        var evitarDuplicados = this.serviciosElegidos.find(function (item) {
          if (item.id === value.id) {
            return item;
          }
        });
        if (!evitarDuplicados) {
          this.serviciosElegidos.push(value);
        }
        if (evitarDuplicados) {
          var idServiceRpetido = evitarDuplicados.id;
          this.serviciosElegidos = this.serviciosElegidos.filter(
            (el) => el.id !== idServiceRpetido
          );
        }
      }
      this.$store.state.servicioElegido = this.serviciosElegidos;

      //hallamos el total en dolares
      var totalDolares = 0.0;

      this.serviciosElegidos.forEach((value) => {
        if (value.type === 0) {
          totalDolares = totalDolares + value.cost;
        }
        if (value.type === 1) {
          totalDolares = totalDolares + value.newCost;
        }
      });
      var result1 = Math.floor(totalDolares * 100) / 100;
      this.$store.state.servicePrecioDolares = parseFloat(result1.toFixed(2));

      //hallamos el total en soles
      var totalSoles = 0.0;

      this.serviciosElegidos.forEach((value) => {
        if (value.type === 0) {
          totalSoles = totalSoles + value.local_amount;
        }
        if (value.type === 1) {
          totalSoles = totalSoles + value.newCostSoles;
        }
      });
      var result2 = Math.floor(totalSoles * 100) / 100;
      this.$store.state.servicePrecioSoles = parseFloat(result2.toFixed(2));
    },
    async getServicio() {
      await axios
        .get(`${BASE_URL}` + "servicesExtras/getList/2")
        .then((response) => {
          this.dataServicios = response.data.body;
          this.changeDataService();
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener los servicios extras";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    changeDataService() {
      this.dataServicios.forEach((value) => {
        if (value.type === 1) {
          var porcentajeProduct =
            this.getServicioProductPrice * (value.cost / 100);
          porcentajeProduct = Math.ceil(100 * porcentajeProduct) / 100;
          if (porcentajeProduct < 5) {
            porcentajeProduct = 5;
          }
          porcentajeProduct = porcentajeProduct + 5;

          value.newCost = Math.ceil(100 * porcentajeProduct) / 100;

          var newCostSoles = porcentajeProduct * value.exchange_rate;

          value.newCostSoles = Math.ceil(100 * newCostSoles) / 100;
        }
      });
    },
  },
  computed: {
    getServicioElegido() {
      return this.$store.state.servicioElegido;
    },
    getServicioProductPrice() {
      return this.$store.state.servicePrecioProduct;
    },
  },
  watch: {
    getServicioProductPrice: function () {
      this.changeDataService();
      if (this.getServicioElegido.length !== 0) {
        this.changeServicioExtra(0);
      }
    },
  },
};
</script>
