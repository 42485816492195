<template>
  <div
    id="login"
    :class="estate ? '' : 'oculto'"
    class="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="max-h-screen overflow-y-auto bg-gray-100 px-8 grid items-center justify-center w-full h-full xl:w-auto xl:h-auto xl:py-24 xl:px-32 xl:rounded-2xl relative"
    >
      <div>
        <h2 class="text-4xl text-center font-semibold mb-2">Iniciar sesión</h2>
        <p class="mb-6 text-center">con tu cuenta para continuar...</p>
        <div class="grid grid-cols-2 items-center gap-4">
          <!-- google -->
          <google-component />
          <!-- facebook -->
          <facebook-component />
        </div>
        <div class="my-6 flex items-center gap-4">
          <hr class="w-full" />
          <span class="h-4 w-8 rounded-full border-2 border-gray-400"></span>
          <hr class="w-full" />
        </div>
        <form action="" class="">
          <!-- USUARIO -->
          <label class="relative grid items-center mb-3">
            <input
              :class="error.email ? 'ring ring-main-red' : ''"
              class="pl-20 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
              type="email"
              placeholder="Ingresa usuario"
              v-model="data.email"
            />
            <div
              class="absolute left-5 inset-y-0 flex items-center text-gray-400"
            >
              <img
                width="29"
                height="29"
                class="inline cursor-pointer"
                src="../../assets/icons/user-login.svg"
                alt="Icono de Envios USA"
              />
            </div>
          </label>
          <!-- CONTRASEÑA -->
          <label class="relative grid items-center mb-6">
            <input
              :class="error.password ? 'ring ring-main-red' : ''"
              class="pl-20 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
              :type="style.password ? 'text' : 'password'"
              placeholder="Ingresa contraseña"
              v-model="data.password"
            />
            <div
              class="absolute right-5 inset-y-0 flex items-center text-gray-400"
            >
              <img
                @click.prevent="viewPassword"
                class="inline cursor-pointer"
                width="23"
                height="15"
                src="../../assets/icons/watch.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <div
              class="absolute left-5 inset-y-0 flex items-center text-gray-400"
            >
              <img
                class="inline cursor-pointer"
                width="23"
                height="29"
                src="../../assets/icons/password-logn.svg"
                alt="Icono de Envios USA"
              />
            </div>
          </label>
          <!-- CHECKBOX -->
          <div
            class="mb-12 sm:mb-6 w-10/12 mx-auto flex items-center justify-between gap-32"
          >
            <div class="flex items-center gap-6">
              <div
                @click.prevent="changeRemind"
                :class="style.remind ? 'border-main-red' : 'border-gray-400'"
                class="h-5 w-5 rounded-sm border-2 cursor-pointer flex items-center justify-center"
                style="padding: 3px; padding-left: 2px; padding-right: 2px"
              >
                <span
                  :class="style.remind ? 'bg-main-red' : 'bg-gray-400'"
                  class="h-full w-full rounded-sm"
                ></span>
              </div>
              <span class="text-xs sm:text-sm">Recordarme</span>
            </div>
            <a
              @click.prevent="petitionResetPassword"
              class="cursor-pointer text-main-red text-xs sm:text-sm underline"
              >Olvidé mi contraseña</a
            >
          </div>
          <!-- BUTTONS -->
          <button
            @click.prevent="petitionLogin"
            class="btn bg-main-red text-white w-full py-5 mb-4 font-bold rounded-2xl"
          >
            Iniciar Sesión
          </button>
          <button
            @click.prevent="changeView"
            class="btn border-2 border-main-red text-main-red w-full py-5 font-bold rounded-2xl"
          >
            Registrarme
          </button>
        </form>
      </div>
      <img
        id="login-exit"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        alt="Icono de Envios USA"
        @click.prevent="closeLogin()"
      />
    </div>
  </div>

  <div
    id=""
    :class="verification ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div class="mx-4 bg-sky px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative">
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Verifica tu correo
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Esta acción requiere una verificación de correo.
          <br class="hiddensm:block" />
          Por favor revisa tu buzón de correo y <br class="hidden sm:block" />
          sigue las instrucciones enviadas.
        </p>
        <p class="mb-6 text-center">El correo fue enviado a:</p>
        <p class="font-semibold text-base xl:text-lg mb-8 text-center">
          {{ email2 }}
        </p>
        <button
          @click.prevent="sendEmail"
          class="w-full block bg-main-red py-6 text-white font-bold rounded-2xl"
        >
          Reenviar
        </button>
      </div>
      <img
        @click.prevent="cloeVerification"
        id="register-exit"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        alt="Icono de Envios USA"
      />
    </div>
  </div>
</template>
<script>
//Componentes
import facebookComponent from "../autentification/socialNetwork/facebookComponent.vue";
import googleComponent from "../autentification/socialNetwork/googleComponent.vue";
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
// import sha1 from "js-sha1";
export default {
  props: ["estate"],
  components: { facebookComponent, googleComponent },
  data() {
    return {
      email2: "",
      idUser: 0,
      verification: false,
      style: {
        remind: false,
        password: false,
      },
      data: {
        email: "",
        password: "",
      },
      error: {
        email: false,
        password: false,
      },
    };
  },
  mounted() {
    const dataRemind = localStorage.getItem("remind");
    if (dataRemind !== null) {
      const data = JSON.parse(dataRemind);
      if (data.status) {
        this.data.email = data.email;
        this.style.remind = data.status;
      }
    }
  },
  methods: {
    cloeVerification() {
      this.verification = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    sendEmail() {
      axios
        .get(`${BASE_URL}` + "client/sendVerificationEmail/" + this.idUser)
        .then((response) => {
          if (response.status === 200) {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Correo enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Inténtelo más tarde";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //cambiar la vista a cambiar contraseña
    petitionResetPassword() {
      this.closeLogin();
      this.$emit("resetPassword", true);
      document.body.classList.add("overflow-y-hidden");
    },
    //resetea todos los errores
    resetError() {
      this.error.email = false;
      this.error.password = false;
    },
    //cambia la vista a registro
    changeView() {
      this.resetError();
      this.data.email = "";
      this.data.password = "";

      window.localStorage.removeItem("open");
      this.$emit("changeView");
    },
    //cambia el type del input de la contraseña
    viewPassword() {
      this.style.password = !this.style.password;
    },
    //cambia la clase de la opción "recordarme"
    changeRemind() {
      this.style.remind = !this.style.remind;
    },
    //válida el correo
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        const expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const resultado = expReg.test(data);
        if (resultado) {
          this.email2 = data;
          this.error.email = false;
          return true;
        } else {
          this.error.email = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo válido";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.email = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el correo";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //válida la contraseña
    validatePassword() {
      const data = this.data.password;
      if (data.length !== 0) {
        if (data.length >= 8) {
          this.error.password = false;
          return true;
        } else {
          this.error.password = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.password = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese la contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //consumo del servicio para iniciar sesión
    petitionLogin() {
      const valEmail = this.validateEmail();
      const valPassword = this.validatePassword();
      if (valEmail && valPassword) {
        axios
          .post(`${BASE_URL}` + "client/login-client", {
            email: this.data.email,
            password: this.data.password,
            store_id: 2,
          })
          .then((response) => {
            if (response.status === 200) {
              this.idUser = response.data.body.id;
              if (response.data.body.attributes.email_verified !== 0) {
                const remind = {
                  status: this.style.remind,
                  email: this.data.email,
                };
                const dataCompressRemind = JSON.stringify(remind);
                window.localStorage.setItem("remind", dataCompressRemind);
                this.resetError();
                this.closeLogin();
                const dataId = response.data.body.id;
                const dataName =
                  response.data.body.attributes.profile.attributes.name;
                const dataLastName =
                  response.data.body.attributes.profile.attributes.surnames;
                const dataImage =
                  response.data.body.attributes.profile.attributes.userable
                    .attributes.profile_image_url;
                const dataValidateDNI =
                  response.data.body.attributes.validation.verified;
                const dataEmail =
                  response.data.body.attributes.profile.attributes.userable
                    .attributes.email;
                const dataIdentificador = response.data.body.client_code;
                const type = 1;

                //ALmaccenamos en el localstorage si con tiene una direcciòn
                if (response.data.body.attributes.addresses.length !== 0) {
                  const data = {};
                  data.nombre =
                    response.data.body.attributes.addresses[0].attributes.address;
                  data.lat =
                    response.data.body.attributes.addresses[0].attributes.lat;
                  data.lng =
                    response.data.body.attributes.addresses[0].attributes.lng;
                  data.departamento =
                    response.data.body.attributes.addresses[0].attributes.department;
                  data.provincia =
                    response.data.body.attributes.addresses[0].attributes.city;
                  data.distrito =
                    response.data.body.attributes.addresses[0].attributes.district;
                  data.location =
                    response.data.body.attributes.addresses[0].attributes.free;
                  const dataCompress = JSON.stringify(data);
                  window.localStorage.setItem("direction", dataCompress);
                }

                const dataUser = {
                  globalID: dataId,
                  globalName: dataName,
                  globalLastName: dataLastName,
                  globalImage: dataImage,
                  globalValidate: dataValidateDNI,
                  glovalEmail: dataEmail,
                  identificador: dataIdentificador,
                  type: type,
                };
                const dataCompress = JSON.stringify(dataUser);
                window.localStorage.setItem("auth", dataCompress);
                if (this.$route.query.redirect) {
                  this.$router.push(String(this.$route.query.redirect));
                  window.localStorage.removeItem("open");
                } else {
                  location.reload();
                  window.localStorage.removeItem("open");
                }
              } else {
                this.verification = true;
                this.data.email = "";
                this.data.password = "";
                this.$emit("close", false);
                window.localStorage.removeItem("open");
              }
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Las credenciales son incorrectas";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.error.errorInputPassword = true;
              this.error.errorInputEmail = true;

              console.log("Error en el login: ", error);
            }
          });
      }
    },
    //cierra el formulario de login
    closeLogin() {
      this.resetError();
      this.$emit("close", false);
      this.data.email = "";
      this.data.password = "";
      document.body.classList.remove("overflow-y-hidden");
      window.localStorage.removeItem("open");
    },
  },
};
</script>
