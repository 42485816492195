<template>
  <div
    :class="directionType ? 'ring-main-green' : 'ring-gray-100'"
    class="relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
  >
    <div
      @click.prevent="calculateDirection"
      :class="directionType ? 'border-b border-main-green' : ''"
      class="rounded-2xl flex items-center justify-between p-6 font-semibold cursor-pointer text-text-blue"
    >
      <div
        class="text-text-blue grid gap-2"
        v-if="
          data.nombre.length !== 0 &&
          data.apellido.length !== 0 &&
          data.direccion.length !== 0 &&
          data.departamento.length !== 0 &&
          data.telefono.length !== 0 &&
          data.documento.length !== 0 &&
          data.piso.length !== 0 &&
          data.referencia.length !== 0 &&
          data.provincia.length !== 0 &&
          data.distrito.length !== 0
        "
      >
        <span class="text-sm xl:text-base"
          >{{ data.nombre }} {{ data.apellido }}</span
        >
        <span class="text-sm xl:text-sm">{{ data.direccion }}</span>
        <span class="text-sm xl:text-sm">{{ data.departamento }}</span>
        <span class="text-sm xl:text-sm">{{ data.telefono }}</span>
      </div>
      <span v-else>Nueva Direccion</span>
      <span
        :class="
          directionType
            ? 'bg-main-green text-white'
            : 'bg-gray-light text-gray-400'
        "
        class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
      >
        {{ directionType ? "Seleccionado" : "Seleccionar" }}
      </span>
    </div>

    <div
      :class="style.direction && directionType ? '' : 'hidden'"
      class="px-2 py-6"
    >
      <div class="xl:grid grid-cols-2 gap-3">
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Nombre</span
          >
          <input
            :class="errorNewDirection.name ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Ingresar el nombre"
            v-model="data.nombre"
          />
        </label>
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Apellidos</span
          >
          <input
            :class="errorNewDirection.apellido ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            placeholder="Ingresar el apellido"
            v-model="data.apellido"
          />
        </label>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Documento</span
          >
          <input
            :class="errorNewDirection.documento ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="tel"
            placeholder="Ingresar el documento"
            v-model="data.documento"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </label>
        <label class="relative block mb-5">
          <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
            >Teléfono</span
          >
          <input
            :class="errorNewDirection.telefono ? 'ring ring-main-red' : ''"
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="number"
            placeholder="Ingresar el teléfono"
            v-model="data.telefono"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </label>
      </div>

      <label class="relative block mb-5" v-if="direction === false">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Dirección</span
        >
        <GMapAutocomplete
          placeholder="Ingrese la dirección"
          @place_changed="setPlace"
          :class="
            error.direccion || errorNewDirection.direccion
              ? 'ring ring-main-red'
              : ''
          "
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        >
        </GMapAutocomplete>
        <div class="absolute right-3 inset-y-0 flex items-center">
          <img
            src="../../../assets/icons/icon-global.svg"
            class="cursor-pointer"
            @click.prevent="directionMap"
            alt="Icono de Envios USA"
          />
        </div>
      </label>

      <label class="relative block mb-5" v-if="direction">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Dirección</span
        >
        <input
          :class="
            error.direccion || errorNewDirection.direccion
              ? 'ring ring-main-red'
              : ''
          "
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese la dirección"
          @keypress="updateDirection"
          v-model="data.direccion"
        />
        <div class="absolute right-3 inset-y-0 flex items-center">
          <img
            src="../../../assets/icons/icon-global.svg"
            class="cursor-pointer"
            @click.prevent="directionMap"
            alt="Icono de Envios USA"
          />
        </div>
      </label>
      <label class="relative block mb-5" v-if="statusMap">
        <figure style="height: 250px" class="mb-6 mt-6">
          <GMapMap
            :center="center"
            :zoom="15"
            map-type-id="terrain"
            class="h-full"
          >
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
              />
            </GMapCluster>
          </GMapMap>
        </figure>
      </label>

      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          ># Piso u Oficina</span
        >
        <input
          :class="errorNewDirection.piso ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingresar piso u oficina"
          v-model="data.piso"
        />
      </label>
      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Referencia</span
        >
        <input
          :class="errorNewDirection.referencia ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingresar referencia"
          v-model="data.referencia"
        />
      </label>

      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Departamento</span
        >
        <input
          :class="errorNewDirection.departamento ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese el departamento"
          v-model="data.departamento"
        />
      </label>
      <label class="relative block mb-5">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Provincia</span
        >
        <input
          :class="errorNewDirection.provincia ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese la provincia"
          v-model="data.provincia"
        />
      </label>
      <label class="relative block">
        <span class="absolute -top-2 left-4 bg-white text-xs font-medium"
          >Distrito</span
        >
        <input
          :class="errorNewDirection.distrito ? 'ring ring-main-red' : ''"
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          type="text"
          placeholder="Ingrese el distrito"
          v-model="data.distrito"
        />
      </label>
      <button
        @click.prevent="changeNewDirectionFormFalse"
        class="bg-bg-black block w-10/12 mx-auto text-center mt-4 p-5 font-semibold rounded-2xl text-white"
      >
        Guardar
      </button>
    </div>

    <!-- <div class="absolute inset-x-0 -bottom-4">
      <figure
        :class="style.direction && directionType ? 'flex' : 'hidden'"
        @click.prevent="changeStyleDirection"
        class="cursor-pointer mx-auto w-8 h-8 p-2 rounded-full bg-white border-2 shadow-lg items-center justify-center"
      >
        <img
          :class="style.direction && directionType ? '' : 'transform rotate-180'"
          src="../../../assets/icons/drop-up-blue.svg"
          alt="Icono de Envios USA"
        />
      </figure>
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["typeDirection", "errorNewDirection", "directionIguals"],
  data() {
    return {
      statusMap: false,
      center: { lat: -12.080730407219086, lng: -77.03606970955586 },
      markers: [
        {
          position: {
            lat: -12.080730407219086,
            lng: -77.03606970955586,
          },
        }, // Along list of clusters
      ],
      direction: false,
      style: {
        direction: false,
      },
      error: {
        direccion: false,
      },
      data: {
        nombre: "",
        apellido: "",
        documento: "",
        telefono: "",
        direccion: "",
        referencia: "",
        piso: "",
        departamento: "",
        provincia: "",
        distrito: "",
        lat: 0,
        lng: 0,
        free: 0,
      },
    };
  },
  mounted() {
    this.getDirectionLocal();
  },
  methods: {
    getDirectionLocal() {
      const direction = localStorage.getItem("direction");

      if (direction !== null) {
        if (this.directionIguals === false) {
          const dataDirection = JSON.parse(direction);
          this.data.direccion = dataDirection.nombre;
          this.data.lat = dataDirection.lat;
          this.data.lng = dataDirection.lng;
          this.data.departamento = dataDirection.departamento;
          this.data.provincia = dataDirection.provincia;
          this.data.distrito = dataDirection.distrito;
          this.data.free = dataDirection.location;
          this.center.lat = dataDirection.lat;
          this.center.lng = dataDirection.lng;
          this.markers[0].position.lat = dataDirection.lat;
          this.markers[0].position.lng = dataDirection.lng;
          this.calculateDirection();
          this.direction = true;
        } else {
          this.data.direccion = "";
          this.data.lat = 0;
          this.data.lng = 0;
          this.data.departamento = "";
          this.data.provincia = "";
          this.data.distrito = "";
          this.data.free = 0;

          this.center.lat = -12.080730407219086;
          this.center.lng = -77.03606970955586;
          this.markers[0].position.lat = -12.080730407219086;
          this.markers[0].position.lng = -77.03606970955586;
        }
      }
    },
    directionMap() {
      this.statusMap = !this.statusMap;
    },
    updateDirection() {
      this.direction = false;
      this.data.direccion = "";
    },
    setPlace(place) {
      this.data.direccion = place.name;
      //--------------------------------//
      this.center.lat = place.geometry.location.lat();
      this.center.lng = place.geometry.location.lng();
      this.markers[0].position.lat = place.geometry.location.lat();
      this.markers[0].position.lng = place.geometry.location.lng();
      //---------------------------------//
      this.data.lat = place.geometry.location.lat();
      this.data.lng = place.geometry.location.lng();
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";
      var location = 0;

      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }

        //Determinamos si la direcciòn pertenece a Lima
        if (
          value.long_name === "Callao Region" ||
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima" ||
          value.long_name === "Callao"
        ) {
          location = 1;
        }
      });
      if (country !== "PE" || country === "") {
        this.data.direccion = "";
        this.data.lat = 0;
        this.data.lng = 0;
        this.error.direccion = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.center.lat = -12.080730407219086;
        this.center.lng = -77.03606970955586;
        this.markers[0].position.lat = -12.080730407219086;
        this.markers[0].position.lng = -77.03606970955586;
      } else {
        this.error.direccion = false;
        this.calculateDirection2();
      }
      this.data.departamento = departamento;
      this.data.provincia = provincia;
      this.data.distrito = distrito;
      this.data.free = location;
    },
    //cambiar el estado de style.direction
    changeStyleDirection() {
      this.style.direction = !this.style.direction;
      this.calculateDirection();
    },
    //Solo numeros
    onlyNumber($event) {
      if (
        $event.key === "-" ||
        $event.key === "," ||
        $event.key === "." ||
        $event.key === "+"
      ) {
        $event.preventDefault();
      }
    },
    //Metodo que envia el tipo de dirección para el calculo de tarifa de servicio.
    //1->dirección existente
    //2->Nueva dirección
    //3->dirección default ->recogo en oficina
    calculateDirection() {
      const typeDirection = 2;
      this.$emit("dataTypeDirection", typeDirection);
      this.$emit("dataDirection", this.data);
      this.$emit("latitud", this.data.lat);
      this.$emit("longitud", this.data.lng);
      this.$emit("calculate");

      if (this.style.direction === true) {
        this.style.direction = false;
      } else {
        this.style.direction = true;
      }
    },
    calculateDirection2() {
      const typeDirection = 2;
      this.$emit("dataTypeDirection", typeDirection);
      this.$emit("dataDirection", this.data);
      this.$emit("latitud", this.data.lat);
      this.$emit("longitud", this.data.lng);
      this.$emit("calculate");
    },
    changeNewDirection() {
      this.style.direction = true;
    },
    changeNewDirectionFormFalse() {
      this.style.direction = false;
      window.scrollTo(0, 0);
    },
  },
  computed: {
    directionType() {
      if (this.typeDirection === 2) {
        this.changeNewDirection();
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    directionIguals: function () {
      this.getDirectionLocal();
    },
  },
};
</script>
