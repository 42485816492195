import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//Importando los estilos de tailwind-css
import "./style/tailwind.css";
//Importando librerias externas
import VueGoogleMaps from "@fawmi/vue-google-maps";
import GAuth from "vue3-google-oauth2";
import VueSplide from "@splidejs/vue-splide";

//Importando el datapicker
// import Datepicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);
// app.component('Datepicker', Datepicker);

app.use(store).use(router).use(VueSplide);
const gAuthOptions = {
  clientId:
    "558563436747-kf5935ob6vaf2mlikc7fg7aq2sdtvr2f.apps.googleusercontent.com",
  scope: "profile",
  prompt: "consent",
  fetch_basic_profile: true,
};
app.use(GAuth, gAuthOptions);
app
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAog3Tamm1l2ni9uEr_mrjOzcIOX0763kU",
      libraries: "places",
    },
  })
  .mount("#app");
