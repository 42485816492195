<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none"
  >
    <!-- Dirección -->
    <exist-direction-component
      @click.prevent="sendDirection"
      @dataTypeDirection="typeDirection = $event"
      @latitud="latitud = $event"
      @longitud="longitud = $event"
      @calculate="sendDirection"
      @dataDirection="dataDirection = $event"
      @dataDirectionName="dataDirectionName = $event"
      @existForm="existForm = $event"
      @DirectionIguals="directionIguals = $event"
      @existDirection="existDirection = $event"
      :typeDirection="typeDirection"
    />
    <!-- Nueva direccion -->
    <new-direction-component
      @click.prevent="sendDirection"
      @dataTypeDirection="typeDirection = $event"
      @latitud="latitud = $event"
      @longitud="longitud = $event"
      @calculate="sendDirection"
      @dataDirection="dataDirection = $event"
      :typeDirection="typeDirection"
      :errorNewDirection="errorNewDirection"
      :directionIguals="directionIguals"
    />
    <!-- Recojo en oficina -->
    <default-direction-component
      @click.prevent="sendDirection"
      @dataTypeDirection="typeDirection = $event"
      @dataDirection="dataDirection = $event"
      @calDirection="sendDirection"
      :latitud="latitud"
      :longitud="longitud"
      :typeDirection="typeDirection"
      :existDirection="existDirection"
    />
  </div>
</template>
<script>
//Componentes
import defaultDirectionComponent from "../direction/defaultComponent.vue";
import existDirectionComponent from "../direction/existComponent.vue";
import newDirectionComponent from "../direction/newComponent.vue";
export default {
  props: ["errorNewDirection"],
  components: {
    defaultDirectionComponent,
    existDirectionComponent,
    newDirectionComponent,
  },
  data() {
    return {
      existDirection: false,
      directionIguals: false,
      existForm: false,
      dataDirectionName: {},
      dataDirection: {},
      typeDirection: 0,
      latitud: 0,
      longitud: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    sendDirection() {
      this.$emit("typeDirection", this.typeDirection);
      this.$emit("latitud", this.latitud);
      this.$emit("longitud", this.longitud);
      this.$emit("dataDirection", this.dataDirection);
      this.$emit("dataDirectionName", this.dataDirectionName);
      this.$emit("calculate");
      this.$emit("existForm", this.existForm);
    },
  },
};
</script>
