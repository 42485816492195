<template>
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img
            src="../../../assets/icons/tarjetas-transferencias.png"
            class="w-7"
          />
          <span>
            Transferencia bancaria
            <span style="font-size: 10px" class="block text-gray-400"
              >Sin importar cual sea tu banco</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //Cuando El monto es diferente de Cero -->
  <span v-else>
    <div
      :class="typePayment ? 'ring-main-green' : 'ring-gray-100'"
      class="text-text-blue relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
    >
      <div
        @click.prevent="selectPayment"
        class="tooltip no-underline rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img
            src="../../../assets/icons/tarjetas-transferencias.png"
            class="w-7"
          />
          <span>
            Transferencia bancaria
            <span style="font-size: 10px" class="block text-gray-400"
              >Sin importar cual sea tu banco</span
            >
          </span>
        </p>
        <span
          :class="
            typePayment
              ? 'bg-main-green text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="transition-colors font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
        >
          {{ typePayment ? "Seleccionado" : "Seleccionar" }}
        </span>

        <span class="tooltip-box font-medium bottom-20"
          >Paga vía transferencia bancaria desde tu banca móvil o banca por
          internet sin importar cual sea tu banco</span
        >
      </div>

      <div :class="typePayment ? '' : 'hidden'" class="px-6 py-6">
        <div class="flex items-start gap-4 xl:gap-8">
          <img
            src="../../../assets/icons/interbank-2.svg"
            class="mt-2 w-12 xl:w-auto"
            alt="Envios USA - Interbank"
          />
          <p>
            <span id="transferencia-nombre" class="block font-bold"
              >GRUPO CASZA S.A.C.</span
            >
            <span id="transferencia-ruc" class="block text-gray-400"
              >20609271044</span
            >
            <span id="transferencia-cuenta" class="block text-gray-400"
              >{{ currencySymbol === "USD" ? "Cuenta corriente: 200-3005545622" : "Cuenta Corriente: 200-3005545615" }}</span
            >
            <span id="transferencia-cci" class="block text-gray-400"
              >{{ currencySymbol === "USD" ? "CCI: 003-200-003005545622-39" : "CCI: 003-200-003005545615-33" }}</span
            >
          </p>
          <div class="ml-auto xl:mr-6" @click.prevent="copyPayment">
            <img
              src="../../../assets/icons/icon-copiar.svg"
              class="cursor-pointer"
              alt="Icono de Envios USA"
            />
            <span class="text-gray-400 text-xs">Copiar</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["paymenSelect","currencySymbol"],
  data() {
    return {};
  },
  methods: {
    copyPayment() {
      const nombre = document.getElementById(
        "transferencia-nombre"
      ).textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById(
        "transferencia-cuenta"
      ).textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de tranferencia con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    selectPayment() {
      const select = 3;
      this.$emit("paymentSelect", select);
    },
  },
  computed: {
    typePayment() {
      if (this.paymenSelect === 3) {
        return true;
      } else {
        return false;
      }
    },
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
};
</script>
