<template>
  <!-- Popup -->
  <popUp />
  <!-- MENU CON SESION INICIADA-->
  <div
    v-if="getAuth"
    id="menu"
    class="oculto fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="oculto-derecha transition-all h-full bg-gray-100 py-2 px-7 ml-auto w-80 flex flex-col justify-between overflow-y-auto xl:overflow-hidden"
    >
      <div>
        <!-- Clientes -->
        <div class="rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden">
          <div
            @click.prevent="selectSessionUser"
            class="cursor-pointer bg-bg-black flex items-center p-3 pr-5 rounded-2xl"
          >
            <img
              v-if="data.image !== null"
              :src="data.image"
              class="mr-3 w-10 h-10 rounded-full object-cover"
              alt="Foto de perfil de comprador en Envios USA"
            />
            <img
              v-else
              src="../../assets/images/user-default.svg"
              class="mr-3 w-10 h-10 rounded-full object-cover"
              alt="Foto de perfil por default de comprador en Envios USA"
            />
            <span class="font-semibold text-white">{{
              data.name.split(" ")[0]
            }}</span>
            <img
              :class="style.sesionUser ? 'transform rotate-180' : ''"
              class="ml-auto cursor-pointer"
              width="11"
              height="7"
              src="../../assets/icons/drop-down-white.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <div
            :class="style.sesionUser ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-7"
          >
            <router-link
              :to="{ name: 'User', params: { type: 'perfil' } }"
              @click.prevent="styleNav"
              class="block"
              >Mi perfil</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <span class="flex items-center justify-between">
              <router-link
                :to="{ name: 'User', params: { type: 'credentials' } }"
                @click.prevent="styleNav"
                >Credenciales</router-link
              >
              <span
                v-if="data.validateDni === false"
                class="h-6 w-6 rounded-full flex items-center justify-center bg-red-500 text-white font-bold"
              >
                1
              </span>
            </span>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <span class="flex items-center justify-between">
              <router-link
                :to="{ name: 'User', params: { type: 'direcciones' } }"
                @click.prevent="styleNav"
                >Mis direcciones</router-link
              >
            </span>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'balance' } }"
              class="block"
              @click.prevent="styleNav"
              >Mi saldo</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'cupones' } }"
              class="block"
              @click.prevent="styleNav"
              >Cupones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'cotizaciones' } }"
              class="block"
              @click.prevent="styleNav"
              >Cotizaciones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'pedidos' } }"
              class="block"
              @click.prevent="styleNav"
              >Mis pedidos</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'recomendados' } }"
              class="block"
              @click.prevent="styleNav"
              >Afiliados</router-link
            >
          </div>
        </div>
        <!-- Clientes -->
        <div
          class="hidden rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden"
        >
          <div
            @click.prevent="selectSessionUser"
            :class="style.sesionUser && 'border-b rounded-2xl'"
            class="cursor-pointer py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Clientes</span>
            <img
              :class="style.sesionUser ? 'transform rotate-180' : ''"
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../assets/icons/drop-down-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <div
            :class="style.sesionUser ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-7"
          >
            <router-link
              :to="{ name: 'User', params: { type: 'perfil' } }"
              @click.prevent="styleNav"
              class="block"
              >Editar perfil</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <span class="flex items-center justify-between">
              <router-link
                :to="{ name: 'User', params: { type: 'credentials' } }"
                @click.prevent="styleNav"
                >Credenciales</router-link
              >
              <span
                v-if="data.validateDni === false"
                class="h-6 w-6 rounded-full flex items-center justify-center bg-red-500 text-white font-bold"
              >
                1
              </span>
            </span>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <span class="flex items-center justify-between">
              <router-link
                :to="{ name: 'User', params: { type: 'direcciones' } }"
                @click.prevent="styleNav"
                >Mi dirección en USA</router-link
              >
            </span>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'balance' } }"
              class="block"
              @click.prevent="styleNav"
              >Saldo</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'cupones' } }"
              class="block"
              @click.prevent="styleNav"
              >Cupones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'cotizaciones' } }"
              class="block"
              @click.prevent="styleNav"
              >Cotizaciones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'pedidos' } }"
              class="block"
              @click.prevent="styleNav"
              >Mis pedidos</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'User', params: { type: 'recomendados' } }"
              class="block"
              @click.prevent="styleNav"
              >Recomendados</router-link
            >
          </div>
        </div>
        <!-- Viajeros -->
        <div class="rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden">
          <div
            @click.prevent="selectSessionViajeros"
            :class="style.sessionViajeros && 'border-b rounded-2xl'"
            class="cursor-pointer py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Viajeros</span>
            <img
              :class="style.sessionViajeros ? 'transform rotate-180' : ''"
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../assets/icons/drop-down-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <div
            :class="style.sessionViajeros ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-7"
          >
            <router-link
              :to="{ name: 'User', params: { type: 'viajes' } }"
              @click.prevent="styleNav"
              class="block"
            >
              Mis viajes
            </router-link>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Contacto' }"
              @click.prevent="styleNav"
              class="block"
            >
              Añadir viaje
            </router-link>
          </div>
        </div>
        <!-- Soporte -->
        <div class="rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden">
          <div
            @click.prevent="selectPostVenta"
            :class="style.postVenta && 'border-b rounded-2xl'"
            class="cursor-pointer py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Soporte</span>
            <img
              :class="style.postVenta ? 'transform rotate-180' : ''"
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../assets/icons/drop-down-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <div
            :class="style.postVenta ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-7"
          >
            <a
              class="block"
              href="https://enviosusaperu.freshdesk.com/support/solutions/folders/72000350320"
              target="_blank"
            >
              Preguntas frecuentes
            </a>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <a
              class="block"
              href="https://enviosusaperu.freshdesk.com/support/home"
              target="_blank"
            >
              Base de conocimiento
            </a>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <a
              class="block"
              href="https://api.whatsapp.com/send?phone=51987294960&text=Hola%2C%20requiero%20ayuda%20%F0%9F%93%A6%3A%20%20"
              target="_blank"
            >
              Contáctanos
            </a>
          </div>
        </div>
        <!-- Tracking -->
        <div
          @click.prevent="openTracking"
          class="cursor-pointer rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden"
        >
          <div
            class="py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Tracking</span>
            <img
              width="7"
              height="11"
              class="cursor-pointer"
              src="../../assets/icons/drop-right-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </div>
        <!-- Redes sociales -->
        <div class="flex gap-4 mb-4 items-center">
          <a href="https://www.instagram.com/envios.usa.peru/" target="Blank">
            <img
              width="39"
              height="39"
              src="../../assets/icons/instagram-circle.svg"
              class="w-8 xl:w-10"
              alt="Instagram de Envios USA"
            />
          </a>
          <a href="https://www.facebook.com/enviosde.usa.peru" target="Blank">
            <img
              width="39"
              height="39"
              src="../../assets/icons/facebook-circle.svg"
              class="w-8 xl:w-10"
              alt="Facebook de Envios USA"
            />
          </a>
        </div>
      </div>

      <div
        @click.prevent="closeSesion"
        class="flex items-center gap-5 p-4 xl:mb-6 cursor-pointer bg-main-red text-white rounded-2xl"
      >
        <img
          src="../../assets/icons/cerrar-sesion-white.svg"
          alt="Icono de Envios USA"
        />
        <span class="font-medium">Cerrar Sesión</span>
      </div>
    </div>
  </div>
  <!-- MENU SIN SESION INICIADA-->
  <div
    v-if="getAuth === false"
    id="menu2"
    class="oculto fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="oculto-derecha transition-all h-full bg-gray-100 py-2 px-7 ml-auto w-80 flex flex-col justify-between overflow-y-auto xl:overflow-hidden"
    >
      <div>
        <div class="rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden">
          <div
            @click.prevent="selectInvitationUser"
            class="cursor-pointer rounded-2xl p-3 pr-5 bg-bg-black flex items-center justify-between"
          >
            <img
              src="../../assets/images/user-default.svg"
              class="mr-3 w-10 h-10 rounded-full object-cover"
              alt="Icono de Envios USA"
            />
            <div class="w-full flex items-center justify-between">
              <span class="font-semibold text-white">Invitado</span>
              <img
                :class="style.invitationUser ? 'transform rotate-180' : ''"
                class="cursor-pointer"
                width="11"
                height="7"
                src="../../assets/icons/drop-down-white.svg"
                alt="Icono de Envios USA"
              />
            </div>
          </div>
          <div
            :class="style.invitationUser ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-7"
          >
            <span @click.prevent="openLogin" class="block cursor-pointer"
              >Iniciar sesión</span
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <span @click.prevent="openRegister" class="block cursor-pointer"
              >Registrarse</span
            >
          </div>
        </div>

        <div
          @click.prevent="openTracking"
          class="cursor-pointer rounded-2xl border shadow-sm mb-4 bg-white overflow-hidden"
        >
          <div
            class="py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Tracking</span>
            <img
              width="7"
              height="11"
              class="cursor-pointer"
              src="../../assets/icons/drop-right-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </div>
        <div class="rounded-2xl border shadow-sm mb-8 bg-white overflow-hidden">
          <a
            href="https://enviosusaperu.freshdesk.com/support/solutions/folders/72000350320"
            target="_blank"
            @click.prevent="question"
            class="py-6 px-7 pr-4 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Preguntas frecuentes</span>
            <img
              width="7"
              height="11"
              class="cursor-pointer"
              src="../../assets/icons/drop-right-gray.svg"
              alt="Icono de Envios USA"
            />
          </a>
        </div>
        <div class="flex gap-4 mb-4 items-center">
          <a href="https://www.instagram.com/envios.usa.peru/" target="Blank">
            <img
              width="39"
              height="39"
              src="../../assets/icons/instagram-circle.svg"
              class="w-8 xl:w-10"
              alt="Instagram de Envios USA"
            />
          </a>
          <a href="https://www.facebook.com/enviosde.usa.peru" target="Blank">
            <img
              width="39"
              height="39"
              src="../../assets/icons/facebook-circle.svg"
              class="w-8 xl:w-10"
              alt="Facebook de Envios USA"
            />
          </a>
        </div>
      </div>
    </div>
  </div>

  <header
    class="fixed top-0 inset-x-0 z-30 h-14 xl:h-24 bg-bg-black text-white"
    style="z-index: 21"
  >
    <div class="u-container py-3 xl:py-5 flex items-center justify-between">
      <router-link
        @click="scrollToTop"
        :to="{ name: 'Home' }"
        class="order-2 xl:order-1"
      >
        <figure>
          <img
            src="../../assets/LOGO-white-2.svg"
            class="w-24 xl:w-auto hidden xl:block"
          />
          <img src="../../assets/LOGO-white-min.svg" class="xl:hidden mt-1" />
        </figure>
      </router-link>
      <buscador class="order-2" />
      <nav class="hidden xl:flex items-center gap-6 order-3">
        <direction-component :username="data.name.split(' ')[0]" />
        <a
          @click.prevent="viewContacto"
          class="font-medium text-sm py-4 px-6 bg-main-orange rounded-2xl cursor-pointer"
        >
          Gana dinero viajando
        </a>
        <a
          @click.prevent="openTracking"
          class="font-medium text-sm cursor-pointer"
          >Tracking</a
        >
        <!-- Sin sesión iniciada -->

        <button
          v-if="getAuth === false"
          id="login-btn"
          @click.prevent="openLogin"
          class="font-medium text-sm"
        >
          Iniciar sesíon
        </button>
        <button
          v-if="getAuth === false"
          @click.prevent="openRegister"
          id="register-btn"
          class="font-medium text-sm py-4 px-8 bg-main-orange rounded-2xl"
        >
          Registrarse
        </button>

        <!-- Con sesión iniciada -->

        <div v-if="getAuth" class="h-12 border-r shadow-sm"></div>
        <div
          v-if="getAuth"
          @click.prevent="navSesion"
          class="flex items-center gap-3 cursor-pointer"
        >
          <button class="font-bold">{{ data.name.split(" ")[0] }}</button>
          <img src="../../assets/icons/drop-down-gray.svg" class="mr-2" />
          <img
            v-if="data.image !== null"
            class="w-14 h-14 rounded-full border shadow-sm"
            :src="data.image"
            alt="Perfil de comprador de Envios USA"
          />
          <img
            v-else
            class="w-14 h-14 rounded-full border shadow-sm"
            src="../../assets/images/user-default.svg"
            alt="Perfil por default de comprador de Envios USA"
          />
        </div>
      </nav>
      <!-- //--------------------------// -->
      <button class="xl:hidden order-1">
        <img
          v-if="getAuth"
          @click.prevent="navSesion"
          id="menu-btm"
          class="mt-2 xl:hidden cursor-pointer"
          src="../../assets/icons/menu.svg"
          alt="Icono de Envios USA"
        />
        <img
          v-if="getAuth === false"
          @click.prevent="navInvitation"
          id="menu-btm"
          class="mt-2 xl:hidden cursor-pointer"
          src="../../assets/icons/menu.svg"
          alt="Icono de Envios USA"
        />
      </button>
      <span class="block xl:hidden order-3"></span>

      <!-- //-------Buscador------------// -->
      <button
        v-on:click="openModal"
        type="button"
        class="bg-main-orange px-5 absolute top-0 bottom-0 right-0 xl:hidden"
      >
        <img
          class=""
          src="../../assets/icons/icon-search-white.svg"
          alt="Buscar..."
        />
      </button>
    </div>
  </header>

  <!-- 
  Modal de cotizar desde un modal -->
  <!-- COMPRA DESDE USA -->
  <div
    id="compra"
    @click.prevent="detectClick"
    :class="estadoModal ? '' : 'hidden'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="px-8 py-12 bg-white rounded-2xl border shadow-sm mx-4">
      <h3 class="font-bold xl:px-12 mb-8 text-center xl:text-xl">
        ¡Busca el producto en la tienda de USA <br class="hidden xl:block" />
        y pega el link!
      </h3>
      <label class="relative block mb-4 border shadow-sm rounded-2xl">
        <input
          type="text"
          :class="error.link ? ' text-main-red' : ''"
          class="rounded-2xl py-5 px-6 pr-16 text-sm w-full font-semibold"
          placeholder="www.tienda.com/producto"
          v-model="data.link"
        />
        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            v-if="data.link.length === 0"
            class="inline cursor-pointer"
            src="../../assets/icons/buscar.svg"
            alt="Icono de Envios USA"
          />
          <img
            v-else
            @click.prevent="resetInput"
            class="inline cursor-pointer"
            src="../../assets/icons/exit.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>
      <button
        @click.prevent="processPurchase"
        @keydown="processPurchase"
        @touchstart="processPurchase"
        class="rounded-2xl bg-main-red py-5 text-white font-bold block w-full"
      >
        Buscar
      </button>
    </div>
  </div>
</template>
<script>
import directionComponent from "../home/directionComponent.vue";
import buscador from "./buscador.vue";
import popUp from "./popUp.vue";

export default {
  components: {
    directionComponent,
    buscador,
    popUp,
  },
  comments: {},
  props: ["process"],
  data() {
    return {
      forceSearch: 1,
      estadoModal: false,
      data: {
        name: "",
        lastName: "",
        image: "",
        validateDni: false,
        link: "",
        oldLink: this.$route.params.link,
      },
      style: {
        navSesion: false,
        navInvitation: false,
        invitationUser: false,
        sesionUser: true,
        sessionViajeros: false,
        postVenta: false,
      },
      error: {
        link: false,
      },
    };
  },
  created() {},
  mounted() {
    const menuBtn = document.getElementById("menu-btm");
    const mostrar = (element) => {
      element.classList.remove("oculto");
      element.classList.add("visible");
    };
    const ocultar = (element) => {
      element.classList.remove("visible");
      element.classList.add("oculto");
    };

    if (this.getAuth) {
      const menu = document.getElementById("menu");
      menuBtn.addEventListener("click", () => {
        mostrar(menu);
        setTimeout(() => {
          menu.firstElementChild.classList.remove("oculto-derecha");
        }, 300);
      });
      menu.addEventListener("click", (e) => {
        if (e.target.matches("#menu")) {
          ocultar(menu);
          setTimeout(() => {
            menu.firstElementChild.classList.add("oculto-derecha");
            document.body.classList.remove("overflow-y-hidden");
          }, 1);
        }
      });
    }
    if (this.getAuth === false) {
      const menu = document.getElementById("menu2");
      menuBtn.addEventListener("click", () => {
        mostrar(menu);
        setTimeout(() => {
          menu.firstElementChild.classList.remove("oculto-derecha");
        }, 300);
      });
      menu.addEventListener("click", (e) => {
        if (e.target.matches("#menu2")) {
          ocultar(menu);
          setTimeout(() => {
            menu.firstElementChild.classList.add("oculto-derecha");
            document.body.classList.remove("overflow-y-hidden");
          }, 1);
        }
      });
    }
    if (localStorage.getItem("open")) {
      this.openLogin();
    }
  },
  methods: {
    viewContacto() {
      const estado = this.getAuth;
      if (estado) {
        this.$router.push({ name: "Contacto" });
      } else {
        this.openLogin();
      }
    },
    seedAlert() {
      window.scrollTo(0, 0);
      this.$emit("openAlert", true);
    },
    //Detecta si da click fuera del input para que se cierre
    detectClick(e) {
      const data = e.target.matches("#compra");
      if (data) {
        this.data.link = "";
        this.estadoModal = false;
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    resetInput() {
      this.data.link = "";
    },
    openModal() {
      this.estadoModal = true;
      document.body.classList.add("overflow-y-hidden");
    },
    changeText() {
      this.error.link = false;
    },
    validateLink() {
      const data = this.data.link;
      if (data.length !== 0) {
        this.error.link = false;
        var urlRegex = /^(ftp|http|https):\/\/?[^ "]+$/;
        const result = urlRegex.test(data);
        if (result) {
          this.error.link = false;
          return true;
        } else {
          this.error.link = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese un link válido 'https://www.'o 'http://www.'";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.link = true;
        this.data.link = "Ingrese el link de su producto";

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el link de su producto";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //verificación si el servicio da respuesta o error
    processPurchase() {
      const result = this.validateLink();
      if (result) {
        if (this.data.oldLink === this.data.link) {
          this.estadoModal = false;
          this.data.link = "";
          this.$emit("equalsProduct");
          if (this.process !== 1) {
            this.$emit("resetProcess", 1);
          }
        } else {
          this.estadoModal = false;
          this.$router.push({
            name: "Purchase",
            params: { link: this.data.link },
          });
          this.data.link = "";
          if (this.process !== 1) {
            this.$emit("resetProcess", 1);
          }
        }
      }
    },
    question() {
      window.open("https://enviosusaperu.freshdesk.com/", "_blank");
    },
    // ocultarNavSesion() {
    //   //Ocultar el nav con sesión iniciada
    //   let menu = document.getElementById("menu");
    //   addEventListener("click", (e) => {
    //     if (e.target.matches("#menu")) {
    //       menu.classList.add("oculto");
    //       menu.classList.remove("visible");
    //       this.style.navSesion = false;
    //       document.body.classList.remove("overflow-y-hidden");
    //     }
    //   });
    // },
    // ocultarNavInvitation() {
    //   //Ocultar el nav sin sesión iniciada
    //   let menu2 = document.getElementById("menu2");
    //   addEventListener("click", (e) => {
    //     if (e.target.matches("#menu2")) {
    //       menu2.classList.add("oculto");
    //       menu2.classList.remove("visible");
    //       this.style.navInvitation = false;
    //       document.body.classList.remove("overflow-y-hidden");
    //     }
    //   });
    // },

    styleNav() {
      const menu = document.getElementById("menu");
      const ocultar = (element) => {
        element.classList.remove("visible");
        element.classList.add("oculto");
      };
      ocultar(menu);
      setTimeout(() => {
        menu.firstElementChild.classList.add("oculto-derecha");
      }, 1);
    },
    styleNav2() {
      const menu = document.getElementById("menu2");
      const ocultar = (element) => {
        element.classList.remove("visible");
        element.classList.add("oculto");
      };
      ocultar(menu);
      setTimeout(() => {
        menu.firstElementChild.classList.add("oculto-derecha");
      }, 1);
    },
    //para abrir el modal de tracking
    openTracking() {
      document.body.classList.add("overflow-y-hidden");
      this.style.navSesion = false;
      this.style.navInvitation = false;
      this.$emit("openTracking", true);
    },
    //Cierrar y abre el select del nav con sesión
    selectSessionUser() {
      this.style.sesionUser = !this.style.sesionUser;
      this.style.sessionViajeros = false;
      this.style.postVenta = false;
    },
    selectSessionViajeros() {
      this.style.sessionViajeros = !this.style.sessionViajeros;
      this.style.sesionUser = false;
      this.style.postVenta = false;
    },
    selectPostVenta() {
      this.style.postVenta = !this.style.postVenta;
      this.style.sesionUser = false;
      this.style.sessionViajeros = false;
    },
    //Cierrar y abre el select del nav sin iniciar sesión
    selectInvitationUser() {
      this.style.invitationUser = !this.style.invitationUser;
    },
    //Cerrar sesión
    closeSesion() {
      //borrar el localStorage del producto
      window.localStorage.removeItem("producto");
      window.localStorage.removeItem("direction");

      if (
        this.currentRouteName === "User" ||
        this.currentRouteName === "Purchase" ||
        this.currentRouteName === "Tracking" ||
        this.currentRouteName === "Detalle" ||
        this.currentRouteName === "Contacto"
      ) {
        window.localStorage.removeItem("auth");
        this.$router.push({ name: "Home" });
      } else {
        window.localStorage.removeItem("auth");
        location.reload();
      }
    },
    //Abrir el nav con sesión
    navSesion() {
      const mostrar = (element) => {
        element.classList.remove("oculto");
        element.classList.add("visible");
      };
      const menu = document.getElementById("menu");

      mostrar(menu);
      setTimeout(() => {
        menu.firstElementChild.classList.remove("oculto-derecha");
      }, 300);

      document.body.classList.add("overflow-y-hidden");
    },
    //Abrir el nav como invitado
    navInvitation() {
      const mostrar = (element) => {
        element.classList.remove("oculto");
        element.classList.add("visible");
      };
      const menu = document.getElementById("menu2");

      mostrar(menu);
      setTimeout(() => {
        menu.firstElementChild.classList.remove("oculto-derecha");
      }, 300);

      document.body.classList.add("overflow-y-hidden");
    },
    //Obteniendo el resultado del localsotrage auth
    getAuthData(data) {
      this.data.name = data.globalName;
      this.data.lastName = data.globalLastName;
      this.data.image = data.globalImage;
      this.data.validateDni = data.globalValidate;
    },
    //abrir el formulario de login
    openLogin() {
      this.style.navInvitation = false;
      this.$emit("openLogin", true);
      document.body.classList.add("overflow-y-hidden");
    },
    //abrir el formulario de registro
    openRegister() {
      this.style.navInvitation = false;
      this.$emit("openRegister", true);
      document.body.classList.add("overflow-y-hidden");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    //Obtiene si existe el localstorage
    getAuth() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data != null) {
        this.getAuthData(dataUser);
        return true;
      } else {
        return false;
      }
    },
    //Nombre de la ruta
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    $route: function () {
      document.body.classList.remove("overflow-y-hidden");
    },
    "$route.params.link": function (newVal) {
      this.data.oldLink = newVal;
    },
  },
};
</script>
