<template>
  <!-- Modal de direccion -->

  <!-- MODAL DE DIRECCION -->
  <div
    :class="style.popUp ? '' : 'oculto'"
    class="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity"
  >
    <div class="mx-4">
      <div
        class="rounded-tr-2xl rounded-tl-2xl bg-main-red py-4 px-6 xl:px-12 flex justify-between cursor-pointer"
      >
        <div class="flex gap-6 items-center mr-auto xl:mr-28">
          <img src="../../../assets/icons/bandera-usa.svg" class="w-10" />
          <span class="text-white text-sm">Tu dirección en USA </span>
        </div>
        <img
          @click="openDirection"
          src="../../../assets/icons/icon-x-white.svg"
          class="w-5"
        />
      </div>
      <div
        class="rounded-br-2xl rounded-bl-2xl bg-white flex px-6 xl:px-12 py-6 leading-6 text-sm font-medium gap-6"
      >
        <div>
          <span class="font-semibold block"> Nombre: </span>
          <span class="font-semibold block"> Dirección: </span>
          <span class="font-semibold block"> Ciudad: </span>
          <span class="font-semibold block"> Estado: </span>
          <span class="font-semibold block"> Código Postal: </span>
          <span class="font-semibold block"> País: </span>
          <span class="font-semibold block"> Teléfono: </span>
        </div>
        <div>
          <span class="block" v-if="stateAuth"
            >{{ user !== null ? user.split(" ", 1)[0] : "" }}
            {{ latsname !== null ? latsname.split(" ", 1)[0] : ""
            }}{{ identificador }}
          </span>
          <span class="block" v-else> {{ direction.nombre }}</span>
          <span class="block"> {{ direction.direccion }} </span>
          <span class="block"> {{ direction.ciudad }}</span>
          <span class="block"> {{ direction.estado }} </span>
          <span class="block"> {{ direction.zipCode }} </span>
          <span class="block"> {{ direction.pais }} </span>
          <span class="block"> {{ direction.telefono }} </span>
        </div>
      </div>
    </div>
  </div>
  <!-- Componente de tipo de servicio -->
  <div
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <!-- Cuando el formulario se encuentra en el estado 1 Se vamostrar los tipos de servicios para que seleccione el usuario -->
    <span v-if="process === 1">
      <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
        Tipo de servicio
      </h3>
      <!-- Servicio lite -->
      <div
        @click.prevent="chooseService(1)"
        :class="style.lite ? 'ring ring-main-green' : 'mb-6'"
        class="grid grid-cols-7 items-center py-6 px-5 shadow-md rounded-2xl cursor-pointer ring ring-transparent hover:ring-main-green transition-colors"
      >
        <div
          :class="
            style.lite
              ? 'border-r border-main-green'
              : 'border-r border-gray-light'
          "
          class="col-span-4 xl:col-span-5 pr-2 xl:pr-5 transition-colors"
        >
          <div class="col-span-4 xl:col-span-5 pr-2 xl:pr-5">
            <div class="flex gap-2 xl:gap-3 mb-4">
              <img src="../../../assets/icons/marcador.svg" />
              <div>
                <span class="font-bold text-xs xl:text-sm block"
                  >Servicio Lite</span
                >
                <span class="font-medium text-xs">(Solo pagas el envío)</span>
              </div>
            </div>
            <p class="text-xs xl:text-base font-medium leading-6 xl:leading-8">
              Recibimos tu compra y nos encargamos de entregartela en Perú.
            </p>
          </div>
        </div>
        <div class="col-span-3 xl:col-span-2 font-bold xl:pl-6 xl:pr-2 mx-auto">
          <p class="text-center text-xs xl:text-base mb-5">
            <span class="text-main-red">USD</span> {{ view.lite }}
          </p>
          <span
            :class="
              style.lite
                ? 'bg-main-green text-white'
                : 'bg-gray-light text-gray-400'
            "
            class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl transition-all"
          >
            {{ style.lite ? "Seleccionado" : "Seleccionar" }}
          </span>
        </div>
      </div>
      <div
        :class="style.lite ? ' ' : 'hidden'"
        class="shadow-md rounded-bl-2xl rounded-br-2xl border-b border-l border-r border-main-green px-6 py-4 leading-6 text-sm font-medium mb-6"
      >
        <div class="hidden items-center mb-4 gap-6">
          <img
            src="../../../assets/icons/bandera-usa.svg"
            class="w-10 rounded-sm"
            alt="Icono de Envios USA"
          />
          <p class="text-sm font-semibold">Tu dirección en USA</p>
        </div>
        <div class="hidden gap-6">
          <div>
            <span class="font-semibold block"> Nombre: </span>
            <span class="font-semibold block"> Dirección: </span>
            <span class="font-semibold block"> Ciudad: </span>
            <span class="font-semibold block"> Estado: </span>
            <span class="font-semibold block"> Código Postal: </span>
            <span class="font-semibold block"> País: </span>
            <span class="font-semibold block"> Teléfono: </span>
          </div>
          <div>
            <span class="block" v-if="stateAuth"
              >{{ user !== null ? user.split(" ", 1)[0] : "" }}
              {{ latsname !== null ? latsname.split(" ", 1)[0] : ""
              }}{{ identificador }}
            </span>
            <span class="block" v-else> {{ direction.nombre }}</span>
            <span class="block"> {{ direction.direccion }} </span>
            <span class="block"> {{ direction.ciudad }}</span>
            <span class="block"> {{ direction.estado }} </span>
            <span class="block"> {{ direction.zipCode }} </span>
            <span class="block"> {{ direction.pais }} </span>
            <span class="block"> {{ direction.telefono }} </span>
          </div>
        </div>
        <div
          @click="openDirection"
          :class="style.lite ? 'flex' : 'hidden'"
          class="py-3 text-sm xl:text-base rounded-2xl items-center justify-between cursor-pointer"
        >
          <div class="flex gap-6 items-center">
            <img
              src="../../../assets/icons/bandera-usa.svg"
              class="w-10 h-10 object-cover rounded-full border shadow-sm"
            />
            <span class="font-medium">Mi dirección en USA</span>
          </div>
          <img src="../../../assets/icons/arrow-right-bold.svg" alt="" />
        </div>
      </div>
      <!-- Servicio premium -->
      <div
        @click.prevent="chooseService(2)"
        :class="style.premium ? 'ring ring-main-green mb-4' : 'mb-6'"
        class="grid grid-cols-7 items-center py-6 px-5 shadow-md rounded-2xl cursor-pointer ring ring-transparent hover:ring-main-green transition-colors"
      >
        <div
          :class="
            style.premium
              ? 'border-r border-main-green'
              : 'border-r border-gray-light'
          "
          class="col-span-4 xl:col-span-5 pr-2 xl:pr-5 transition-colors"
        >
          <div class="col-span-4 xl:col-span-5 pr-2 xl:pr-5">
            <div class="flex gap-3 mb-4">
              <img
                src="../../../assets/icons/corona.svg"
                alt="Icono de Envios USA"
              />
              <div>
                <span class="font-bold text-xs xl:text-sm block"
                  >Servicio Premium</span
                >
                <span class="font-medium text-xs"
                  >(Nos encargamos de todo)</span
                >
              </div>
            </div>
            <p class="text-xs xl:text-base font-medium leading-6 xl:leading-8">
              Realizamos la compra y el envío desde USA, hasta la entrega en la
              dirección final.
            </p>
          </div>
        </div>
        <div class="col-span-3 xl:col-span-2 font-bold xl:pl-6 xl:pr-2 mx-auto">
          <p class="text-center text-xs xl:text-base mb-5">
            <span class="text-main-red">USD</span> {{ view.premium }}
          </p>
          <span
            :class="
              style.premium
                ? 'bg-main-green text-white'
                : 'bg-gray-light text-gray-400'
            "
            class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl transition-all"
          >
            {{ style.premium ? "Seleccionado" : "Seleccionar" }}
          </span>
        </div>
        <div class="hidden items-center justify-between px-6 py-6 xl:py-7">
          <div>
            <div class="flex gap-3 mb-4">
              <img
                src="../../../assets/icons/corona.svg"
                alt="Icono de Envios USA"
              />
              <div>
                <span class="font-bold text-xs xl:text-sm block"
                  >Servicio Premium</span
                >
                <span class="font-medium text-xs"
                  >(Nos encargamos de todo)</span
                >
              </div>
            </div>
            <p class="text-xs xl:text-base font-medium leading-6 xl:leading-8">
              Realizamos la compra y el envío desde USA, hasta la entrega en la
              dirección final.
            </p>
          </div>
          <div class="font-bold w-max pl-2 xl:pl-6 xl:pr-2">
            <p
              :class="style.premium ? 'text-main-green' : ''"
              class="text-xs xl:text-base w-max mb-5"
            >
              USD {{ view.premium }}
            </p>

            <span
              :class="
                style.premium
                  ? 'bg-main-green ring-main-green '
                  : 'bg-gray-400 ring-gray-400'
              "
              class="block mx-auto w-3 xl:w-4 h-3 xl:h-4 rounded-full ring ring-offset-2"
            ></span>
          </div>
        </div>
      </div>

      <p
        v-if="typeService === 2"
        class="font-semibold text-center text-xs xl:text-sm"
      >
        ¡Nos encargamos de todo el servicio!
        <span class="text-main-green"> Hasta que este en tus manos. </span>
      </p>
    </span>
    <!-- Se va mostrar el tipo de servicio que selecciono el usuario -->
    <span v-if="process === 2 || process === 3">
      <h3
        class="text-text-blue font-semibold xl:text-lg mb-5 flex justify-between items-center"
      >
        Tipo de servicio
        <span
          @click.prevent="changeForm"
          class="text-sm xl:text-base text-main-red cursor-pointer"
          >(Cambiar)</span
        >
      </h3>
      <!-- Se muestra esta tarjeta cuando el servicio que elijio el usuario es el lite -->
      <span v-if="typeService === 1">
        <div
          @click.prevent="chooseService(1)"
          :class="style.lite ? 'ring ring-main-green' : 'mb-6'"
          class="grid grid-cols-7 items-center py-6 px-5 shadow-md rounded-2xl cursor-pointer ring ring-transparent hover:ring-main-green transition-colors"
        >
          <div
            :class="
              style.lite
                ? 'border-r border-main-green'
                : 'border-r border-gray-light'
            "
            class="col-span-4 xl:col-span-5 pr-2 xl:pr-5 transition-colors"
          >
            <div class="col-span-4 xl:col-span-5 pr-2 xl:pr-5">
              <div class="flex gap-2 xl:gap-3 mb-4">
                <img src="../../../assets/icons/marcador.svg" />
                <div>
                  <span class="font-bold text-xs xl:text-sm block"
                    >Servicio Lite</span
                  >
                  <span class="font-medium text-xs">(Solo pagas el envío)</span>
                </div>
              </div>
              <p
                class="text-xs xl:text-base font-medium leading-6 xl:leading-8"
              >
                Recibimos tu compra y nos encargamos de entregartela en Perú.
              </p>
            </div>
          </div>
          <div
            class="col-span-3 xl:col-span-2 font-bold xl:pl-6 xl:pr-2 mx-auto"
          >
            <p class="text-center text-xs xl:text-base mb-5">
              <span class="text-main-red">USD</span> {{ view.lite }}
            </p>
            <span
              :class="
                style.lite
                  ? 'bg-main-green text-white'
                  : 'bg-gray-light text-gray-400'
              "
              class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl transition-all"
            >
              {{ style.lite ? "Seleccionado" : "Seleccionar" }}
            </span>
          </div>
        </div>
        <p class="font-semibold text-center text-xs xl:text-sm mt-4">
          Utiliza esta dirección en USA, compra tu producto y
          <span
            @click.prevent="openDirection"
            class="relative text-main-red cursor-pointer"
          >
            envíala aquí
          </span>
        </p>
      </span>
      <!-- Se muestra esta tarjeta cuando el servicio que elijio el usuario es el premium -->
      <span v-if="typeService === 2">
        <div
          @click.prevent="chooseService(2)"
          :class="style.premium ? 'ring ring-main-green mb-4' : 'mb-6'"
          class="grid grid-cols-7 items-center py-6 px-5 shadow-md rounded-2xl cursor-pointer ring ring-transparent hover:ring-main-green transition-colors"
        >
          <div
            :class="
              style.premium
                ? 'border-r border-main-green'
                : 'border-r border-gray-light'
            "
            class="col-span-4 xl:col-span-5 pr-2 xl:pr-5 transition-colors"
          >
            <div class="col-span-4 xl:col-span-5 pr-2 xl:pr-5">
              <div class="flex gap-3 mb-4">
                <img
                  src="../../../assets/icons/corona.svg"
                  alt="Icono de Envios USA"
                />
                <div>
                  <span class="font-bold text-xs xl:text-sm block"
                    >Servicio Premium</span
                  >
                  <span class="font-medium text-xs"
                    >(Nos encargamos de todo)</span
                  >
                </div>
              </div>
              <p
                class="text-xs xl:text-base font-medium leading-6 xl:leading-8"
              >
                Realizamos la compra y el envío desde USA, hasta la entrega en
                la dirección final.
              </p>
            </div>
          </div>

          <div
            class="col-span-3 xl:col-span-2 font-bold xl:pl-6 xl:pr-2 mx-auto"
          >
            <p class="text-center text-xs xl:text-base mb-5">
              <span class="text-main-red">USD</span> {{ view.premium }}
            </p>
            <span
              :class="
                style.premium
                  ? 'bg-main-green text-white'
                  : 'bg-gray-light text-gray-400'
              "
              class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl transition-all"
            >
              {{ style.premium ? "Seleccionado" : "Seleccionar" }}
            </span>
          </div>
          <div class="hidden items-center justify-between px-6 py-6 xl:py-7">
            <div>
              <div class="flex gap-3 mb-4">
                <img
                  src="../../../assets/icons/corona.svg"
                  alt="Icono de Envios USA"
                />
                <div>
                  <span class="font-bold text-xs xl:text-sm block"
                    >Servicio Premium</span
                  >
                  <span class="font-medium text-xs"
                    >(Nos encargamos de todo)</span
                  >
                </div>
              </div>
              <p
                class="text-xs xl:text-base font-medium leading-6 xl:leading-8"
              >
                Realizamos la compra y el envío desde USA, hasta la entrega en
                la dirección final.
              </p>
            </div>
            <div class="font-bold w-max pl-2 xl:pl-6 xl:pr-2">
              <p
                :class="style.premium ? 'text-main-green' : ''"
                class="text-xs xl:text-base w-max mb-5"
              >
                USD {{ view.premium }}
              </p>

              <span
                :class="
                  style.premium
                    ? 'bg-main-green ring-main-green '
                    : 'bg-gray-400 ring-gray-400'
                "
                class="block mx-auto w-3 xl:w-4 h-3 xl:h-4 rounded-full ring ring-offset-2"
              ></span>
            </div>
          </div>
        </div>
        <p class="font-semibold text-center text-xs xl:text-sm">
          ¡Nos encargamos de todo el servicio!
          <span class="text-main-green"> Hasta que este en tus manos. </span>
        </p>
      </span>
    </span>
  </div>
</template>
<script>
import { directionUsa } from "../../../config/global";
export default {
  props: ["process", "view", "dataTarifa"],
  data() {
    return {
      direction: directionUsa,
      typeService: 2,
      style: {
        lite: false,
        premium: false,
        popUp: false,
      },
      user: "",
      identificador: "",
      latsname: "",
    };
  },
  created() {},
  mounted() {
    this.chooseService(this.typeService);
  },
  methods: {
    openDirection() {
      this.style.popUp = !this.style.popUp;
    },
    getUser() {
      const dataLocal = JSON.parse(localStorage.getItem("auth"));
      this.user = dataLocal.globalName;
      this.identificador = dataLocal.identificador;
      this.latsname = dataLocal.globalLastName;
    },
    // 1 ->lite
    // 2 ->premium
    chooseService(data) {
      if (data === 1) {
        this.style.lite = true;
        this.style.premium = false;
        this.typeService = 1;
      }
      if (data === 2) {
        this.style.lite = false;
        this.style.premium = true;
        this.typeService = 2;
      }
      this.$emit("typeService", this.typeService);
      this.$emit("calculate");
    },
    changeForm() {
      this.$emit("process", 1);
      // this.$emit("resetPayment", 0);
    },
  },
  computed: {
    stateAuth() {
      const data = localStorage.getItem("auth");
      if (data !== null) {
        this.getUser();
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
