<template>
  <button
    @click.prevent="openFormDirection"
    class="text-sm text-main-orange flex items-center justify-star text-left gap-2"
  >
    <img src="../../assets/icons/icon-market-orange.png" alt="" />
    <div>
      <span class="block">Enviar a {{ this.username }}</span>
      <span class="font-bold">{{ data.direction }}</span>
    </div>
  </button>
  <!-- Modal para ingresar la dirección -->
  <div
    @click.prevent="closeModal($event)"
    :class="style.direction ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="px-8 py-12 bg-white rounded-2xl border shadow-sm text-bg-black"
      id="modalConfirm"
    >
      <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
        Elige tu ubicación
      </h3>
      <!-- Departamento -->
      <label
        class="relative block mb-4 border shadow-sm rounded-2xl"
        v-if="!inputDirection"
      >
        <GMapAutocomplete
          placeholder="Mi ubicación"
          @place_changed="setPlace"
          :class="error.direccion ? 'ring ring-main-red' : ''"
          class="rounded-2xl pr-12 py-5 px-6 text-sm w-full font-semibold"
        >
        </GMapAutocomplete>

        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            width="16"
            height="20"
            class="inline cursor-pointer"
            src="../../assets/icons/ubicacion.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>

      <label class="relative block mb-4 border shadow-sm rounded-2xl" v-else>
        <input
          placeholder="Mi ubicación"
          :value="data.direction"
          class="rounded-2xl pr-12 py-5 px-6 text-sm w-full font-semibold"
          @keypress="updateDirection"
        />

        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            width="16"
            height="20"
            class="inline cursor-pointer"
            src="../../assets/icons/ubicacion.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>
      <button
        @click.prevent="openFormDirection"
        class="rounded-2xl bg-main-red py-5 text-white font-bold block w-full"
        v-if="selectStatus"
      >
        Actualizar
      </button>
      <button
        class="rounded-2xl bg-gray-400 py-5 text-white font-bold block w-full cursor-not-allowed"
        v-else
      >
        Actualizar
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["username"],
  data() {
    return {
      //esta variable sirve para detectar si el usuario solo dio enter o si selecciono una opcion de direcciòn
      inputDirection: false,
      selectStatus: false,
      style: {
        direction: false,
      },
      error: {
        direccion: false,
      },
      data: {
        direction: "Ubicación",
      },
    };
  },
  mounted() {
    const direction = localStorage.getItem("direction");
    if (direction !== null) {
      const dataDirection = JSON.parse(direction);
      this.data.direction = dataDirection.nombre;
      this.inputDirection = true;
    }
  },
  onMounted() {
    this.selectStatus = false;
  },
  methods: {
    updateDirection() {
      this.inputDirection = false;
    },
    setPlace(place) {
      var nombreDirection = place.name;

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";
      var location = 0;

      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }

        //Determinamos si la direcciòn pertenece a Lima
        if (
          value.long_name === "Callao Region" ||
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima" ||
          value.long_name === "Callao"
        ) {
          location = 1;
        }
      });

      if (country !== "PE" || country === "") {
        nombreDirection = "";
        lat = 0;
        lng = 0;
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//
      } else {
        this.selectStatus = true;
        this.data.direction = nombreDirection;
        this.error.direccion = false;
        const data = {};
        data.nombre = nombreDirection;
        data.lat = lat;
        data.lng = lng;
        data.departamento = departamento;
        data.provincia = provincia;
        data.distrito = distrito;
        data.location = location;
        const dataCompress = JSON.stringify(data);
        window.localStorage.setItem("direction", dataCompress);
      }
    },
    openFormDirection() {
      this.style.direction = !this.style.direction;
      if (this.style.direction === true) {
        document.body.classList.add("overflow-y-hidden");
        this.selectStatus = false;
      }
      if (this.style.direction === false) {
        document.body.classList.remove("overflow-y-hidden");
        this.selectStatus = false;
      }
    },
    closeModal(e) {
      const modalclose = document
        .getElementById("modalConfirm")
        .contains(e.target);
      if (modalclose === false) {
        this.style.direction = false;
        this.selectStatus = false;
        document.body.classList.remove("overflow-y-hidden");
      }
    },
  },
};
</script>
