<template>
  <!-- Elos ya confian en nosotros -->
  <section class="bg-white shadow-lg mt-10 xl:mt-6 mb-8">
    <div class="u-container py-10">
      <h3
        class="text-sm xl:text-2xl text-center font-normal text-bg-black mb-10"
      >
        Ellos ya <span class="font-semibold">confían en nosotros</span>
      </h3>
      <Splide :options="optionMarcas" :extensions="extensions">
        <SplideSlide v-for="marca in marcas" :key="marca" class="items-center">
          <a :href="marca.link" target="_blank">
            <img :src="marca.img" data-slide="1" class="mx-auto" />
          </a>
        </SplideSlide>
      </Splide>
    </div>
  </section>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

//IMportando imagenes de las marcas
import marca1 from "@/assets/images/tienda-1.png";
import marca2 from "@/assets/images/tienda-2.png";
import marca3 from "@/assets/images/tienda-3.png";
import marca4 from "@/assets/images/tienda-4.png";
import marca5 from "@/assets/images/tienda-5.png";
import marca6 from "@/assets/images/tienda-6.png";
import marca7 from "@/assets/images/tienda-7.png";
import marca8 from "@/assets/images/tienda-8.png";
import marca9 from "@/assets/images/tienda-9.png";
import marca10 from "@/assets/images/tienda-10.png";

export default {
  data() {
    return {
      marcas: [
        { link: "https://www.mercadolibre.com.pe", img: marca1 },
        { link: "https://www.linio.com.pe", img: marca2 },
        { link: "https://www.falabella.com", img: marca3 },
        { link: "https://simple.ripley.cl", img: marca4 },
        { link: "https://www.oechsle.pe", img: marca5 },
        { link: "https://www.plazavea.com.pe", img: marca6 },
        { link: "https://www.realplaza.com", img: marca7 },
        { link: "https://www.lumingo.com", img: marca8 },
        { link: "https://www.promart.pe", img: marca9 },
        { link: "https://www.inretail.pe", img: marca10 },
      ],
      optionMarcas: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 4,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
        interval: 7000,
        resetProgress: true,
        rewind: true,
        speed: 1500,
      },
      extensions: {
        AutoScroll,
      },
    };
  },
};
</script>
