<template>
  <header-component @openTracking="autentification.estateTracking = $event" />
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
  <main class="min-h-screen relative">
    <section class="u-container">
      <article
        class="xl:w-2/3 mx-auto bg-white px-6 xl:px-28 py-20 xl:py-48 text-center border shadow-md rounded-2xl relative z-10"
      >
        <p class="text-sm mb-16">
          “Compra en miles de tiendas de todo el mundo y recíbelo en casa”
        </p>
        <h2 class="text-main-red text-xl xl:text-3xl font-bold mb-6">
          Hola, Bienvenido 🤩
        </h2>
        <h3 class="text-2xl xl:text-4xl mb-8">Tu amigo @{{ name }}</h3>
        <p class="font-bold text-sm xl:text-xl mb-10">
          Te ha compartido $ 10 <span class="text-main-red">USD</span> <br />
          para comprar en nuestra plataforma
        </p>
        <button
          @click.prevent="changeView"
          class="text-sm xl:text-base block mx-auto mb-28 py-4 px-28 font-semibold text-white bg-main-green rounded-xl"
        >
          Registrate
        </button>
      </article>
    </section>
    <img
      class="absolute inset-0 object-cover w-full h-screen"
      src="../assets/images/bg-flujo-compra.png"
      alt="Mosaico..."
    />
  </main>
  <footer-component />
</template>
<script>
import headerComponent from "../components/header/headerComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: { headerComponent, footerComponent, trackingComponent },
  mounted() {
    this.scrollToTop();
    this.getNameCodeUser();
  },
  data() {
    return {
      code: this.$route.params.code,
      name: null,
      autentification: { estateTracking: false },
    };
  },
  created() {
    if (localStorage.getItem("auth")) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    getNameCodeUser() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.code)
        .then((response) => {
          this.name =
            response.data.body.data.attributes.profile.attributes.name;
        })
        .catch((err) => {
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description = "Error al obtener datos";
          this.$store.state.alert.estado = true;
          //--------------------------//
          console.log(err);
        });
    },
    changeView() {
      location.href = "https://enviosusaperu.com/?invitation=" + this.code;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
