<template>
  <div
    id="notificaciones"
    class="text-sm xl:text-base border shadow-lg bg-white rounded-2xl overflow-hidden mb-10 xl:block"
  >
    <div class="hidden xl:block p-5 text-white font-semibold bg-main-red">
      <p class="text-xs xl:text-base font-semibold text-center">
        Notificaciones
      </p>
    </div>
    <p class="font-semibold px-4 xl:px-6 mb-5 mt-6 text-text-blue">
      Tiempo estimado de llegada
    </p>
    <div class="flex items-center justify-between mb-8 px-4 xl:px-6">
      <p class="text-xl font-semibold">
        08:30 <span class="text-sm font-normal">AM</span>
      </p>
      <p class="font-semibold">{{ fechaEntrega }}</p>
    </div>
    <hr class="mb-8 mx-4 xl:mx-6" />
    <div class="px-4 xl:px-6 mb-16">
      <div
        @click.prevent="seeMarker(data.latitud, data.longitud)"
        :key="data"
        v-for="data in tracking"
        :class="data.status ? 'border-main-orange' : ''"
        class="mb-4 border-2 hover:border-main-orange shadow-lg hover:shadow-lg rounded-2xl p-8 transition-all delay-400 cursor-pointer"
      >
        <div class="flex items-center justify-between mb-4">
          <p
            :class="data.status ? 'text-main-orange' : 'text-gray-500'"
            class="flex items-center gap-4 font-bold"
          >
            <span
              :class="data.status ? 'bg-main-orange' : 'bg-gray-500'"
              class="w-8 h-8 rounded-full flex items-center justify-center text-white font-bold"
            >
              {{ data.statusNotification }}
              <!-- {{ data.number }} -->
            </span>
            <span v-if="data.statusNotification === 1"> EN REVISIÓN</span>
            <span v-if="data.statusNotification === 2"> COMPRADO </span>
            <span v-if="data.statusNotification === 3">
              PREPARANDO PARA ENVIAR
            </span>
            <span v-if="data.statusNotification === 4">LISTO PARA ENVIAR </span>
            <span v-if="data.statusNotification === 5"> ENVIADO </span>
            <span v-if="data.statusNotification === 6">
              LISTO PARA ENTREGAR
            </span>
            <span v-if="data.statusNotification === 7"> ENTREGADO</span>
            <span v-if="data.statusNotification === 8"> SINIESTRADO</span>
            <span v-if="data.statusNotification === 9">
              EN PROCESO DE DEVOLUCIÓN</span
            >
            <span v-if="data.statusNotification === 10">
              RETORNADO AL PROVEEDOR
            </span>
          </p>
          <p class="text-sm">{{ data.fecha }}</p>
        </div>
        <!-- <p v-if="data.statusNotification === 0">
          Tu orden <b>{{ data.code }}</b> fué recibida por nuestros colaboradores
          de EnviosUsaPeru.
        </p>
        <p v-if="data.statusNotification === 1">
          {{ data.atributos }}
        </p>
        <p v-if="data.statusNotification === 3">
          {{ data.atributos }}
        </p> -->
        <!-- <p class="text-sm">{{ data.fecha }}</p> -->
      </div>
    </div>
    <!-- <div class="hidden py-16 rounded-bl-2xl rounded-br-2xl">
      <p class="text-sm xl:text-base text-center font-semibold mb-8">
        ¡Lo sentimos, estamos trabajando <br />
        para brindarte una mejor experiencia!
      </p>
      <img
        src="../../assets/icons/traking-error.svg"
        class="mx-auto"
        alt="Icono de Envios USA"
      />
    </div> -->
  </div>
</template>
<script>
export default {
  props: ["tracking", "fechaEntrega"],
  methods: {
    seeMarker(lat, lng) {
      if (lat !== null && lng !== null) {
        this.$emit("seeMarkerLat", lat);
        this.$emit("seeMarkerLng", lng);
      }
    },
  },
};
</script>
