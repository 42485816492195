<template>
  <div
    :class="statusModalDni ? 'oculto' : ''"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-white px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Número de DNI a verificar
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Indicanos tu número de DNI para proceder a verificar
        </p>
        <label>
          <span class="block font-semibold mb-3 text-sm"
            >Número de documento:</span
          >
          <input
            type="number"
            id="cel"
            class="block w-full p-4 rounded-md border shadow-md mb-8"
            placeholder="Ingrese el número de su documento de identidad"
            v-model="documentUpdate"
          />
        </label>

        <button
          @click.prevent="updateDocument"
          class="w-full block bg-main-red py-6 text-white font-bold rounded-2xl"
        >
          Continuar
        </button>
      </div>
      <img
        @click.prevent="changeSection"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        alt="Cerrar"
      />
    </div>
  </div>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3
        class="flex items-center gap-6 text-xl xl:text-3xl font-bold text-main-red mb-10"
      >
        <button @click.prevent="changeSection" type="button">
          <img src="../../assets/icons/icono-volver.png" alt="" />
        </button>
        Verifica tu identidad
      </h3>
      <div class="grid xl:grid-cols-3 gap-6 xl:gap-8 items-start mb-8">
        <div>
          <h4 class="text-text-blue font-bold text-sm xl:text-base mb-4">
            Agregar cara anterior del DNI
          </h4>
          <!-- ESTADO INICIAL -->
          <label v-if="statusAdelante === 1" class="cursor-pointer py-4 block">
            <img src="../../assets/images/DNI-front.png" alt="Subir dni" />
            <input
              type="file"
              @change="dataImage($event, true)"
              accept="image/png,image/jpeg,image/jpg"
              class="hidden"
            />
          </label>
          <!-- APROBADA -->
          <div v-else-if="statusAdelante === 3">
            <label
              class="border-2 border-dashed border-main-green rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAdelante" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-check.png"
                    class="w-14"
                  />
                </div>
              </div>
              <!-- <input
                type="file"
                @change="dataImage($event, true)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              /> -->
            </label>
            <p class="text-sm xl:text-base p-4">
              Hemos <b>verificado</b> correctamente esta cara de tu documento
            </p>
          </div>
          <!-- WARNING -->
          <div v-else-if="statusAdelante === 2">
            <div
              class="border-2 border-dashed border-main-orange rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAdelante" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-info.png"
                    class="w-14"
                  />
                </div>
              </div>
            </div>
            <p class="text-sm xl:text-base p-4">
              Esta cara de tu documento requiere <b>verificación manual</b> del
              equipo de soporte. (Este proceso puede demorar hasta 48 horas.)
            </p>
            <label
              class="ml-4 font-semibold text-white bg-main-green p-5 rounded-xl block xl:w-max cursor-pointer"
            >
              Cambiar archivo adjunto
              <input
                type="file"
                @change="dataImage($event, true)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              />
            </label>
          </div>
          <!-- RECHAZADA -->
          <div v-else-if="statusAdelante === 4">
            <div
              class="border-2 border-dashed border-main-red rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAdelante" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-error.png"
                    class="w-14"
                  />
                </div>
              </div>
            </div>
            <p class="text-sm xl:text-base p-4">
              Esta cara de tu documento ha sido <b>rechazada</b> por nuestro
              equipo de soporte
            </p>
            <label
              class="ml-4 font-semibold text-white bg-main-green p-5 rounded-xl block xl:w-max cursor-pointer"
            >
              Intentar nuevamente
              <input
                type="file"
                @change="dataImage($event, true)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              />
            </label>
          </div>
        </div>
        <div>
          <h4 class="text-text-blue font-bold text-sm xl:text-base mb-4">
            Agregar cara posterior del DNI
          </h4>
          <!-- Estado INICIAL -->
          <label class="cursor-pointer py-4 block" v-if="statusAtras === 1">
            <img src="../../assets/images/DNI-reverse.png" alt="Subir dni" />
            <input
              type="file"
              @change="dataImage($event, false)"
              accept="image/png,image/jpeg,image/jpg"
              class="hidden"
            />
          </label>
          <!-- APROBADA -->
          <div v-else-if="statusAtras === 3">
            <label
              class="border-2 border-dashed border-main-green rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAtras" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-check.png"
                    class="w-14"
                  />
                </div>
              </div>
              <!-- <input
                type="file"
                @change="dataImage($event, false)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              /> -->
            </label>
            <p class="text-sm xl:text-base p-4">
              Hemos <b>verificado</b> correctamente esta cara de tu documento
            </p>
          </div>
          <!-- WARNING -->
          <div v-else-if="statusAtras === 2">
            <div
              class="border-2 border-dashed border-main-orange rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAtras" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-info.png"
                    class="w-14"
                  />
                </div>
              </div>
            </div>
            <p class="text-sm xl:text-base p-4">
              Esta cara de tu documento requiere <b>verificación manual</b> del
              equipo de soporte. (Este proceso puede demorar hasta 48 horas.)
            </p>
            <label
              class="ml-4 font-semibold text-white bg-main-green p-5 rounded-xl block xl:w-max cursor-pointer"
            >
              Cambiar archivo adjunto
              <input
                type="file"
                @change="dataImage($event, false)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              />
            </label>
          </div>
          <!-- RECHAZADA -->
          <div v-else-if="statusAtras === 4">
            <div
              class="border-2 border-dashed border-main-red rounded-2xl p-4 block"
            >
              <div class="relative">
                <img :src="imgAtras" class="h-52 w-full" />
                <div
                  class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"
                >
                  <img
                    src="../../assets/icons/credenciales-error.png"
                    class="w-14"
                  />
                </div>
              </div>
            </div>
            <p class="text-sm xl:text-base p-4">
              Esta cara de tu documento ha sido <b>rechazada</b> por nuestro
              equipo de soporte
            </p>
            <label
              class="ml-4 font-semibold text-white bg-main-green p-5 rounded-xl block xl:w-max cursor-pointer"
            >
              Intentar nuevamente
              <input
                type="file"
                @change="dataImage($event, false)"
                accept="image/png,image/jpeg,image/jpg"
                class="hidden"
              />
            </label>
          </div>
        </div>

        <p class="text-xs xl:text-base xl:hidden text-bg-black-blue">
          Si tienes algún problema con verificar tu identidad,
          <br class="hidden xl:block" />
          por favor contactate al <b>123 456 890.</b>
        </p>
      </div>
      <div class="mb-8 border-t border-dashed"></div>
      <p class="text-xs xl:text-base hidden xl:block text-bg-black-blue">
        Si tienes algún problema con verificar tu identidad,
        <br class="hidden xl:block" />
        por favor contactate al
        <a
          href="https://api.whatsapp.com/send?phone=51987294960&text=Hola%2C%20requiero%20ayuda%20%F0%9F%93%A6%3A%20%20"
          target="_blank"
          ><b>(+51) 987 294 960</b></a
        >
      </p>
    </article>
    <div
      :class="preload ? '' : 'oculto'"
      class="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
    >
      <div
        class="mx-4 px-8 xl:px-16 py-24 xl:py-32 bg-white rounded-2xl border shadow-sm"
      >
        <img
          class="animate-spin mb-8 xl:mb-12 mx-auto"
          src="../../assets/icons/loader.svg"
          style="animation-duration: 2s"
          alt="Icono de Sendi"
        />
        <p class="font-semibold text-lg text-center">
          Espera un momento, estamos verificando tu identidad
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      documentUpdate: null,
      statusModalDni: false,
      statusAdelante: 1,
      imgAdelante: "",
      statusAtras: 1,
      imgAtras: "",
      imageData: "",
      preload: false,
    };
  },
  created() {
    this.getVerification();
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    updateDocument() {
      if (this.documentUpdate) {
        axios
          .post(`${BASE_URL}` + "admin/changeDni/" + this.id, {
            dni: this.documentUpdate,
          })
          .then(() => {
            //----------component--------//
            this.$store.state.alert.titulo = "success";
            this.$store.state.alert.description = "Número de dni guardada";
            this.$store.state.alert.estado = true;
            this.statusModalDni = true;
            //--------------------------//
          })
          .catch(() => {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al actualizar el documento de identidad";
            this.$store.state.alert.estado = true;
            //--------------------------//
          });
      }
    },
    serviceVerification(imageData, positionDni) {
      this.preload = true;
      const position = positionDni;
      axios
        .put(`${BASE_URL}` + "client/" + this.id + "/validateClientDocument", {
          document_image: imageData,
          side: position,
        })
        .then((response) => {
          this.preload = false;
          if (response) {
            const data = response.data.body.attributes;
            this.statusAdelante = data.document_face_state;
            this.imgAdelante = data.document_face_url;
            this.statusAtras = data.document_seal_state;
            this.imgAtras = data.document_seal_url;
          }
          const data = response.data.body;
          const verificationDni = data.attributes.validation.verified;
          if (verificationDni) {
            const dataLocal = JSON.parse(localStorage.getItem("auth"));
            const id = dataLocal.globalID;
            const name = dataLocal.globalName;
            const apellido = dataLocal.globalLastName;
            const img = dataLocal.globalImage;
            const dni = verificationDni;
            const email = dataLocal.glovalEmail;
            const dataIdentificador = dataLocal.identificador;
            const type = dataLocal.type;
            const dataUser = {
              globalID: id,
              globalName: name,
              globalLastName: apellido,
              globalImage: img,
              globalValidate: dni,
              glovalEmail: email,
              identificador: dataIdentificador,
              type: type,
            };
            const dataCompress = JSON.stringify(dataUser);
            localStorage.setItem("auth", dataCompress);
            location.reload();
          }
        })
        .catch((error) => {
          this.preload = false;
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al Verificar su DNI";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //se obtiene la imagen
    dataImage(e, positionDni) {
      const file = e.target.files[0];

      let reader = new FileReader();

      reader.onload = (e) => {
        this.imageData = e.target.result;
        this.serviceVerification(this.imageData, positionDni);
      };
      reader.readAsDataURL(file);
    },
    //se obtiene los datos de la verificación
    getVerification() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.id)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data.body.data.attributes;
            this.statusAdelante = data.document_face_state;
            this.imgAdelante = data.document_face_url;
            this.statusAtras = data.document_seal_state;
            this.imgAtras = data.document_seal_url;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener su resultado de la verificación";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    changeSection() {
      this.$emit("changeSection", "credentials");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
