<template>
  <!-- Cupón de descuento -->

  <div
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
      Cupón de descuento
    </h3>
    <form class="border shadow-lg rounded-2xl">
      <label class="relative block">
        <input
          class="text-xs xl:text-base px-6 py-5 pr-32 border focus:border-blue-400 rounded-2xl w-full transition-colors"
          type="text"
          placeholder="Código de descuento"
          v-model="codeCupon"
          :disabled="statusCupon"
        />
        <button
          @click.prevent="statusCupon ? restCupon() : getCupon()"
          :class="statusCupon ? 'bg-main-orange' : 'bg-bg-black'"
          class="absolute px-6 py-3 right-2 xl:right-4 bottom-2 top-2 xl:bottom-3 xl:top-3 cursor-pointer bg-bg-black rounded-xl text-white text-sm font-semibold"
        >
          {{ statusCupon ? "Quitar" : "Aplicar" }}
        </button>
      </label>
    </form>
  </div>
  <!--Fin  Cupón de descuento -->
</template>
<script>
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["idUser"],
  data() {
    return {
      codeCupon: "",
    };
  },
  methods: {
    restCupon() {
      this.$store.commit("resetCupon");
    },
    getCupon() {
      if (this.codeCupon.length !== 0) {
        axios
          .get(
            `${BASE_URL}` +
              "coupon/getCouponByCode/" +
              this.codeCupon +
              "/" +
              this.idUser
          )
          .then((response) => {
            if (response) {
              this.$store.state.cuponStatu = true;
              this.$store.state.cuponCode = response.data.body.attributes.code;
              this.$store.state.cuponMonto =
                response.data.body.attributes.amount;
              this.$store.state.cuponMontoSoles =
                response.data.body.attributes.local_amount;
              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Cupón Aplicado";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          })
          .catch((error) => {
            if (error) {
              this.$store.state.cuponStatu = null;
              this.$store.state.cuponCode = "";
              this.$store.state.cuponMonto = null;
              this.$store.state.cuponMontoSoles = null;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Cupón Erróneo";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Error";
        this.$store.state.alert.description = "Ingrese el cupón";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
    },
  },
  computed: {
    statusCupon() {
      return this.$store.state.cuponStatu;
    },
  },
};
</script>
