<template>
  <section class="hidden xl:block relative pb-8">
    <div
      class="z-10 relative u-container px-16 flex items-center justify-between bg-white shadow-sm"
    >
      <div>
        <h3 class="text-4xl font-medium mb-4">Regístrate</h3>
        <p>Regístrate para empezar a comprar sín límites.</p>
      </div>
      <button
        @click.prevent="openRegister"
        id="register-btn-two"
        class="shadow-red rounded-2xl bg-main-red py-4 px-12 text-white font-bold"
      >
        Registrarse Ahora
      </button>
    </div>
    <div class="absolute inset-x-0 bottom-0 h-1/2 bg-bgGray"></div>
  </section>
</template>
<script>
export default {
  methods: {
    //abrir el formulario de registro
    openRegister() {
      this.$emit("openRegister", true);
    },
  },
};
</script>
