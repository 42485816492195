<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <div class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">
        Cotizaciones
      </h3>

      <!-- Lista -->
      <div>
        <header
          class="grid xl:grid-cols-12 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
        >
          <p class="text-center xl:hidden">Producto</p>
          <p class="hidden xl:block col-span-1"></p>
          <p class="hidden xl:block col-span-6">Producto</p>
          <p class="hidden xl:block col-span-2 xl:text-center">
            URL del producto
          </p>
          <p class="hidden xl:block col-span-3"></p>
        </header>
        <div v-for="cotizacion in dataCotizacion" :key="cotizacion">
          <div
            v-if="cotizacion.img !== 'vacío'"
            class="grid grid-cols-12 gap-5 xl:gap-3 xl:grid-cols-12 p-5 border-b text-sm xl:text-base items-center"
          >
            <p class="order-1 xl:order-1 col-span-2 xl:col-span-1">
              <img :src="cotizacion.img" alt="Producto" class="w-16" />
            </p>
            <p class="order-2 xl:order-2 col-span-10 xl:col-span-6">
              <span class="line-clamp-2">{{ cotizacion.title }}</span
              ><br />
              <span class="flex gap-3 items-center">
                <img src="../../assets/icons/icon-calendar.png" alt="" />
                <span class="pt-1">{{ cotizacion.created_at }}</span>
              </span>
            </p>
            <p
              class="mb-3 xl:mb-0 order-3 xl:order-3 col-span-10 xl:col-span-2 col-start-3 text-main-orange underline"
            >
              <a
                :href="cotizacion.url"
                class="p-4 rounded-md bg-blue-500 flex items-center justify-center xl:mx-auto w-max"
                target="_blank"
              >
                <img src="../../assets/icons/ir-al-link-white.png" alt="" />
              </a>
            </p>
            <p
              class="mb-3 xl:mb-0 order-4 col-span-10 xl:col-span-3 col-start-3 col-start-3 xl:col-start-auto"
            >
              <router-link
                :to="{ name: 'Purchase', params: { link: cotizacion.url } }"
                class="rounded-md px-6 py-4 bg-purple-500 text-white ring-2 ring-purple-500 hover:bg-white hover:text-purple-500 transition-colors"
              >
                Seguir cotizando
              </router-link>
            </p>
          </div>
        </div>
        <!-- Loader -->
        <p class="font-medium text-center pb-8 pt-14" v-if="loading">
          Cargando sus cotizaciones...
        </p>
        <!-- Si no tiene cotizaciones -->
        <p
          class="font-medium text-center pb-8 pt-14"
          v-if="dataCotizacion.length === 0"
        >
          Tu historial de cotizaciones está vacío
        </p>
        <!-- Pagination -->
        <div
          class="mt-12 flex justify-end items-center gap-12 col-start-3 xl:col-start-auto"
        >
          <p>
            Pág.
            <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
              dataTable.from
            }}</span>
            de {{ dataTable.page }}
          </p>
          <div class="flex items-center gap-4">
            <figure
              v-if="dataTable.pastStatus"
              @click.prevent="getCotizacionPast"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-left-bold.svg" />
            </figure>
            <figure
              v-if="dataTable.nextStatus"
              @click.prevent="getCotizacionNext"
              class="cursor-pointer p-3 rounded-full bg-white shadow-md"
            >
              <img src="../../assets/icons/arrow-right-bold.svg" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["id"],
  mounted() {
    this.scrollToTop();
  },
  data() {
    return {
      loading: true,
      dataCotizacion: {},
      pageNext: 1,
      pagePast: 1,

      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
    };
  },
  created() {
    this.getCotizacionNext();
  },
  methods: {
    getCotizacionNext() {
      axios
        .get(
          `${BASE_URL}` +
            "client/getQuotationList/" +
            this.id +
            `?length=${this.dataTable.pagination}&page=${this.pageNext}`
        )
        .then((response) => {
          this.loading = false;
          this.dataCotizacion = response.data.body.data;
          this.dataTable.page = response.data.body.last_page;
          this.dataTable.from = response.data.body.current_page;
          this.dataTable.next = response.data.body.next_page_url;
          this.dataTable.past = response.data.body.prev_page_url;

          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");

            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }

          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al listar sus cotizaciones";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    getCotizacionPast() {
      axios
        .get(
          `${BASE_URL}` +
            "client/getQuotationList/" +
            this.id +
            `?length=${this.dataTable.pagination}&page=${this.pagePast}`
        )
        .then((response) => {
          this.dataCotizacion = response.data.body.data;
          this.dataTable.page = response.data.body.last_page;
          this.dataTable.from = response.data.body.current_page;
          this.dataTable.next = response.data.body.next_page_url;
          this.dataTable.past = response.data.body.prev_page_url;

          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al listar sus cotizaciones";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
