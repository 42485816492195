<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">
        Eliminar cuenta
      </h3>
      <router-link
        :to="{ name: 'User', params: { type: 'perfil' } }"
        class="flex items-center gap-3 mb-6 text-sm xl:text-base font-semibold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: #0b132a; transform: ; msfilter: "
        >
          <path
            d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
          ></path>
        </svg>
        Volver
      </router-link>

      <section class="xl:w-8/12">
        <p class="font-medium text-sm xl:text-base mb-6">
          Si eliminas tu perfil, no podrás recuperar el contenido ni la
          información de tus cuentas bancarias y operaciones realizadas en
          EnviosUsaPeru
        </p>
        <p class="font-medium text-sm xl:text-base mb-8">
          No continues con el proceso sino estas 100% seguro de tu desición esta
          acción no es reversible y las cuentas eliminadas no podran ser
          restauradas luego
        </p>
        <label for="motivo" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Motivo de eliminación
          <span class="text-main-green">(Opcional)</span></label
        >
        <div class="relative mb-6">
          <textarea
            class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
            id="motivo"
            placeholder="Describe tus motivos aqui..."
            rows="4"
            v-model="motivo"
          ></textarea>
          <p
            class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
          >
            Cuentanos ¿por qué deseas eliminar tu cuenta?
          </p>
        </div>
        <button
          class="py-4 px-6 bg-main-red text-white font-semibold rounded-xl text-sm flex items-center gap-3"
          type="button"
          @click.prevent="toggleModal"
        >
          <img src="../../assets/icons/eliminar.png" alt="Editar..." />
          <span>Elimiinar cuenta</span>
        </button>
      </section>
    </article>
  </section>

  <!-- Modal de confirmación -->
  <div
    :class="!stateModal && 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="mx-4 px-8 xl:px-24 py-16 xl:py-24 bg-white rounded-2xl border shadow-sm"
    >
      <p
        class="text-gray-400 font-semibold xl:text-lg text-center mb-8 xl:mb-12 px-12 xl:px-28"
      >
        ¿Está seguro de eliminar su cuenta?
      </p>
      <button
        @click="deleteAccount"
        class="block mb-6 text-center w-full text-white bg-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
      >
        Si
      </button>
      <button
        @click="toggleModal"
        class="block text-center w-full text-main-red border-2 border-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
      >
        No
      </button>
    </div>
  </div>
</template>

<script>
import { server } from "../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;

export default {
  props: ["id"],
  data() {
    return {
      stateModal: false,
      motivo: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    deleteAccount() {
      axios
        .delete(`${BASE_URL}client/${this.id}/delete`, {
          info: this.motivo,
        })
        .then(() => {
          this.$store.state.alert.titulo = "Exito";
          this.$store.state.alert.description = "Tu cuenta ha sido eliminada";
          this.$store.state.alert.estado = true;
          this.closeSesion();
        })
        .catch((err) => {
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description = "Error al eliminar cuenta";
          this.$store.state.alert.estado = true;
          //--------------------------//
          console.warn(err);
        });
    },
    closeSesion() {
      window.localStorage.removeItem("producto");
      window.localStorage.removeItem("auth");
      window.localStorage.removeItem("direction");
      this.$router.push({ name: "Home" });
    },
    toggleModal() {
      this.stateModal = !this.stateModal;
    },
  },
};
</script>
