<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <template v-if="!loading">
        <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">
          Atrapar pedidos
        </h3>
        <div
          class="flex flex-wrap items-start justify-between gap-6 pb-6 border-b mb-6"
        >
          <div>
            <h4 class="mb-3 text-sm xl:text-base font-semibold text-black">
              <span class="block text-sm xl:text-base opacity-60">Origen</span>
              {{ travel.attributes.country_origin }},
              {{ travel.attributes.origin }}
            </h4>
            <h4 class="mb-4 text-sm xl:text-base font-semibold text-black">
              <span class="block text-sm xl:text-base opacity-60">Destino</span>
              {{ travel.attributes.country_destination }},
              {{ travel.attributes.destination }}
            </h4>
            <h4 class="text-sm font-semibold text-black">
              <span class="block text-sm xl:text-base opacity-60"
                >Fecha de llegada</span
              >
              {{ travel.attributes.arrival_date }}
            </h4>
          </div>
          <div>
            <h4 class="text-sm xl:text-xl font-semibold mb-4">
              <span class="text-main-red">USD</span>
              {{ Number(recompensa).toFixed(2) }}
              <span
                class="block font-normal opacity-60 text-xs xl:text-sm text-right"
                >Ganancias</span
              >
            </h4>
            <h4 class="text-sm xl:text-xl font-semibold">
              <span class="text-main-red">USD</span>
              {{ Number(recompensa).toFixed(2) }}
              <span
                class="block font-normal opacity-60 text-xs xl:text-sm text-right"
                >Pago total</span
              >
            </h4>
          </div>
          <div class="flex gap-4 w-full mt-4">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=51987294960&text=Hola%2C%20requiero%20ayuda%20%F0%9F%93%A6%3A%20%20"
              class="p-4 py-3 font-medium rounded-md bg-green-500 text-green-500 ring ring-green-500 bg-opacity-20 text-sm xl:text-base"
            >
              Ayuda
            </a>
            <button
              v-if="travel.attributes.orders.orders.length == 0"
              @click="openModal({ idModal: 'modal-cancelar-viaje' })"
              class="p-4 py-3 font-medium rounded-md bg-red-500 text-red-500 ring ring-red-500 bg-opacity-20 text-sm xl:text-base"
            >
              Cancelar viaje
            </button>
          </div>
        </div>
        <h4 class="text-text-blue text-sm xl:text-xl font-bold mb-6">
          Pedidos disponibles para entregar
        </h4>
        <div class="grid gap-6 xl:gap-10">
          <template v-for="pedido in filteredPedidos" :key="pedido.id">
            <div
              class="p-5 xl:px-10 border border-gray-100 shadow-md rounded-md"
            >
              <header class="flex items-center justify-between gap-6 mb-4">
                <div class="flex items-center gap-6">
                  <figure>
                    <img
                      v-if="pedido.profile_image_url"
                      :src="pedido.profile_image_url"
                      class="w-8 h-8 object-cover rounded-full"
                    />
                    <img
                      v-else
                      src="../../assets/icons/user-login.svg"
                      class="w-8 h-8 object-cover rounded-full"
                    />
                  </figure>
                  <p class="text-xs xl:text-base">
                    <b>{{ pedido.name }}</b> <br class="xl:hidden" />
                    <span class="opacity-70 xl:pl-3">{{
                      pedido.timeEspera
                    }}</span>
                  </p>
                </div>
              </header>
              <article class="grid grid-cols-12 gap-4 xl:gap-6 items-start">
                <figure class="p-4 col-span-12 xl:col-span-4 border-shadow-sm">
                  <img
                    v-if="pedido.product_image_url"
                    :src="pedido.product_image_url"
                    class="w-full"
                  />
                  <img
                    v-else
                    src="../../assets/images/caja-paso-4.svg"
                    class="w-full"
                  />
                </figure>
                <div class="col-span-12 xl:col-span-8 xl:p-4">
                  <h3 class="font-bold text-sm xl:text-lg line-clamp-2 mb-4">
                    {{ pedido.product_info }}
                  </h3>
                  <p class="text-xs xl:text-sm mb-2">
                    Entregar en
                    <span class="font-medium">{{
                      pedido.destination_address
                    }}</span>
                  </p>
                  <p class="text-xs xl:text-sm mb-2">
                    Entregar desde
                    <span class="font-medium">{{
                      pedido.origin_address2
                    }}</span>
                  </p>
                  <p class="text-xs xl:text-sm mb-6">
                    Antes de
                    <span class="font-medium">{{ pedido.delivery_date }}</span>
                  </p>
                  <div class="p-5 rounded-md bg-blue-100 bg-opacity-80 mb-6">
                    <p class="text-xs xl:text-base mb-2">
                      Donde comprar
                      <a
                        target="_blank"
                        :href="pedido.reference_link"
                        class="font-semibold text-blue-500"
                        >{{ getStore(pedido.reference_link) }}</a
                      >
                    </p>
                    <p class="text-xs xl:text-base">
                      Precio del producto:
                      <span class="font-semibold"
                        ><span class="text-main-red">USD</span>
                        {{ pedido.client_price.toFixed(2) }}</span
                      >
                      + Sales tax:
                      <span class="font-semibold"
                        ><span class="text-main-red">USD</span>
                        {{ pedido.real_tax.toFixed(2) }}</span
                      >
                    </p>
                  </div>
                  <div class="flex items-center justify-between mb-4">
                    <p class="text-xs xl:text-base font-medium">
                      Recompensa de entrega
                    </p>
                    <p class="text-sm xl:text-2xl font-bold">
                      <span class="text-main-red">USD</span>
                      {{ pedido.igv.toFixed(2) }}
                    </p>
                  </div>

                  <button
                    @click="atrapar(pedido.id_order)"
                    class="block w-full xl:w-10/12 xl:mx-auto font-semibold rounded-md bg-blue-500 text-blue-500 ring ring-blue-500 bg-opacity-20 py-5 text-sm xl:text-base"
                  >
                    Atrapar pedido
                  </button>
                </div>
              </article>
            </div>
          </template>
        </div>
      </template>
      <!-- Loader -->
      <p v-if="loading" class="font-medium text-center pb-8 pt-14">
        Cargando pedidos...
      </p>
      <!-- Sino hay pedidos disponibles -->
      <p
        class="font-medium text-center pb-8 pt-14"
        v-if="pedidos.length === 0 && !loading"
      >
        No hay pedidos disponibles
      </p>
      <!-- Pagination -->
      <div
        v-if="!loading"
        class="mt-12 flex justify-end items-center gap-12 col-start-3 xl:col-start-auto"
      >
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{ page + 1 }}</span>
          de {{ totalPages }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="page > 0"
            @click="getPrevPage"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-left-bold.svg" />
          </figure>
          <figure
            v-if="page + 1 < totalPages"
            @click="getNextPage"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-right-bold.svg" />
          </figure>
        </div>
      </div>
    </article>

    <!-- Modal cancelar viaje -->
    <div
      id="modal-cancelar-viaje"
      class="oculto fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
    >
      <div
        class="mx-4 px-8 xl:px-24 py-16 xl:py-24 bg-white rounded-2xl border shadow-sm"
      >
        <p
          class="text-gray-400 font-semibold xl:text-lg text-center mb-8 xl:mb-12 px-12 xl:px-28"
        >
          ¿Está seguro de cancelar su viaje?
        </p>
        <button
          @click="deleteTravel(travel.id)"
          class="block mb-6 text-center w-full text-white bg-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
        >
          Si
        </button>
        <button
          @click="closeModal('modal-cancelar-viaje')"
          class="block text-center w-full text-main-red border-2 border-main-red font-bold text-xs xl:text-base py-6 rounded-2xl"
        >
          No
        </button>
      </div>
    </div>
  </section>
</template>
<style></style>
<script>
import axios from "axios";
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

export default {
  props: ["id"],
  data() {
    return {
      loading: true,

      pedidos: [],
      filteredPedidos: [],
      page: 0,
      limit: 4,
      offset: this.page * this.limit,
      totalPages: 0,

      // travelClientId: this.$route.params.idTravel,
      travelClientId: undefined,
      travel: null,
      recompensa: 0,
    };
  },
  created() {
    this.scrollToTop();
    this.getPedidos();
    this.getTravelClientId();
    this.getTravel(this.travelClientId);

    if (this.travelClientId === undefined) {
      this.$router.push({ name: "User", params: { type: "viajes" } });
    }
  },
  mounted() {
    // console.log(this.test)
  },
  methods: {
    // Calcular recompensa del viaje
    calculateRecompensa() {
      if (this.travel.attributes.orders.orders.length > 1) {
        const travelOrders = this.travel.attributes.orders.orders;

        travelOrders.map((order) => {
          this.recompensa += order.arancel_cost;

          return order.arancel_cost;
        });
      } else {
        this.recompensa = 0;
      }
    },
    // Obtener datos del viaje
    getTravel(id) {
      const API_URL = `${BASE_URL}travels/get/${id}`;
      axios
        .get(API_URL)
        .then((res) => {
          this.travel = res.data.body;
          this.calculateRecompensa();
        })
        .catch((error) => {
          if (error) {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al obtener viaje";
            this.$store.state.alert.estado = true;
          }
        });
    },
    // Obtener pedidos disponibles
    getPedidos() {
      const API_URL = `${BASE_URL}travels/getAbles`;
      axios
        .get(API_URL, {
          params: {
            client_id: this.id,
          },
        })
        .then((res) => {
          this.pedidos = res.data.body;
          this.totalPages = Math.ceil(this.pedidos.length / this.limit);
          this.loading = false;
          this.filteredPedidos = this.pedidos.slice(
            this.offset,
            (this.page + 1) * this.limit
          );
          console.log(this.filteredPedidos);
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al listar pedidos";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    // FLecha de siguiente
    getNextPage() {
      this.page = this.page + 1;
      this.offset = this.page * this.limit;

      this.filteredPedidos = this.pedidos.slice(
        this.offset,
        (this.page + 1) * this.limit
      );
      this.scrollToTop();
    },
    // Flecha de atras
    getPrevPage() {
      this.page = this.page - 1;
      this.offset = this.page * this.limit;

      this.filteredPedidos = this.pedidos.slice(
        this.offset,
        (this.page + 1) * this.limit
      );
      this.scrollToTop();
    },
    // Atrapar Pedido
    atrapar(idorder) {
      const API_URL = `${BASE_URL}travels/assign/${idorder}`;
      axios
        .post(API_URL, {
          travel_client_id: this.travelClientId,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$store.state.alert.titulo = "Exito";
            this.$store.state.alert.description = "Pedido atrapado con exito";
            this.$store.state.alert.estado = true;

            // this.loading = true
            this.pedidos = this.pedidos.filter(
              (pedido) => pedido.id_order !== idorder
            );
            this.filteredPedidos = this.filteredPedidos.filter(
              (pedido) => pedido.id_order !== idorder
            );
            // this.getPedidos()
            this.scrollToTop();
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al atrapar pedido";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },

    getStore(link) {
      if (link !== null) {
        var separation1 = link.split(".", 2);
        if (separation1[0] !== "https://www") {
          var separation2 = link.split("//", 3);
          var separation3 = separation2[1].split(".", 1);

          return separation3[0];
        }

        return separation1[1][0].toUpperCase() + separation1[1].substring(1);
      }
      return "Sin store";
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    // Obtener ID del viaje en el local storage para atraparpedidos
    getTravelClientId() {
      this.travelClientId = localStorage.getItem("atraparPedidosID");
    },

    // Eliminar viaje
    deleteTravel(id) {
      const API_URL = `${BASE_URL}client/deleteTravel/${id}`;
      axios
        .delete(API_URL)
        .then((res) => {
          if (res.status === 200) {
            this.closeModal("modal-cancelar-viaje");
            this.$router.push({ name: "User", params: { type: "viajes" } });
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al cancelar viaje";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },

    openModal({ idModal }) {
      const modal = document.getElementById(idModal);
      modal.classList.remove("oculto");
    },
    closeModal(id) {
      const modal = document.getElementById(id);
      modal.classList.add("oculto");

      this.travelToDelete = null;
      this.orderToDelete = null;
    },
  },
};
</script>
