<template>
  <!-- Correo enviado con exito -->
  <div
    :class="confirm ? '' : 'oculto'"
    @click.prevent="closeModal($event)"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="mx-4 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm"
      id="modalConfirm"
    >
      <img
        width="124"
        height="124"
        class="mb-8 xl:mb-12 mx-auto"
        src="../../assets/images/check-green.svg"
        alt="Icono de Envios USA"
      />
      <p class="text-gray-400 font-semibold text-lg text-center mb-2">
        El correo fue envíado a:
      </p>
      <p class="font-semibold text-lg text-center mb-2">{{ data.email }}</p>
      <p class="text-gray-400 font-semibold text-lg text-center">
        Por favor, revisa tu buzón de entrada o spam.
      </p>
    </div>
  </div>
  <!------------------------>
  <div
    :class="resetPassword ? '' : 'oculto'"
    id=""
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="bg-gray-100 px-8 grid items-center justify-center w-full h-full xl:w-auto xl:h-auto xl:py-40 xl:px-32 xl:rounded-2xl relative"
    >
      <div>
        <h2 class="text-4xl text-center font-semibold mb-2">
          Recuperar contraseña
        </h2>
        <p class="mb-6 text-center">
          Enviaremos un enlace a tu correo electrónico
        </p>
        <form action="" class="">
          <!-- USUARIO -->
          <label class="relative grid items-center mb-4">
            <input
              :class="error.email ? 'ring ring-main-red' : ''"
              class="px-6 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
              type="email"
              v-model="data.email"
              placeholder="Correo electrónico"
            />
          </label>
          <!-- BUTTONS -->
          <button
            @click.prevent="petitionResetPassword"
            class="btn bg-main-red text-white w-full py-5 mb-4 font-bold rounded-2xl"
          >
            Enviar
          </button>
        </form>
      </div>
      <img
        id="login-exit"
        @click.prevent="closeResetPasword"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../assets/icons/exit.svg"
        alt="Icono de Envios USA"
      />
    </div>
  </div>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["resetPassword"],
  data() {
    return {
      confirm: false,
      data: {
        email: "",
      },
      error: {
        email: false,
      },
    };
  },
  methods: {
    closeModal(e) {
      const modalclose = document
        .getElementById("modalConfirm")
        .contains(e.target);
      if (modalclose === false) {
        this.confirm = false;
        this.data.email = "";
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    //metodo para enviar al servicio para cambiar contraseña
    petitionResetPassword() {
      const valCorreo = this.validateEmail();
      if (valCorreo) {
        axios
          .post(`${BASE_URL}` + "client/sendResetPasswordLink", {
            email: this.data.email,
            store_id: 2,
          })
          .then((response) => {
            if (response.status === 200) {
              this.closeResetPasword();
              this.confirm = true;
              this.error.email = false;
              document.body.classList.add("overflow-y-hidden");
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const mensajeError = error.response.data.errors.email[0].message;

              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = mensajeError;
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else if (error.response.status === 404) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Correo no encontrado";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al pedir cambio de contraseña";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    //Validar el campo email
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        this.error.email = false;
        const expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        const resultado = expReg.test(data);
        if (resultado) {
          this.error.email = false;
          return true;
        } else {
          this.error.email = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.email = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //cerrar formulario
    closeResetPasword() {
      this.$emit("closeResetPassword", false);
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
