<template>
  <div
    :class="estado ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="mx-4 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm">
      <img
        class="animate-spin mb-8 xl:mb-12 mx-auto"
        src="../../assets/icons/loader.svg"
        style="animation-duration: 2s"
      />
      <p
        class="font-semibold text-lg text-center"
        v-if="currentRouteName === 'Home'"
      >
        Buscando producto
      </p>
      <p
        class="font-semibold text-lg text-center"
        v-if="currentRouteName === 'Purchase'"
      >
        Obteniendo datos del producto
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["estado"],
  computed: {
    //Nombre de la ruta
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
