<template>
  <!-- Componente preload -->
  <preload-component :preload="preload" />
  <!-- inicio de sesión -->
  <login-component
    :estate="autentification.estateLogin"
    @close="autentification.estateLogin = $event"
    @changeView="changeView"
  />
  <!-- registro -->
  <register-component
    :estate="autentification.estateRegister"
    @close="autentification.estateRegister = $event"
  />
  <div
    class="fixed inset-x-0 bottom-0 xl:relative bg-white border shadow-lg rounded-2xl pb-2 xl:pb-8 px-4 py-6 xl:py-8 xl:px-10 xl:mb-8"
    style="z-index: 1"
  >
    <h3 class="text-text-blue font-semibold xl:text-lg mb-5 hidden xl:block">
      Detalles de pago
      <span
        @click.prevent="openAll"
        class="font-normal text-sm text-main-red cursor-pointer"
      >
        {{ arrowGeneral ? "(Ver menos)" : "(Ver más)" }}
      </span>
    </h3>
    <!-- Detalles -->
    <div class="py-4 px-5 rounded-2xl border mb-4 xl:mb-8">
      <div :class="dataPayment === 2 && terminosAndConditions ? 'pb-4' : ''">
        <span :class="arrowGeneral ? '' : 'hidden'" class="xl:block">
          <!-- Cif -->
          <p
            @click.prevent="openStatusSelect('cif')"
            class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
          >
            <span
              >{{
                typeService === 2 || typeService === 0
                  ? "Producto"
                  : "Gastos en USA"
              }}
            </span>
            <span class="flex items-center gap-3">
              <span v-if="currencySymbol === 'USD'">
                $
                {{
                  typeService === 2 || typeService === 0
                    ? dataCifImpuesto.dolares.cifGeneral.cif
                    : (
                        parseFloat(
                          dataCifImpuesto.dolares.cifGeneral.warehouse
                        ) + parseFloat(dataCifImpuesto.dolares.cifGeneral.flete)
                      ).toFixed(2)
                }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/.
                {{
                  typeService === 2 || typeService === 0
                    ? dataCifImpuesto.soles.cifGeneral.cif
                    : (
                        parseFloat(dataCifImpuesto.soles.cifGeneral.warehouse) +
                        parseFloat(dataCifImpuesto.soles.cifGeneral.flete)
                      ).toFixed(2)
                }}</span
              >

              <img
                :class="style.statusArrowCif ? 'transform rotate-180' : ''"
                class="cursor-pointer"
                src="../../../assets/icons/drop-down-black.svg"
                alt="Icono de Envios USA"
              />
            </span>
          </p>
          <hr class="my-4" />
          <div
            :class="style.statusArrowCif ? 'grid' : 'hidden'"
            class="text-sm text-gray-400 gap-3"
          >
            <span
              v-if="typeService === 2 || typeService === 0"
              class="grid gap-3"
            >
              <p class="flex items-center justify-between">
                <span class="flex gap-2">
                  <img
                    src="../../../assets/icons/flecha-enter.svg"
                    class="w-2"
                    alt="Icono de Envios USA"
                  />Precio del producto
                </span>

                <span v-if="currencySymbol === 'USD'">
                  $ {{ dataCifImpuesto.dolares.cifGeneral.precio }}</span
                >
                <span v-if="currencySymbol === 'PEN'">
                  S/. {{ dataCifImpuesto.soles.cifGeneral.precio }}</span
                >
              </p>
              <p class="flex items-center justify-between">
                <span class="flex gap-2 tooltip">
                  <img
                    src="../../../assets/icons/flecha-enter.svg"
                    class="w-2"
                    alt="Icono de Envios USA"
                  />
                  <span class="flex gap-3">
                    Shipping
                    <img
                      src="../../../assets/icons/question.svg"
                      alt="Icono de Envios USA"
                    />
                  </span>

                  <span class="tooltip-box">
                    Cobrado por la tienda o proveedor por enviar su compra al
                    warehouse
                  </span>
                </span>
                <span v-if="currencySymbol === 'USD'">
                  $ {{ dataCifImpuesto.dolares.cifGeneral.shipping }}</span
                >
                <span v-if="currencySymbol === 'PEN'">
                  S/. {{ dataCifImpuesto.soles.cifGeneral.shipping }}</span
                >
              </p>
              <p class="flex items-center justify-between">
                <span class="flex gap-2 tooltip">
                  <img
                    src="../../../assets/icons/flecha-enter.svg"
                    class="w-2"
                    alt="Icono de Envios USA"
                  /><span class="flex gap-3">
                    Tax
                    <img
                      src="../../../assets/icons/question.svg"
                      alt="Icono de Envios USA"
                  /></span>
                  <span class="tooltip-box">
                    Impuestos de ley que el gobierno de USA cobra a todas las
                    personas
                  </span></span
                >

                <span v-if="currencySymbol === 'USD'">
                  $ {{ dataCifImpuesto.dolares.cifGeneral.tax }}</span
                >
                <span v-if="currencySymbol === 'PEN'">
                  S/. {{ dataCifImpuesto.soles.cifGeneral.tax }}</span
                >
              </p>
            </span>
            <p class="flex items-center justify-between">
              <span class="flex gap-2 tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />

                <span class="flex gap-3">
                  Warehouse
                  <img
                    src="../../../assets/icons/question.svg"
                    alt="Icono de Envios USA"
                  />
                </span>
                <span class="tooltip-box">
                  Cobrado por la logistica de almacenaje en USA
                </span>
              </span>

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataCifImpuesto.dolares.cifGeneral.warehouse }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataCifImpuesto.soles.cifGeneral.warehouse }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-2 tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />

                <span class="flex gap-3">
                  Millas de vuelo
                  <img
                    src="../../../assets/icons/question.svg"
                    alt="Icono de Envios USA"
                  />
                </span>
                <span class="tooltip-box">
                  Tasa que se paga al viajero como recompensa por el transporte
                  aereo de su compra
                </span></span
              >

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataCifImpuesto.dolares.cifGeneral.flete }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataCifImpuesto.soles.cifGeneral.flete }}</span
              >
            </p>
            <hr class="my-3" />
          </div>
          <!-- impuestos -->
          <p
            @click.prevent="openStatusSelect('impuestos')"
            class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
          >
            <span>Recompensa viajero</span>
            <span
              class="flex items-center gap-3"
              :class="
                dataCifImpuesto.dolares.impuestoGeneral.estadoImpuesto === 0
                  ? 'text-main-green font-bold'
                  : ''
              "
            >
              <span v-if="currencySymbol === 'USD'">
                {{
                  dataCifImpuesto.dolares.impuestoGeneral.estadoImpuesto !== 0
                    ? "$ " + dataCifImpuesto.dolares.impuestoGeneral.impuesto
                    : "Gratis"
                }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                {{
                  dataCifImpuesto.dolares.impuestoGeneral.estadoImpuesto !== 0
                    ? " S/. " + dataCifImpuesto.soles.impuestoGeneral.impuesto
                    : "Gratis"
                }}</span
              >
              <!-- :class="style.statusArrowImpuesto ? 'transform rotate-180' : ''" -->
              <img
                class="cursor-pointer opacity-0"
                src="../../../assets/icons/drop-down-black.svg"
                alt="Icono de Envios USA"
              />
            </span>
          </p>
          <hr class="my-4" />
          <!-- SIN USAR -->
          <!-- :class="style.statusArrowImpuesto ? 'grid ' : 'hidden'"  -->
          <div class="hidden text-sm text-gray-400 gap-3">
            <p class="flex items-center justify-between">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />Ad/Valorem</span
              >

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataCifImpuesto.dolares.impuestoGeneral.valorem }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataCifImpuesto.soles.impuestoGeneral.valorem }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  alt="Icono de Envios USA"
                  class="w-2"
                />IGV</span
              >

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataCifImpuesto.dolares.impuestoGeneral.igv }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataCifImpuesto.soles.impuestoGeneral.igv }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />IPM</span
              >

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataCifImpuesto.dolares.impuestoGeneral.ipm }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataCifImpuesto.soles.impuestoGeneral.ipm }}</span
              >
            </p>
            <hr class="my-3" />
          </div>
          <!-- tarifa -->
          <p
            @click.prevent="openStatusSelect('tarifa')"
            class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
          >
            <span>Tarifa de servicio</span>
            <span class="flex items-center gap-3">
              <span v-if="currencySymbol === 'USD'">
                $ {{ dataTarifa.dolares.tarifaServicio }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/. {{ dataTarifa.soles.tarifaServicio }}</span
              >

              <img
                class="cursor-pointer"
                :class="style.statusArrowTarifa ? 'transform rotate-180' : ''"
                src="../../../assets/icons/drop-down-black.svg"
                alt="Icono de Envios USA"
              />
            </span>
          </p>
          <hr class="my-4" />
          <div
            :class="style.statusArrowTarifa ? 'grid' : 'hidden'"
            class="text-sm text-gray-400 gap-3"
          >
            <p class="flex items-center justify-between">
              <span class="flex gap-2 tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />

                <span class="flex gap-3">
                  Margen de servicio
                  <img
                    src="../../../assets/icons/question.svg"
                    alt="Icono de Envios USA"
                  />
                </span>
                <span class="tooltip-box"
                  >Cobrado por los procedimientos aduaneros de importación</span
                >
              </span>

              <span v-if="currencySymbol === 'USD'">
                $ {{ dataTarifa.dolares.tramiteAduanero }}</span
              >
              <span v-if="currencySymbol === 'PEN'">
                S/ {{ dataTarifa.soles.tramiteAduanero }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-2 tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />

                <span class="flex gap-3">
                  Envío nacional
                  <img
                    src="../../../assets/icons/question.svg"
                    alt="Icono de Envios USA"
                  />
                </span>
                <span class="tooltip-box"
                  >Cobrado por la distribución nacional de la importación hasta
                  la dirección final del cliente</span
                ></span
              >

              <span
                v-if="currencySymbol === 'USD'"
                :class="
                  dataTarifa.dolares.envioNacional === '0.00'
                    ? 'text-main-green font-bold'
                    : ''
                "
              >
                {{
                  dataTarifa.dolares.envioNacional === "0.00"
                    ? "Gratis"
                    : "$ " + dataTarifa.dolares.envioNacional
                }}</span
              >
              <span
                v-if="currencySymbol === 'PEN'"
                :class="
                  dataTarifa.soles.envioNacional === '0.00'
                    ? 'text-main-green font-bold'
                    : ''
                "
              >
                {{
                  dataTarifa.soles.envioNacional === "0.00"
                    ? "Gratis"
                    : "S/. " + dataTarifa.soles.envioNacional
                }}</span
              >
            </p>
            <hr class="my-3" />
          </div>

          <!-- Servicios Adicionales -->
          <span v-if="servicioElegido.length !== 0">
            <p
              @click.prevent="openStatusSelect('service')"
              class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
            >
              <span>Servicios Adicionales</span>
              <span class="flex items-center gap-3">
                <span v-if="currencySymbol === 'PEN'">
                  S/ {{ Number(servicePrecioSoles).toFixed(2) }}</span
                >
                <span v-if="currencySymbol === 'USD'">
                  $ {{ Number(servicePrecioDolares).toFixed(2) }}</span
                >

                <img
                  :class="
                    style.statusArrowService ? 'transform rotate-180' : ''
                  "
                  class="cursor-pointer"
                  src="../../../assets/icons/drop-down-black.svg"
                  alt="Icono de Envios USA"
                />
              </span>
            </p>
            <hr class="my-4" />

            <div
              :class="style.statusArrowService ? 'grid' : 'hidden'"
              class="text-sm text-gray-400 gap-3"
            >
              <p
                class="flex items-center justify-between"
                v-for="data in servicioElegido"
                :key="data"
              >
                <span class="flex gap-2">
                  <img
                    src="../../../assets/icons/flecha-enter.svg"
                    class="w-2"
                    alt="Icono de Envios USA"
                  />{{ data.name }}
                </span>

                <span v-if="currencySymbol === 'PEN'" class="">
                  S/
                  {{
                    data.type === 1
                      ? data.newCostSoles.toFixed(2)
                      : data.local_amount.toFixed(2)
                  }}
                </span>
                <span v-if="currencySymbol === 'USD'" class="">
                  $
                  {{
                    data.type === 1
                      ? data.newCost.toFixed(2)
                      : data.cost.toFixed(2)
                  }}
                </span>
              </p>
              <hr class="my-3" />
            </div>
          </span>

          <span v-if="statusCupon">
            <p
              class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
            >
              <span>Cupón</span>
              <span class="flex items-center gap-3">
                - {{ currencySymbol === "USD" ? "$ " : "S/ " }}
                {{
                  currencySymbol === "USD"
                    ? Number(montoCupon).toFixed(2)
                    : Number(montoCuponSoles).toFixed(2)
                }}
                <!-- :class="style.statusArrowImpuesto ? 'transform rotate-180' : ''" -->
                <img
                  class="cursor-pointer opacity-0"
                  src="../../../assets/icons/drop-down-black.svg"
                  alt="Icono de Envios USA"
                />
              </span>
            </p>
            <hr class="my-4" />
          </span>

          <span v-if="getStadoBalanceCheck">
            <p
              class="text-xs xl:text-sm font-bold flex items-center justify-between cursor-pointer"
            >
              <span>Balance</span>
              <span class="flex items-center gap-3">
                - {{ currencySymbol === "USD" ? "$" : "S/" }}
                {{
                  currencySymbol === "USD"
                    ? Number(saldoUtilizado.dolares).toFixed(2)
                    : saldoUtilizado.soles
                }}
                <img
                  class="cursor-pointer opacity-0"
                  src="../../../assets/icons/drop-down-black.svg"
                  alt="Icono de Envios USA"
                />
              </span>
            </p>
            <hr class="my-4" />
          </span>
        </span>

        <p
          class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
        >
          <span>Pago total</span>
          <span>
            <span class="text-main-red" v-if="currencySymbol === 'USD'"
              >USD
            </span>
            <span class="text-main-red" v-if="currencySymbol === 'PEN'"
              >PEN
            </span>
            <span v-if="currencySymbol === 'USD'">
              {{ totalPaymentDolares }}
            </span>
            <span v-if="currencySymbol === 'PEN'">
              {{ totalPaymentSoles }}
            </span>
          </span>
        </p>
      </div>
      <!-- boton para pagar con paypal -->
      <span
        v-show="
          dataPayment === 2 &&
          terminosAndConditions &&
          process === 3 &&
          getStatusBalance === false
        "
        class="block mx-4 mt-4 xl:mx-0 xl:mt:0 overflow-auto h-24 xl:h-auto"
        style="max-height: 70vh"
      >
        <div class="w-full" ref="paypal"></div>
      </span>

      <!-- SIN USAR / FLECHA PARA ABRIR MENU EN DESKTOP -->
      <div class="hidden absolute inset-x-0 -top-4 items-center justify-center">
        <button
          @click.prevent="openAll"
          class="block bg-white w-9 h-9 rounded-full border shadow-md cursor-pointer"
          type="button"
        >
          <img
            :class="arrowGeneral ? 'transform rotate-180' : ''"
            src="../../../assets/icons/drop-down-black.svg"
            class="mx-auto"
            alt="Icono de Envios USA"
          />
        </button>
      </div>
    </div>
    <!-- Boton para pagar cont ransferencia bancaria -->
    <span v-if="process === 1 || process === 2">
      <button
        @click.prevent="next"
        :class="
          butonPayment
            ? 'bg-main-green text-white'
            : ' bg-gray-light text-gray-400 cursor-not-allowed'
        "
        class="w-10/12 flex items-center justify-center gap-3 mx-auto py-4 px-6 rounded-2xl text-center font-semibold"
      >
        <!-- <img v-if="process === 1 || process === 2" src="../../../assets//icons/loading-button.png" class="animate-spin "> -->
        <span v-if="process === 1 || process === 2">Continuar</span>
        <span v-else>Pagar</span>
      </button>
    </span>
    <!-- Terminos y condiciones MOBILE -->
    <div
      class="flex items-center justify-center gap-5 xl:hidden mb-4 text-sm"
      v-if="process === 3"
    >
      <span
        @click.prevent="agree"
        :class="
          terminosAndConditions
            ? 'bg-main-blue ring-main-blue'
            : ' ring-gray-400 bg-gray-400'
        "
        class="block w-3 h-3 ring ring-offset-2 cursor-pointer"
      ></span>
      <p class="text-left xl:text-center">
        Acepto los
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/articles/72000536514-t%C3%A9rminos-y-condiciones"
          target="Blank"
          class="font-semibold underline"
          >términos y condiciones</a
        >
      </p>
    </div>
    <!-- Boton pagar -->
    <span v-if="process === 3">
      <button
        v-if="
          dataPayment === 0 ||
          dataPayment === 3 ||
          dataPayment === 4 ||
          getStatusBalance ||
          (dataPayment === 2 && terminosAndConditions === false)
        "
        @click.prevent="next"
        :class="
          butonPayment
            ? 'bg-main-green text-white'
            : 'bg-gray-light bg-gray-400 cursor-not-allowed'
        "
        class="transition-colors w-10/12 flex items-center justify-center gap-3 mx-auto py-4 px-6 rounded-2xl text-center font-semibold"
      >
        <span v-if="process === 1 || process === 2">Continuar</span>
        <span v-else>Pagar</span>
      </button>
    </span>
    <!-- Terminos y condiciones DESKTOP -->
    <div
      :class="
        dataPayment === 0 ||
        dataPayment === 3 ||
        dataPayment === 4 ||
        (dataPayment === 2 && terminosAndConditions === false) ||
        getStatusBalance
          ? 'pt-8'
          : ''
      "
      class="hidden xl:flex items-center justify-center gap-5"
      v-if="process === 3"
    >
      <span
        @click.prevent="agree"
        :class="
          terminosAndConditions
            ? 'bg-main-blue ring-main-blue'
            : ' ring-gray-400 bg-gray-400'
        "
        class="block w-3 h-3 ring ring-offset-2 cursor-pointer"
      ></span>
      <p class="text-left xl:text-center">
        Acepto los
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/articles/72000536514-t%C3%A9rminos-y-condiciones"
          target="Blank"
          class="font-semibold underline"
          >términos y condiciones</a
        >
      </p>
    </div>

    <!-- Flecha -->
    <div class="absolute inset-x-0 -top-4 xl:hidden cursor-pointer">
      <figure
        @click.prevent="openAllMobile"
        class="mx-auto w-8 h-8 rounded-full bg-white border-2 shadow-lg flex items-center justify-center"
      >
        <img
          :class="arrowGeneral ? '' : 'transform rotate-180'"
          src="../../../assets/icons/drop-down-red.svg"
          alt="Icono de Envios USA"
        />
      </figure>
    </div>
  </div>
</template>
<script>
//Importando componentes
import preloadComponent from "../../preload/preloadComponent.vue";
import loginComponent from "../../autentification/loginComponent.vue";
import registerComponent from "../../autentification/registerComponent.vue";
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  components: { preloadComponent, loginComponent, registerComponent },
  props: [
    "process",
    "dataCifImpuesto",
    "dataTarifa",
    "typeDirection",
    "dataDirection",
    "dataPayment",
    "typeService",
    "stateAuth",
    "currencySymbol",
    "dataDirectionName",
    "existForm",
  ],
  data() {
    return {
      arrowGeneral: false,
      responsePaypal: {},
      terminosAndConditions: false,
      dataPurchase: {
        email: "",
        numberTracking: "",
      },
      autentification: {
        estateLogin: false,
        estateRegister: false,
      },
      style: {
        statusArrowAll: false,
        statusArrowCif: false,
        statusArrowImpuesto: false,
        statusArrowTarifa: false,
        statusArrowService: false,
      },
      error: {
        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
        direccion: false,
        documento: false,
        telefono: false,
      },
      error2: {
        name: false,
        category: false,
        description: false,
        peso: false,
        precio: false,
      },
      idUser: 0,
      emailUser: "",
      preload: false,
      paypal: {
        order_id: 0,
      },
      saldoUtilizado: {
        soles: "",
        dolares: "",
      },
    };
  },
  mounted: function () {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AZbmOOR1Oj4uLw6WoihETpwdswIkUf2qR0y_LAzTsKPpxbAQlLxBOcz8AIBOwWhKTquSlzL_Yl_QVLWd";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);

    // AZoYd202u5lD3JMG4FEIvuKRdkKGibXzE7ynRCfFMo0_MOVsitzShzg1Xi7m05i2wa7kP0wNf0UP7jas --> Production
    // AZbmOOR1Oj4uLw6WoihETpwdswIkUf2qR0y_LAzTsKPpxbAQlLxBOcz8AIBOwWhKTquSlzL_Yl_QVLWd --> Development
    //---//
  },

  created() {
    this.getIdUser();
  },
  methods: {
    //siguiente paso
    next() {
      //data contiene el nivel del formulario en la cual se encuentra el usuario
      const data = this.process;
      //Cuanto esta en el formulario del detalle del producto
      if (data === 1) {
        if (this.stateAuth === true) {
          const valproduct = this.valDataProduct();
          if (valproduct) {
            if (this.typeService !== 0) {
              this.$emit("next", 2);
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Seleccione el tipo de servicio";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
        } else {
          this.autentification.estateLogin = true;
        }
      }
      //Cuanto esta en el formulario de Dirección
      if (data === 2) {
        if (this.typeDirection === 1) {
          if (this.existForm === false) {
            this.$emit("next", 3);
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Actualizar o cerrar el formulario de direccón";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        }
        if (this.typeDirection === 2) {
          const resultData = this.valData();
          const resulDirection = this.valDirection();
          const resulDataEspecial = this.valDataEspecial();
          if (resultData && resulDirection && resulDataEspecial) {
            this.$emit("next", 3);
          }
        }
        if (this.typeDirection === 3) {
          this.$emit("next", 3);
        }
        if (this.typeDirection === 0) {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Seleccione una dirección";
          this.$store.state.alert.estado = true;
          //--------------------------//
        }
      }
      //Cuanto esta en el formulario de metodo de pago

      if (data === 3) {
        if (this.getStatusBalance === false) {
          if (this.dataPayment === 3) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
          if (this.dataPayment === 4) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
          if (this.dataPayment === 2) {
            if (this.terminosAndConditions === false) {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
          if (this.dataPayment === 0) {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Selecciono un método de pago";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        } else {
          if (this.getStatusBalance) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
        }
      }
    },
    // preparación del objeto para crear la orden
    preparationObjectPurchase() {
      var objetoOrden = new Object();
      //Datos del producto
      objetoOrden.product_info = this.dataCifImpuesto.product.name;

      objetoOrden.mp_category = this.dataCifImpuesto.product.keyCateogry;

      objetoOrden.product_specimens = this.dataCifImpuesto.product.cantidad;
      objetoOrden.price = this.dataCifImpuesto.product.precio;
      objetoOrden.weight = this.dataCifImpuesto.product.peso;
      objetoOrden.weight_unit_key = this.dataCifImpuesto.product.unidad;
      //Tiempo de tienda - envios usa o sendi
      objetoOrden.store = "enviosusa";
      objetoOrden.description = this.dataCifImpuesto.product.description;

      //variaciones seleccionadas
      if (this.dataCifImpuesto.product.selectVariaton !== null) {
        objetoOrden.variations = this.dataCifImpuesto.product.selectVariaton;
      }

      // Tipo de moneda
      objetoOrden.currency = this.currencySymbol;
      //-------------------------//
      this.typeService === 1
        ? (objetoOrden.direct_type = 1)
        : (objetoOrden.direct_type = 2);

      //-------------------------//

      objetoOrden.product_image_url = this.dataCifImpuesto.product.img;

      //-------------------------//
      objetoOrden.reference_link = this.dataCifImpuesto.product.linkReference;

      if (this.$store.state.saldoTotalStatu === false) {
        //Metodo de Pago - 5 si es transferencia
        if (this.dataPayment === 3) {
          objetoOrden.payment_method_flag = 5;
        }
        if (this.dataPayment === 2) {
          objetoOrden.payment_method_flag = 2;
        }
        if (this.dataPayment === 2) {
          objetoOrden.paypal_order_id = this.paypal.order_id;
        }
        //Para ATM enviar 4
        if (this.dataPayment === 4) {
          objetoOrden.payment_method_flag = 4;
          objetoOrden.mp_payer_email = this.emailUser;
          objetoOrden.mp_payment_method_id = "pagoefectivo_atm";
        }
      } else {
        objetoOrden.payment_method_flag = 6;
      }

      //parametro del cupon
      if (this.$store.state.cuponStatu === true) {
        objetoOrden.coupon_code = this.$store.state.cuponCode;
      }

      if (this.$store.state.saldoStatu) {
        objetoOrden.balance_flag = true;
      } else {
        objetoOrden.balance_flag = false;
      }

      //Si el usuario agrego oytro tipo de servicio
      if (this.servicioElegido.length !== 0) {
        var idService = "";
        this.servicioElegido.forEach((value, index) => {
          if (index === 0) {
            idService = idService.concat(value.id);
          } else {
            idService = idService.concat("," + value.id);
          }
        });

        objetoOrden.extra_services_id = idService;
      }

      // si recoge en oficina es true por lo contrario false
      this.typeDirection === 3
        ? (objetoOrden.at_office_flag = true)
        : (objetoOrden.at_office_flag = false);
      //aqui se manda el vaucher
      // si la dirección es una existente
      if (this.typeDirection === 1) {
        objetoOrden.shipping_address_id = this.dataDirection;
        const data = {};
        data.nombre = this.dataDirectionName.direccion;
        data.lat = this.dataDirectionName.lat;
        data.lng = this.dataDirectionName.lng;
        data.departamento = this.dataDirectionName.departamento;
        data.provincia = this.dataDirectionName.provincia;
        data.distrito = this.dataDirectionName.distrito;
        const dataCompress = JSON.stringify(data);
        window.localStorage.setItem("direction", dataCompress);
      }
      // si es una nueva dirección
      if (this.typeDirection === 2) {
        objetoOrden.shipping_address_name = this.dataDirection.direccion;
        objetoOrden.shipping_address_telephone = this.dataDirection.telefono;
        objetoOrden.shipping_address_reference = this.dataDirection.referencia;
        objetoOrden.shipping_address_inner = this.dataDirection.piso;
        objetoOrden.shipping_address_contact_name = this.dataDirection.nombre;
        objetoOrden.shipping_address_contact_lastname =
          this.dataDirection.apellido;
        objetoOrden.shipping_address_document_number =
          this.dataDirection.documento;
        objetoOrden.shipping_address_document_type = 1;
        objetoOrden.shipping_address_district = this.dataDirection.distrito;
        objetoOrden.shipping_address_city = this.dataDirection.provincia;
        objetoOrden.shipping_address_department =
          this.dataDirection.departamento;
        objetoOrden.shipping_address_alias = "alias";
        objetoOrden.shipping_address_lat = this.dataDirection.lat;
        objetoOrden.shipping_address_lng = this.dataDirection.lng;
      }
      objetoOrden.quotation_id = this.dataCifImpuesto.product.idCotizacion;
      this.createOrder(objetoOrden);
    },
    //Servicio para crear la orden
    createOrder(objetoOrden) {
      document.body.classList.add("overflow-y-hidden");
      if (
        this.dataPayment === 3 ||
        this.dataPayment === 4 ||
        this.dataPayment === 2 ||
        this.getStatusBalance
      ) {
        this.preload = true;
      }
      axios
        .post(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.idUser +
            "/createDirectOrderByClient",
          objetoOrden
        )
        .then((response) => {
          document.body.classList.remove("overflow-y-hidden");
          if (response.status === 200) {
            this.preload = false;
            const responseData = response.data.body;
            this.$emit("next", 4);
            this.$emit("purchaseData", responseData);
            window.localStorage.removeItem("producto");
            window.localStorage.removeItem("open");
            //Resetenado el store del precio
            this.$store.state.cuponStatu = null;
            this.$store.state.cuponCode = "";
            this.$store.state.cuponMonto = null;
            this.$store.state.cuponMontoSoles = null;
            this.$store.state.saldoStatu = false;
            this.$store.state.saldoMonto = 0.0;
            this.$store.state.saldoMontoSoles = 0.0;
            this.$store.state.saldoTotalStatu = false;
            this.$store.commit("resetServicios");
          }
        })
        .catch((error) => {
          document.body.classList.remove("overflow-y-hidden");
          if (error) {
            this.preload = false;
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Lo sentimos ocurrió un error";
            this.$store.state.alert.estado = true;
            console.log("Error al crear orden: ", error);
            //--------------------------//
          }
        });
    },
    //Metodo de paypal
    setLoaded: function () {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.dataCifImpuesto.product.name.substring(
                    0,
                    125
                  ),
                  amount: {
                    currency_code: "USD",
                    value: this.totalPaymentDolares,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();

            this.paypal.order_id = order.id;

            this.preparationObjectPurchase();
          },
          onError: (err) => {
            if (err) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al realizar el pago con Paypal";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          },
        })
        .render(this.$refs.paypal);
    },
    //metodo para el  input de aceptar teminos y condiciones
    agree() {
      this.terminosAndConditions = !this.terminosAndConditions;
      this.$emit("terminos", this.terminosAndConditions);
    },
    //Obtenemos el ID del usuario
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data !== null) {
        this.idUser = dataUser.globalID;
        this.emailUser = dataUser.glovalEmail;
      }
    },
    //abrir el select segun el tipo
    openStatusSelect(id_select) {
      this.arrowGeneral = true;
      // this.arrowGeneral = !this.arrowGeneral;
      if (id_select === "cif") {
        this.style.statusArrowCif = !this.style.statusArrowCif;
      }
      if (id_select === "impuestos") {
        this.style.statusArrowImpuesto = !this.style.statusArrowImpuesto;
      }
      if (id_select === "tarifa") {
        this.style.statusArrowTarifa = !this.style.statusArrowTarifa;
      }
      if (id_select === "service") {
        this.style.statusArrowService = !this.style.statusArrowService;
      }
    },
    //Abrir todos los detalles
    openAll() {
      this.arrowGeneral = !this.arrowGeneral;
      if (
        this.style.statusArrowCif === true ||
        this.style.statusArrowImpuesto === true ||
        this.style.statusArrowTarifa === true ||
        this.style.statusArrowService === true
      ) {
        this.style.statusArrowCif = false;
        this.style.statusArrowImpuesto = false;
        this.style.statusArrowTarifa = false;
        this.style.statusArrowService = false;
      } else if (
        this.style.statusArrowCif === false ||
        this.style.statusArrowImpuesto === false ||
        this.style.statusArrowTarifa === false ||
        this.style.statusArrowService === false
      ) {
        this.style.statusArrowCif = true;
        this.style.statusArrowImpuesto = true;
        this.style.statusArrowTarifa = true;
        this.style.statusArrowService = true;
      }
    },
    //Abrir detalles en mobile
    openAllMobile() {
      this.arrowGeneral = !this.arrowGeneral;
      if (
        this.style.statusArrowCif === true ||
        this.style.statusArrowImpuesto === true ||
        this.style.statusArrowTarifa === true ||
        this.style.statusArrowService === true
      ) {
        this.style.statusArrowCif = false;
        this.style.statusArrowImpuesto = false;
        this.style.statusArrowTarifa = false;
        this.style.statusArrowService = false;
      }
    },
    //validateData "nombre"- "apellido" - "piso" -"referencia" - "departamento" - "provincia" - "distrito"
    valData() {
      this.$emit("errorNewDirection", this.error);
      const name = this.dataDirection.nombre;
      const apellido = this.dataDirection.apellido;
      const piso = this.dataDirection.piso;
      const referencia = this.dataDirection.referencia;
      const departamento = this.dataDirection.departamento;
      const provincia = this.dataDirection.provincia;
      const distrito = this.dataDirection.distrito;
      if (name.length !== 0) {
        this.error.name = false;
        if (apellido.length !== 0) {
          this.error.apellido = false;
          if (piso.length !== 0) {
            this.error.piso = false;
            if (referencia.length !== 0) {
              this.error.referencia = false;
              if (departamento.length !== 0) {
                this.error.departamento = false;
                if (provincia.length !== 0) {
                  this.error.provincia = false;
                  if (distrito.length !== 0) {
                    this.error.distrito = false;
                    return true;
                  } else {
                    this.error.distrito = true;
                    //----------component--------//
                    this.$store.state.alert.titulo = "Validación";
                    this.$store.state.alert.description = "Ingrese el distrito";
                    this.$store.state.alert.estado = true;
                    //--------------------------//

                    return false;
                  }
                } else {
                  this.error.provincia = true;
                  //----------component--------//
                  this.$store.state.alert.titulo = "Validación";
                  this.$store.state.alert.description = "Ingrese la provincia";
                  this.$store.state.alert.estado = true;
                  //--------------------------//
                  return false;
                }
              } else {
                this.error.departamento = true;
                //----------component--------//
                this.$store.state.alert.titulo = "Validación";
                this.$store.state.alert.description = "Ingrese el departamento";
                this.$store.state.alert.estado = true;
                //--------------------------//
                return false;
              }
            } else {
              this.error.referencia = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description = "Ingrese una referencia";
              this.$store.state.alert.estado = true;
              //--------------------------//
              return false;
            }
          } else {
            this.error.piso = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese el piso u oficina";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.apellido = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese el apellido";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.name = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el nombre";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    // metodo para validar dirección
    valDirection() {
      const direction = this.dataDirection.direccion;
      const lat = this.dataDirection.lat;
      const lng = this.dataDirection.lng;
      if (direction.length !== 0) {
        this.error.direccion = false;
        if (lat !== 0) {
          this.error.direccion = false;
          if (lng !== 0) {
            this.error.direccion = false;
            return true;
          } else {
            this.error.direccion = true;

            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Ingrese una dirección  y selecciónela";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.direccion = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese una dirección  y selecciónela";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese una dirección  y selecciónela";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //validateData "documento " - "telefono" - "correo de contacto"
    valDataEspecial() {
      const documento = this.dataDirection.documento.toString();
      const telefono = this.dataDirection.telefono.toString();

      if (documento.length !== 0) {
        this.error.documento = false;
        if (documento.length >= 8) {
          this.error.documento = false;
          if (telefono.length !== 0) {
            this.error.telefono = false;
            if (telefono.length >= 9) {
              this.error.telefono = false;
              return true;
            } else {
              this.error.telefono = true;

              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "El teléfono debe ser mínimo 9 dígitos";
              this.$store.state.alert.estado = true;
              //--------------------------//
              return false;
            }
          } else {
            this.error.telefono = true;

            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese su teléfono";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.documento = true;

          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "El documento debe ser mínimo 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.documento = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el número de su documento";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //validate campos del producto
    valDataProduct() {
      this.$emit("errorProduct", this.error2);
      const dataName = this.dataCifImpuesto.product.name;
      // const dataDescription = this.dataCifImpuesto.product.description;
      const dataCategory = this.dataCifImpuesto.product.category;
      //validamos el precio que no sea solo 000000
      const dataPrecio = String(this.dataCifImpuesto.product.precio);

      const dataPeso = String(this.dataCifImpuesto.product.peso);
      const combination = this.dataCifImpuesto.product.combination;
      if (dataPeso.length !== 0 && this.dataCifImpuesto.product.peso !== 0) {
        this.error2.peso = false;
        if (
          dataPrecio.length !== 0 &&
          this.dataCifImpuesto.product.precio !== 0
        ) {
          this.error2.precio = false;
          if (dataName.length !== 0) {
            this.error2.name = false;
            if (dataCategory.length !== 0) {
              this.error2.category = false;
              if (combination) {
                return true;
              } else {
                //----------component--------//
                this.$store.state.alert.titulo = "Validación";
                this.$store.state.alert.description =
                  "Características no encontradas";
                this.$store.state.alert.estado = true;
                //--------------------------//

                return false;
              }
            } else {
              this.error2.category = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Seleccione la categoría del producto";
              this.$store.state.alert.estado = true;
              //--------------------------//

              return false;
            }
          } else {
            this.error2.name = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Ingrese el nombre del producto";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          this.error2.precio = true;

          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese el precio";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error2.peso = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el peso";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //Cambio de vista de login a registro
    changeView() {
      this.autentification.estateLogin = false;
      this.autentification.estateRegister = true;
    },

    //Validación de datos para el boton
    valDataProductButton() {
      const dataName = this.dataCifImpuesto.product.name;
      const dataCategory = this.dataCifImpuesto.product.category;
      const dataPrecio = String(this.dataCifImpuesto.product.precio);
      const dataPeso = String(this.dataCifImpuesto.product.peso);
      const combination = this.dataCifImpuesto.product.combination;
      if (dataPeso.length !== 0 && this.dataCifImpuesto.product.peso !== 0) {
        if (
          dataPrecio.length !== 0 &&
          this.dataCifImpuesto.product.precio !== 0
        ) {
          if (dataName.length !== 0) {
            if (dataCategory.length !== 0) {
              if (combination) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    valDataButton() {
      const name = this.dataDirection.nombre;
      const apellido = this.dataDirection.apellido;
      const piso = this.dataDirection.piso;
      const referencia = this.dataDirection.referencia;
      const departamento = this.dataDirection.departamento;
      const provincia = this.dataDirection.provincia;
      const distrito = this.dataDirection.distrito;
      if (name.length !== 0) {
        if (apellido.length !== 0) {
          if (piso.length !== 0) {
            if (referencia.length !== 0) {
              if (departamento.length !== 0) {
                if (provincia.length !== 0) {
                  if (distrito.length !== 0) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // metodo para validar dirección
    valDirectionButton() {
      const direction = this.dataDirection.direccion;
      const lat = this.dataDirection.lat;
      const lng = this.dataDirection.lng;
      if (direction.length !== 0) {
        if (lat !== 0) {
          if (lng !== 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    //validateData "documento " - "telefono" - "correo de contacto"
    valDataEspecialButton() {
      const documento = this.dataDirection.documento.toString();
      const telefono = this.dataDirection.telefono.toString();

      if (documento.length !== 0) {
        if (documento.length >= 8) {
          if (telefono.length !== 0) {
            if (telefono.length >= 9) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeStatusTotalTrue() {
      this.$store.state.saldoTotalStatu = true;
    },
    changeStatusTotalFalse() {
      this.$store.state.saldoTotalStatu = false;
    },
    obtenerValorSaldoUtilizadoDolar(data) {
      this.saldoUtilizado.dolares = data;
    },
    obtenerValorSaldoUtilizadoSoles(data) {
      this.saldoUtilizado.soles = data;
    },
  },
  computed: {
    servicePrecioSoles() {
      return this.$store.state.servicePrecioSoles;
    },
    servicePrecioDolares() {
      return this.$store.state.servicePrecioDolares;
    },
    servicioElegido() {
      return this.$store.state.servicioElegido;
    },
    montoCupon() {
      return this.$store.state.cuponMonto;
    },
    montoCuponSoles() {
      return this.$store.state.cuponMontoSoles;
    },
    statusCupon() {
      return this.$store.state.cuponStatu;
    },
    getStadoBalanceCheck() {
      return this.$store.state.saldoStatu;
    },
    getMontoDolares() {
      return this.$store.state.saldoMonto;
    },
    getMontoSoles() {
      return this.$store.state.saldoMontoSoles;
    },
    getStatusBalance() {
      return this.$store.state.saldoTotalStatu;
    },
    butonPayment() {
      const data = this.process;
      if (data === 1) {
        const valproduct = this.valDataProductButton();
        if (valproduct) {
          if (this.typeService !== 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (data === 2) {
        if (
          (this.typeDirection === 1 && this.existForm === false) ||
          this.typeDirection === 3
        ) {
          return true;
        } else if (this.typeDirection === 2) {
          const resultData = this.valDataButton();
          const resulDirection = this.valDirectionButton();
          const resulDataEspecial = this.valDataEspecialButton();
          if (resultData && resulDirection && resulDataEspecial) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (data === 3) {
        if (this.dataPayment === 3 && this.terminosAndConditions) {
          return true;
        } else if (this.dataPayment === 4 && this.terminosAndConditions) {
          return true;
        } else if (this.getStatusBalance && this.terminosAndConditions) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    totalPaymentDolares() {
      var total1 = 0.0;
      var reserva = 0.0;
      if (this.typeService === 2) {
        total1 = parseFloat(this.dataCifImpuesto.dolares.cifGeneral.cif);
      }
      if (this.typeService === 1) {
        total1 = parseFloat(this.dataCifImpuesto.dolares.cifGeneral.cif2);
      }

      var total2 = parseFloat(
        this.dataCifImpuesto.dolares.impuestoGeneral.impuesto
      );
      var total3 = parseFloat(this.dataTarifa.dolares.tarifaServicio);
      var total = total1 + total2 + total3;
      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioDolares;
      }
      total = Math.ceil(100 * total) / 100;
      if (this.statusCupon === true) {
        total = total - this.montoCupon;
      }
      reserva = total;
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMonto;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.obtenerValorSaldoUtilizadoDolar(reserva);
          this.changeStatusTotalTrue();
        } else {
          this.obtenerValorSaldoUtilizadoDolar(this.$store.state.saldoMonto);
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }

      return total.toFixed(2);
    },
    totalPaymentSoles() {
      var total1 = 0.0;
      var reserva = 0.0;
      if (this.typeService === 2) {
        total1 = parseFloat(this.dataCifImpuesto.soles.cifGeneral.cif);
      }
      if (this.typeService === 1) {
        total1 = parseFloat(this.dataCifImpuesto.soles.cifGeneral.cif2);
      }

      var total2 = parseFloat(
        this.dataCifImpuesto.soles.impuestoGeneral.impuesto
      );
      var total3 = parseFloat(this.dataTarifa.soles.tarifaServicio);
      var total = total1 + total2 + total3;
      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioSoles;
      }
      total = Math.ceil(100 * total) / 100;

      if (this.statusCupon === true) {
        total = total - this.montoCuponSoles;
      }
      reserva = total;
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMontoSoles;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.obtenerValorSaldoUtilizadoSoles(reserva);
          this.changeStatusTotalTrue();
        } else {
          this.obtenerValorSaldoUtilizadoSoles(
            this.$store.state.saldoMontoSoles
          );
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }

      return total.toFixed(2);
    },
  },
};
</script>
