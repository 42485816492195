<template>
  <!-- SECTION TESTIMONIOS -->
  <section class="u-container">
    <article class="">
      <h2
        class="font-medium text-xl xl:text-4xl mb-6 text-center text-bg-black"
      >
        Con la confianza de
        <span class="font-bold xl:mt-2 xl:block"
          >miles de clientes felices</span
        >
      </h2>
      <p
        class="xl:w-1/2 mx-auto xl:leading-8 text-center mb-16 text-sm xl:text-base"
      >
        Estas son las historias de nuestros clientes y
        <br class="hidden xl:block" />
        las razones por que deciden confiar en nosotros.
      </p>
      <!-- SLIDER SPLIDE-->
      <Splide :options="options" :extensions="extensions">
        <SplideSlide
          class="mx-6 border-2 bg-white hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-1.svg" />
            <div>
              <span class="block text-lg font-medium">Hugo Vaquez</span>
              <span class="block text-sm text-gray-400 -mt-1"
                >Lambayeque, Perú</span
              >
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Súper recomendado, envié un paquete desde NYC a Lima.
          </p>
        </SplideSlide>
        <SplideSlide
          class="mx-6 border-2 bg-white hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-2.svg" />
            <div>
              <span class="block text-lg font-medium">Yessica Castillo</span>
              <span class="block text-sm text-gray-400 -mt-1"
                >Huancavelica, Perú</span
              >
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Excelente servicio 100% garantizado. muchas gracias.
          </p>
        </SplideSlide>
        <SplideSlide
          class="mx-6 border-2 bg-white hover:bg-white hover:border-main-red hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-3.svg" />
            <div>
              <span class="block text-lg font-medium">Alex Wong</span>
              <span class="block text-sm text-gray-400 -mt-1">Lima, Perú</span>
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Feliz por la atención, llego a mi casa antes del tiempo y estoy
            feliz por eso.
          </p>
        </SplideSlide>
      </Splide>
    </article>
  </section>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export default {
  data() {
    return {
      options: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 3,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
        autoScroll: {
          speed: 1,
        },
      },
      extensions: {
        AutoScroll,
      },
    };
  },
};
</script>
