<template>
  <!-- FOOTER -->
  <footer class="bg-bg-black text-white">
    <div
      class="u-container grid grid-cols-2 gap-x-4 gap-y-12 xl:gap-0 xl:grid-cols-4 items-start"
    >
      <div class="col-span-2 xl:col-span-1 order-2 xl:order-1">
        <img
          class="hidden xl:block mb-10"
          src="../../assets/LOGO-white-2.svg"
        />
        <h2 class="text-xl font-bold xl:hidden mb-8">EnvíosUsaPerú</h2>
        <ul class="mb-6 xl:mb-12">
          <li class="flex gap-4 items-center font-medium mb-6">
            <img src="../../assets/icons/ubicacion--white.svg" />
            <span>Jr. Emilio Althaus Nro. 121 Int. 403</span>
          </li>
          <li class="flex gap-4 items-center font-medium mb-6">
            <img src="../../assets/icons/email--white.svg" />
            <a href="mailto:hola@enviosusaperu.com">hola@enviosusaperu.com</a>
          </li>
          <li class="flex gap-4 items-center font-medium">
            <img src="../../assets/icons/telefono--white.svg" />
            <span>(+51) 987 294 960</span>
          </li>
        </ul>
        <div class="hidden xl:flex gap-6 mb-6">
          <a href="https://www.instagram.com/envios.usa.peru/" target="_blank">
            <img src="../../assets/icons/instagram-circle.svg" />
          </a>
          <a href="https://www.facebook.com/enviosde.usa.peru" target="_blank">
            <img src="../../assets/icons/facebook-circle.svg" />
          </a>
        </div>
        <small class="block text-gray-400 mb-10 xl:mb-0"
          >©2021EnvíosUsaPerú</small
        >
        <div class="flex gap-6 items-center justify-start xl:hidden">
          <a href="https://www.instagram.com/envios.usa.peru/" target="_blank">
            <img src="../../assets/icons/instagram--white.svg" />
          </a>
          <a href="https://www.facebook.com/enviosde.usa.peru" target="_blank">
            <img src="../../assets/icons/facebook--white.svg" />
          </a>
        </div>
      </div>
      <div class="grid xl:order-2 text-sm xl:text-base">
        <a href="#" class="text-base xl:text-lg font-semibold mb-8">EMPRESA</a>
        <a href="#" class="mb-8">Acerca de Nosotros</a>
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/articles/72000536514-t%C3%A9rminos-y-condiciones"
          target="_blank"
          class="mb-8"
          >Aviso Legal</a
        >
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/articles/72000536513-pol%C3%ADtica-de-privacidad"
          target="_blank"
          class="mb-8"
          >Privacidad</a
        >
        <a href="#" class="mb-8">Seguridad</a>
        <a href="#" class="mb-8">Desarrolladores</a>
        <a href="#" class="text-main-blue">+ Ver mas</a>
      </div>
      <div class="grid xl:order-3 text-sm xl:text-base">
        <a href="#" class="text-base xl:text-lg font-semibold mb-8">CLIENTES</a>
        <a href="#" class="mb-8">Servicio</a>
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/folders/72000350320"
          target="_blank"
          class="mb-8"
          >Preguntas Frecuentes</a
        >
        <a
          href="https://enviosusaperu.freshdesk.com/support/home"
          target="_blank"
          class="mb-8"
          >Base de Conocimiento</a
        >
        <a
          href="https://enviosusaperu.freshdesk.com/support/solutions/articles/72000536515-mercader%C3%ADa-prohibida"
          target="_blank"
          class="mb-8"
          >Productos prohibidos</a
        >
        <router-link
          :to="{ name: 'Tiendas' }"
          target="_blank"
          class="xl:block mb-8"
        >
          Guía de Tiendas</router-link
        >
        <a href="#" class="xl:block mb-8">Libro de Reclamaciones</a>
        <a href="#" class="xl:block mb-8">Atención al cliente</a>
      </div>
      <div class="hidden xl:block xl:order-4">
        <div class="flex gap-8 items-center mb-2">
          <img class="w-12" src="../../assets/icons/icon-visa.svg" alt="VISA" />
          <img
            class="w-12"
            src="../../assets/icons/icon-master-card.svg"
            alt="Master card"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-american-express.svg"
            alt="American express"
          />
        </div>
        <div class="flex gap-8 items-center mb-6">
          <img
            class="w-12"
            src="../../assets/icons/icon-pago-efectivo.svg"
            alt="Pago efectivo"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-dinners-club.svg"
            alt="Dinners club"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-paypal.svg"
            alt="Paypal"
          />
        </div>
        <small class="text-sm mb-10 block">
          + Más tarjetas locales y tarjetas de pago
        </small>
        <div class="flex gap-4">
          <router-link :to="{ name: 'Complaints' }">
            <img
              src="../../assets/icons/libro_reclamaciones_white.png"
              class="w-36"
            />
          </router-link>
          <a
            href="https://aws.amazon.com/what-is-cloud-computing"
            target="_blank"
          >
            <img
              class="w-36"
              src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
              alt="Powered by AWS Cloud Computing"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      seeMore: false,
    };
  },
  methods: {
    moreDetail() {
      this.seeMore = !this.seeMore;
    },
  },
  computed: {
    yearActuality() {
      var currentTime = new Date();
      var year = currentTime.getFullYear();
      return year;
    },
  },
};
</script>
