<template>
  <!-- cabecera -->
  <header-component
    @openLogin="autentification.estateLogin = $event"
    @openRegister="autentification.estateRegister = $event"
    @openTracking="autentification.estateTracking = $event"
    @openAlert="seeAlert = $event"
  />
  <!-- HERO -->
  <hero-component />
  <!-- Slider mejores tiendas -->
  <mejores-tiendas />
  <!-- Pasos de uso -->
  <steps-component />
  <!-- Por que comprar en EUP -->
  <comprar-en-eup />
  <!-- Ellos ya confian en nosotros -->
  <marcas />
  <!-- descripciones -->
  <description-component
    :getAuth="getAuth"
    @openLogin="autentification.estateLogin = $event"
  />
  <!-- testimonios -->
  <testimony-component />

  <!-- registro -->
  <register-view-component
    @openRegister="autentification.estateRegister = $event"
    v-if="!getAuth"
  />
  <!-- footer -->
  <footer-component />
  <!-- inicio de sesión -->
  <login-component
    :estate="autentification.estateLogin"
    @close="autentification.estateLogin = $event"
    @changeView="changeView"
    @resetPassword="resetPassword = $event"
  />
  <!-- registro -->
  <register-component
    :estate="autentification.estateRegister"
    @close="autentification.estateRegister = $event"
  />
  <!-- Modal para pedir cambiar contraseña -->
  <reset-password-component
    @closeResetPassword="resetPassword = $event"
    :resetPassword="resetPassword"
  />
  <!-- tracking -->
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
</template>

<script>
//Componentes
import headerComponent from "../components/header/headerComponent.vue";
import heroComponent from "../components/home/heroComponent.vue";
import stepsComponent from "../components/home/stepsComponent.vue";
import descriptionComponent from "../components/home/descriptionComponent.vue";

import mejoresTiendas from "../components/home/mejoresTiendas.vue";
import comprarEnEup from "../components/home/comprarEnEup.vue";
import marcas from "../components/home/marcas.vue";

import testimonyComponent from "../components/home/testimonyComponent.vue";
import registerViewComponent from "../components/home/registerViewComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import loginComponent from "../components/autentification/loginComponent.vue";
import registerComponent from "../components/autentification/registerComponent.vue";
import resetPasswordComponent from "../components/autentification/resetPasswordComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";

export default {
  components: {
    headerComponent,
    heroComponent,
    stepsComponent,
    descriptionComponent,
    testimonyComponent,
    registerViewComponent,
    footerComponent,
    loginComponent,
    registerComponent,
    trackingComponent,
    resetPasswordComponent,
    mejoresTiendas,
    comprarEnEup,
    marcas,
  },
  data() {
    return {
      seeAlert: false,
      resetPassword: false,
      autentification: {
        estateLogin: false,
        estateRegister: false,
        estateTracking: false,
      },
    };
  },

  mounted() {
    window.localStorage.removeItem("producto");
  },

  methods: {
    //Cambio de vista de login a registro
    changeView() {
      this.autentification.estateLogin = false;
      this.autentification.estateRegister = true;
    },
  },
  computed: {
    //Obtiene si existe el localstorage
    getAuth() {
      const data = localStorage.getItem("auth");
      if (data != null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
