<template>
  <!-- Agradecimiento-->
  <article
    class="relative bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <button
      @click.prevent="imprimir"
      class="tooltip fixed xl:absolute right-4 top-20 xl:right-16 xl:top-12 block ml-auto bg-bg-black text-white rounded-2xl p-3 xl:py-4 xl:px-6 font-bold flex items-center gap-4"
    >
      <img
        src="../../../assets/icons/icon-impresora.svg"
        class="w-6 xl:w-8"
        alt="Icono de Envios USA"
      />
      <span
        class="tooltip-box bottom-16 xl:bottom-20 text-xs xl:text-sm -left-6 xl:left-0"
        >Imprimir</span
      >
    </button>
    <div class="xl:p-8 text-center">
      <h4 class="text-sm xl:text-base font-semibold mb-10">
        Solicitud recibida
      </h4>
      <img
        src="../../../assets/images/caja-paso-4.svg"
        class="mx-auto mb-8"
        alt="Icono de Envios USA"
      />
      <p class="mb-4 text-xs xl:text-sm">
        Tu número de tracking ha sido enviado a:

        <a href="#" class="text-main-red block font-bold">{{
          purchaseData.attributes.client.attributes.profile.attributes.userable
            .attributes.email
        }}</a>
      </p>
      <div
        class="font-medium text-sm xl:text-base text-black text-center flex items-center justify-center gap-3 border-b border-l border-r shadow-xl rounded-xl px-6 pb-6"
      >
        <p id="tracking-code">
          {{ purchaseData.attributes.system_tracking_number }}
        </p>
        <button
          type="button"
          @click.prevent="copyCode"
          @keydown="copyCode"
          class="cursor-pointer"
          alt="Icono de Envios USA"
        >
          <img src="../../../assets/icons/icon-copiar-3.png" alt="" />
        </button>
      </div>
    </div>
  </article>
  <!-- Invoice de compra -->
  <article
    v-if="data.typeService === 1"
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3
      v-if="data.typeService === 1 && data.vaucher === false"
      class="text-text-blue font-semibold xl:text-lg mb-6 text-center"
    >
      No olvides agregar tu factura comercial
    </h3>
    <label
      v-if="data.typeService === 1 && data.vaucher === false"
      class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-main-orange py-5 text-sm xl:text-base flex items-center justify-center gap-4"
    >
      <input
        type="file"
        class="hidden"
        @change="factura"
        ref="fileupload"
        accept="image/*,.pdf"
      />
      <img src="../../../assets/icons/subir-archivo.png" />
      Agregar factura comercial
    </label>

    <span v-if="data.typeService === 1 && data.vaucher === true">
      <div
        class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-main-green text-main-green ring ring-main-green bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
      >
        Factura comercial cargado
        <img src="../../../assets/icons/check-green.png" class="w-6" />
      </div>

      <div class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base">
        <span
          v-for="(invoice, index) in invoices"
          :key="invoice"
          class="cursor-pointer"
        >
          <a
            :href="invoice.url"
            target="_Blank"
            class="flex items-center gap-4"
          >
            <img src="../../../assets/icons/file.svg" alt="" />
            Invoice-{{ index + 1 }}
            <span class="tooltip ml-auto">
              <img
                v-if="invoice.status === 1"
                src="../../../assets/icons/check-green.png"
                class=""
              />
              <img
                v-if="invoice.status === 0"
                src="../../../assets/icons/error-orange.png"
                class=""
              />
              <span class="tooltip-box">{{
                invoice.status === 1
                  ? "Factura aprobada"
                  : "Factura en revisión"
              }}</span>
            </span>
          </a>
          <hr class="my-3" />
        </span>

        <label
          class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
        >
          <input
            type="file"
            class="hidden"
            @change="factura"
            ref="fileupload"
            accept="image/*,.pdf"
          />
          <img src="../../../assets/icons/plus-blue.svg" />
          Agregar otra factura comercial
        </label>
      </div>
    </span>
  </article>
  <!-- Servicio adicional review -->
  <article
    v-if="servicioReview"
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3 class="text-main-blue -mb-1 font-medium">REVIEW</h3>
    <h3 class="font-bold xl:text-xl mb-6">Servicio adicional</h3>

    <article
      class="border shadow-sm rounded-md p-4 grid gap-6 bg-blue-50 bg-opacity-70"
    >
      <span v-if="review.statusPregunta">
        <div class="flex gap-4 items-center">
          <img src="../../../assets/images/user-default.svg" alt="" />
          <p
            class="mr-8 px-4 py-3 bg-white rounded-lg font-medium border shadow-sm"
          >
            {{ review.pregunta }}
            <span class="block mt-3 opacity-80 text-xs">
              {{ convertDate(review.pregunta_created_at) }}
            </span>
          </p>
        </div>
      </span>

      <div class="flex mt-6" v-if="review.statusPregunta === false">
        <template v-if="statusPayed">
          <textarea
            class="border rounded-tl-md rounded-bl-md p-4 w-full focus:z-10 focus:outline-none focus:ring focus:ring-blue-500 transition-colors"
            rows="1"
            style="resize: none"
            placeholder="Describe que debemos revisar..."
            v-model="review.pregunta"
          ></textarea>
          <button
            class="p-4 px-6 rounded-tr-md rounded-br-md bg-gray-600 text-white font-semibold cursor-not-allowed"
            v-if="review.pregunta.length === 0"
          >
            Enviar
          </button>
          <button
            @click.prevent="crearPreguntaReviewService"
            class="p-4 px-6 rounded-tr-md rounded-br-md bg-main-green text-white font-semibold"
            v-else
          >
            Enviar
          </button>
        </template>
        <template v-else>
          <textarea
            class="border rounded-tl-md rounded-bl-md p-4 w-full focus:z-10 focus:outline-none focus:ring focus:ring-blue-500 transition-colors"
            rows="1"
            style="resize: none"
            placeholder="Describe que debemos revisar..."
            readonly
          ></textarea>
          <button
            type="button"
            class="cursor-not-allowed p-4 px-6 rounded-tr-md rounded-br-md bg-gray-600 text-white font-semibold cursor-not-allowed"
          >
            Enviar
          </button>
        </template>
      </div>
    </article>
  </article>
  <!-- Productos relacionados -->
  <article
    v-if="this.purchaseData.attributes.code_amazon"
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
      Te podría interesar
    </h3>
    <span v-if="productsRecommend">
      <div class="grid grid-cols-3 gap-8">
        <a
          @click.prevent="reloadViewProductRecomend(data.url)"
          v-for="data in productsRecommend"
          :key="data"
          class="cursor-pointer"
        >
          <img :src="data.image" class="w-full h-40 object-contain mb-3" />
          <img src="../../../assets/images/amazon.svg" class="w-16" />
          <p class="text-xs xl:text-sm font-medium line-clamp-2 mb-3">
            {{ data.title }}
          </p>
          <p class="text-xs xl:text-sm font-bold">
            <span class="text-main-orange">USD</span>
            {{ data.price }}
          </p>
        </a>
      </div>
    </span>

    <span v-else>
      <div class="grid grid-cols-3 gap-8">
        <a href="#">
          <img
            src="../../../assets/icons/loader.svg"
            class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
            style="animation-duration: 1.5s"
          />
          <p class="text-center text-xs xl:text-sm font-medium line-clamp mb-3">
            Cargando
          </p>
        </a>
        <a href="#">
          <img
            src="../../../assets/icons/loader.svg"
            class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
            style="animation-duration: 1.5s"
          />
          <p class="text-center text-xs xl:text-sm font-medium line-clamp mb-3">
            Cargando
          </p>
        </a>
        <a href="#">
          <img
            src="../../../assets/icons/loader.svg"
            class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
            style="animation-duration: 1.5s"
          />
          <p class="text-center text-xs xl:text-sm font-medium line-clamp mb-3">
            Cargando
          </p>
        </a>
      </div>
    </span>
  </article>
</template>
<script>
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["purchaseData"],
  data() {
    return {
      review: {
        statusPregunta: false,
        pregunta: "",
        pregunta_created_at: "",
      },
      productsRecommend: null,
      data: {
        id: this.purchaseData.id,
        typeService: this.purchaseData.attributes.direct_type,
        vaucher:
          this.purchaseData.attributes.voucher_usa_link === null ? false : true,
      },
      imageVaucher: "",
      invoices: [],
    };
  },
  mounted() {
    if (this.purchaseData.attributes.code_amazon !== null) {
      this.getRecommendProducts(this.purchaseData.attributes.code_amazon);
    }
  },
  methods: {
    // Crear un pregunta para el servicio de review
    crearPreguntaReviewService() {
      if (this.review.pregunta.length !== 0) {
        axios
          .post(`${BASE_URL}` + "purchaseOrder/new-review/" + this.data.id, {
            review_mensaje: this.review.pregunta,
            from_admin: "0",
          })
          .then((res) => {
            this.review.statusPregunta = true;
            this.review.pregunta_created_at =
              res.data.body.attributes.created_at;
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Error al enviar el review";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    reloadViewProductRecomend(codeProduct) {
      this.$router.push({ name: "Purchase", params: { link: codeProduct } });
    },
    //metodo para traer productos recomendados segun el codigo de la orden
    getRecommendProducts(codeAmazon) {
      axios
        .get(`${BASE_URL}` + "product/getMalltinaProductRelation/" + codeAmazon)
        .then((response) => {
          this.productsRecommend = response.data.body ?? null;
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al traer productos relacionados";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    imprimir() {
      window.print();
    },
    copyCode() {
      const trackingCode = document.getElementById("tracking-code").textContent;
      navigator.clipboard
        .writeText(trackingCode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "El número de tracking se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    factura(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageVaucher = e.target.result;
        this.sendVaucher();
      };
      reader.readAsDataURL(file);
    },
    sendVaucher() {
      axios
        .put(
          `${BASE_URL}` + "purchaseOrder/update-order-no-grabr/" + this.data.id,
          {
            voucher_usa: this.imageVaucher,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$refs.fileupload.value = null;
            this.imageVaucher = "";
            this.data.vaucher = true;

            this.invoices.push(response.data.body.attributes.vouchers_usa);

            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Factura comercial enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//

            // location.reload();
            // this.closeModal();
            // this.exito = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.imageVaucher = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su factura comercial";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    convertDate(dateString) {
      const months = {
        1: "enero",
        2: "febrero",
        3: "marzo",
        4: "abril",
        5: "mayo",
        6: "junio",
        7: "julio",
        8: "agosto",
        9: "septiembre",
        10: "octubre",
        11: "noviembre",
        12: "diciembre",
      };
      // Parse the date string to a Date object
      const date = new Date(dateString);

      // Get the day of the month
      const day = date.getDate();

      // Get the month
      const month = date.getMonth() + 1;

      // Get the year
      const year = date.getFullYear();

      // Format the date
      const formattedDate = `${day} de ${months[month]} del ${year}`;

      // Return the formatted date
      return formattedDate;
    },
  },
  computed: {
    servicioReview() {
      const foundReview = this.purchaseData.attributes.services.find(
        (element) => element.name === "Review"
      );
      if (foundReview) {
        return true;
      } else {
        return false;
      }
    },
    statusPayed() {
      const statusPayed = this.purchaseData.attributes.payed;
      console.log(statusPayed === 3);
      return statusPayed === 3;
    },
  },
};
</script>
