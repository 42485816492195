<template>
  <!-- Por que comprar ? -->
  <section class="bg-white">
    <div
      class="u-container pb-40 xl:pb-20 grid xl:grid-cols-2 items-center justify-between gap-10 xl:gap-0 relative"
    >
      <article>
        <h3
          class="text-lg xl:text-4xl text-bg-black mb-6 text-center xl:text-left"
        >
          <span class="xl:block mb-1 xl:mb-2">¿Por qué</span>
          <span class="xl:block mb-1 xl:mb-2 font-bold">Comprar en</span>
          <span class="block font-bold">EnviosUsaPerú?</span>
        </h3>
        <p class="mb-8 xl:mb-0 text-xs xl:text-base xl:leading-8">
          Descubre por qué Envíos Usa Perú es la mejor opción para comprar en
          <br class="hidden xl:block" />
          tiendas extranjeras con servicios Lite y Premium, protección al
          comprador y <br class="hidden xl:block" />
          cotizaciones all-inclusive sin cobros adicionales. ¡Regístrate hoy!
          <!-- Todas tus compras desde importación desde Estados Unidos <br class="hidden xl:block">
          tienen compra protegida, y muchos descuentos con las mejores <br class="hidden xl:block">
          tarifas en todo el mercado. <br> -->

          <span class="block mt-4"
            >Comprar en <b>EnviosUsaPerú</b>, es fácil, rápido y seguro.
          </span>
        </p>
      </article>
      <aside class="xl:relative">
        <img
          src="../../assets/images/description-1.svg"
          class="mx-auto w-10/12 xl:w-auto"
        />
        <article
          class="absolute -bottom-4 inset-x-0 xl:w-max mx-auto flex items-center gap-6 xl:gap-8 px-8 py-10 xl:px-20 xl:py-14 bg-sky-black rounded-2xl shadow-2xl"
        >
          <img src="../../assets/images/escudo.png" alt="Compra protegida" />
          <div>
            <h4 class="text-xs xl:text-base font-semibold mb-2">
              COMPRA PROTEGIDA
            </h4>
            <p class="text-xs xl:text-sm mb-4">
              Te reembolsamos el 100% de <br />
              tu compra, si no llega tu producto
            </p>
            <h4 class="text-xs xl:text-base font-semibold text-main-red mb-4">
              TE REGALAMOS HASTA <span class="text-black">$20 USD</span> <br />
              EN TU PRIMERA COMPRA
            </h4>
            <router-link
              to="/compra/segura"
              class="text-xs xl:text-sm text-blue-500 font-semibold underline"
              >Más Información</router-link
            >
          </div>
        </article>
      </aside>
    </div>
  </section>
</template>
<script></script>
