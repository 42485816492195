<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white xl:border xl:shadow-lg xl:border-none xl:shadow-none"
  >
    <h4
      class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center justify-between"
    >
      Detalles del producto
      <span class="text-xs xl:text-sm text-textColor">
        <span>N° orden:</span>
        <span class="ml-2 text-main-red">EUP{{ purchaseData.id }}</span>
      </span>
    </h4>

    <!-- Producto -->
    <div class="p-6 rounded-2xl border shadow-sm mb-8">
      <div class="flex items-center gap-4 mb-6">
        <img
          v-if="purchaseData.attributes.product_image_url !== null"
          class="w-28"
          :src="purchaseData.attributes.product_image_url"
          alt="Compra realizada en Envios USA"
        />
        <img
          v-else
          class="w-28"
          src="../../../assets/images/caja-paso-4.svg"
          alt="Compra realizada en Envios USA"
        />
        <div class="text-xs xl:text-base font-bold">
          <!-- Estatus / Tipo de servicio -->
          <div class="flex items-center gap-4 mb-4">
            <span class="flex gap-3 items-center">
              <img src="../../../assets/icons/icon-tracking-statu.png" />
              <span>
                <span
                  class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 1
                  "
                >
                  EN REVISIÓN</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 2
                  "
                >
                  COMPRADO</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 3
                  "
                >
                  PREPARANDO PARA ENVIAR</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 4
                  "
                >
                  LISTO PARA ENVIAR</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 5
                  "
                >
                  ENVIADO</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 6
                  "
                >
                  LISTO PARA ENTREGAR</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-green bg-opacity-20 w-max text-main-green font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 7
                  "
                >
                  ENTREGADO</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 8
                  "
                >
                  SINIESTRADO</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 9
                  "
                >
                  EN PROCESO DE DEVOLUCIÓN</span
                ><span
                  class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
                  v-if="
                    purchaseData.attributes.order_tracking_details[
                      purchaseData.attributes.order_tracking_details.length - 1
                    ].attributes.status === 10
                  "
                >
                  RETORNADO AL PROVEEDOR</span
                >
              </span>
            </span>
            <span
              class="px-3 py-1 rounded-full text-xs border border-main-orange w-max text-main-orange font-semibold"
              >Servicio
              {{
                purchaseData.attributes.direct_type === 1 ? "Lite" : "Premium"
              }}</span
            >
          </div>
          <div class="flex items-start gap-4">
            <p class="line-clamp-2">
              {{ purchaseData.attributes.product_info }}
            </p>
            <a
              :href="purchaseData.attributes.reference_link"
              target="_Blank"
              v-if="purchaseData.attributes.reference_link !== null"
            >
              <img
                src="../../../assets/icons/icon-url.svg"
                style="max-width: 32px"
                alt="Icono de Envios USA"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="flex flex-col xl:flex-row gap-5 text-xs xl:text-base">
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Precio:</span>
          <span class="font-bold"
            ><span class="text-main-red">USD</span>
            {{ purchaseData.attributes.real_price }}</span
          >
        </p>
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Cantidad:</span>
          <span class="font-bold">{{
            purchaseData.attributes.product_specimens
          }}</span>
        </p>
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Peso:</span>
          <span class="font-bold"
            >{{ purchaseData.attributes.weight_kg }} kg</span
          >
        </p>
      </div>
      <!-- <div class="mb-10">
        <p class="text-sm xl:text-base font-bold pb-3 xl:pl-6 border-b border-gray-200">
          Caracteristica 1
        </p>
      </div>
      <div class="mb-10">
        <p class="text-sm xl:text-base font-bold pb-3 xl:pl-6 border-b border-gray-200">
          Caracteristica 2
        </p>
      </div> -->
    </div>

    <!-- Dirección de entrega -->
    <hr class="my-4" />
    <!-- Dirección de entrega -->
    <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
      Dirección de entrega
    </h4>
    <div
      class="relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
      v-if="purchaseData.attributes.at_office_flag === 0"
    >
      <div class="flex items-center gap-4 xl:gap-6 py-4 px-5 xl:p-6">
        <img src="../../../assets/icons/oficina.svg" alt="" />
        <div
          class="flex items-center justify-between font-semibold cursor-pointer"
        >
          <div class="text-text-blue">
            <p class="text-sm xl:text-base mb-2">
              {{
                purchaseData.attributes.shipping_address.attributes.owner_name
              }}
              {{
                purchaseData.attributes.shipping_address.attributes
                  .owner_lastname
              }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{ purchaseData.attributes.shipping_address.attributes.address }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{
                purchaseData.attributes.shipping_address.attributes.department
              }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{
                purchaseData.attributes.shipping_address.attributes
                  .telephone_number
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Dirección de entrega CASO 2 -->
    <div
      class="relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
      v-if="purchaseData.attributes.at_office_flag === 1"
    >
      <div
        class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-text-blue text-sm xl:text-base">Recojo en Oficina</p>
      </div>
      <div
        class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
      >
        <div class="flex items-center gap-4 xl:gap-6">
          <img src="../../../assets/icons/oficina.svg" alt="" />
          <div>
            <p class="text-sm font-bold">Lima</p>
            <p class="text-xs text-gray-400">
              Jr. Emilio Althaus Nro. 121 Oficina 403
            </p>
          </div>
        </div>
        <a
          href="https://goo.gl/maps/e2vcgRdQ93BdyYrC7"
          target="Blank"
          class="ml-auto"
        >
          <img src="../../../assets/icons/ver-direccion.png" class="ml-auto" />
        </a>
      </div>
    </div>
    <!-- Métodos de pago -->
    <hr class="my-4" />
    <h4
      class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center gap-6"
    >
      Método de pago
      <span class="flex gap-3 items-center">
        <img src="../../../assets/icons/icon-pago-status.png" alt="" />
        <span>
          <span
            v-if="purchaseData.attributes.payed === 1"
            class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
            >PENDIENTE</span
          >
          <span
            v-else-if="purchaseData.attributes.payed === 2"
            class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
            >EN PROCESO</span
          >
          <span
            v-else-if="purchaseData.attributes.payed === 3"
            class="px-3 py-1 rounded-full text-xs bg-main-green bg-opacity-20 w-max text-main-green font-semibold"
            >CONFIRMADO</span
          >
          <span
            v-else-if="purchaseData.attributes.payed === 10"
            class="px-3 py-1 rounded-full text-xs bg-main-red bg-opacity-20 w-max text-main-red font-semibold"
            >RECHAZADO"</span
          >
          <span
            v-else-if="purchaseData.attributes.payed === 11"
            class="px-3 py-1 rounded-full text-xs bg-main-orange bg-opacity-20 w-max text-main-orange font-semibold"
            >RECLAMADO</span
          >
          <span
            v-else-if="purchaseData.attributes.payed === 12"
            class="px-3 py-1 rounded-full text-xs bg-gray-400 bg-opacity-20 w-max text-gray-400 font-semibold"
            >RETORNADO</span
          >
        </span>
      </span>
    </h4>
    <div class="mb-8">
      <!-- Tarjeta de debito -->
      <div
        class="hidden text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1"
      >
        <label class="relative block">
          <span class="bg-white absolute left-4 -top-2 text-xs font-medium"
            >Tarjeta de débito</span
          >
          <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/tarjetas-de-credito-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
            type="text"
            value="0000-0000-0000-0000"
            readonly
          />
          <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
            <img
              class="w-6"
              src="../../../assets/icons/visa.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </label>
      </div>
      <!-- Tarjeta de debito -->
      <div
        class="hidden text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1"
      >
        <label class="relative block">
          <span class="bg-white absolute left-4 -top-2 text-xs font-medium"
            >Tarjeta de crédito</span
          >
          <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/tarjetas-de-credito-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
            type="text"
            value="0000-0000-0000-0000"
            readonly
          />
          <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
            <img
              class="w-6"
              src="../../../assets/icons/visa.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </label>
      </div>
      <!-- Banca por internet y móvil -->
      <div
        class="hidden text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
      >
        <div
          class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
        >
          <div class="absolute inset-y-0 left-4 xl:left-6 flex items-center">
            <img
              src="../../../assets/icons/banca-por-internet.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <span>
            Banca por internet y móvil
            <span class="block text-xs text-gray-400 font-normal"
              >Via pago efectivo</span
            >
          </span>
        </div>
      </div>
      <!-- Pago Efectivo -->
      <div
        v-if="purchaseData.attributes.payment_method_flag === 4"
        class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-3"
      >
        <div
          class="relative rounded-2xl border-b pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
        >
          <div class="absolute inset-y-0 left-4 xl:left-6 flex items-center">
            <img
              src="../../../assets/icons/pago-efectivo-p.png"
              class="w-8"
              alt="pago efectivo"
            />
          </div>
          <span class="text-text-blue">
            PagoEfectivo
            <span class="block text-xs text-gray-400 font-normal"
              >Agentes y agencias</span
            >
          </span>
        </div>
        <div class="py-4">
          <p
            class="text-gray-400 text-xs xl:text-base flex items-center justify-center px-2 mb-2 gap-2 xl:gap-6"
            v-if="purchaseData.attributes.payment_method_flag === 4"
          >
            <span>Este es tu código de pago CIP:</span>
            <span
              class="text-right text-textColor font-bold xl:text-xl flex items-center gap-2"
            >
              {{ purchaseData.attributes.atm_payment.payment_code }}
              <span class="tooltip text-left">
                <img src="../../../assets/icons/icon-clock.png" alt="" />
                <span class="tooltip-box -left-8"
                  >Tienes tiempo hasta el
                  {{ purchaseData.attributes.atm_payment.expiration }}</span
                >
              </span>
            </span>
          </p>
          <a
            :href="purchaseData.attributes.atm_payment.external_resource_url"
            target="_blank"
            class="block text-center text-main-blue font-semibold text-xs xl:text-base"
            v-if="purchaseData.attributes.payment_method_flag === 4"
            >Ver instrucciones de pago</a
          >
        </div>
      </div>
      <!-- Transferencia bancaria -->
      <div
        class="text-text-blue relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
        v-if="purchaseData.attributes.payment_method_flag === 5"
      >
        <div
          class="relative rounded-2xl border-b flex items-center justify-between pl-16 xl:pl-20 p-6 font-semibold cursor-pointer"
        >
          <div class="absolute inset-y-0 left-2 xl:left-4 flex items-center">
            <img
              src="../../../assets/icons/tarjetas-transferencias.png"
              alt="Icono de Envios USA"
            />
          </div>
          <span class="text-xs xl:text-base">Transferencia bancaria</span>
        </div>
        <div class="px-6 py-6">
          <div class="flex items-start gap-3 xl:gap-8 mb-8">
            <img
              src="../../../assets/icons/interbank-2.svg"
              class="mt-2 w-10 xl:w-auto"
              alt="Icono de Envios USA"
            />
            <p class="text-xs xl:text-base">
              <span id="transferencia-nombre" class="block font-bold"
                >GRUPO CASZA S.A.C.</span
              >
              <span id="transferencia-ruc" class="block text-gray-400"
                >20609271044</span
              >
              <span id="transferencia-cuenta" class="block text-gray-400"
                >{{ moneda === "USD" ? "Cuenta corriente: 200-3005545622" : "Cuenta Corriente: 200-3005545615" }}</span
              >
              <span id="transferencia-cci" class="block text-gray-400"
                >{{ moneda === "USD" ? "CCI: 003-200-003005545622-39" : "CCI: 003-200-003005545615-33" }}</span
              >
            </p>
            <div class="ml-auto xl:mr-6" @click.prevent="copyPayment">
              <img
                src="../../../assets/icons/icon-copiar.svg"
                class="cursor-pointer w-5 xl:w-auto mx-auto"
                alt="Icono de Envios USA"
              />
              <span class="text-gray-400 text-xs">Copiar</span>
            </div>
          </div>
          <div v-if="purchaseData.attributes.payment_method_flag === 5">
            <div class="grid grid-cols-12 xl:gap-4 items-center">
              <label
                for="num_operacion"
                class="col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base mb-4 font-semibold cursor-pointer"
                >N° Operación:</label
              >
              <input
                :class="
                  styleTrans && trans.number
                    ? 'border border-main-orange '
                    : 'border border-gray-600 '
                "
                type="text"
                class="col-span-12 xl:col-span-9 mb-4 rounded-2xl border shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                placeholder="Escribe aquí ..."
                v-model="trans.number"
                :disabled="styleTrans"
              />

              <span
                class="font-semibold mb-6 col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base"
                >Voucher:</span
              >
              <label
                :class="
                  styleTrans && trans.nombreImg === 'Voucher cargado'
                    ? 'border border-main-orange '
                    : 'border border-gray-600 '
                "
                class="underline col-span-12 xl:col-span-9 mb-6 rounded-2xl border shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
              >
                <img src="../../../assets/icons/agregar__boleta.png" alt="" />
                <input
                  type="file"
                  @change="factura($event)"
                  class="overflow-hidden h-0 w-0"
                  :disabled="styleTrans"
                  accept="image/*,.pdf"
                />
                <span v-if="trans.nombreImg === 'Voucher cargado'">
                  <a :href="trans.url.url" target="_Blank">{{
                    trans.nombreImg
                  }}</a>
                </span>
                <span v-else> {{ trans.nombreImg }} </span>
              </label>
            </div>

            <button
              :class="
                trans.number.length !== 0 ? 'bg-main-red' : ' bg-gray-500'
              "
              v-if="styleTrans === false"
              @click.prevent="sendVaucher"
              class="bg-bg-black text-white py-5 font-bold rounded-2xl block w-10/12 mx-auto mb-2"
            >
              Enviar
            </button>
            <p v-if="styleTrans" class="text-center text-xs mt-2 xl:text-sm">
              Acreditaremos tu pago en las próximas 48 horas
            </p>
          </div>
        </div>
      </div>
      <!-- Paypal -->
      <div
        v-if="purchaseData.attributes.payment_method_flag === 2"
        class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
      >
        <div
          class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
        >
          <div class="absolute inset-y-0 left-4 xl:left-6 flex items-center">
            <img
              src="../../../assets/icons/paypal.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <span>Paypal</span>
        </div>
      </div>
      <!-- Tarjeta -->
      <div
        v-if="purchaseData.attributes.payment_method_flag === 3"
        class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1"
      >
        <label class="relative block">
          <span class="bg-white absolute left-4 -top-2 text-xs font-medium"
            >Tarjeta de débito</span
          >
          <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/tarjetas-de-credito-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
            type="text"
            value="0000-0000-0000-0000"
            readonly
          />
          <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
            <img
              class="w-6"
              src="../../../assets/icons/visa.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </label>
      </div>

      <div
        class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
        v-if="purchaseData.attributes.payment_method_flag === 6"
      >
        <div
          class="relative rounded-2xl p-6 font-bold cursor-pointer text-main-green"
        >
          <span>Mi balance</span>
        </div>
      </div>
    </div>
    <!-- Detalles de pago -->
    <hr class="my-4" />
    <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
      Detalles de pago
    </h4>
    <div class="py-4 px-5 rounded-2xl border mb-8 text-xs xl:text-base">
      <div>
        <div>
          <p class="flex items-center justify-between mb-2 font-bold">
            <span>{{ typeService === 2 ? "Producto" : "Gastos en USA" }}</span>
            <span
              >{{ moneda }}
              {{
                typeService === 2
                  ? detalle.cif
                  : (
                      parseFloat(detalle.warehouse) + parseFloat(detalle.flete)
                    ).toFixed(2)
              }}</span
            >
          </p>
          <hr class="my-3" />
          <span v-if="typeService === 2">
            <p class="xl:pl-2 flex items-center justify-between mb-2">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />
                Precio del producto</span
              >
              <span>{{ moneda }} {{ detalle.precioProducto }}</span>
            </p>
            <p class="xl:pl-2 flex items-center justify-between mb-2">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />Shipping</span
              >
              <span>{{ moneda }} {{ detalle.shipping }}</span>
            </p>
            <p class="xl:pl-2 flex items-center justify-between mb-2">
              <span class="flex gap-2">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt="Icono de Envios USA"
                />Tax</span
              >
              <span>{{ moneda }} {{ detalle.tax }}</span>
            </p>
          </span>

          <p class="xl:pl-2 flex items-center justify-between mb-2">
            <span class="flex gap-2">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt="Icono de Envios USA"
              />Warehouse</span
            >
            <span>{{ moneda }} {{ detalle.warehouse }}</span>
          </p>
          <p class="xl:pl-2 flex items-center justify-between mb-2">
            <span class="flex gap-2">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt="Icono de Envios USA"
              />Millas de vuelo</span
            >
            <span>{{ moneda }} {{ detalle.flete }}</span>
          </p>
        </div>
        <hr class="my-5" />
        <div>
          <!-- //si contiene impuestos -->
          <span v-if="detalle.impuestos !== '0.00'">
            <p class="flex items-center justify-between font-bold">
              <span>Recompensa viajero</span>
              <span>{{ moneda }} {{ detalle.impuestos }}</span>
            </p>
            <p
              class="hidden flex items-center justify-between mb-2 font-semibold"
            >
              <span>Ad/Valorem</span>
              <span>{{ moneda }} {{ detalle.valorem }}</span>
            </p>
            <p
              class="hidden flex items-center justify-between mb-2 font-semibold"
            >
              <span>IGV</span>
              <span>{{ moneda }} {{ detalle.igv }}</span>
            </p>
            <p
              class="hidden flex items-center justify-between mb-2 font-semibold"
            >
              <span>IPM</span>
              <span>{{ moneda }} {{ detalle.ipm }}</span>
            </p>
          </span>
          <!-- //Si no contiene impuestos -->
          <p
            v-else
            class="text-main-green flex items-center justify-between font-bold"
          >
            <span>Recompensa viajero</span>
            <span>Gratis</span>
          </p>
        </div>
        <hr class="my-5" />
        <div>
          <p class="flex items-center justify-between mb-2 font-bold">
            <span>Tarifa de servicio</span>
            <span>{{ moneda }} {{ detalle.tarifa }}</span>
          </p>
          <hr class="my-5" />
          <p class="xl:pl-2 flex items-center justify-between mb-2">
            <span class="flex gap-2">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt="Icono de Envios USA"
              />Margen de servicio</span
            >
            <span
              :class="
                detalle.tramiteAduanero === '0.00'
                  ? 'text-main-green font-bold'
                  : detalle.tramiteAduanero
              "
              >{{ moneda }}
              {{
                detalle.tramiteAduanero === "0.00"
                  ? "Gratis"
                  : detalle.tramiteAduanero
              }}</span
            >
          </p>
          <p class="xl:pl-2 flex items-center justify-between mb-2">
            <span class="flex gap-2">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                alt="Icono de Envios USA"
                class="w-2"
              />Envío nacional</span
            >
            <span :class="free === 1 ? 'text-main-green font-bold' : ''"
              >{{ moneda }}
              {{ free === 1 ? "Gratis" : detalle.envioNacional }}</span
            >
          </p>
        </div>
        <hr class="my-5" v-if="servicios.length !== 0" />
        <div>
          <p
            class="flex items-center justify-between mb-2 font-bold"
            v-if="servicios.length !== 0"
          >
            <span>Servicios Adicionales</span>
            <span
              >{{ moneda }}
              {{
                moneda === "USD"
                  ? servicioDolares.toFixed(2)
                  : servicioSoles.toFixed(2)
              }}</span
            >
          </p>
          <hr class="my-5" />
          <p
            class="xl:pl-2 flex items-center justify-between mb-2"
            v-for="data in servicios"
            :key="data"
          >
            <span class="flex gap-2">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt="Icono de Envios USA"
              />{{ data.name }}</span
            >
            <span>
              {{ moneda }}
              {{
                moneda === "USD"
                  ? data.pivot.amount.toFixed(2)
                  : (data.pivot.amount * data.exchange_rate).toFixed(2)
              }}
            </span>
          </p>
          <hr v-if="servicios.length !== 0" class="my-6" />
        </div>
        <div>
          <p
            class="flex items-center justify-between mb-2 font-bold"
            v-if="purchaseData.attributes.coupon_discount !== null"
          >
            <span>Cupón utilizado</span>
            <span>
              {{ moneda === "USD" ? "- USD" : "- PEN" }}
              {{
                moneda === "USD"
                  ? Number(purchaseData.attributes.coupon_discount).toFixed(2)
                  : Number(
                      purchaseData.attributes.coupon_discount *
                        purchaseData.attributes.exchange
                    ).toFixed(2)
              }}
              <!-- {{ purchaseData.attributes.coupon_discount }}  -->
            </span>
          </p>
          <hr
            class="my-6"
            v-if="purchaseData.attributes.coupon_discount !== null"
          />
          <p
            class="flex items-center justify-between mb-2 font-bold"
            v-if="purchaseData.attributes.balance_discount !== null"
          >
            <span>Saldo utilizado</span>
            <span>- USD {{ purchaseData.attributes.balance_discount }} </span>
          </p>
          <hr
            class="my-6"
            v-if="purchaseData.attributes.balance_discount !== null"
          />
        </div>

        <!-- v-if="
            purchaseData.attributes.coupon_discount !== null ||
            purchaseData.attributes.balance_discount !== null
          " -->
        <p
          class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
          v-if="purchaseData.attributes.new_total_cost === null"
        >
          <span>Pago total</span>
          <span>
            <span class="text-main-red" v-if="moneda === 'USD'">USD </span>
            <span class="text-main-red" v-if="moneda === 'PEN'">PEN </span>
            <span v-if="moneda === 'USD'"> {{ detalle.totalPagar }}</span>
            <span v-if="moneda === 'PEN'"> {{ detalle.totalPagar }}</span>
          </span>
        </p>
        <p
          class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
          v-else
        >
          <span>Pago total</span>
          <span>
            <span class="text-main-red" v-if="moneda === 'USD'">USD </span>
            <span class="text-main-red" v-if="moneda === 'PEN'">PEN </span>
            <span v-if="moneda === 'USD'">
              {{ purchaseData.attributes.new_total_cost }}</span
            >
            <span v-if="moneda === 'PEN'">
              {{ purchaseData.attributes.new_total_cost }}</span
            >
          </span>
        </p>
      </div>
    </div>
    <div class="hidden xl:flex items-center justify-center gap-4 mb-6">
      <img src="../../../assets/icons/entrega.svg" alt="Icono de Envios USA" />
      <p>
        Tiempo estimado de entrega:
        <span class="font-semibold text-main-red">{{
          purchaseData.attributes.delivery_date
        }}</span>
      </p>
    </div>
  </div>
</template>
<script>
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["purchaseData"],
  data() {
    return {
      servicioSoles: 0.0,
      servicioDolares: 0.0,
      styleTrans: false,
      trans: {
        number: "",
        img: "",
        nombreImg: "Adjuntar archivo",
        url: null,
      },
      moneda: this.purchaseData.attributes.currency,
      typeService: this.purchaseData.attributes.direct_type,

      servicios: this.purchaseData.attributes.services,

      free:
        this.purchaseData.attributes.shipping_address === null
          ? 1
          : this.purchaseData.attributes.shipping_address.attributes.free,

      detalle: {
        cif: (
          this.purchaseData.attributes.cif_cost *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        precioProducto: (
          this.purchaseData.attributes.real_price *
          this.purchaseData.attributes.product_specimens *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        shipping: (
          this.purchaseData.attributes.real_shipping *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        tax: (
          this.purchaseData.attributes.real_tax *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        warehouse: (
          this.purchaseData.attributes.warehouse *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        flete: (
          this.purchaseData.attributes.flete_cost *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        impuestos: (
          this.purchaseData.attributes.arancel_cost *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        valorem: (
          this.purchaseData.attributes.ad_valorem *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        igv: (
          this.purchaseData.attributes.igv *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        ipm: (
          this.purchaseData.attributes.ipm *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        tarifa: (
          this.purchaseData.attributes.distribution_cost *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        tramiteAduanero: (
          this.purchaseData.attributes.tramite_aduanero *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        envioNacional: (
          this.purchaseData.attributes.national_shipping *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
        totalPagar: (
          this.purchaseData.attributes.total_cost *
          this.purchaseData.attributes.exchange
        ).toFixed(2),
      },
    };
  },
  mounted() {
    this.$store.commit("resetServicios");
    this.getTotalServices();
    window.scrollTo(0, 0);
    //Actualziando direccion del localstorage
    if (
      this.purchaseData.attributes.shipping_address &&
      this.purchaseData.attributes.original_shipping_address_id === null
    ) {
      const data = {};
      data.nombre =
        this.purchaseData.attributes.shipping_address.attributes.address;
      data.lat = this.purchaseData.attributes.shipping_address.attributes.lat;
      data.lng = this.purchaseData.attributes.shipping_address.attributes.lng;
      data.departamento =
        this.purchaseData.attributes.shipping_address.attributes.department;
      data.provincia =
        this.purchaseData.attributes.shipping_address.attributes.city;
      data.distrito =
        this.purchaseData.attributes.shipping_address.attributes.district;
      data.location =
        this.purchaseData.attributes.shipping_address.attributes.free;
      const dataCompress = JSON.stringify(data);
      window.localStorage.setItem("direction", dataCompress);
    }
  },
  methods: {
    getTotalServices() {
      var totalServicioDolar = 0.0;
      var totalServicioSoles = 0.0;
      if (this.servicios.length !== 0) {
        this.servicios.forEach((value) => {
          totalServicioDolar = totalServicioDolar + value.pivot.amount;
          totalServicioSoles =
            totalServicioSoles + value.pivot.amount * value.exchange_rate;
        });
        this.servicioDolares = totalServicioDolar;
        this.servicioSoles = totalServicioSoles;
      }
    },
    factura(e) {
      const file = e.target.files[0];
      this.trans.nombreImg = e.target.files[0].name;
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.trans.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    sendVaucher() {
      const result = this.validateFormTrans();
      if (result) {
        axios
          .put(
            `${BASE_URL}` +
              "purchaseOrder/update-order-no-grabr/" +
              this.purchaseData.id,
            {
              payment_code: this.trans.number,
              voucher: { voucher: this.trans.img, isFromAdmin: false },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.trans.number = response.data.body.attributes.payment_code;
              this.trans.url =
                response.data.body.attributes.vouchers_usa[0] ?? null;
              if (response.data.body.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }
              this.styleTrans = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Orden Actualizada";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al subir el voucher de la transferencia de pago";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    validateFormTrans() {
      const imagen = this.trans.img;
      const codigo = this.trans.number;

      if (codigo.length !== 0) {
        if (imagen.length === 0) {
          this.trans.img = null;
        }
        return true;
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Debe subir el N° de Operación";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },

    copyPayment() {
      const nombre = document.getElementById(
        "transferencia-nombre"
      ).textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById(
        "transferencia-cuenta"
      ).textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de tranferencia con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    imprimir() {
      window.print();
    },
  },
};
</script>
