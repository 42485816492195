<template>
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../assets/icons/paypal.svg" />
          <span>
            Paypal
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga utilizando tu saldo Paypal y/o tarjetas extranjeras</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //Cuando El monto es diferente de Cero -->
  <span v-else>
    <div
      :class="typePayment ? 'ring-main-green' : 'ring-gray-100'"
      class="text-text-blue relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
    >
      <div
        @click.prevent="selectPayment"
        class="tooltip no-underline rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../assets/icons/paypal.svg" />
          <span>
            Paypal
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga utilizando tu saldo Paypal y/o tarjetas extranjeras</span
            >
          </span>
        </p>
        <span
          :class="
            typePayment
              ? 'bg-main-green text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="transition-colors font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
        >
          {{ typePayment ? "Seleccionado" : "Seleccionar" }}
        </span>

        <span class="tooltip-box font-medium bottom-20">
          Paypal es sinonimo de seguridad y confianza. Utiliza tarjetas
          extranjeras, nacionales y/o paga con dinero de tu saldo en tu cuenta
          Paypal
        </span>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["paymenSelect", "terminos"],
  data() {
    return {};
  },
  methods: {
    selectPayment() {
      const select = 2;
      this.$emit("paymentSelect", select);
      // if (this.terminos === false) {
      //   alert("Aceptar términos y condiciones");
      // }
    },
  },
  computed: {
    typePayment() {
      if (this.paymenSelect === 2) {
        return true;
      } else {
        return false;
      }
    },
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
};
</script>
