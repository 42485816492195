<template>
  <!-- cabecera -->
  <header-component
    @openLogin="autentification.estateLogin = $event"
    @openRegister="autentification.estateRegister = $event"
    @openTracking="autentification.estateTracking = $event"
    @equalsProduct="newGetproduct"
    @resetProcess="process = $event"
    :process="process"
  />
  <!-- tracking componente -->
  <tracking-component
    :estate="autentification.estateTracking"
    @closeTracking="autentification.estateTracking = $event"
  />
  <!-- Banner de descuento -->
  <section class="hidden xl:block">
    <img src="../assets/images/franjas-envios.jpg" class="w-full" />
  </section>
  <!-- proceso de compra -->
  <section class="section-flujo-compra">
    <article class="u-container py-14 grid xl:grid-cols-2 gap-8">
      <!-- Bloque de la izquierda -->
      <section>
        <!-- //Componente de balance -->
        <balance-component v-if="process === 3" />
        <article class="xl:bg-white xl:border xl:shadow-lg rounded-2xl">
          <!-- Form de Detalles del producto -->
          <div class="xl:border xl:shadow-lg rounded-2xl">
            <!-- Timeline Detail Product -->
            <div
              v-if="process === 1"
              class="px-4 xl:px-0 text-white flex items-center w-full xl:rounded-tl-2xl xl:rounded-tr-2xl xl:overflow-hidden font-semibold mb-16 xl:mb-9 xl:grid xl:items-stretch grid-cols-3"
            >
              <!-- Paso - 1 -->
              <div
                class="relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-main-red xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-main-red xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                  >1</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Detalles del producto</span
                >
                <span class="text-xs text-main-red absolute -bottom-6 -left-3"
                  >Producto</span
                >
              </div>
              <div class="bg-gray-400 w-full h-2 xl:hidden"></div>
              <!-- Paso - 2 -->
              <div
                class="relative xl:px-4 xl:py-3 cursor-not-allowed text-gray-400 xl:bg-gray-light transition-colors"
              >
                <span
                  class="bg-gray-400 xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl text-white xl:text-gray-400"
                  >2</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Dirección de entrega</span
                >
                <span class="text-xs text-gray-400 absolute -bottom-6 -left-4"
                  >Dirección</span
                >
              </div>
              <div class="bg-gray-400 w-full h-2 xl:hidden"></div>
              <!-- Paso - 3 -->
              <div
                class="relative xl:px-4 xl:py-3 cursor-pointer text-gray-400 xl:bg-gray-light transition-colors"
              >
                <span
                  class="bg-gray-400 xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl text-white xl:text-gray-400"
                  >3</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Métodos de pago</span
                >
                <span class="text-xs text-gray-400 absolute -bottom-6 left-0"
                  >Pago</span
                >
              </div>
            </div>
            <!-- Timeline  Direction -->
            <div
              v-if="process === 2"
              class="px-4 xl:px-0 text-white flex items-center w-full xl:rounded-tl-2xl xl:rounded-tr-2xl xl:overflow-hidden font-semibold mb-16 xl:mb-9 xl:grid xl:items-stretch grid-cols-3"
            >
              <!-- Paso - 1 -->
              <!-- <div @click.prevent="changeForm(1)" class="relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-main-red xl:hover:bg-main-red transition-colors">
                <span
                  class="bg-main-red xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                  >1</span
                >
                <span class="font-bold text-xs hidden xl:block">Detalles del producto</span>
                <span class="text-xs text-textColor absolute -bottom-6 -left-3"
                  >Producto</span
                >
              </div>
              <div class="bg-main-red w-full h-2 xl:hidden"></div> -->
              <div
                @click.prevent="changeForm(1)"
                class="relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-bg-black xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-bg-black xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                >
                  <img src="../assets/icons/check-green.png" class="my-2" />
                </span>
                <span class="font-bold text-xs hidden xl:block"
                  >Detalles del producto</span
                >
                <span class="text-xs text-bg-black absolute -bottom-6 -left-3"
                  >Producto</span
                >
              </div>
              <div class="bg-bg-black w-full h-2 xl:hidden"></div>
              <!-- Paso - 2 -->
              <div
                class="relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-main-red xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-main-red ring ring-bg-black xl:ring-transparent xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                  >2</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Dirección de entrega</span
                >
                <span class="text-xs text-main-red absolute -bottom-6 -left-4"
                  >Dirección</span
                >
              </div>
              <div class="bg-gray-400 w-full h-2 xl:hidden"></div>
              <!-- Paso 3 -->
              <div
                class="relative xl:px-4 xl:py-3 cursor-pointer text-gray-400 xl:bg-gray-light xl:hover:bg-main-red transition-colors cursor-not-allowed"
              >
                <span
                  class="bg-gray-400 xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl text-white xl:text-gray-400"
                  >3</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Métodos de pago</span
                >
                <span class="text-xs text-gray-400 absolute -bottom-6 left-0"
                  >Pago</span
                >
              </div>
            </div>
            <!-- Timeline  Payment -->
            <div
              v-if="process === 3"
              class="px-4 xl:px-0 text-white flex items-center w-full xl:rounded-tl-2xl xl:rounded-tr-2xl xl:overflow-hidden font-semibold mb-16 xl:mb-9 xl:grid xl:items-stretch grid-cols-3"
            >
              <!-- Paso 1 -->
              <div
                @click.prevent="changeForm(1)"
                class="relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-bg-black xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-bg-black xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                >
                  <img src="../assets/icons/check-green.png" class="my-2" />
                </span>
                <span class="font-bold text-xs hidden xl:block"
                  >Detalles del producto</span
                >
                <span class="text-xs text-bg-black absolute -bottom-6 -left-3"
                  >Producto</span
                >
              </div>
              <div class="bg-bg-black w-full h-2 xl:hidden"></div>
              <!-- Paso 2 -->
              <div
                @click.prevent="changeForm(2)"
                class="relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-bg-black xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-bg-black ring ring-bg-black xl:ring-transparent xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                >
                  <img src="../assets/icons/check-green.png" class="my-2" />
                </span>
                <span class="font-bold text-xs hidden xl:block"
                  >Dirección de entrega</span
                >
                <span class="text-xs text-bg-black absolute -bottom-6 -left-4"
                  >Dirección</span
                >
              </div>
              <div class="bg-bg-black w-full h-2 xl:hidden"></div>
              <!-- Paso 3 -->
              <div
                class="relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-main-red xl:hover:bg-main-red transition-colors"
              >
                <span
                  class="bg-main-red ring ring-bg-black xl:ring-transparent xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl"
                  >3</span
                >
                <span class="font-bold text-xs hidden xl:block"
                  >Métodos de pago</span
                >
                <span class="text-xs text-main-red absolute -bottom-6 left-0"
                  >Pago</span
                >
              </div>
            </div>
            <!-- Timeline  Resumen -->
            <div
              v-if="process === 4"
              class="bg-main-red text-white p-5 rounded-tl-2xl rounded-tr-2xl xl:mb-9"
            >
              <p
                class="text-xs xl:text-base font-semibold flex items-center justify-center gap-2"
              >
                Tu compra se ha realizado con exito
                <img src="../assets/icons/check-green.png" alt="" />
              </p>
            </div>
            <!-- Detalles del producto -->
            <span v-show="process === 1">
              <detail-component
                @dataCifImpuesto="dataCifImpuesto = $event"
                :changeMoney="changeMoney"
                :newLinkProduct="newLinkProduct"
                :errorProduct="errorProduct"
                :typeService="typeService"
                @calculate="calculateTarifaServicio"
                @store="store = $event"
                :forceSearch="forceSearch"
              />
            </span>

            <!-- Direcciones del usuario -->
            <span v-show="process === 2">
              <direction-component
                @typeDirection="typeDirection = $event"
                @calculate="calculateTarifaServicio"
                @latitud="latitud = $event"
                @longitud="longitud = $event"
                @dataDirection="dataDirection = $event"
                @dataDirectionName="dataDirectionName = $event"
                @existForm="existForm = $event"
                :errorNewDirection="errorNewDirection"
              />
            </span>

            <!-- Metodos de pago -->

            <span v-show="process === 3">
              <payment-component
                @dataPayment="dataPayment = $event"
                @currencySymbol="currencySymbol = $event"
                @next="process = $event"
                @purchaseData="purchaseData = $event"
                @changeSymboloMoney="currencySymbol = $event"
                :currencySymbol="currencySymbol"
                :dataCifImpuesto="dataCifImpuesto"
                :dataTarifa="dataTarifa"
                :typeDirection="typeDirection"
                :dataDirection="dataDirection"
                :typeService="typeService"
                :terminos="terminos"
              />
            </span>

            <!-- Resumen -->
            <span v-if="process === 4">
              <resumen-component
                v-show="process === 4"
                :purchaseData="purchaseData"
              />
            </span>
          </div>
        </article>
      </section>
      <!-- Bloque de la derecha -->
      <div>
        <!-- Tipo de servicio -->
        <type-service-component
          v-if="process === 1 || process === 2 || process === 3"
          @typeService="typeService = $event"
          @process="process = $event"
          @calculate="calculateTarifaServicio"
          :process="process"
          :view="view"
          :dataTarifa="dataTarifa"
        />
        <span v-show="process === 3">
          <servicio-extra-component />
        </span>
        <span v-show="process === 3">
          <cupon-component :idUser="idUser" />
        </span>

        <!-- Detalles de pago -->
        <span v-if="process === 1 || process === 2 || process === 3">
          <detail-payment-component
            @next="process = $event"
            @errorNewDirection="errorNewDirection = $event"
            @purchaseData="purchaseData = $event"
            @errorProduct="errorProduct = $event"
            @terminos="terminos = $event"
            :process="process"
            :dataCifImpuesto="dataCifImpuesto"
            :dataTarifa="dataTarifa"
            :typeDirection="typeDirection"
            :dataDirection="dataDirection"
            :dataPayment="dataPayment"
            :typeService="typeService"
            :stateAuth="stateAuth"
            :currencySymbol="currencySymbol"
            :dataDirectionName="dataDirectionName"
            :existForm="existForm"
          />
        </span>

        <!-- Información de envio -->
        <information-component
          v-if="process === 1 || process === 2 || process === 3"
          :store="store"
        />
        <!-- Solicitud recibida -->
        <thank-you-component
          v-if="process === 4"
          :purchaseData="purchaseData"
        />
      </div>
    </article>
  </section>
  <!-- inicio de sesión -->
  <login-component
    :estate="autentification.estateLogin"
    @close="autentification.estateLogin = $event"
    @changeView="changeView"
    @resetPassword="resetPassword = $event"
  />
  <!-- registro -->
  <register-component
    :estate="autentification.estateRegister"
    @close="autentification.estateRegister = $event"
  />
  <!-- Modal para pedir cambiar contraseña -->
  <reset-password-component
    @closeResetPassword="resetPassword = $event"
    :resetPassword="resetPassword"
  />

  <!-- footer -->
  <footer-component />
</template>
<script>
//Componentes
import headerComponent from "../components/header/headerComponent.vue";
import footerComponent from "../components/footer/footerComponent.vue";
import loginComponent from "../components/autentification/loginComponent.vue";
import registerComponent from "../components/autentification/registerComponent.vue";
import detailComponent from "../components/purchase/left/detailComponent.vue";
import directionComponent from "../components/purchase/left/directionComponent.vue";
import paymentComponent from "../components/purchase/left/paymentComponent.vue";
import resumenComponent from "../components/purchase/left/resumenComponent.vue";
import typeServiceComponent from "../components/purchase/right/typeServiceComponent.vue";
import detailPaymentComponent from "../components/purchase/right/detailPaymentComponent.vue";
import thankYouComponent from "../components/purchase/right/thankYouComponent.vue";
import informationComponent from "../components/purchase/right/informationComponent.vue";
import trackingComponent from "../components/tracking/modalComponent.vue";
import resetPasswordComponent from "../components/autentification/resetPasswordComponent.vue";
import cuponComponent from "../components/purchase/right/cuponesComponent.vue";
import balanceComponent from "../components/purchase/payment/balanceComponent.vue";
import servicioExtraComponent from "../components/purchase/right/servicioExtraComponent.vue";
//Importando serve
import { server } from "../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
import BalanceComponent from "../components/usuario/balanceComponent.vue";
import ServicioExtraComponent from "../components/purchase/right/servicioExtraComponent.vue";
export default {
  components: {
    headerComponent,
    footerComponent,
    loginComponent,
    registerComponent,
    detailComponent,
    directionComponent,
    paymentComponent,
    resumenComponent,
    typeServiceComponent,
    detailPaymentComponent,
    thankYouComponent,
    informationComponent,
    trackingComponent,
    resetPasswordComponent,
    cuponComponent,
    balanceComponent,
    BalanceComponent,
    servicioExtraComponent,
    ServicioExtraComponent,
  },
  data() {
    return {
      existForm: false,
      forceSearch: 1,
      terminos: false,
      view: {
        lite: "0.00",
        premium: "0.00",
      },
      store: "",
      resetPassword: false,
      newProductLink: this.$route.params.link,
      purchaseData: {},
      currencySymbol: "USD",
      typeService: 0,
      dataPayment: 0,
      autentification: {
        estateLogin: false,
        estateRegister: false,
        estateTracking: false,
      },
      errorNewDirection: {},
      errorProduct: {},
      dataDirection: {},
      dataDirectionName: {},
      process: 1,
      dataCifImpuesto: {
        product: {
          name: "",
          description: "",
          cantidad: 0,
          precio: 0.0,
          peso: 0.0,
          selectVariaton: null,
        },
        dolares: {
          cifGeneral: {
            cif: "0.00",
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
          },
          impuestoGeneral: {
            impuesto: "0.00",
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            estadoImpuesto: 1,
          },
        },
        soles: {
          cifGeneral: {
            cif: "0.00",
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
          },
          impuestoGeneral: {
            impuesto: "0.00",
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            estadoImpuesto: 1,
          },
        },
        sumaTramite: "0.00",
        margen: "0.00",
        service: {
          sumLite: "0.00",
          sumPremium: "0.00",
        },
      },
      typeDirection: 0,
      dataTarifa: {
        dolares: {
          tarifaServicio: "0.00",
          tramiteAduanero: "0.00",
          manipulacion: "0.00",
          doorTodoor: "0.00",
          envioNacional: "0.00",
        },
        soles: {
          tarifaServicio: "0.00",
          tramiteAduanero: "0.00",
          manipulacion: "0.00",
          doorTodoor: "0.00",
          envioNacional: "0.00",
        },
      },
      latitud: 0,
      longitud: 0,
      changeMoney: 0.0,
      idUser: null,
    };
  },
  mounted() {
    this.scrollToTop();
    this.calculateTarifaServicio();
  },
  created() {
    this.getMoney();
  },
  methods: {
    resetLink() {
      this.newProductLink = "";
    },
    //metodo para cambiar el formulario
    changeForm(data) {
      this.process = data;
      // this.dataPayment = 0;
    },
    //metodo para hallar tarifa de servicio
    calculateTarifaServicio() {
      //Si el tramite es menor de 10 toma 10;
      // var defaultTramite = 10;
      //declaracion de las variables
      var tarifa = 0.0;
      var tramite = 0.0;
      var manipulacion = 0.0;
      var door = 0.0;
      var envio = 0.0;
      //----------------------------------//
      if (this.typeDirection === 0) {
        envio = manipulacion + door + (18 / 100) * (10 + manipulacion + door);
        envio = Math.ceil(100 * envio) / 100;
        //tramite
        var data2 = parseFloat(this.dataCifImpuesto.sumaTramite);
        var margen2 = parseFloat(this.dataCifImpuesto.margen);

        tramite = (data2 + envio) * margen2 * 1.18;
        tramite = Math.ceil(100 * tramite) / 100;
        // if (tramite < 10) {
        //   tramite = 10;
        // }
        //Aplicando Cupon valido
        if (this.$store.state.cuponStatu === true) {
          if (
            this.dataCifImpuesto.dolares.impuestoGeneral.estadoImpuesto === 1
          ) {
            var resultDescuento1 = tramite - this.$store.state.cuponMonto;
            if (resultDescuento1 <= 0) {
              this.$store.state.cuponMonto = tramite;
              this.$store.state.cuponMontoSoles = (
                tramite * this.changeMoney
              ).toFixed(2);
            }
          } else {
            var valorDescontar1 = Math.ceil(
              0.01 * parseFloat(this.dataCifImpuesto.dolares.cifGeneral.precio)
            );
            if (valorDescontar1 < 1) {
              valorDescontar1 = 1;
            }
            this.$store.state.cuponMonto = valorDescontar1;
            this.$store.state.cuponMontoSoles =
              valorDescontar1 * this.changeMoney;
          }
        }
        //---------------------------//
        //operación para hallar el total
        //operación para hallar el total
        tarifa = envio + tramite;
        tarifa = Math.ceil(100 * tarifa) / 100;

        //almacenamos los resultados como sttring en nuestra variable del state "dataTarifa"
        //dolares
        this.dataTarifa.dolares.tarifaServicio = tarifa.toFixed(2);
        this.dataTarifa.dolares.tramiteAduanero = tramite.toFixed(2);
        this.dataTarifa.dolares.manipulacion = manipulacion.toFixed(2);
        this.dataTarifa.dolares.doorTodoor = door.toFixed(2);
        this.dataTarifa.dolares.envioNacional = envio.toFixed(2);
        //soles
        this.dataTarifa.soles.tarifaServicio = (
          tarifa * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.tramiteAduanero = (
          tramite * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.manipulacion = (
          manipulacion * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.doorTodoor = (door * this.changeMoney).toFixed(2);
        this.dataTarifa.soles.envioNacional = (
          envio * this.changeMoney
        ).toFixed(2);

        //Apartado para hallar el totaal para lite y premium solo para mostrar al usuario //
        //------------------------------------------------------------------------------//
        //Lite
        var dataLite = parseFloat(this.dataCifImpuesto.service.sumLite);
        var margenLite = parseFloat(this.dataCifImpuesto.margen);
        var tramiteLite = (dataLite + envio) * margenLite * 1.18;
        tramiteLite = Math.ceil(100 * tramiteLite) / 100;

        this.view.lite = tramiteLite.toFixed(2);

        //Premium
        var dataPremium = parseFloat(this.dataCifImpuesto.service.sumPremium);
        var margenPremium = parseFloat(this.dataCifImpuesto.margen);
        var tramitePremium = (dataPremium + envio) * margenPremium * 1.18;
        tramitePremium = Math.ceil(100 * tramitePremium) / 100;

        this.view.premium = tramitePremium.toFixed(2);

        //------------------------------------------------------------------------------//
      }
      if (this.typeDirection === 1) {
        axios
          .get(`${BASE_URL}` + "purchaseOrder/getDoorToDoor", {
            params: {
              latitud: this.latitud,
              longitud: this.longitud,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              door = response.data;
              door = Math.ceil(10 * door) / 10;
              //Manipulación
              manipulacion = 4;
              //envio nacional

              if (this.dataDirectionName.free === 1) {
                envio = 0.0;
              } else {
                envio =
                  manipulacion + door + (18 / 100) * (10 + manipulacion + door);
              }
              envio = Math.ceil(100 * envio) / 100;
              //tramite
              var data1 = parseFloat(this.dataCifImpuesto.sumaTramite);
              var margen = parseFloat(this.dataCifImpuesto.margen);

              tramite = (data1 + envio) * margen * 1.18;
              tramite = Math.ceil(100 * tramite) / 100;
              // if (tramite < 10) {
              //   tramite = 10;
              // }
              //Aplicando Cupon valido
              if (this.$store.state.cuponStatu === true) {
                if (
                  this.dataCifImpuesto.dolares.impuestoGeneral
                    .estadoImpuesto === 1
                ) {
                  var resultDescuento2 = tramite - this.$store.state.cuponMonto;
                  if (resultDescuento2 <= 0) {
                    this.$store.state.cuponMonto = tramite;
                    this.$store.state.cuponMontoSoles = (
                      tramite * this.changeMoney
                    ).toFixed(2);
                  }
                } else {
                  var valorDescontar2 = Math.ceil(
                    0.01 *
                      parseFloat(this.dataCifImpuesto.dolares.cifGeneral.precio)
                  );
                  if (valorDescontar2 < 1) {
                    valorDescontar2 = 1;
                  }
                  this.$store.state.cuponMonto = valorDescontar2;
                  this.$store.state.cuponMontoSoles =
                    valorDescontar2 * this.changeMoney;
                }
              }

              //operación para hallar el total
              tarifa = envio + tramite;
              tarifa = Math.ceil(100 * tarifa) / 100;
              //almacenamos los resultados como sttring en nuestra variable del state "dataTarifa"
              //dolares
              this.dataTarifa.dolares.tarifaServicio = tarifa.toFixed(2);
              this.dataTarifa.dolares.tramiteAduanero = tramite.toFixed(2);
              this.dataTarifa.dolares.manipulacion = manipulacion.toFixed(2);
              this.dataTarifa.dolares.doorTodoor = door.toFixed(2);
              this.dataTarifa.dolares.envioNacional = envio.toFixed(2);
              //soles
              this.dataTarifa.soles.tarifaServicio = (
                tarifa * this.changeMoney
              ).toFixed(2);
              this.dataTarifa.soles.tramiteAduanero = (
                tramite * this.changeMoney
              ).toFixed(2);
              this.dataTarifa.soles.manipulacion = (
                manipulacion * this.changeMoney
              ).toFixed(2);
              this.dataTarifa.soles.doorTodoor = (
                door * this.changeMoney
              ).toFixed(2);
              this.dataTarifa.soles.envioNacional = (
                envio * this.changeMoney
              ).toFixed(2);

              //Apartado para hallar el totaal para lite y premium solo para mostrar al usuario //
              //------------------------------------------------------------------------------//
              //Lite
              var dataLite1 = parseFloat(this.dataCifImpuesto.service.sumLite);
              var margenLite1 = parseFloat(this.dataCifImpuesto.margen);
              var tramiteLite1 = (dataLite1 + envio) * margenLite1 * 1.18;
              tramiteLite1 = Math.ceil(100 * tramiteLite1) / 100;

              this.view.lite = tramiteLite1.toFixed(2);

              //Premium
              var dataPremium1 = parseFloat(
                this.dataCifImpuesto.service.sumPremium
              );
              var margenPremium1 = parseFloat(this.dataCifImpuesto.margen);
              var tramitePremium1 =
                (dataPremium1 + envio) * margenPremium1 * 1.18;
              tramitePremium1 = Math.ceil(100 * tramitePremium1) / 100;
              this.view.premium = tramitePremium1.toFixed(2);
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al obtener DoorToDoor";
              this.$store.state.alert.estado = true;
              //--------------------------
            }
          });
      }
      if (this.typeDirection === 2) {
        if (this.latitud !== 0 && this.longitud !== 0) {
          axios
            .get(`${BASE_URL}` + "purchaseOrder/getDoorToDoor", {
              params: {
                latitud: this.latitud,
                longitud: this.longitud,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                door = response.data;
                door = Math.ceil(10 * door) / 10;
                //Manipulación
                manipulacion = 4;
                //envio nacional

                if (this.dataDirection.free === 1) {
                  envio = 0.0;
                } else {
                  envio =
                    manipulacion +
                    door +
                    (18 / 100) * (10 + manipulacion + door);
                }
                envio = Math.ceil(100 * envio) / 100;

                //tramite
                var data1 = parseFloat(this.dataCifImpuesto.sumaTramite);
                var margen = parseFloat(this.dataCifImpuesto.margen);
                tramite = (data1 + envio) * margen * 1.18;
                tramite = Math.ceil(100 * tramite) / 100;
                // if (tramite < 10) {
                //   tramite = 10;
                // }
                //Aplicando Cupon valido
                if (this.$store.state.cuponStatu === true) {
                  if (
                    this.dataCifImpuesto.dolares.impuestoGeneral
                      .estadoImpuesto === 1
                  ) {
                    var resultDescuento3 =
                      tramite - this.$store.state.cuponMonto;
                    if (resultDescuento3 <= 0) {
                      this.$store.state.cuponMonto = tramite;
                      this.$store.state.cuponMontoSoles = (
                        tramite * this.changeMoney
                      ).toFixed(2);
                    }
                  } else {
                    var valorDescontar3 = Math.ceil(
                      0.01 *
                        parseFloat(
                          this.dataCifImpuesto.dolares.cifGeneral.precio
                        )
                    );
                    if (valorDescontar3 < 1) {
                      valorDescontar3 = 1;
                    }
                    this.$store.state.cuponMonto = valorDescontar3;
                    this.$store.state.cuponMontoSoles =
                      valorDescontar3 * this.changeMoney;
                  }
                }
                //---------------------------//
                //operación para hallar el total
                //operación para hallar el total
                tarifa = envio + tramite;
                tarifa = Math.ceil(100 * tarifa) / 100;
                //almacenamos los resultados como sttring en nuestra variable del state "dataTarifa"
                //dolares
                this.dataTarifa.dolares.tarifaServicio = tarifa.toFixed(2);
                this.dataTarifa.dolares.tramiteAduanero = tramite.toFixed(2);
                this.dataTarifa.dolares.manipulacion = manipulacion.toFixed(2);
                this.dataTarifa.dolares.doorTodoor = door.toFixed(2);
                this.dataTarifa.dolares.envioNacional = envio.toFixed(2);
                //soles
                this.dataTarifa.soles.tarifaServicio = (
                  tarifa * this.changeMoney
                ).toFixed(2);
                this.dataTarifa.soles.tramiteAduanero = (
                  tramite * this.changeMoney
                ).toFixed(2);
                this.dataTarifa.soles.manipulacion = (
                  manipulacion * this.changeMoney
                ).toFixed(2);
                this.dataTarifa.soles.doorTodoor = (
                  door * this.changeMoney
                ).toFixed(2);
                this.dataTarifa.soles.envioNacional = (
                  envio * this.changeMoney
                ).toFixed(2);
                //Apartado para hallar el totaal para lite y premium solo para mostrar al usuario //
                //------------------------------------------------------------------------------//
                //Lite
                var dataLite2 = parseFloat(
                  this.dataCifImpuesto.service.sumLite
                );
                var margenLite2 = parseFloat(this.dataCifImpuesto.margen);
                var tramiteLite2 = (dataLite2 + envio) * margenLite2 * 1.18;
                tramiteLite2 = Math.ceil(100 * tramiteLite2) / 100;

                this.view.lite = tramiteLite2.toFixed(2);

                //Premium
                var dataPremium2 = parseFloat(
                  this.dataCifImpuesto.service.sumPremium
                );
                var margenPremium2 = parseFloat(this.dataCifImpuesto.margen);
                var tramitePremium2 =
                  (dataPremium2 + envio) * margenPremium2 * 1.18;
                tramitePremium2 = Math.ceil(100 * tramitePremium2) / 100;

                this.view.premium = tramitePremium2.toFixed(2);
              }
            })
            .catch((error) => {
              if (error) {
                //----------component--------//
                this.$store.state.alert.titulo = "Error";
                this.$store.state.alert.description =
                  "Error al obtener DoorToDoor";
                this.$store.state.alert.estado = true;
                //--------------------------
              }
            });
        }
      }
      if (this.typeDirection === 3) {
        //operación para hallar envio nacional
        envio = 0.0;
        // if (this.directionLocation === "lima") {
        //   envio = 0.0;
        // } else {
        //   envio = manipulacion + door + (18 / 100) * (10 + manipulacion + door);
        // }
        envio = Math.ceil(100 * envio) / 100;
        //tramite
        var data1 = parseFloat(this.dataCifImpuesto.sumaTramite);
        var margen = parseFloat(this.dataCifImpuesto.margen);
        tramite = (data1 + envio) * margen * 1.18;
        tramite = Math.ceil(100 * tramite) / 100;
        // if (tramite < 10) {
        //   tramite = 10;
        // }
        //Aplicando Cupon valido
        if (this.$store.state.cuponStatu === true) {
          if (
            this.dataCifImpuesto.dolares.impuestoGeneral.estadoImpuesto === 1
          ) {
            var resultDescuento4 = tramite - this.$store.state.cuponMonto;
            if (resultDescuento4 <= 0) {
              this.$store.state.cuponMonto = tramite;
              this.$store.state.cuponMontoSoles = (
                tramite * this.changeMoney
              ).toFixed(2);
            }
          } else {
            var valorDescontar4 = Math.ceil(
              0.01 * parseFloat(this.dataCifImpuesto.dolares.cifGeneral.precio)
            );
            if (valorDescontar4 < 1) {
              valorDescontar4 = 1;
            }
            this.$store.state.cuponMonto = valorDescontar4;
            this.$store.state.cuponMontoSoles =
              valorDescontar4 * this.changeMoney;
          }
        }
        //---------------------------//
        //operación para hallar el total
        tarifa = envio + tramite;
        tarifa = Math.ceil(100 * tarifa) / 100;
        //almacenamos los resultados como sttring en nuestra variable del state "dataTarifa"
        //dolares
        this.dataTarifa.dolares.tarifaServicio = tarifa.toFixed(2);
        this.dataTarifa.dolares.tramiteAduanero = tramite.toFixed(2);
        this.dataTarifa.dolares.manipulacion = manipulacion.toFixed(2);
        this.dataTarifa.dolares.doorTodoor = door.toFixed(2);
        this.dataTarifa.dolares.envioNacional = envio.toFixed(2);
        //soles
        this.dataTarifa.soles.tarifaServicio = (
          tarifa * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.tramiteAduanero = (
          tramite * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.manipulacion = (
          manipulacion * this.changeMoney
        ).toFixed(2);
        this.dataTarifa.soles.doorTodoor = (door * this.changeMoney).toFixed(2);
        this.dataTarifa.soles.envioNacional = (
          envio * this.changeMoney
        ).toFixed(2);
        //Apartado para hallar el totaal para lite y premium solo para mostrar al usuario //
        //------------------------------------------------------------------------------//
        //Lite
        var dataLite3 = parseFloat(this.dataCifImpuesto.service.sumLite);
        var margenLite3 = parseFloat(this.dataCifImpuesto.margen);
        var tramiteLite3 = (dataLite3 + envio) * margenLite3 * 1.18;
        tramiteLite3 = Math.ceil(100 * tramiteLite3) / 100;

        this.view.lite = tramiteLite3.toFixed(2);

        //Premium
        var dataPremium3 = parseFloat(this.dataCifImpuesto.service.sumPremium);
        var margenPremium3 = parseFloat(this.dataCifImpuesto.margen);
        var tramitePremium3 = (dataPremium3 + envio) * margenPremium3 * 1.18;
        tramitePremium3 = Math.ceil(100 * tramitePremium3) / 100;

        this.view.premium = tramitePremium3.toFixed(2);
      }
    },
    //Cambio de vista de login a registro
    changeView() {
      this.autentification.estateLogin = false;
      this.autentification.estateRegister = true;
    },
    //Metodo para obtener el el precio de la moneda para hacer la conversión a soles
    getMoney() {
      axios
        .get(`${BASE_URL}` + "exchange_rate/list")
        .then((response) => {
          if (response.status === 200) {
            this.changeMoney = response.data.sale;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener el valor de la moneda";
            this.$store.state.alert.estado = true;
            //--------------------------
          }
        });
    },
    //metodo para enviar la nueva url que el usuario mando
    newGetproduct() {
      if (this.newProductLink.length !== 0) {
        var urlRegex = /^(ftp|http|https):\/\/?www.[^ "]+$/;
        const result = urlRegex.test(this.newProductLink);
        if (result) {
          if (this.linkProduct === this.newProductLink) {
            this.forceSearch++;
          } else {
            this.$router.push({
              name: "Purchase",
              params: { link: this.newProductLink },
            });
          }
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese un link válido 'https://www.'o 'http://www.'";
          this.$store.state.alert.estado = true;
          //--------------------------
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el link de su producto";
        this.$store.state.alert.estado = true;
        //--------------------------
      }
    },
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      this.idUser = dataUser.globalID;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    stateAuth() {
      const data = localStorage.getItem("auth");
      if (data !== null) {
        this.getIdUser();
        return true;
      } else {
        return false;
      }
    },
    linkProduct() {
      return this.$route.params.link;
    },
  },
  watch: {
    "$store.state.cuponStatu": function (val) {
      if (val === true) {
        this.calculateTarifaServicio();
      }
    },
    process: function (val) {
      window.scrollTo(0, 0);
      if (val === 1 || val === 2) {
        this.currencySymbol = "USD";
      }
    },
    "dataCifImpuesto.product.cantidad": function () {
      this.calculateTarifaServicio();
    },
    "dataCifImpuesto.product.precio": function () {
      this.calculateTarifaServicio();
    },
    "dataCifImpuesto.product.peso": function () {
      this.calculateTarifaServicio();
    },
  },
};
</script>
