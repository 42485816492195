<template>
  <!-- Error 404 -->
  <div
    :class="error.token ? '' : 'oculto'"
    class="fixed inset-0 sm:py-8 bg-black bg-opacity-50 xl:bg-sky z-50 flex items-center justify-center"
  >
    <section
      class="u-container py-0 bg-white rounded-3xl sm:border sm:shadow-md grid"
    >
      <article
        class="px-8 py-12 xl:py-20 relative flex flex-col items-center justify-center"
      >
        <div class="sm:w-8/12 mx-auto">
          <img
            class="mx-auto mb-12"
            src="../assets/images/404.svg"
            alt="Icono de Envios USA"
          />
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-12 text-4xl">
            ¡Uy! Algo salió mal
          </h2>
          <h4 class="font-semibold text-center mb-12 text-2xl">
            {{ message }}
          </h4>
          <!-- BUTTON -->
          <button
            @click.prevent="generationToken"
            class="block py-5 px-20 xl:px-40 mx-auto bg-main-red font-semibold text-white rounded-2xl"
          >
            Volver a Generar Token
          </button>
        </div>
        <img
          @click.prevent="closeError"
          src="../assets/icons/exit.svg"
          class="absolute top-8 right-4 sm:top-8 sm:right-8 cursor-pointer"
          alt="Icono de Envios USA"
        />
      </article>
    </section>
  </div>

  <!-- Contraseña cambiada con exito -->
  <div
    :class="confirmReset ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="mx-4 px-8 xl:px-28 py-24 bg-white rounded-2xl border shadow-sm">
      <img
        width="124"
        height="124"
        class="mb-8 xl:mb-12 mx-auto"
        src="../assets/images/check-green.svg"
        alt="Icono de Envios USA"
      />
      <p class="text-gray-400 font-semibold text-lg text-center mb-10">
        ¡Tú contraseña se restableció <br />
        existosamente!
      </p>
      <button
        @click.prevent="generationToken"
        class="block w-full py-6 rounded-2xl bg-main-red text-white font-semibold"
      >
        Volver a la página de incio
      </button>
    </div>
  </div>
  <!-- RECUPERAR CONTRASEÑA 2 -->
  <div class="min-h-screen">
    <div
      id=""
      class="fixed h-screen inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
    >
      <div
        class="bg-gray-100 px-8 grid items-center justify-center w-full h-full xl:py-40 xl:px-32 xl:rounded-2xl relative"
      >
        <div>
          <h2 class="text-4xl text-center font-semibold mb-2">
            Recuperar contraseña
          </h2>
          <p class="mb-6 text-center">
            Enviaremos un enlace a tu correo electrónico
          </p>
          <form action="" class="">
            <!-- USUARIO -->
            <label class="relative grid items-center mb-5">
              <input
                v-model="data.email"
                :class="error.email ? 'ring ring-main-red' : ''"
                class="px-6 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
                type="text"
                placeholder="Correo electrónico"
              />
            </label>
            <label class="relative grid items-center mb-5">
              <img
                @click.prevent="changeInput(1)"
                src="../assets/icons/watch.svg"
                class="absolute right-6 top-7 cursor-pointer"
                alt="Icono de Envios USA"
              />
              <input
                :class="error.password ? 'ring ring-main-red' : ''"
                class="px-6 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
                :type="style.new ? 'text' : 'password'"
                placeholder="Nueva contraseña"
                v-model="data.password"
              />
            </label>
            <label class="relative grid items-center mb-5">
              <img
                @click.prevent="changeInput(2)"
                src="../assets/icons/watch.svg"
                class="absolute right-6 top-7 cursor-pointer"
                alt="Icono de Envios USA"
              />
              <input
                :class="error.confirmPassword ? 'ring ring-main-red' : ''"
                class="px-6 py-6 input w-full text-xs sm:text-sm font-medium bg-white border shadow-sm rounded-2xl"
                :type="style.old ? 'text' : 'password'"
                placeholder="Repita nueva contraseña"
                v-model="data.confirmPassword"
              />
            </label>
            <!-- BUTTONS -->
            <button
              @click.prevent="resetPassword"
              class="btn bg-main-red text-white w-full py-5 mb-4 font-bold rounded-2xl"
            >
              Guardar y continuar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { server } from "../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;
export default {
  components: {},
  data() {
    return {
      confirmReset: false,
      data: {
        token: this.$route.params.token,
        email: "",
        password: "",
        confirmPassword: "",
      },
      error: {
        token: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      style: {
        new: false,
        old: false,
      },
      message: "",
    };
  },
  mounted() {
    this.scrollToTop();

    window.localStorage.removeItem("open");
    window.localStorage.removeItem("producto");
  },
  created() {},
  methods: {
    changeInput(data) {
      if (data === 1) {
        this.style.new = !this.style.new;
      } else if (data === 2) {
        this.style.old = !this.style.old;
      }
    },
    inicio() {
      this.$router.push({ name: "Home" });
      this.error.token = false;
      this.error.email = false;
      this.error.password = false;
      this.error.confirmPassword = false;

      this.data.email = "";
      this.data.password = "";
      this.data.confirmPassword = "";
      this.message = "";
      this.confirmReset = false;
    },
    generationToken() {
      this.$router.push({ name: "Home" });
    },
    closeError() {
      this.error.token = false;
      this.error.token = false;
      this.error.email = false;
      this.error.password = false;
      this.error.confirmPassword = false;

      this.data.email = "";
      this.data.password = "";
      this.data.confirmPassword = "";
      this.message = "";
      this.confirmReset = false;
    },
    validateToken() {
      const data = this.data.token;
      if (data.length === 0) {
        this.error.token = true;
        this.message =
          "El token esta incompleto, vuelva a dar click al botón que le llegó al correo";
        return false;
      } else {
        this.message = "";
        return true;
      }
    },
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(data);
        if (resultado === true) {
          this.error.email = false;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo válido";
          this.$store.state.alert.estado = true;
          //--------------------------

          this.error.email = true;
          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo ";
        this.$store.state.alert.estado = true;
        //--------------------------
        this.error.email = true;
        return false;
      }
    },
    validatePassword() {
      const dataPassword = this.data.password;
      const confirmPassword = this.data.confirmPassword;

      if (dataPassword.length !== 0) {
        if (dataPassword.length >= 8) {
          if (dataPassword === confirmPassword) {
            this.error.password = false;
            this.error.confirmPassword = false;
            return true;
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Las contraseñas no coinciden";
            this.$store.state.alert.estado = true;
            //--------------------------

            this.error.password = true;
            this.error.confirmPassword = true;
            return false;
          }
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------

          this.error.password = true;
          return false;
        }
      } else {
        this.error.password = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su nueva contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------

        return false;
      }
    },
    resetPassword() {
      const valEmail = this.validateEmail();
      const valPassword = this.validatePassword();
      const valToken = this.validateToken();
      if (valEmail && valPassword && valToken) {
        axios
          .post(`${BASE_URL}` + "client/resetPassword", {
            token: this.data.token,
            email: this.data.email,
            password: this.data.password,
            password_confirmation: this.data.confirmPassword,
            store_id: 2,
          })
          .then((response) => {
            if (response.status === 200) {
              this.error.token = false;
              this.error.email = false;
              this.error.password = false;
              this.error.confirmPassword = false;

              this.data.email = "";
              this.data.password = "";
              this.data.confirmPassword = "";
              this.message = "";
              this.confirmReset = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.error.token = true;
              this.message = "El token ya expiró,vuelva a generar otro";
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Vuelva a intentarlo más tarde";
              this.$store.state.alert.estado = true;
              //--------------------------
            }
          });
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {},
};
</script>
