<template>
  <nav class="hidden xl:block py-10 bg-white min-h-screen">
    <router-link
      v-on:mouseenter="handleHover('perfil')"
      v-on:mouseleave="handleLeave()"
      :to="{ name: 'User', params: { type: 'perfil' } }"
      @click.prevent="changeView('perfil')"
      :class="
        formSelect === 'perfil' ? 'font-semibold bg-main-red text-white' : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'perfil'">
        <img src="../../assets/icons/perfil-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'perfil'"
          src="../../assets/icons/perfil-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/perfil.png" alt="" />
      </template>
      Mi Perfil
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('credenciales')"
      v-on:mouseleave="handleLeave()"
      :to="{ name: 'User', params: { type: 'credentials' } }"
      @click.prevent="changeView('credentials')"
      :class="
        formSelect === 'credentials' || formSelect === 'verification'
          ? 'font-semibold bg-main-red text-white'
          : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template
        v-if="formSelect === 'credentials' || formSelect === 'verification'"
      >
        <img src="../../assets/icons/credenciales-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'credenciales'"
          src="../../assets/icons/credenciales-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/credenciales.png" alt="" />
      </template>
      Credenciales
      <span
        v-if="dni === false"
        class="absolute top-5 right-8 w-7 h-7 rounded-full flex items-center justify-center text-main-red font-bold bg-white leading-8"
        >1</span
      >
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('direccion')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'direcciones' } }"
      @click.prevent="changeView('direcciones')"
      :class="
        formSelect === 'direcciones'
          ? 'font-semibold bg-main-red text-white'
          : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'direcciones'">
        <img src="../../assets/icons/direccion-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'direccion'"
          src="../../assets/icons/direccion-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/direccion.png" alt="" />
      </template>
      Mis direcciones
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('saldo')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'balance' } }"
      @click.prevent="changeView('balance')"
      :class="
        formSelect === 'balance' ? 'font-semibold bg-main-red text-white' : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'balance'">
        <img src="../../assets/icons/saldo-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'saldo'"
          src="../../assets/icons/saldo-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/saldo.png" alt="" />
      </template>
      Mi saldo
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('cupones')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'cupones' } }"
      @click.prevent="changeView('cupones')"
      :class="
        formSelect === 'cupones' ? 'font-semibold bg-main-red text-white' : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'cupones'">
        <img src="../../assets/icons/cupones-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'cupones'"
          src="../../assets/icons/cupones-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/cupones.png" alt="" />
      </template>
      Cupones
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('cotizaciones')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'cotizaciones' } }"
      @click.prevent="changeView('cotizaciones')"
      :class="
        formSelect === 'cotizaciones'
          ? 'font-semibold bg-main-red text-white'
          : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'cotizaciones'">
        <img src="../../assets/icons/cotizaciones-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'cotizaciones'"
          src="../../assets/icons/cotizaciones-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/cotizaciones.png" alt="" />
      </template>
      Cotizaciones
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('pedidos')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'pedidos' } }"
      @click.prevent="changeView('pedidos')"
      :class="formSelect === 'pedidos' ? 'bg-main-red text-white' : ''"
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'pedidos'">
        <img src="../../assets/icons/pedidos-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'pedidos'"
          src="../../assets/icons/pedidos-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/pedidos.png" alt="" />
      </template>
      Mis pedidos
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('recomendados')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'recomendados' } }"
      @click.prevent="changeView('recomendados')"
      :class="
        formSelect === 'recomendados'
          ? 'font-semibold bg-main-red text-white'
          : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'recomendados'">
        <img src="../../assets/icons/recomendados-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'recomendados'"
          src="../../assets/icons/recomendados-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/recomendados.png" alt="" />
      </template>
      Afiliados
    </router-link>
    <router-link
      v-on:mouseenter="handleHover('viajes')"
      v-on:mouseleave="handleLeave"
      :to="{ name: 'User', params: { type: 'viajes' } }"
      @click.prevent="changeView('viajes')"
      :class="
        formSelect === 'viajes' ? 'font-semibold bg-main-red text-white' : ''
      "
      class="w-72 p-5 flex items-center gap-4 border-b hover:bg-main-red hover:text-white transition-colors"
    >
      <template v-if="formSelect === 'viajes'">
        <img src="../../assets/icons/viajes-white.png" alt="" />
      </template>
      <template v-else>
        <img
          v-if="this.data.targetHover == 'viajes'"
          src="../../assets/icons/viajes-white.png"
          alt=""
        />
        <img v-else src="../../assets/icons/viajes.png" alt="" />
      </template>
      Mis Viajes
    </router-link>
  </nav>
</template>
<script>
//Importando imagen por default
import imgDefault from "../../assets/images/user-default.svg";
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["formSelect", "img", "id", "dni"],
  data() {
    return {
      data: {
        img: "",
        imagePreview: "",
        targetHover: "test",
      },
    };
  },
  created() {
    this.asingData();
  },
  methods: {
    //Obteniendo el archivo del input file
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.updateImage();
      };
      reader.readAsDataURL(file);
    },
    //Servicio para actualizar la imagen
    updateImage() {
      axios
        .put(`${BASE_URL}` + "client/update-client-image/" + this.id, {
          profile_image: this.imagePreview,
        })
        .then((response) => {
          if (response.status === 200) {
            const dataId = response.data.body.id;
            const dataName =
              response.data.body.attributes.profile.attributes.name;
            const dataLastName =
              response.data.body.attributes.profile.attributes.surnames;
            const dataImage =
              response.data.body.attributes.profile.attributes.userable
                .attributes.profile_image_url;
            const dataLocal = JSON.parse(localStorage.getItem("auth"));
            const dni = response.data.body.attributes.validation.verified;
            const email = dataLocal.glovalEmail;
            const dataIdentificador = dataLocal.identificador;
            const type = dataLocal.type;
            const dataUser = {
              globalID: dataId,
              globalName: dataName,
              globalLastName: dataLastName,
              globalImage: dataImage,
              globalValidate: dni,
              glovalEmail: email,
              identificador: dataIdentificador,
              type: type,
            };
            const dataCompress = JSON.stringify(dataUser);
            localStorage.setItem("auth", dataCompress);
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al intentar cambiar la foto de Perfil";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //obteniendo los datos del localstorage para la imagen y datos del usuario
    asingData() {
      if (this.img === null) {
        this.data.img = imgDefault;
      } else {
        this.data.img = this.img;
      }
    },
    //Cambio de vista
    changeView(data) {
      this.$emit("cambio", data);
      this.$router.push({ name: "User", params: { type: data } });
    },
    //Cerrar sesión
    closeSesion() {
      window.localStorage.removeItem("producto");
      window.localStorage.removeItem("auth");
      window.localStorage.removeItem("direction");
      this.$router.push({ name: "Home" });
    },
    handleHover(target) {
      this.data.targetHover = target;
    },
    handleLeave() {
      this.data.targetHover = "";
    },
  },
};
</script>
