<template>
  <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
    <div class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">Pedidos</h3>
      <!-- Lista -->
      <header
        class="grid xl:grid-cols-8 gap-3 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
      >
        <p class="text-center xl:hidden">Dashboard</p>
        <p class="hidden xl:block col-span-1"></p>
        <p class="hidden xl:block col-span-3">Descripción</p>
        <p class="hidden xl:block col-span-1">Fecha</p>
        <p class="hidden xl:block col-span-1">Estado</p>
        <p class="hidden xl:block col-span-1">Monto</p>
        <p class="hidden xl:block col-span-1"></p>
      </header>
      <div v-for="(pedido, index) in dataPedidos" :key="pedido">
        <div
          class="relative grid grid-cols-12 gap-5 xl:gap-3 xl:grid-cols-8 p-5 border-b text-sm xl:text-base items-center"
        >
          <p class="col-span-2 xl:col-span-1">
            <img
              v-if="pedido.attributes.product_image_url !== null"
              class="w-16"
              :src="pedido.attributes.product_image_url"
              alt="Compras realizadas para Envios USA"
            /><img
              v-else
              class="w-16"
              src="../../assets/images/caja-paso-4.svg"
              alt="Compras realizadas para Envios USA"
            />
          </p>
          <p class="col-span-10 xl:col-span-3 col-start-3 xl:col-start-auto">
            <span
              :class="
                pedido.attributes.direct_type === 1
                  ? 'text-main-blue'
                  : 'text-main-orange'
              "
              class="text-xs font-semibold flex items-center gap-3 mb-2"
            >
              {{
                pedido.attributes.direct_type === 1
                  ? "SERVICIO LITE"
                  : "SERVICIO PREMIUM"
              }}
              <a
                :href="pedido.attributes.reference_link"
                target="_Blank"
                class="cursor-pointer"
                v-if="pedido.attributes.reference_link !== null"
              >
                <img src="../../assets/icons/ir-al-link.png" alt="" />
              </a>
            </span>
            <span class="line-clamp-2">{{
              pedido.attributes.product_info
            }}</span>
            <span
              :class="
                pedido.attributes.status == 10
                  ? 'text-main-red bg-main-red'
                  : 'text-main-green bg-main-green'
              "
              class="font-semibold text-xs px-2 py-1 rounded-xl bg-opacity-20"
            >
              {{ pedido.attributes.status == 10 ? "Cancelada" : "Activa" }}
            </span>
          </p>
          <p
            class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto flex xl:flex-col gap-4 xl:gap-2"
          >
            <span>
              <span class="text-xs font-semibold block">F. compra:</span>
              <span class="text-xs block">{{
                pedido.attributes.fechaCreada
              }}</span>
            </span>
            <span>
              <span class="text-xs font-semibold block">F. entrega:</span>
              <span class="text-xs block">{{
                pedido.attributes.fechaEntrega
              }}</span>
            </span>
          </p>
          <p class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto">
            <span class="flex gap-2 mb-3 items-center tooltip no-underline">
              <img src="../../assets/icons/icon-pago-status.png" alt="" />
              <span>
                <span
                  v-if="pedido.attributes.payed === 1"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  >PENDIENTE</span
                >
                <span
                  v-else-if="pedido.attributes.payed === 2"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  >EN PROCESO</span
                >
                <span
                  v-else-if="pedido.attributes.payed === 3"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-green bg-main-green bg-opacity-20"
                  >CONFIRMADO</span
                >
                <span
                  v-else-if="pedido.attributes.payed === 10"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-red bg-main-red bg-opacity-20"
                  >RECHAZADO"</span
                >
                <span
                  v-else-if="pedido.attributes.payed === 11"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  >RECLAMADO</span
                >
                <span
                  v-else-if="pedido.attributes.payed === 12"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  >RETORNADO</span
                ></span
              >
              <span class="tooltip-box">Estado de pago</span>
            </span>
            <span class="flex gap-3 items-center tooltip no-underline">
              <img src="../../assets/icons/icon-tracking-statu.png" alt="" />
              <span>
                <span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 1
                  "
                >
                  EN REVISIÓN</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 2
                  "
                >
                  COMPRADO</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 3
                  "
                >
                  PREPARANDO PARA ENVIAR</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 4
                  "
                >
                  LISTO PARA ENVIAR</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 5
                  "
                >
                  ENVIADO</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-orange bg-main-orange bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 6
                  "
                >
                  LISTO PARA ENTREGAR</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-green bg-main-green bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 7
                  "
                >
                  ENTREGADO</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-red bg-main-red bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 8
                  "
                >
                  SINIESTRADO</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-red bg-main-red bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 9
                  "
                >
                  EN PROCESO DE DEVOLUCIÓN</span
                ><span
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 10
                  "
                >
                  RETORNADO AL PROVEEDOR</span
                ></span
              >
              <span class="tooltip-box">Estado del tracking</span>
            </span>
          </p>
          <p class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto">
            <span class="font-semibold">
              <span class="text-main-red">{{
                pedido.attributes.currency
              }}</span>
              {{
                (
                  pedido.attributes.total_cost * pedido.attributes.exchange
                ).toFixed(2)
              }}
            </span>
          </p>
          <p
            class="absolute top-5 xl:top-auto right-5 xl:right-auto xl:relative col-span-10 xl:col-span-1 cursor-pointer"
            @click="showTooltip('tooltip-' + index)"
          >
            <button type="button" class="px-4">
              <img
                src="../../assets/icons/icon-tres-puntos.svg"
                class="cursor-pointer"
              />
              <span
                :id="'tooltip-' + index"
                class="oculto tooltipPedidos absolute bg-white border shadow-lg py-2 -top-2 -left-36 rounded-xl text-left transition-opacity z-10"
              >
                <router-link
                  :to="{ name: 'Detalle', params: { codeProduct: pedido.id } }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ver-detalles.png" alt="" />
                  Ver Detalles
                </router-link>
                <router-link
                  :to="{
                    name: 'Tracking',
                    params: { code: pedido.attributes.system_tracking_number },
                  }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ver-tracking.png" alt="" />
                  Ver Tracking
                </router-link>
                <button
                  v-if="
                    pedido.attributes.travel_client_id &&
                    !pedido.attributes.qualification
                  "
                  @click="
                    openModal(
                      pedido.attributes.travel_client_id,
                      pedido.attributes.client.id,
                      pedido.id
                    )
                  "
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/calificar.png" class="w-5" />
                  Calificar viajero
                </button>
                <button
                  v-else-if="pedido.attributes.qualification"
                  @click="
                    openFilledModal({
                      score: pedido.attributes.qualification.attributes.score,
                      review: pedido.attributes.qualification.attributes.review,
                    })
                  "
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/calificar.png" class="w-5" />
                  Ver calificación
                </button>

                <router-link
                  :to="{
                    name: 'Ayuda',
                  }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-main-blue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ayuda.png" class="w-5" />
                  Ayuda
                </router-link>
              </span>
            </button>
          </p>
        </div>
      </div>
      <!-- Sino tiene pedidos -->
      <p
        class="font-medium text-center pb-8 pt-14"
        v-if="dataPedidos.length === 0"
      >
        Tu historial de pedidos está vacío
      </p>
      <!-- Loader -->
      <p v-if="loading" class="font-medium text-center pb-8 pt-14">
        Cargando sus pedidos...
      </p>
      <!-- Pagination -->
      <div class="mt-12 flex justify-end items-center gap-12">
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
            dataTable.from
          }}</span>
          de
          {{ dataTable.page }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="dataTable.pastStatus"
            @click.prevent="getPedidosPast"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-left-bold.svg" />
          </figure>
          <figure
            v-if="dataTable.nextStatus"
            @click.prevent="getPedidosNext"
            class="cursor-pointer p-3 rounded-full bg-white shadow-md"
          >
            <img src="../../assets/icons/arrow-right-bold.svg" />
          </figure>
        </div>
      </div>
    </div>
  </section>

  <section
    id="calificar"
    @click.prevent="detectClick"
    :class="!estateModal && 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <article
      class="mx-4 px-4 xl:px-8 py-6 xl:py-12 bg-white rounded-2xl border shadow-sm w-full xl:w-1/3"
    >
      <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
        Calificar viajero
      </h3>
      <article class="flex items-center justify-center mb-4">
        <div>
          <img
            v-if="starActive >= 1 || starHoverActive >= 1"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
            @mouseleave="handleLeaveStar"
            @click="selectStar(1)"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
            @mouseover="handleHoverStar(1)"
          />
        </div>
        <div>
          <img
            v-if="starActive >= 2 || starHoverActive >= 2"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
            @mouseleave="handleLeaveStar"
            @click="selectStar(2)"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
            @mouseover="handleHoverStar(2)"
          />
        </div>
        <div>
          <img
            v-if="starActive >= 3 || starHoverActive >= 3"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
            @mouseleave="handleLeaveStar"
            @click="selectStar(3)"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
            @mouseover="handleHoverStar(3)"
          />
        </div>
        <div>
          <img
            v-if="starActive >= 4 || starHoverActive >= 4"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
            @mouseleave="handleLeaveStar"
            @click="selectStar(4)"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
            @mouseover="handleHoverStar(4)"
          />
        </div>
        <div>
          <img
            v-if="starActive >= 5 || starHoverActive >= 5"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
            @mouseleave="handleLeaveStar"
            @click="selectStar(5)"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
            @mouseover="handleHoverStar(5)"
          />
        </div>
      </article>
      <article class="relative mb-6">
        <textarea
          v-model="qualifications.data.description"
          :class="qualifications.error.description && 'ring ring-main-red'"
          class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
          placeholder="Excelente servicio, rápido y eficiente."
          rows="3"
        ></textarea>
        <p
          class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
        >
          Cuentanos que te parecio el servicio.
        </p>
      </article>
      <button
        @click="createCalification"
        class="border-2 border-main-green text-main-green hover:bg-main-green hover:text-white focus:bg-main-green focus:text-white transition-colors rounded-md p-4 font-semibold block w-full text-sm xl:text-base focus:outline-none"
      >
        Enviar
      </button>
    </article>
  </section>

  <section
    id="filledModal"
    @click.prevent="detectClick"
    :class="!stateFilledModal && 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <article
      class="mx-4 px-4 xl:px-8 py-6 xl:py-12 bg-white rounded-2xl border shadow-sm w-full xl:w-1/3"
    >
      <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
        Calificación de viajero
      </h3>
      <article class="flex items-center justify-center mb-4">
        <div>
          <img
            v-if="qualifications.dataFilled.score >= 1"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
          />
        </div>
        <div>
          <img
            v-if="qualifications.dataFilled.score >= 2"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
          />
        </div>
        <div>
          <img
            v-if="qualifications.dataFilled.score >= 3"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
          />
        </div>
        <div>
          <img
            v-if="qualifications.dataFilled.score >= 4"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
          />
        </div>
        <div>
          <img
            v-if="qualifications.dataFilled.score >= 5"
            src="../../assets/icons/calificado.png"
            class="cursor-pointer px-3 py-2"
          />
          <img
            v-else
            src="../../assets/icons/calificar.png"
            class="cursor-pointer px-3 py-2"
          />
        </div>
      </article>
      <article class="relative">
        <textarea
          v-model="qualifications.dataFilled.description"
          class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
          readonly
          rows="3"
        ></textarea>
        <p
          class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
        >
          Esta es la calificación que dejaste a este viajero
        </p>
      </article>
    </article>
  </section>
</template>

<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      loading: true,
      testId: 0,
      maxIndex: 0,
      minIndex: 0,
      dataPedidos: {},
      pageNext: 1,
      pagePast: 1,
      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },

      estateModal: false,
      starActive: 0,
      starHoverActive: 0,
      travel_client_id: null,
      emisor_id: null,
      order_id: null,
      qualifications: {
        data: {
          title: "Buen servicio",
          description: "",
        },
        error: {
          description: false,
        },
        dataFilled: {
          description: "TEST",
          score: 3,
        },
      },
      stateFilledModal: false,
    };
  },
  created() {
    this.getPedidosNext();
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    //Servicio para obtener los pedidos del cliente y dar la flecha siguiente
    getPedidosNext() {
      axios
        .get(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.id +
            `/list-orders-by-client?orderBy=created_at&length=${this.dataTable.pagination}&page=${this.pageNext}`
        )
        .then((response) => {
          this.loading = false;
          this.dataPedidos = response.data.data;
          this.dataTable.page = response.data.meta.last_page;
          this.dataTable.from = response.data.meta.current_page;
          this.dataTable.next = response.data.links.next;
          this.dataTable.past = response.data.links.prev;
          console.log(this.dataPedidos);

          // console.log(this.dataPedidos[0].qualification.attributes.review)
          // console.log(this.dataPedidos[1].qualification)

          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("&page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("&page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
          var max = this.maxIndex;
          var length = this.dataTable.pagination;

          this.dataPedidos.forEach((value, index) => {
            if (index === 0) {
              this.minIndex = max; // 0 4 8 12
            }

            max += 1;

            value.Idproducto = max;

            if (length === index + 1) {
              this.maxIndex = max; // 4 8 12 16
            }

            // let fechaCreada = new Date(value.attributes.created_at.date);
            let fechaCreada = new Date(value.attributes.order_create_date);
            let diaCreada = fechaCreada.getDate();
            let mesCreada = fechaCreada.getMonth() + 1;
            let yearCreada = fechaCreada.getFullYear();
            value.attributes.fechaCreada =
              diaCreada + " / " + mesCreada + " / " + yearCreada;

            let fechaEntrega = new Date(
              value.attributes.promised_delivery_date
            );
            let diaEntrega = fechaEntrega.getDate() + 1;
            let mesEntrega = fechaEntrega.getMonth() + 1;
            let yearEntrega = fechaEntrega.getFullYear();
            value.attributes.fechaEntrega =
              diaEntrega + " / " + mesEntrega + " / " + yearEntrega;
          });
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al listar sus pedidos";
            this.$store.state.alert.estado = true;
            //--------------------------//

            console.warn(error);
          }
        });
    },
    //Servicio para obtener los pedidos del cliente y dar la flecha de atras
    getPedidosPast() {
      axios
        .get(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.id +
            `/list-orders-by-client?orderBy=created_at&length=${this.dataTable.pagination}&page=${this.pagePast}`
        )
        .then((response) => {
          this.dataPedidos = response.data.data;
          this.dataTable.page = response.data.meta.last_page;
          this.dataTable.from = response.data.meta.current_page;
          this.dataTable.next = response.data.links.next;
          this.dataTable.past = response.data.links.prev;

          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("&page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("&page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
          var min = this.minIndex - this.dataTable.pagination; //0 - 4 - 8 - 12
          this.minIndex = min;
          var length = this.dataTable.pagination;

          this.dataPedidos.forEach((value, index) => {
            min += 1;
            value.Idproducto = min;

            if (length === index + 1) {
              this.maxIndex = min; // 4
            }

            let fechaCreada = new Date(value.attributes.order_create_date);
            let diaCreada = fechaCreada.getDate();
            let mesCreada = fechaCreada.getMonth() + 1;
            let yearCreada = fechaCreada.getFullYear();
            value.attributes.fechaCreada =
              diaCreada + " / " + mesCreada + " / " + yearCreada;

            let fechaEntrega = new Date(
              value.attributes.promised_delivery_date
            );
            let diaEntrega = fechaEntrega.getDate() + 1;
            let mesEntrega = fechaEntrega.getMonth() + 1;
            let yearEntrega = fechaEntrega.getFullYear();
            value.attributes.fechaEntrega =
              diaEntrega + " / " + mesEntrega + " / " + yearEntrega;
          });
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al listar sus pedidos";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    // Crear calificacion de viajero
    createCalification() {
      const API_URL = `${BASE_URL}qualification/create-qualification`;
      const DATA = {
        titulo: this.qualifications.data.title,
        comentario: this.qualifications.data.description,
        puntaje: this.starActive,
        emisor: this.emisor_id,
        receptor: this.travel_client_id,
        idorder: this.order_id,
        tipo: "Viajero",
      };

      // Validar que el campo descripcion no este vacio
      if (this.qualifications.data.description.trim() === "") {
        this.qualifications.error.description = true;
      } else {
        this.qualifications.error.description = false;
      }

      // Enviar data si el campo no esta vacio
      if (!this.qualifications.error.description) {
        axios
          .post(API_URL, DATA)
          .then((res) => {
            if (res.status === 200) {
              this.estateModal = false;
              this.starActive = 0;
              this.starHoverActive = 0;
              this.travel_client_id = null;
              this.emisor_id = null;
              this.qualifications.error.description = false;
              this.qualifications.data.description = "";

              //----------component--------//
              this.$store.state.alert.titulo = "Exito";
              this.$store.state.alert.description =
                "Calificación enviada con exito";
              this.$store.state.alert.estado = true;
              setTimeout(() => {
                location.reload();
              }, 700);
              //--------------------------//
            }
          })
          .catch((err) => {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al enviar calificación";
            this.$store.state.alert.estado = true;
            //--------------------------//
            console.warn(err);
          });
      }
    },

    showTooltip(idTooltip) {
      const tooltips = [...document.querySelectorAll(".tooltipPedidos")];
      const tooltipAbierto = tooltips.find(
        (tooltip) => !tooltip.matches(".oculto")
      );
      if (tooltipAbierto && !tooltipAbierto.matches(`#${idTooltip}`)) {
        tooltipAbierto.classList.add("oculto");
      }

      const tooltip = document.getElementById(idTooltip);
      tooltip.classList.toggle("oculto");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    openModal(id_receptor, id_emisor, id_order) {
      this.estateModal = true;
      this.travel_client_id = id_receptor;
      this.emisor_id = id_emisor;
      this.order_id = id_order;
    },
    openFilledModal({ score, review }) {
      this.stateFilledModal = true;
      this.qualifications.dataFilled.description = review;
      this.qualifications.dataFilled.score = score;
    },
    //Detecta si da click fuera del input para que se cierre
    detectClick(e) {
      const data = e.target.matches("#calificar, #filledModal");
      if (data) {
        this.estateModal = false;
        this.stateFilledModal = false;
        this.starActive = 0;
        this.starHoverActive = 0;
        this.travel_client_id = null;
        this.emisor_id = null;
        this.order_id = null;
        this.qualifications.error.description = false;
        this.qualifications.data.description = "";
      }
    },
    // Detecta el evento para mostrar la estrella en dorado cuando se hace hover
    handleHoverStar(star) {
      this.starHoverActive = star;
    },
    handleLeaveStar() {
      this.starHoverActive = 0;
    },
    selectStar(star) {
      this.starActive = star;
    },
  },
};
</script>
