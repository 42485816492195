<template>
  <!-- SECCION PASOS DE USO / SLIDER -->
  <section class="hidden u-container relative">
    <h2 class="font-medium text-xl xl:text-4xl mb-16 text-center">
      ¿Cómo comprar en EnvíosUsaPerú?
    </h2>
    <Splide :options="optionPasos">
      <SplideSlide class="grid xl:grid-cols-2 items-center gap-8">
        <aside class="order-2 xl:order-1">
          <figure class="">
            <img class="mx-auto" src="../../assets/images/pasos-de-uso.svg" />
          </figure>
          <div class="hidden gap-4 xl:w-10/12 xl:mx-auto">
            <span class="py-2 px-6 rounded-2xl bg-main-red"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
          </div>
        </aside>
        <article class="order-1 xl:order-2">
          <div
            class="flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-main-red bg-opacity-10"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-main-red text-sm"
              >
                1
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2 text-sm xl:text-base">
                ¿Qué deseas comprar?
              </h3>
              <p class="text-xs xl:text-sm">
                Busca el producto que quieres en la tienda donde deseas que se
                realice la compra.
              </p>
            </div>
          </div>
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                2
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                Siempre estamos listos para ayudarte
              </h3>
              <p class="text-sm">
                Copia el link del producto y pégalo en
                <span class="text-main-red font-medium">EnvíosUsaPerú</span>
                para recibir la cotización total.
              </p>
            </div>
          </div>
          <div class="hidden xl:grid grid-cols-12 items-center mb-8">
            <span
              class="mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                3
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">Realiza el pago</h3>
              <p class="text-sm">
                Aceptamos todas las tarjetas (Crédito y Débito), Paypal,
                depósito o transferencia bancaría.
              </p>
            </div>
          </div>
          <div class="hidden xl:grid grid-cols-12 items-center mb-8">
            <span
              class="mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                4
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                ¡Todo está listo! Espéralo en casa
              </h3>
              <p class="text-sm">
                Nos encargaremos de todo. No tendrás que hacer ningún trámite ni
                papeleo, solo esperar en la comodidad de tu casa.
              </p>
            </div>
          </div>
        </article>
      </SplideSlide>
      <SplideSlide class="grid xl:grid-cols-2 items-center gap-8">
        <aside class="order-2 xl:order-1">
          <figure class="">
            <img class="mx-auto" src="../../assets/images/pasos-de-uso-2.svg" />
          </figure>
          <div class="hidden gap-4 xl:w-10/12 xl:mx-auto">
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="py-2 px-6 rounded-2xl bg-main-red"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
          </div>
        </aside>
        <article class="order-1 xl:order-2">
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                1
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2 text-sm xl:text-base">
                ¿Qué deseas comprar?
              </h3>
              <p class="text-xs xl:text-sm">
                Busca el producto que quieres en la tienda donde deseas que se
                realice la compra.
              </p>
            </div>
          </div>
          <div
            class="flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-main-red bg-opacity-10"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-main-red text-sm"
              >
                2
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                Siempre estamos listos para ayudarte
              </h3>
              <p class="text-sm">
                Copia el link del producto y pégalo en
                <span class="text-main-red font-medium">EnvíosUsaPerú</span>
                para recibir la cotización total.
              </p>
            </div>
          </div>
          <div class="hidden xl:grid grid-cols-12 items-center mb-8">
            <span
              class="mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                3
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">Realiza el pago</h3>
              <p class="text-sm">
                Aceptamos todas las tarjetas (Crédito y Débito), Paypal,
                depósito o transferencia bancaría.
              </p>
            </div>
          </div>
          <div class="hidden xl:grid grid-cols-12 items-center mb-8">
            <span
              class="mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                4
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                ¡Todo está listo! Espéralo en casa
              </h3>
              <p class="text-sm">
                Nos encargaremos de todo. No tendrás que hacer ningún trámite ni
                papeleo, solo esperar en la comodidad de tu casa.
              </p>
            </div>
          </div>
        </article>
      </SplideSlide>
      <SplideSlide class="grid xl:grid-cols-2 items-center gap-8">
        <aside class="order-2 xl:order-1">
          <figure class="">
            <img class="mx-auto" src="../../assets/images/pasos-de-uso-3.svg" />
          </figure>
          <div class="hidden gap-4 xl:w-10/12 xl:mx-auto">
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="py-2 px-6 rounded-2xl bg-main-red"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
          </div>
        </aside>
        <article class="order-1 xl:order-2">
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                1
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2 text-sm xl:text-base">
                ¿Qué deseas comprar?
              </h3>
              <p class="text-xs xl:text-sm">
                Busca el producto que quieres en la tienda donde deseas que se
                realice la compra.
              </p>
            </div>
          </div>
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                2
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                Siempre estamos listos para ayudarte
              </h3>
              <p class="text-sm">
                Copia el link del producto y pégalo en
                <span class="text-main-red font-medium">EnvíosUsaPerú</span>
                para recibir la cotización total.
              </p>
            </div>
          </div>
          <div
            class="flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-main-red bg-opacity-10"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-main-red text-sm"
              >
                3
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">Realiza el pago</h3>
              <p class="text-sm">
                Aceptamos todas las tarjetas (Crédito y Débito), Paypal,
                depósito o transferencia bancaría.
              </p>
            </div>
          </div>
          <div class="hidden xl:grid grid-cols-12 items-center mb-8">
            <span
              class="mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                4
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                ¡Todo está listo! Espéralo en casa
              </h3>
              <p class="text-sm">
                Nos encargaremos de todo. No tendrás que hacer ningún trámite ni
                papeleo, solo esperar en la comodidad de tu casa.
              </p>
            </div>
          </div>
        </article>
      </SplideSlide>
      <SplideSlide class="grid xl:grid-cols-2 items-center gap-8">
        <aside class="order-2 xl:order-1">
          <figure class="">
            <img class="mx-auto" src="../../assets/images/pasos-de-uso-4.svg" />
          </figure>
          <div class="hidden gap-4 xl:w-10/12 xl:mx-auto">
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="w-4 rounded-full bg-gray-200"></span>
            <span class="py-2 px-6 rounded-2xl bg-main-red"></span>
          </div>
        </aside>
        <article class="order-1 xl:order-2">
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                1
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2 text-sm xl:text-base">
                ¿Qué deseas comprar?
              </h3>
              <p class="text-xs xl:text-sm">
                Busca el producto que quieres en la tienda donde deseas que se
                realice la compra.
              </p>
            </div>
          </div>
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                2
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                Siempre estamos listos para ayudarte
              </h3>
              <p class="text-sm">
                Copia el link del producto y pégalo en
                <span class="text-main-red font-medium">EnvíosUsaPerú</span>
                para recibir la cotización total.
              </p>
            </div>
          </div>
          <div
            class="hidden gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-gray-100"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-gray-400 text-sm"
              >
                3
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">Realiza el pago</h3>
              <p class="text-sm">
                Aceptamos todas las tarjetas (Crédito y Débito), Paypal,
                depósito o transferencia bancaría.
              </p>
            </div>
          </div>
          <div
            class="flex gap-7 xl:gap-0 xl:grid grid-cols-12 items-center mb-8"
          >
            <span
              class="xl:mx-auto col-span-2 w-12 h-12 flex items-center justify-center rounded-full bg-main-red bg-opacity-10"
            >
              <span
                class="text-white font-bold w-7 h-7 flex items-center justify-center rounded-full bg-main-red text-sm"
              >
                4
              </span>
            </span>
            <div class="col-span-10">
              <h3 class="font-semibold mb-2">
                ¡Todo está listo! Espéralo en casa
              </h3>
              <p class="text-sm">
                Nos encargaremos de todo. No tendrás que hacer ningún trámite ni
                papeleo, solo esperar en la comodidad de tu casa.
              </p>
            </div>
          </div>
        </article>
      </SplideSlide>
    </Splide>
  </section>
  <!-- SECCION PASOS DE USO / VIDEO -->
  <section class="relative">
    <article class="u-container z-10 relative">
      <h2 class="text-xl xl:text-4xl mb-8 xl:mb-16 text-center text-bg-black">
        ¿Cómo comprar en <b>EnvíosUsaPerú</b>?
      </h2>
      <div class="w-11/12 xl:w-8/12 mx-auto">
        <div style="padding: 56.25% 0 0 0; position: relative">
          <!-- <iframe src="https://player.vimeo.com/video/803218145?h=cc142821de&autoplay=0&title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> -->
          <iframe
            src="https://player.vimeo.com/video/804046498?h=8d3ebff2e4&autoplay=0&title=0&byline=0&portrait=0"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </article>
    <img
      src="../../assets/images/mosaico-azul.png"
      class="absolute inset-x-0 bottom-28 xl:inset-0 m-auto w-full object-cover"
    />
  </section>
</template>
<script>
export default {
  data() {
    return {
      optionPasos: {
        arrows: false,
        direction: "ttb",
        wheel: true,
        releaseWheel: true,
        perPage: 1,
        height: "600px",
        breakpoints: {
          640: {
            height: "400px",
          },
        },
      },
    };
  },
};
</script>
