<template>
  <!-- TRAKING MODAL -->
  <div
    id="tracking"
    @click.prevent="detectClick"
    :class="estate ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="px-8 py-12 bg-white rounded-2xl border shadow-sm">
      <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
        ¡Nunca pierdas de vista <br />
        tu producto!
      </h3>

      <!-- Departamento -->
      <label class="relative block mb-4 border shadow-sm rounded-2xl">
        <input
          :class="error.code ? 'ring ring-main-red' : ''"
          class="text-center rounded-2xl py-5 px-6 text-sm w-full font-semibold"
          type="text"
          v-model="data.code"
          placeholder="#A23598416"
        />
        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            @click.prevent="petitionValidateCode(data.code)"
            class="inline cursor-pointer"
            width="21"
            height="21"
            src="../../assets/icons/buscar.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>
      <button
        @click.prevent="petitionValidateCode(data.code)"
        class="rounded-2xl bg-main-red py-5 text-white font-bold block w-full"
      >
        Buscar
      </button>
    </div>
  </div>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["estate"],
  data() {
    return {
      data: {
        code: "",
      },
      error: {
        code: false,
      },
    };
  },
  methods: {
    //validar si el código existe
    petitionValidateCode(data) {
      const validateTracking = this.validateCode();
      if (validateTracking) {
        axios
          .get(`${BASE_URL}` + "package/" + data.trim() + "/getSystemTracks")
          .then((response) => {
            if (response.status === 200) {
              this.error.code = false;
              this.$router.push({
                name: "Tracking",
                params: { code: data },
              });
              this.error.code = false;
              this.data.code = "";
              this.$emit("closeTracking", false);
              this.$emit("codeTracking", data);
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.error.code = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "El código ingresado no existe";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              this.error.code = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Código inválido";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    // validar em campo del código
    validateCode() {
      const data = this.data.code;
      if (data.length !== 0) {
        this.error.code = false;
        return true;
      } else {
        this.error.code = true;

        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el código de tracking";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //Detecta si da click fuera del input para que se cierre
    detectClick(e) {
      const data = e.target.matches("#tracking");
      if (data) {
        this.error.code = false;
        this.data.code = "";
        this.$emit("closeTracking", false);
        document.body.classList.remove("overflow-y-hidden");
      }
    },
  },
};
</script>
