<template>
  <!-- Modal de cual es el peso -->
  <div
    :class="modalPeso ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative mx-4 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm"
    >
      <p class="font-semibold text-2xl xl:text-4xl text-center mb-8 xl:mb-16">
        ¿Cuál es el peso?
      </p>
      <!-- Imagenes -->
      <div class="flex items-center xl:gap-28 mb-6 w-full justify-between">
        <img
          src="../../../assets/images/producto-camara.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Envios USA"
        />
        <img
          src="../../../assets/images/la-caja.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Envios USA"
        />
        <img
          src="../../../assets/images/peso-completo.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Envios USA"
        />
      </div>
      <!-- Textos -->
      <div class="flex items-center justify-between mb-8">
        <h3 class="font-bold text-center text-xs xl:text-base">Tu producto</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">+</h3>
        <h3 class="font-bold text-center text-xs xl:text-base">La caja</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">=</h3>
        <h3 class="font-bold text-center text-xs xl:text-base text-main-red">
          El peso <br />
          completo
        </h3>
      </div>
      <button
        @click.prevent="changeModalPeso"
        class="mb-6 text-white bg-main-red font-bold text-sm xl:text-base py-6 w-full xl:w-3/5 block mx-auto rounded-2xl"
      >
        Aceptar
      </button>
      <p class="text-center text-sm xl:text-base">
        Considerar peso completo.
        <a
          href="https://enviosusaperu.freshdesk.com/a/solutions/articles/72000550873"
          target="_blank"
          class="underline font-semibold"
          >Más información</a
        >
        <img
          @click.prevent="changeModalPeso"
          src="../../../assets/icons/exit.svg"
          class="cursor-pointer absolute top-8 right-4 xl:right-8"
          alt="Icono de Envios USA"
        />
      </p>
    </div>
  </div>
  <!-- fin Modal de cual es el peso -->
  <preload-product-component :estado="preload.product" />
  <preload-error-component
    :estado="preload.error"
    :productoLink="productLink"
    @clodeErrorModal="preload.error = $event"
  />

  <div
    class="px-4 xl:px-9 py-4 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none xl:py-0"
  >
    <!-- Nombre del producto -->
    <div class="mb-8">
      <label
        for="nombre_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Nombre del producto</label
      >
      <input
        :class="errorProduct.name ? 'ring ring-main-red' : ''"
        class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        id="nombre_producto"
        type="text"
        placeholder="Ingresa el nombre del producto"
        v-model="data.name"
      />
    </div>
    <!-- Foto referencial / Si hay error -->
    <div
      v-if="detectError"
      style="max-height: 250px"
      class="relative flex items-center justify-center relative rounded-2xl bg-white border shadow-sm p-4 py-12 mb-24"
    >
      <span class="bg-white absolute px-2 -top-2 left-3 text-gray-400 text-xs"
        >Foto referencial (Opcional)</span
      >
      <label class="cursor-pointer">
        <img
          class="w-40 h-60 object-contain mx-auto"
          src="../../../assets/icons/camara.svg"
          v-if="data.imgInitial.length === 0"
        />
        <img
          v-else
          class="w-40 h-60 object-contain mx-auto"
          :src="data.imgInitial"
        />
        <input
          type="file"
          @change="previewImage"
          class="hidden"
          accept="image/png,image/jpeg,image/jpg"
        />
      </label>
      <img
        @click.prevent="cancelImage"
        class="absolute top-5 right-5 cursor-pointer"
        src="../../../assets/icons/exit.svg"
      />
      <div class="absolute inset-x-0 -bottom-10 flex justify-center">
        <div
          class="py-4 px-6 xl:py-5 xl:px-10 flex gap-6 border-2 shadow-sm rounded-2xl bg-white"
        >
          <button class="px-2 cursor-pointer">
            <img
              class="cursor-pointer transform hover:scale-90 transition-all"
              src="../../../assets/icons/icon-restar.svg"
              @click.prevent="cantidadStock(0)"
            />
          </button>
          <input
            class="w-16 text-center font-semibold text-2xl xl:text-3xl"
            type="number"
            v-model="data.stock"
            @change="validateCantidad"
            disabled
          />
          <button class="px-2 cursor-pointer">
            <img
              class="cursor-pointer transform hover:scale-90 transition-all"
              src="../../../assets/icons/icon-sumar.svg"
              @click.prevent="cantidadStock(1)"
            />
          </button>
        </div>
      </div>
    </div>
    <!-- Foto referencial / Si no hay error -->
    <div
      class="relative block border shadow-sm rounded-2xl py-5 px-16 mb-16"
      v-else
    >
      <span class="absolute -top-2 left-7 bg-white text-xs font-medium"
        >Fotos referencial</span
      >
      <img
        id="image-screen"
        class="h-40 mx-auto cursor-pointer mb-10 block transition-colors"
        :src="data.imgInitial"
        alt="Cotización de la compra en Envios USA"
      />

      <div class="mb-12 mx-auto" v-if="data.img.length !== 1">
        <splide :slides="data.img" :options="options">
          <splide-slide v-for="slide in data.img" :key="slide">
            <div>
              <!-- <img
                class="w-8/12 h-24 object-cover mx-auto cursor-pointer border-2 border-transparent hover:border-green-300 border-green-300 transition-all"
                @click.prevent="changeImage(slide)"
                :src="slide"
              /> -->
              <img
                class="max-w-full w-auto max-h-16 mx-auto cursor-pointer"
                @click.prevent="changeImage(slide)"
                :src="slide"
                alt="Productos en cotización de Envios USA"
              />
            </div>
          </splide-slide>
        </splide>
      </div>

      <div class="absolute inset-x-0 -bottom-10 flex justify-center">
        <div
          class="py-4 px-6 xl:py-5 xl:px-10 flex items-center gap-6 border-2 shadow-sm rounded-2xl bg-white"
        >
          <img
            class="cursor-pointer transform hover:scale-90 transition-all"
            src="../../../assets/icons/icon-restar.svg"
            alt="Icono de Envios USA"
            @click.prevent="cantidadStock(0)"
          />
          <input
            class="w-16 text-center font-semibold text-2xl xl:text-3xl"
            type="number"
            v-model="data.stock"
            @change="validateCantidad"
            disabled
          />
          <img
            @click.prevent="cantidadStock(1)"
            class="cursor-pointer transform hover:scale-90 transition-all"
            src="../../../assets/icons/icon-sumar.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </div>
    </div>
    <!-- CARACTERISTICAS -->
    <div class="mb-8" v-for="(variation, index) in variations" :key="variation">
      <label :for="index" class="block mb-2 font-semibold text-xs xl:text-sm">{{
        variation.variation
      }}</label>
      <div class="relative">
        <select
          :id="index"
          @change="detectPrice($event, index)"
          class="cursor-pointer p-5 input w-full text-sm xl:text-base font-semibold bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl focus:outline-none focus:ring focus:ring-blue-400"
        >
          <option selected hidden>Selecciona una opción</option>
          <option
            v-for="(variantes, index) in variation.product"
            :key="variantes"
            :value="index"
            :selected="variantes.check"
          >
            {{ variantes.title }}
          </option>
        </select>
        <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
          <img src="../../../assets/icons/drop-down-black.svg" />
        </div>
      </div>
    </div>
    <!-- Precio y Peso -->
    <div class="grid xl:grid-cols-2 gap-8 xl:gap-6 mb-8">
      <!-- Precio referencial -->
      <div>
        <label
          for="precio_referencial"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Precio referencial</label
        >
        <div class="relative">
          <div
            class="absolute left-6 inset-y-0 flex items-center font-bold text-main-red"
          >
            <span>USD</span>
          </div>
          <input
            :class="errorProduct.precio ? 'ring ring-main-red' : ''"
            id="precio_referencial"
            class="pl-16 p-6 input w-full font-semibold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            v-model="data.precio"
            @change="calculate"
            @keypress="onlyNumber"
            @keydown="precioNumber"
          />
          <span class="absolute bottom-2 left-6 text-xs text-gray-400"
            >Precio Max. USD 3000.00</span
          >
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center text-main-red"
          >
            <img
              id="popup-btn"
              src="../../../assets/icons/question.svg"
              @mouseover="style.popUp = true"
              @mouseleave="style.popUp = false"
            />
            <!-- POP UP -->
            <div
              :class="style.popUp ? '' : 'oculto'"
              class="z-10 absolute -top-16 xl:-top-20 -right-14 xl:-right-32 bg-main-red text-white text-xs xl:text-sm text-center p-4 rounded-2xl w-max transition-all delay-400"
            >
              Si el precio de tu producto excede el valor máximo,
              <span class="block">
                comunícate con atención al cliente para poder ayudarte
              </span>
              <span
                class="absolute -bottom-7 right-10 xl:right-28 w-14 h-8 triangle bg-main-red"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label for="peso" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Peso</label
        >
        <div class="relative">
          <input
            :class="errorProduct.peso ? 'ring ring-main-red' : ''"
            id="peso"
            class="p-6 input w-full font-bold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            placeholder="1.16"
            v-model="data.peso"
            @change="calculate"
            @keypress="onlyNumber"
            @keydown="precioNumber"
          />
          <div class="absolute right-6 inset-y-0 flex items-center font-bold">
            <span
              @change="calculate"
              @click.prevent="getLabelPeso('kg')"
              :class="style.kg ? 'text-main-red' : ''"
              class="cursor-pointer px-3 hover:text-main-red transition-colors"
              >kg</span
            >
            <span
              @change="calculate"
              @click.prevent="getLabelPeso('lb')"
              :class="style.lb ? 'text-main-red' : ''"
              class="cursor-pointer px-3 hover:text-main-red transition-colors border-l border-r"
              >lb</span
            >
            <span
              @change="calculate"
              @click.prevent="getLabelPeso('oz')"
              :class="style.oz ? 'text-main-red' : ''"
              class="cursor-pointer px-3 hover:text-main-red transition-colors border-r"
              >oz</span
            >
            <img
              @click.prevent="changeModalPeso"
              src="../../../assets/icons/icon-info.svg"
              class="pl-3 cursor-pointer"
              alt="Icono de Envios USA"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Categoria... -->
    <div
      :class="errorProduct.category ? 'ring ring-main-red' : ''"
      class="mb-8 transition-colors rounded-2xl"
    >
      <label class="block mb-2 font-semibold text-xs xl:text-sm"
        >Categoría</label
      >
      <div class="py-4 px-6 border shadow-sm rounded-2xl bg-white">
        <div class="xl:flex items-center justify-between">
          <div class="flex items-center gap-4">
            <img
              v-if="data.imgCategory === null || data.imgCategory.length === 0"
              src="../../../assets/icons/categoria.svg"
              alt="Categorías de Envios USA"
              class="w-14 xl:w-16"
            />
            <img
              v-else
              :src="data.imgCategory"
              class="w-14 xl:w-16"
              alt="Categorías de Envios USA"
            />
            <div>
              <p
                class="text-xs xl:text-sm leading-6 mb-1"
                v-if="listCategory.length === 0"
              >
                Cargando categoría ...
              </p>
              <p
                class="text-xs xl:text-sm leading-6 mb-1"
                v-else-if="data.pathCategory.length !== 0"
              >
                {{ data.pathCategory }}
                <span class="block font-bold text-sm xl:text-base">{{
                  data.category
                }}</span>
              </p>
              <p class="text-xs xl:text-sm leading-6 mb-1" v-else>
                Sin definir
              </p>

              <button
                v-if="listCategory.length !== 0"
                @click.prevent="moreCategory"
                type="button"
                class="block text-main-green font-semibold text-xs xl:text-sm"
              >
                Cambiar categoria
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Descripción del producto -->
    <div class="mb-8">
      <label
        for="descripcion_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Describe tu producto
        <span class="text-main-green">(Opcional)</span></label
      >
      <div class="relative">
        <textarea
          v-model="data.description"
          :class="errorProduct.description ? 'ring ring-main-red' : ''"
          id="descripcion_producto"
          class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
          placeholder="Talla, tamaño, capacidad, color, etc"
          rows="3"
        ></textarea>
        <p
          class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
        >
          Ayúdanos a elegir correctamente tu producto
        </p>
      </div>
    </div>
  </div>

  <!-- Modal para cambiar categoria -->
  <div
    :class="style.select ? '' : 'oculto'"
    class="py-8 px-4 flex items-center justify-center fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      :class="category.estado === 1 ? 'xl:py-20' : ' xl:pb-20  xl:pt-16 '"
      class="bg-sky py-6 xl:px-12 bg-white rounded-2xl border shadow-sm w-full xl:w-max"
    >
      <p class="px-6 text-sm text-main-red mb-10" v-if="category.estado === 0">
        <!-- Cat. 1er Grado
        <span class="text-textColor mx-2">></span>
        Cat. 2do Grado -->

        Inicio > {{ defaultPath }}
      </p>

      <header
        :class="category.estado === 1 ? 'xl:gap-60' : 'xl:gap-28'"
        class="px-6 flex items-center justify-between"
      >
        <h3
          :class="category.estado === 1 ? '' : 'flex items-center gap-4'"
          class="text-xl xl:text-2xl font-bold"
        >
          <span v-if="category.estado === 1"> Elegir categoria</span>
          <img
            @click.prevent="backCategory"
            src="../../../assets/icons/arrow-right-red.svg"
            class="cursor-pointer"
            v-if="category.estado === 0"
            alt="Icono de Envios USA"
          />

          <span v-if="category.estado === 0"> ¿Qué opción lo describe?</span>
        </h3>

        <button type="button" @click.prevent="moreCategory">
          <img src="../../../assets/icons/exit.svg" alt="Icono de Envios USA" />
        </button>
      </header>
      <hr
        :class="category.estado === 1 ? 'my-7' : 'mt-7 mb-8 '"
        class="mx-4 xl:mx-0"
      />
      <label class="relative block mb-10 px-4" v-if="category.estado === 1">
        <input
          v-on:keyup.enter="foundCategory"
          class="text-sm px-6 py-4 pr-14 border focus:border-blue-400 rounded-2xl shadow-md w-full transition-colors"
          placeholder="Buscar categoría"
          v-model="foundName"
        />
        <div class="absolute right-12 inset-y-0 flex items-center">
          <img
            v-if="foundName.length === 0"
            class="inline cursor-pointer"
            width="21"
            height="21"
            src="../../../assets/icons/buscar.svg"
            alt="Icono de Envios USA"
          />
          <img
            @click.prevent="resetFoundName"
            v-if="foundName.length !== 0"
            class="inline cursor-pointer"
            src="../../../assets/icons/exit.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>

      <div class="max-h-96 overflow-y-auto text-textColor mb-8">
        <span v-for="list in listCategory" :key="list">
          <span
            :class="list.style ? 'bg-main-red' : ''"
            @click.prevent="nextCategory(list)"
            v-if="list.selected !== true"
            class="flex items-center justify-between cursor-pointer py-2 px-6 bg-opacity-20 hover:bg-main-red hover:bg-opacity-20 font-semibold text-sm"
          >
            {{ list.name }}
            <img
              v-if="list.leaf === false"
              width="7"
              height="11"
              src="../../../assets/icons/arrow-right-bold.svg"
              alt="Icono de Envios USA"
            />
            <span
              v-if="list.leaf"
              class="block h-3 w-3 rounded-sm bg-gray-400 ring ring-gray-400 ring-offset-2 hover:ring-main-blue hover:bg-main-blue cursor-pointer transition-colors"
            ></span>
          </span>
        </span>
      </div>
      <p class="px-6 font-medium">
        Sí, no encuentras la categoría del producto, debes elegir <b>"Otros"</b>
      </p>
    </div>
  </div>
  <!-- Modal para confirmar categoria -->
  <div
    :class="confirCategory ? '' : 'oculto'"
    class="py-8 px-4 flex items-center justify-center fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="bg-sky py-6 xl:py-14 px-4 xl:px-12 bg-white rounded-2xl border shadow-sm w-full xl:w-max"
    >
      <header class="xl:px-6 flex items-center justify-between xl:gap-60">
        <h3 class="text-xl xl:text-2xl font-bold">Confirma la categoría</h3>
        <button type="button" @click.prevent="closeConfirmCategory">
          <img src="../../../assets/icons/exit.svg" alt="Icono de Envios USA" />
        </button>
      </header>
      <hr class="my-7 mx-4 xl:mx-0" />

      <div class="border shadow-sm rounded-2xl bg-white mb-8">
        <div
          class="p-4 xl:p-6 relative block border-b shadow-sm rounded-2xl xl:border-none xl:shadow-none"
        >
          <span class="absolute -top-2 left-5 bg-white text-xs font-medium"
            >Categoría</span
          >
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-5">
              <img
                v-if="defaulCategory.picture === null"
                src="../../../assets/icons/categoria.svg"
                class="w-14 xl:w-16"
                alt="Categorías de Envios USA"
              />
              <img
                v-else
                :src="defaulCategory.picture"
                class="w-14 xl:w-16"
                alt="Categorías de Envios USA"
              />
              <p class="text-xs xl:text-sm leading-5 xl:leading-6 mb-1">
                <span class="xl:block">
                  {{ defaulCategory.path }}
                </span>

                <span class="block font-bold text-sm xl:text-base">
                  {{ defaulCategory.name }}</span
                >
              </p>
            </div>

            <span
              @click.prevent="confirmNewCategory"
              :class="
                confirmCategorystyle
                  ? 'bg-main-blue ring-main-blue'
                  : 'ring-gray-400 bg-gray-400 '
              "
              class="hidden xl:block cursor-pointer h-3 w-3 rounded-sm ring ring-offset-2"
            ></span>
          </div>
        </div>
        <div
          @click.prevent="confirmNewCategory"
          class="cursor-pointer flex xl:hidden items-center gap-6 justify-center py-6 rounded-2xl"
        >
          <span
            :class="confirmCategorystyle ? 'text-main-blue' : 'text-gray-400'"
            class="block text-sm font-semibold"
            >Selecionar categoría</span
          >
          <span
            :class="
              confirmCategorystyle
                ? 'bg-main-blue ring-main-blue'
                : 'ring-gray-400 bg-gray-400 '
            "
            class="block h-3 w-3 rounded-sm ring ring-offset-2"
          ></span>
        </div>
      </div>
      <button
        v-if="confirmCategorystyle"
        @click.prevent="selectCategory(defaulCategory)"
        type="button"
        class="mb-7 block w-full py-5 text-center rounded-2xl bg-main-red text-white font-bold xl:text-xl"
      >
        Confirmar
      </button>
      <button
        v-else
        type="button"
        class="mb-7 block w-full py-5 text-center rounded-2xl bg-gray-400 text-white font-bold xl:text-xl cursor-not-allowed"
      >
        Confirmar
      </button>
      <button
        @click.prevent="categoryChange"
        type="button"
        class="block w-full text-center text-main-red font-bold xl:text-xl"
      >
        Cambiar categoría
      </button>
    </div>
  </div>
</template>

<script>
//importando componentes
import preloadProductComponent from "../../preload/preloadProductComponent.vue";
import preloadErrorComponent from "../../preload/preloadErrorComponent.vue";
//Importando server
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
export default {
  props: [
    "changeMoney",
    "newLinkProduct",
    "errorProduct",
    "typeService",
    "forceSearch",
  ],
  components: {
    preloadProductComponent,
    preloadErrorComponent,
    Splide,
    SplideSlide,
  },
  data() {
    return {
      variacionesSeleccionadas: null,
      detectError: false,
      modalPeso: false,
      //Datos para el manejo de las variaciones
      variations: [],
      //Almacena las combinaciones de las variaciones
      combination: [],
      //Variable que contiene las combinacioens que elige el usuario
      userCombination: [],
      //Varible que cuando es true es cuando encuentra combinaciones y puede darle a continuar
      combinationStatus: false,
      //Variable indica si trae variaciones o no para guardar en el localStorage
      variationStatus: false,
      //Esta variable almacena las variables padre para pode ser reutilziado
      categoryPrincipal: [],
      //
      confirmCategorystyle: false,
      //Esta variable almacena el key de la ultima categoria seleccionada para poder retroceder con el servicio.
      lastVariable: "",
      //--------//
      defaultPath: "",
      foundName: "",
      confirCategory: false,
      category: {
        estado: 1, //1->es el padre y 0->es el hijo
      },
      defaulCategory: {},
      options: {
        rewind: true,
        perPage: 3,
        perMove: 1,
        pagination: false,
        cover: true,
        autoplay: true,
        breakpoints: {
          1279: {
            perPage: 2,
            arrows: false,
          },
        },
      },
      productLink: this.$route.params.link,
      style: {
        popUp: false,
        kg: true,
        lb: false,
        oz: false,
        select: false,
      },
      data: {
        name: "",
        description: "",
        category: "",
        imgCategory: "",
        pathCategory: "",
        kyCategory: "",
        imgInitial: "",
        linkReference: "",
        img: [],
        stock: 1,
        stockMax: 0,
        precio: 0.0,
        peso: 0.0,
        labelPeso: "kg",
        store: "",
      },
      preload: {
        product: false,
        error: false,
      },
      listCategory: [],
      porcentaje: {
        porcentajeWarehouse: 0.0,
        porcentajeValorem: 0.0,
        porcentajeIgv: 0.0,
        porcentajeIpm: 0.0,
        margen: 0.0,
      },
      sku: "",
      idUsuario: null,
      idCotizacion: null,
    };
  },
  created() {
    this.getIdUser();
  },
  mounted() {
    window.scrollTo(0, 0);
    var producto = window.localStorage.getItem("producto");
    if (this.$route.query.sharedProduct !== "true") {
      if (producto === null) {
        this.getDetailProduct();
      } else {
        const dataProduct = JSON.parse(producto);
        this.data.name = dataProduct.name;
        this.data.imgInitial = dataProduct.imagen;
        this.data.img = dataProduct.imagenes;
        this.data.stock = dataProduct.stock;
        // this.data.stockMax = dataProduct.stock;
        this.data.precio = dataProduct.precio;
        this.data.peso = dataProduct.peso;
        this.data.labelPeso = dataProduct.unit;
        this.data.linkReference = dataProduct.linkReference;
        this.data.category = dataProduct.categoria;
        this.data.description = dataProduct.description;
        this.data.store = dataProduct.store;
        this.detectError = dataProduct.detectError;
        this.variations = dataProduct.variations;
        this.combination = dataProduct.combinations;
        this.variationStatus = dataProduct.estado;
        if (this.variationStatus) {
          this.combinationStatus = true;
          this.userCombination = new Array(parseInt(this.variations.length));
          this.variations.forEach((value1, index1) => {
            value1.product.forEach((value2, index2) => {
              if (value2.check) {
                this.userCombination[index1] = index2;
              }
            });
          });
        } else {
          this.combinationStatus = true;
        }

        this.data.kyCategory = dataProduct.kyCategory;
        if (this.data.kyCategory.length === 0) {
          this.changeCategory(this.data.name, false);
        }
        if (this.data.kyCategory.length !== 0) {
          this.changeCategory(this.data.kyCategory, true);
        }

        this.getLabelPeso(this.data.labelPeso);
        this.$emit("calculate");
        this.$emit("store", this.data.store);
        this.calculate();
      }
    } else {
      this.getDetailProduct();
      this.$router.replace({ query: undefined });
    }
  },
  methods: {
    createCotizacion() {
      axios
        .post(`${BASE_URL}` + "quotation/save", {
          item_url: this.productLink,
          product_image_url: this.data.img[0],
          product_name: this.data.name,
          price: this.data.precio,
          idUsuario: this.idUsuario,
          idStore: 2,
        })
        .then((response) => {
          this.idCotizacion = response.data.body.id;
        })
        .catch(() => {});
    },
    cancelImage() {
      this.data.imgInitial = "";
    },
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.data.imgInitial = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeModalPeso() {
      this.modalPeso = !this.modalPeso;
    },
    detectPrice(event, arreglo) {
      const indixeV = parseInt(event.target.value);
      this.userCombination[arreglo] = indixeV;
      let sku;
      this.combination.map((data) => {
        if (
          JSON.stringify(data.list) === JSON.stringify(this.userCombination)
        ) {
          sku = data.code;
          return sku;
        }
      });
      if (sku) {
        this.sku = sku;
        this.calculate();
        this.combinationStatus = true;
        this.productLink = "https://www.amazon.com/dp/" + this.sku;
        this.getDetailProduct();
      } else {
        this.combinationStatus = false;
      }
    },
    resetFoundName() {
      this.foundName = "";
    },
    confirmNewCategory() {
      this.confirmCategorystyle = !this.confirmCategorystyle;
    },
    backCategory() {
      axios
        .get(`${BASE_URL}` + "meli/getMeliParentCatsByKey", {
          params: {
            key: this.lastVariable,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.listCategory = response.data.data;

            if (response.data.parent !== null) {
              this.defaultPath = response.data.parent.path;
            } else {
              this.defaultPath = "";
            }

            if (response.data.parent !== null) {
              this.lastVariable = response.data.parent.key;
            } else {
              this.lastVariable = null;
            }

            if (response.data.parent === null) {
              this.category.estado = 1;
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al predecir la categoría";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    foundCategory() {
      axios
        .post(`${BASE_URL}` + "meli/getMeliCats", {
          title: this.foundName,
          key: false,
        })
        .then((response) => {
          if (response.status === 200) {
            response.data.forEach((value) => {
              if (value.selected) {
                this.defaulCategory = value;
                this.confirCategory = true;
                this.style.select = false;
              }
            });
            if (this.confirCategory === false) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "No se encontró la categoría";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al predecir la categoría";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    closeConfirmCategory() {
      this.confirCategory = false;
      this.style.select = false;
      document.body.classList.remove("overflow-y-hidden");
    },
    categoryChange() {
      this.confirCategory = false;
      this.style.select = true;

      this.category.estado = 1;
      this.listCategory = this.categoryPrincipal;
      this.confirmCategorystyle = false;
    },
    nextCategory(data) {
      if (data.leaf === false) {
        axios
          .get(`${BASE_URL}` + "meli/getMeliChindrenCatsByKey", {
            params: {
              key: data.key,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.defaultPath = response.data.parent.path;
              this.category.estado = 0;
              this.listCategory = response.data.data;
              this.lastVariable = response.data.parent.key;
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Seleccione la categoría";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
      if (data.leaf === true) {
        this.confirmCategorystyle = true;
        this.confirCategory = true;
        this.style.select = false;
        this.defaulCategory = data;
      }
    },
    moreCategory() {
      this.category.estado = 1;
      this.listCategory = this.categoryPrincipal;
      this.confirmCategorystyle = false;
      this.style.select = !this.style.select;
      if (this.style.select === true) {
        document.body.classList.add("overflow-y-hidden");
      }
      if (this.style.select === false) {
        document.body.classList.remove("overflow-y-hidden");
      }
    },
    selectCategory(data) {
      this.confirmCategorystyle = false;
      this.confirCategory = false;
      this.style.select = false;
      this.data.category = data.name;
      this.data.kyCategory = data.key;
      this.data.imgCategory = data.picture;
      this.data.pathCategory = data.path;
      this.porcentaje.porcentajeWarehouse = data.subpartida.seguro;
      this.porcentaje.porcentajeValorem = data.subpartida.ad_valorem;
      this.porcentaje.porcentajeIgv = data.subpartida.igv;
      this.porcentaje.porcentajeIpm = data.subpartida.ipm;
      this.porcentaje.margen = data.margin;
      this.calculate();
      this.$emit("calculate");
      this.style.select = false;
      document.body.classList.remove("overflow-y-hidden");
      this.saveLocalStorage();
    },
    //metodo para las categorias api
    changeCategory(data, status) {
      axios
        .post(`${BASE_URL}` + "meli/getMeliCats", {
          title: data,
          key: status,
        })
        .then((response) => {
          if (response.status === 200) {
            this.listCategory = response.data;
            this.categoryPrincipal = response.data;

            this.listCategory.forEach((value) => {
              if (value.selected) {
                this.data.category = value.name;

                this.data.kyCategory = value.key;

                this.data.imgCategory = value.picture;
                this.data.pathCategory = value.path;

                this.porcentaje.porcentajeWarehouse = value.subpartida.seguro;
                this.porcentaje.porcentajeValorem = value.subpartida.ad_valorem;
                this.porcentaje.porcentajeIgv = value.subpartida.igv;
                this.porcentaje.porcentajeIpm = value.subpartida.ipm;
                this.porcentaje.margen = value.margin;
                this.saveLocalStorage();
              }
            });
            this.calculate();
            this.$emit("calculate");
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Seleccione la categoría";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //Guardando los datos ne localStorage
    saveLocalStorage() {
      const data = {};
      data.name = this.data.name;
      data.imagen = this.data.imgInitial;
      data.imagenes = this.data.img;
      data.stock = this.data.stock;
      // data.stock = this.data.stockMax;
      data.precio = this.data.precio;
      data.peso = this.data.peso;
      data.unit = this.data.labelPeso;
      data.linkReference = this.data.linkReference;
      data.categoria = this.data.category;
      data.kyCategory = this.data.kyCategory;
      data.description = this.data.description;
      data.store = this.data.store;
      data.variations = this.variations;
      data.combinations = this.combination;
      data.estado = this.variationStatus;
      data.detectError = this.detectError;
      const dataCompress = JSON.stringify(data);
      window.localStorage.setItem("producto", dataCompress);
    },
    //validando la cantidad que el usuario ingresa defrente en el input
    validateCantidad() {
      const data = this.data.stock;
      const datamax = this.data.stockMax;
      const datamin = 1;
      for (var i = datamin; i <= datamax; i++) {
        if (i === data) {
          this.calculate();
        }
      }
    },
    //metodo de suma y resta respecto al stock
    cantidadStock(data) {
      const datamin = 1;
      if (data === 1) {
        if (this.data.stock < 100) {
          this.data.stock++;
          this.calculate();
        }
      }
      if (data === 0) {
        if (this.data.stock > datamin) {
          this.data.stock--;
          this.calculate();
        }
      }
    },
    //metodo para hallar el total de cif y impuestos tanto para dólares y soles
    calculate() {
      //------------------------------------------//
      //variables que dependen del tipo de categoría
      //------------------------------------------//

      var porcentajeTax = 7 / 100;
      var porcentajeWarehouse = this.porcentaje.porcentajeWarehouse;
      var porcentajeValorem = this.porcentaje.porcentajeValorem;
      var porcentajeIgv = this.porcentaje.porcentajeIgv;
      var porcentajeIpm = this.porcentaje.porcentajeIpm;
      //------------------------------------------//
      //variables necesarias para el calculo
      //------------------------------------------//
      var precio = parseFloat(this.data.precio);
      var cantidad = parseFloat(this.data.stock);
      if (isNaN(precio)) {
        precio = 0.0;
      }
      var peso = parseFloat(this.data.peso * cantidad);

      if (isNaN(peso)) {
        peso = 0.0;
      }

      var labelPeso = this.data.labelPeso;
      //------------------------------------------//
      //la conversión  para que el peso siempre sea en KG
      //------------------------------------------//
      if (labelPeso === "lb") {
        peso = peso * (1 / 2.2046);
      } else if (labelPeso === "oz") {
        peso = peso * (1 / 35.274);
      }
      //------------------------------------------//
      //-----------------CIF----------------------//
      //------------------------------------------//
      // precio
      var precioTotal = precio * cantidad;
      precioTotal = Math.ceil(100 * precioTotal) / 100;
      this.$store.state.servicePrecioProduct = precioTotal;

      // shipping
      var shipping = 0.0;
      // tax
      var tax = porcentajeTax * precioTotal + shipping;
      tax = Math.ceil(100 * tax) / 100;
      //warehouse
      var warehouse = porcentajeWarehouse * (precioTotal + shipping + tax);
      warehouse = Math.ceil(100 * warehouse) / 100;
      //flete
      // ->5 cantidades
      // ->1

      var flete = 0.0;

      if (cantidad === 1) {
        var flete1 = 15 * (peso / cantidad);
        flete1 = Math.ceil(10 * flete1) / 10;
        if (flete1 < 10) {
          flete1 = 10;
        }

        flete = flete1;
      } else {
        var fletefirst = 15 * (peso / cantidad);

        fletefirst = Math.ceil(10 * fletefirst) / 10;

        if (fletefirst < 10) {
          fletefirst = 10;
        }

        var fleteOthers = (cantidad - 1) * (peso / cantidad) * 15;
        fleteOthers = Math.ceil(10 * fleteOthers) / 10;
        if (fleteOthers < 10) {
          fleteOthers = 10;
        }

        flete = fletefirst + fleteOthers;
      }
      // ->4
      // 4 * peso * 15
      // mas de dos unidades

      // un solo resultado

      //total CIF -> flete + warehouse + tax + shipping  + precioTotal

      //Premium
      var totalCif = flete + warehouse + tax + shipping + precioTotal;
      totalCif = Math.ceil(100 * totalCif) / 100;

      //Lite
      var totalCifl = flete + warehouse;
      totalCifl = Math.ceil(100 * totalCifl) / 100;
      //------------------------------------------//
      //-----------------IMPUESTOS - "mayor a 200 se cobra impuestos"----------------------//
      //------------------------------------------//
      var valorem = 0.0;
      var igv = 0.0;
      var ipm = 0.0;
      // 1 -> si se cobrra impuesto y 0  no se cobra impueto
      var estadoImpuesto = 0;
      if (precioTotal > 200) {
        //estado del impuesto
        estadoImpuesto = 1;
        //Ad/Valorem
        valorem = porcentajeValorem * totalCif;
        valorem = Math.ceil(valorem);
        //Igv
        igv = porcentajeIgv * (valorem + totalCif);
        igv = Math.ceil(igv);
        //IPM
        ipm = porcentajeIpm * (valorem + totalCif);
        ipm = Math.ceil(ipm);
      }
      // Total de impuestos -> valorem + igv + ipm
      var totalImpuesto = valorem + igv + ipm;
      totalImpuesto = Math.ceil(100 * totalImpuesto) / 100;
      //------------------------------------------//
      var serviceSum = 0.0;
      //Servicio Premium

      if (this.typeService === 2) {
        serviceSum =
          precioTotal +
          shipping +
          tax +
          warehouse +
          flete +
          valorem +
          igv +
          ipm;
        serviceSum = Math.ceil(100 * serviceSum) / 100;
      }
      //Servicio lite
      if (this.typeService === 1) {
        serviceSum = warehouse + flete + valorem + igv + ipm;
        serviceSum = Math.ceil(100 * serviceSum) / 100;
      }

      var sumLite = 0.0;
      sumLite = warehouse + flete + valorem + igv + ipm;
      sumLite = Math.ceil(100 * sumLite) / 100;

      var sumPremium = 0.0;
      sumPremium =
        precioTotal + shipping + tax + warehouse + flete + valorem + igv + ipm;
      sumPremium = Math.ceil(100 * sumPremium) / 100;

      //Este emit llega a detalles de pago
      this.$emit("dataCifImpuesto", {
        product: {
          name: this.data.name,
          description: this.data.description,
          category: this.data.category,
          keyCateogry: this.data.kyCategory,
          cantidad: this.data.stock,
          precio: this.data.precio,
          peso: this.data.peso,
          unidad: this.data.labelPeso,
          img: this.data.imgInitial,
          linkReference: this.data.linkReference,
          combination: this.combinationStatus,
          selectVariaton: this.variacionesSeleccionadas,
          idCotizacion: this.idCotizacion,
        },
        dolares: {
          cifGeneral: {
            cif2: totalCifl.toFixed(2),
            cif: totalCif.toFixed(2),
            precio: precioTotal.toFixed(2),
            shipping: shipping.toFixed(2),
            tax: tax.toFixed(2),
            warehouse: warehouse.toFixed(2),
            flete: flete.toFixed(2),
          },
          impuestoGeneral: {
            impuesto: totalImpuesto.toFixed(2),
            valorem: valorem.toFixed(2),
            igv: igv.toFixed(2),
            ipm: ipm.toFixed(2),
            estadoImpuesto: estadoImpuesto,
          },
        },
        soles: {
          cifGeneral: {
            cif2: (totalCifl * this.changeMoney).toFixed(2),
            cif: (totalCif * this.changeMoney).toFixed(2),
            precio: (precioTotal * this.changeMoney).toFixed(2),
            shipping: (shipping * this.changeMoney).toFixed(2),
            tax: (tax * this.changeMoney).toFixed(2),
            warehouse: (warehouse * this.changeMoney).toFixed(2),
            flete: (flete * this.changeMoney).toFixed(2),
          },
          impuestoGeneral: {
            impuesto: (totalImpuesto * this.changeMoney).toFixed(2),
            valorem: (valorem * this.changeMoney).toFixed(2),
            igv: (igv * this.changeMoney).toFixed(2),
            ipm: (ipm * this.changeMoney).toFixed(2),
            estadoImpuesto: estadoImpuesto,
          },
        },
        sumaTramite: serviceSum.toFixed(2),
        margen: this.porcentaje.margen.toFixed(2),
        service: {
          sumLite: sumLite.toFixed(2),
          sumPremium: sumPremium.toFixed(2),
        },
      });
    },
    //obtiene la unidad del peso
    getLabelPeso(data) {
      if (data === "kg") {
        this.data.labelPeso = "kg";
        this.style.kg = true;
        this.style.lb = false;
        this.style.oz = false;
      }
      if (data === "lb") {
        this.data.labelPeso = "lb";
        this.style.kg = false;
        this.style.lb = true;
        this.style.oz = false;
      }
      if (data === "oz") {
        this.data.labelPeso = "oz";
        this.style.kg = false;
        this.style.lb = false;
        this.style.oz = true;
      }
      this.calculate();
    },

    //consumiendo el servicio para obtener los detalles del producto

    getDetailProduct() {
      this.preload.product = true;
      window.localStorage.removeItem("producto");
      document.body.classList.add("overflow-y-hidden");
      axios
        .get(`${BASE_URL}` + "product/getDetailByZyte", {
          params: {
            url: this.productLink,
            idUsuario: this.idUsuario,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            document.body.classList.remove("overflow-y-hidden");
            //Datos de la tienda
            this.data.store = response.data.body.store;
            //
            this.preload.product = false;
            this.data.name =
              response.data.body.title === null ? "" : response.data.body.title;

            this.data.img = response.data.body.images;
            this.data.imgInitial = response.data.body.images[0];
            this.data.stockMax = response.data.body.stock;
            this.data.precio = response.data.body.original_price;
            this.data.peso = response.data.body.weight;
            this.data.labelPeso = response.data.body.weight_unit;
            this.data.linkReference = response.data.body.reference_link;
            //Secciòn para almacenar las variaciones
            if (response.data.body.detailOptions !== null) {
              this.variations = response.data.body.detailOptions.variations;
              this.combination = response.data.body.detailOptions.variationsMap;
              this.variationStatus = true;
              this.combinationStatus = true;
              this.userCombination = new Array(
                parseInt(this.variations.length)
              );

              //Rellenamos los que ya estan deleccionados
              this.variations.forEach((value1, index1) => {
                value1.product.forEach((value2, index2) => {
                  if (value2.check) {
                    this.userCombination[index1] = index2;
                  }
                });
              });

              //-----------------
            } else {
              this.combinationStatus = true;
              this.variationStatus = false;
              this.variations = [];
            }

            //-----------------------------------------
            this.changeCategory(this.data.name, false);
            this.calculate();
            this.saveLocalStorage();
            this.getLabelPeso(this.data.labelPeso);
            this.$emit("calculate");
            this.createCotizacion();
          }
        })
        .catch((error) => {
          if (error) {
            //metodo que se encarga de resetear todos los campos
            //---------------------------------------------------//
            var storeNameLink = this.productLink.split(".", 2);
            this.data.store = storeNameLink[1];
            this.detectError = true;
            this.combinationStatus = true;
            this.changeCategory(this.data.name, false);
            document.body.classList.remove("overflow-y-hidden");
            this.preload.error = true;
            this.preload.product = false;
            this.resetDataError();
          }
        });
    },
    resetDataError() {
      this.data.imgInitial = "";
      this.data.linkReference = this.productLink;
      this.data.name = "";
      this.data.description = "";
      this.variations = [];
      this.data.precio = 0.0;
      this.data.peso = 0.0;
      this.saveLocalStorage();
    },
    // metodo para cambiar la imagen principal
    changeImage(data) {
      this.data.imgInitial = data;
    },
    precioNumber($event) {
      const val = $event.target.value;
      if (val.includes(".")) {
        const dotIndex = val.indexOf(".");
        const valLength = val.length;
        const diferencia = valLength - dotIndex;
        if (
          diferencia > 2 &&
          $event.key !== "Backspace" &&
          $event.key !== "Tab"
        ) {
          $event.preventDefault();
        }
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    //Obtenemos el ID del usuario
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data !== null) {
        this.idUsuario = dataUser.globalID;
      } else {
        this.idUsuario = null;
      }
    },
  },
  watch: {
    idCotizacion: function () {
      this.calculate();
    },
    variations: function (val) {
      this.variacionesSeleccionadas = null;
      var dataSelect = [];

      val.forEach((value1, index1) => {
        this.userCombination.forEach((value2, index2) => {
          if (index1 === index2) {
            dataSelect.push(
              value1.variation + "->" + value1.product[value2].title + "%0A"
            );
          }
        });
      });
      this.variacionesSeleccionadas = dataSelect.join("");
    },

    forceSearch: function () {
      this.getDetailProduct();
      this.calculate();
    },
    "data.stock": function () {
      this.saveLocalStorage();
    },
    "data.store": function () {
      this.$emit("store", this.data.store);
    },
    "data.imgInitial": function () {
      this.saveLocalStorage();
      this.calculate();
    },
    "data.precio": function () {
      this.calculate();
      this.saveLocalStorage();
    },
    "data.labelPeso": function () {
      this.calculate();
      this.saveLocalStorage();
    },
    "data.peso": function () {
      this.calculate();
      this.saveLocalStorage();
    },
    "data.description": function () {
      this.calculate();
      this.saveLocalStorage();
    },
    "data.name": function () {
      this.calculate();
      this.saveLocalStorage();
    },
    "$route.params.link": function (newVal) {
      if (this.$route.name === "Purchase") {
        this.productLink = newVal;
        this.getDetailProduct();
        location.reload();
      }
    },
    typeService: function () {
      this.calculate();
      this.$emit("calculate");
    },
  },
};
</script>
