<template>
  <preload-component :preload="preload" />
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../assets/icons/tarjeta-credito.svg" />
          <span>
            Tarjeta de crédito/débito
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga con tarjetas nacionales</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //Cuando El monto es diferente de Cero -->
  <span v-else>
    <div
      :class="typePayment ? 'ring-main-green' : 'ring-gray-100'"
      class="text-text-blue relative ring hover:ring-main-green shadow-sm rounded-2xl mb-8"
    >
      <div
        @click.prevent="selectPayment"
        :class="typePayment && 'border-b border-main-green'"
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../assets/icons/tarjeta-credito.svg" />
          <span>
            Tarjeta de crédito/débito
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga con tarjetas nacionales</span
            >
          </span>
        </p>
        <span
          :class="
            typePayment
              ? 'bg-main-green text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="transition-colors font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
        >
          {{ typePayment ? "Seleccionado" : "Seleccionar" }}
        </span>
      </div>
      <!-- Formulario de mercado pago -->
      <form
        :class="formulario && typePayment ? '' : 'hidden'"
        class="px-2 py-6"
        id="form-checkout"
      >
        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/tarjetas-de-credito-gray.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardNumber"
            name="cardNumber"
            v-model="tarjeta.number"
          />
          <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
            <img
              v-if="tipoTarjeta.length !== 0"
              class="w-6"
              :src="tipoTarjeta"
              alt="Envios USA - tipo de tarjeta"
            />
          </div>
        </label>

        <div class="grid grid-cols-3 gap-3">
          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../assets/icons/calendario.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__cardExpirationMonth"
              name="cardExpirationMonth"
              v-model="tarjeta.mes"
              maxlength="2"
            />
          </label>

          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../assets/icons/calendario.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__cardExpirationYear"
              name="cardExpirationYear"
              v-model="tarjeta.year"
              maxlength="2"
            />
          </label>

          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../assets/icons/tarjeta-outline-gray.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__securityCode"
              name="securityCode"
              v-model="tarjeta.cvv"
              maxlength="3"
            />
            <div
              class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
            >
              <img
                @mouseover="style.popUp = true"
                @mouseleave="style.popUp = false"
                class="cursor-pointer"
                src="../../../assets/icons/question.svg"
                alt="Icono de Envios USA"
              />

              <!-- POPUP -->
              <span
                :class="style.popUp ? '' : 'oculto'"
                class="z-10 absolute bottom-16 -right-12 bg-main-red p-4 rounded-2xl w-max transition-all delay-400"
              >
                <img
                  class="w-32 mx-auto"
                  src="../../../assets/images/CCV.svg"
                  alt="Icono de Envios USA"
                />
                <span class="text-xs text-white text-center block">
                  El código de seguridad está en la <br />
                  parte posterior de tu tarjeta
                </span>
                <span
                  class="absolute -bottom-7 right-8 w-14 h-8 triangle bg-main-red"
                ></span>
              </span>
            </div>
          </label>
        </div>
        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/user-outline.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardholderName"
            name="cardholderName"
            v-model="tarjeta.titular"
          />
        </label>

        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/mail.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardholderEmail"
            name="cardholderEmail"
            v-model="tarjeta.email"
          />
        </label>

        <label class="relative mb-3 xl:mb-5 hidden">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../assets/icons/mail.svg"
              alt="Icono de Envios USA"
            />
          </div>
          <select
            name="issuer"
            id="form-checkout__issuer"
            class="cursor-pointer pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
          ></select>
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center font-bold text-main-red"
          >
            <img
              src="../../../assets/icons/drop-down-black.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </label>

        <div class="grid grid-cols-2 gap-3">
          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../assets/icons/dni.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <select
              name="identificationType"
              id="form-checkout__identificationType"
              class="cursor-pointer pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            ></select>
            <div
              class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
            >
              <img
                src="../../../assets/icons/drop-down-black.svg"
                alt="Icono de Envios USA"
              />
            </div>
          </label>
          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../assets/icons/tarjeta-outline-gray.svg"
                alt="Icono de Envios USA"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__identificationNumber"
              name="identificationNumber"
              v-model="tarjeta.documento"
            />
          </label>
        </div>

        <label class="relative block mb-8">
          <select
            name="installments"
            id="form-checkout__installments"
            class="cursor-pointer p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl text-main-green"
          ></select>
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center font-bold text-main-red"
          >
            <img
              src="../../../assets/icons/drop-down-black.svg"
              alt="Icono de Envios USA"
            />
          </div>
        </label>

        <div
          v-if="
            tarjeta.number.length === 0 ||
            tarjeta.mes.length === 0 ||
            tarjeta.year.length === 0 ||
            tarjeta.cvv.length === 0 ||
            tarjeta.titular.length === 0 ||
            tarjeta.email.length === 0 ||
            tarjeta.documento.length === 0
          "
          @click.prevent="validatePaymentForm(1)"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-gray-400 cursor-pointer"
        >
          Pagar
        </div>
        <div
          v-else-if="terminos === false"
          @click.prevent="validatePaymentForm(2)"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-gray-400 cursor-pointer"
        >
          Pagar
        </div>
        <button
          v-else
          type="submit"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-main-green"
          id="form-checkout__submit"
        >
          Pagar
        </button>
      </form>

      <!--------->
      <div class="absolute inset-x-0 -bottom-4">
        <figure
          @click.prevent="openForm"
          class="cursor-pointer mx-auto w-8 h-8 rounded-full bg-white border-2 shadow-lg flex items-center justify-center"
        >
          <img
            :class="formulario ? '' : 'transform rotate-180'"
            src="../../../assets/icons/drop-up-blue.svg"
            alt="Icono de Envios USA"
          />
        </figure>
      </div>
    </div>
  </span>
</template>
<script>
//Importando componentes
import preloadComponent from "../../preload/preloadComponent.vue";
//Importando serve
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  components: { preloadComponent },
  props: [
    "dataCifImpuesto",
    "dataTarifa",
    "typeDirection",
    "dataDirection",
    "typeService",
    "currencySymbol",
    "paymenSelect",
    "terminos",
  ],
  data() {
    return {
      preload: false,
      idUser: 0,
      typeCard: "",
      tipoTarjeta: "",
      style: {
        popUp: false,
      },
      formulario: false,
      tarjeta: {
        number: "",
        mes: "",
        year: "",
        cvv: "",
        titular: "",
        email: "",
        documento: "",
      },
    };
  },
  mounted() {
    const formularioMercado = this.mercadoPago();
    formularioMercado.mount();
  },
  unmounted() {
    const formularioMercado = this.mercadoPago();
    formularioMercado.unmount();
  },
  created() {
    this.getIdUser();
  },
  methods: {
    validatePaymentForm(data) {
      if (data === 1) {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Rellenar todo el formulario";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
      if (data === 2) {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Aceptar términos y condiciones";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
    },
    //Metodo de Mercado Paog
    mercadoPago() {
      const mpMercado = window.mp;
      const cardForm = mpMercado.cardForm({
        amount: this.totalPaymentSoles,
        autoMount: false,
        form: {
          id: "form-checkout",
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular de la tarjeta",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número de la tarjeta",
          },
          cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "MM",
          },
          cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "AA",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "CVV",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Cuotas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número de documento",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emisor",
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn("Form Mounted handling error: ", error);
          },
          onFormUnmounted: (error) => {
            if (error)
              return console.warn("Form Unmounted handling error: ", error);
          },
          onPaymentMethodsReceived: (error, paymentMethods) => {
            if (error)
              return console.warn("paymentMethods handling error: ", error);
            this.typeCard = paymentMethods[0].payment_type_id;
            this.tipoTarjeta = paymentMethods[0].thumbnail;
          },
          onSubmit: (event) => {
            event.preventDefault();
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData();
            const mercadoPago = JSON.stringify({
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: this.dataCifImpuesto.product.name,
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            });
            const data = JSON.parse(mercadoPago);
            this.preparationObjectPurchase(data);
          },
        },
      });
      return cardForm;
    },
    // preparación del objeto para crear la orden
    preparationObjectPurchase(data) {
      var objetoOrden = new Object();
      //Datos del producto
      objetoOrden.product_info = this.dataCifImpuesto.product.name;
      objetoOrden.product_specimens = this.dataCifImpuesto.product.cantidad;
      objetoOrden.price = this.dataCifImpuesto.product.precio;
      objetoOrden.weight = this.dataCifImpuesto.product.peso;
      objetoOrden.weight_unit_key = this.dataCifImpuesto.product.unidad;
      //Tiempo de tienda - envios usa o sendi
      objetoOrden.store = "enviosusa";
      if (this.dataCifImpuesto.product.selectVariaton !== null) {
        objetoOrden.variations = this.dataCifImpuesto.product.selectVariaton;
      }
      // Tipo de moneda
      objetoOrden.currency = this.currencySymbol;
      //-------------------------//
      this.typeService === 1
        ? (objetoOrden.direct_type = 1)
        : (objetoOrden.direct_type = 2);

      //-------------------------//
      objetoOrden.product_image_url = this.dataCifImpuesto.product.img;
      //-------------------------//
      objetoOrden.reference_link = this.dataCifImpuesto.product.linkReference;

      if (this.$store.state.cuponStatu === true) {
        objetoOrden.coupon_code = this.$store.state.cuponCode;
      }

      if (this.$store.state.saldoStatu) {
        objetoOrden.balance_flag = true;
      } else {
        objetoOrden.balance_flag = false;
      }

      //Si el usuario agrego oytro tipo de servicio
      //Si el usuario agrego oytro tipo de servicio
      if (this.servicioElegido.length !== 0) {
        var idService = "";
        this.servicioElegido.forEach((value, index) => {
          if (index === 0) {
            idService = idService.concat(value.id);
          } else {
            idService = idService.concat("," + value.id);
          }
        });

        objetoOrden.extra_service_id = idService;
      }

      //Metodo de Pago - 3 si es pago con tarjeta

      objetoOrden.payment_method_flag = 3;

      // si recoge en oficina es true por lo contrario false
      this.typeDirection === 3
        ? (objetoOrden.at_office_flag = true)
        : (objetoOrden.at_office_flag = false);
      //aqui se manda el vaucher
      // si la dirección es una existente
      if (this.typeDirection === 1) {
        objetoOrden.shipping_address_id = this.dataDirection;
      }
      // si es una nueva dirección
      if (this.typeDirection === 2) {
        objetoOrden.shipping_address_name = this.dataDirection.direccion;
        objetoOrden.shipping_address_telephone = this.dataDirection.telefono;
        objetoOrden.shipping_address_reference = this.dataDirection.referencia;
        objetoOrden.shipping_address_inner = this.dataDirection.piso;
        objetoOrden.shipping_address_contact_name = this.dataDirection.nombre;
        objetoOrden.shipping_address_contact_lastname =
          this.dataDirection.apellido;
        objetoOrden.shipping_address_document_number =
          this.dataDirection.documento;
        objetoOrden.shipping_address_document_type = 1;
        objetoOrden.shipping_address_district = this.dataDirection.distrito;
        objetoOrden.shipping_address_city = this.dataDirection.provincia;
        objetoOrden.shipping_address_department =
          this.dataDirection.departamento;
        objetoOrden.shipping_address_alias = "alias";
        objetoOrden.shipping_address_lat = this.dataDirection.lat;
        objetoOrden.shipping_address_lng = this.dataDirection.lng;
      }
      //Datos por pagar con mercado pago
      objetoOrden.mp_payment_from = 1;
      objetoOrden.mp_token_card = data.token;
      objetoOrden.mp_payment_method_id = data.payment_method_id;
      objetoOrden.mp_payer_email = data.payer.email;
      // objetoOrden.mp_customer_id = test.issuer_id;
      objetoOrden.mp_payment_type = this.typeCard;
      objetoOrden.mp_installments = data.installments;
      objetoOrden.mp_issuer_id = data.issuer_id;
      this.createOrder(objetoOrden);
    },
    //Servicio para crear la orden
    createOrder(objetoOrden) {
      this.preload = true;

      axios
        .post(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.idUser +
            "/createDirectOrderByClient",
          objetoOrden
        )
        .then((response) => {
          if (response.status === 200) {
            this.preload = false;
            const responseData = response.data.body;
            this.$emit("next", 4);
            this.$emit("purchaseData", responseData);
            this.$emit("paymentMercadoPago");
            window.localStorage.removeItem("producto");
            window.localStorage.removeItem("open");

            //Resetenado el store del precio
            this.$store.state.cuponStatu = null;
            this.$store.state.cuponCode = "";
            this.$store.state.cuponMonto = null;
            this.$store.state.cuponMontoSoles = null;
            this.$store.state.saldoStatu = false;
            this.$store.state.saldoMonto = 0.0;
            this.$store.state.saldoMontoSoles = 0.0;
            this.$store.state.saldoTotalStatu = false;
            this.$store.commit("resetServicios");
          }
        })
        .catch((error) => {
          if (error) {
            this.preload = false;
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "El pago no se realizó correctamente";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //Obtenemos el ID del usuario
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data !== null) {
        this.idUser = dataUser.globalID;
      }
    },
    // cambiar el estilo del formulario
    openForm() {
      this.formulario = !this.formulario;
      const select = 1;
      this.$emit("paymentSelect", select);
    },

    //Seleccionando el tipo de pago
    selectPayment() {
      const select = 1;

      this.$emit("paymentSelect", select);
    },
    changeFormulario() {
      this.formulario = true;
    },
    changeStatusTotalTrue() {
      this.$store.state.saldoTotalStatu = true;
    },
    changeStatusTotalFalse() {
      this.$store.state.saldoTotalStatu = false;
    },
  },
  computed: {
    servicePrecioSoles() {
      return this.$store.state.servicePrecioSoles;
    },
    servicioElegido() {
      return this.$store.state.servicioElegido;
    },
    montoCuponSoles() {
      return this.$store.state.cuponMontoSoles;
    },
    statusCupon() {
      return this.$store.state.cuponStatu;
    },
    totalPaymentSoles() {
      var total1 = 0.0;
      if (this.typeService === 2) {
        total1 = parseFloat(this.dataCifImpuesto.soles.cifGeneral.cif);
      }
      if (this.typeService === 1) {
        total1 = parseFloat(this.dataCifImpuesto.soles.cifGeneral.cif2);
      }
      var total2 = parseFloat(
        this.dataCifImpuesto.soles.impuestoGeneral.impuesto
      );
      var total3 = parseFloat(this.dataTarifa.soles.tarifaServicio);
      var total = total1 + total2 + total3;
      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioSoles;
      }
      total = Math.ceil(100 * total) / 100;
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMontoSoles;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.changeStatusTotalTrue();
        } else {
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }

      if (this.statusCupon === true) {
        total = total - this.montoCuponSoles;
      }

      return total.toFixed(2);
    },
    typePayment() {
      if (this.paymenSelect === 1) {
        this.changeFormulario();
        return true;
      } else {
        return false;
      }
    },
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
  watch: {
    totalPaymentSoles: function (val) {
      if (val) {
        this.$emit("ResetFormTarjeta");
      }
    },
  },
};
</script>
