<template>
  <div
    :class="directionType ? 'ring-main-green' : 'ring-gray-100'"
    @click.prevent="calculateDirection"
    class="relative ring hover:ring-main-green shadow-sm rounded-2xl"
  >
    <div
      :class="directionType && 'border-b border-main-green'"
      class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
    >
      <p class="text-text-blue text-sm xl:text-base">Recojo en Oficina</p>
      <span
        :class="
          directionType
            ? 'bg-main-green text-white'
            : 'bg-gray-light text-gray-400'
        "
        class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
      >
        {{ directionType ? "Seleccionado" : "Seleccionar" }}
      </span>
    </div>
    <div
      :class="!directionType && 'hidden'"
      class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
    >
      <div class="flex items-center gap-4 xl:gap-6">
        <img src="../../../assets/icons/oficina.svg" alt="" />
        <div>
          <p class="text-sm font-bold">Lima</p>
          <p class="text-xs text-gray-400">
            Jr. Emilio Althaus Nro. 121 Oficina 403
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["typeDirection", "existDirection"],
  data() {
    return {};
  },
  mounted() {
    this.detectUserDirection();
  },
  methods: {
    //Metodo que envia el tipo de dirección para el calculo de tarifa de servicio.
    //1->dirección existente
    //2->Nueva dirección
    //3->dirección default ->recogo en oficina
    calculateDirection() {
      const typeDirection = 3;
      this.$emit("dataTypeDirection", typeDirection);
      this.$emit("dataDirection", {});
    },
    detectUserDirection() {
      const direction = localStorage.getItem("direction");
      if (this.existDirection === false && direction === null) {
        this.calculateDirection();
        this.$emit("calDirection");
      }
    },
  },
  computed: {
    directionType() {
      if (this.typeDirection === 3) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
